import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Box, Button, Typography } from "@mui/material";
import Papa from "papaparse";
import { PORT } from "../../Api/api";
import "./entriesCsv.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Layout from "../../lib/Layout";
import { FaDownload } from "react-icons/fa";
import AddBackbtn from "../../lib/AddBackbtn";
import { ToastContainer, toast } from "react-toastify";
import { DataContext } from "../../lib/DataContext";
import { useContext } from "react";
import { doesNotMatch } from "assert";
import { LanguageContext } from "../../lib/LanguageContext";
const PurposeImportCsv = () => {
  const { addItem, removeItem, updateItem } = useContext(DataContext);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [entries, setEntries] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const createdBy = JSON.parse(localStorage.getItem("roleId"));
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const role_level = JSON.parse(localStorage.getItem("role")) || null;
  const { language } = useContext(LanguageContext);
  const fetchEntries = async () => {
    try {
      let response = await axios.get(`${PORT}/getEntries`);
      const filterData = response.data.data;
      const filterOccasionalEntries = filterData
        .filter((entry) => entry.defaultPermissionLevel === "1")
        .filter((entry) => entry.entryType === "Occasional");
      setEntries(filterOccasionalEntries);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchEntries();
  }, []);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setCsvData([]);
    setError("");

    if (!selectedFile) {
      setError("No file selected");
      return;
    }

    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    // Check if it's a valid CSV or Excel file
    if (
      fileExtension !== "csv" &&
      fileExtension !== "xlsx" &&
      fileExtension !== "xls"
    ) {
      setError("Only CSV or Excel files are allowed");
      setButtonDisabled(true);
      return;
    }

    if (fileExtension === "csv") {
      // Handle CSV parsing
      Papa.parse(selectedFile, {
        header: true,
        complete: (result) => {
          const data = result.data;
          const validData = data.filter(
            (row) => row.purpose && row.linkedEntry
          ); // Ensure both columns exist

          if (validData.length === 0) {
            setError(
              "Your CSV file is empty or missing required data (purpose & linkedEntry)"
            );
            setButtonDisabled(true);
          } else {
            setCsvData(validData);
            setError("");
            setButtonDisabled(false);
          }
        },
        error: (error) => {
          console.error("Error parsing CSV file:", error);
          setError("Error parsing CSV file");
          setCsvData([]);
          setButtonDisabled(true);
        },
      });
    } else {
      // Handle Excel parsing (XLSX / XLS)
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        // Assuming the first sheet contains the data we need
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Parse the data from the sheet
        const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // header: 1 means the first row is treated as headers
        // Check if the file has valid data
        const headers = excelData[0];
        const purposeIndex = headers.indexOf("purpose");
        const linkedEntryIndex = headers.indexOf("linkedEntry");

        if (purposeIndex === -1 || linkedEntryIndex === -1) {
          setError(
            "Excel file must contain 'purpose' and 'linkedEntry' columns"
          );
          setCsvData([]);
          setButtonDisabled(true);
        } else {
          const validData = excelData
            .slice(1)
            .filter((row) => row[purposeIndex] && row[linkedEntryIndex]);

          if (validData.length === 0) {
            setError(
              "Your Excel file is empty or missing required data (purpose & linkedEntry)"
            );
            setButtonDisabled(true);
          } else {
            // Convert the valid data into a format for display
            const formattedData = validData.map((row) => ({
              purpose: row[purposeIndex],
              linkedEntry: row[linkedEntryIndex],
            }));

            setCsvData(formattedData);
            setError("");
            setButtonDisabled(false);
          }
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading Excel file:", error);
        setError("Error reading the Excel file");
        setCsvData([]);
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || csvData?.length === 0) {
      setError("Please select a valid CSV or Excel file with data.");
      return;
    }

    const formData = new FormData();
    console.log(file, "FIle");
    formData.append("purposeCsvBySuperAdmin", file); // Send the file
    formData.append("createdBy", createdBy);
    formData.append("society_id", society_id);
    formData.append("purposeIcon", "");
    formData.append("defaultPermissionLevel", role_level);

    try {
      const response = await axios.post(
        `${PORT}/purposeImportFormCsvBySuperAdmin`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response.data.success) {
        addItem(response.data.result);
        setMessage(response.data.msg);
      } else {
        setMessage("Failed to import entries");
      }
      const res = response.data.msg;
      setMessage(res);
      setCsvData([]);
      setFile(null);
      document.querySelector('input[type="file"]').value = null; // Clear the file input field
      toast.success(res);
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("File upload failed");
    }
  };

  const handleDownload = () => {
    const headers = ["purpose"];

    // Mapping entries to the CSV data
    const data = entries.map((entry) => {
      return {
        linkedEntry: entry.titleEnglish, // Assuming 'titleEnglish' is the linked entry
      };
    });

    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(data, { header: headers });

    worksheet["A2"] = { v: "Celebrations and Special Occasions", t: "s" };
    worksheet["A3"] = { v: " Networking and Community Building", t: "s" };
    worksheet["A4"] = { v: "Entertainment and Recreation", t: "s" };

    worksheet["!cols"] = [{ wch: 20 }, { wch: 20 }];

    // Set row heights (if needed)
    worksheet["!rows"] = [{ hpt: 20 }];

    // Adjusting row heights (optional)
    const rowHeights = {};
    for (let i = 0; i < data.length; i++) {
      rowHeights[i + 1] = { hpx: 30 }; // Set height of all rows to 30px
    }

    // Applying row heights to the worksheet
    worksheet["!rows"] = rowHeights;
    rowHeights[0] = { hpx: 40 };

    // Generate Excel file (xlsx format)
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Purpose of Occasional ");
    // Export the workbook to Excel format
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Creating a Blob from the Excel file
    // Create a Blob from the Excel buffer
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate}`;

    // Save the Excel file
    saveAs(blob, `Purpose of Occasional${dateTime}.xlsx`);
  };

  return (
    <div>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? "Import Purpose of Occasional " : "मकान सूची आयात करें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="container-fluid py-4 ">
          <div className="row">
            <div className="col-12  col-margin_top">
              <div className="card mb-4">
                <div className="card-header pb-0 mt--5">
                  <div className="heading_import">
                    <h4></h4>
                    <button
                      className="buttom_download"
                      onClick={handleDownload}
                    >
                      <FaDownload />
                      Download Sample
                    </button>
                  </div>
                  <hr />
                  <div classNameName="error_msg_csv">
                    {error && (
                      <Typography id="modal-description" color="error">
                        <div
                          className="alerts
                    alerts-danger
                    "
                          role="alert"
                        >
                          {error}
                        </div>
                      </Typography>
                    )}
                  </div>
                  <div className="drag-file-area">
                    <span className="material-icons-outlined upload-icon">
                      {" "}
                      Purpose of Occasional Import{" "}
                    </span>
                    <h3 className="dynamic-message">
                      {" "}
                      Drag &amp; drop any file here{" "}
                    </h3>
                    <label className="label">
                      <span className="browse-files">
                        <div className="input_csv_input">
                          <input type="file" onChange={handleFileChange} />
                        </div>
                        <span className="browse-files-text">browse file</span>{" "}
                        <span>from device</span>{" "}
                      </span>{" "}
                    </label>
                  </div>
                  <span className="cannot-upload-message">
                    {csvData.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <h3 className="preview_heading_csv">
                          File Data Preview
                        </h3>
                        <table className="tabel_csv_data_preview">
                          <thead>
                            <tr>
                              <th>Purpose</th>
                              <th>Linked Entry</th>
                            </tr>
                          </thead>
                          <tbody>
                            {csvData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.purpose}</td>
                                <td>{row.linkedEntry}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    )}
                  </span>

                  {/* <br/> */}
                  <form onSubmit={handleSubmit}>
                    <div className="upload_button_div">
                      <button
                        disabled={buttonDisabled}
                        type="submit"
                        className="upload-button"
                      >
                        {" "}
                        Upload
                      </button>
                    </div>
                  </form>
                  {/* </div> */}
                  {csvData.length > 0 ? (
                    ""
                  ) : (
                    <div className="main-content mt-custom">
                      <div className="title">
                        Sample Purpose of Occasional - CSV
                      </div>
                      <div className="cell-content">
                        <div>fx</div>
                        <div></div>
                      </div>
                      <div className="purpose_cells">
                        <div className="cells__spacer"></div>
                        <div className="cells__alphabet">A</div>
                        <div className="cells__alphabet">B</div>
                        {/* <div className="cells__alphabet">B</div>
                      <div className="cells__alphabet">C</div> */}

                        <div className="cells__number">1</div>
                        <div className="cells__number">2</div>
                        <div className="cells__number">3</div>
                        <div className="cells__number">4</div>
                        <div className="cells__number">5</div>
                        <div className="cells__number">6</div>
                        <div className="cells__number">7</div>
                        <div className="cells__number">8</div>
                        <div className="cells__number">9</div>
                        <div className="cells__number">10</div>
                        <div className="cells__number">11</div>
                        <div className="cells__number">12</div>
                        <div className="cells__number">13</div>
                        <div className="cells__number">14</div>
                        <div className="cells__number">15</div>
                        <div className="cells__input">Purpose</div>
                        <div className="cells__input">Linked Entry</div>
                        <div className="cells__input">
                          Celebrations and Special Occasions
                        </div>
                        <div className="cells__input">Doctor</div>

                        <div className="cells__input">
                          Entertainment and Recreation
                        </div>
                        <div className="cells__input">Courier</div>

                        <div className="cells__input">
                          Networking and Community Building
                        </div>
                        <div className="cells__input">Plumber</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </div>
  );
};

export default PurposeImportCsv;
