import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { PORT } from "../../../../Api/api";
import { LanguageContext } from "../../../../lib/LanguageContext";
import SmallLoader from "../../../../Loading/SmallLoader";
import decodeToken from "../../affiliateLib/decodeToken/DecodeToken";
const AffiliateTopSocieties = () => {
  const [topSocieties, setTopSocieties] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("January 2025");
  const [availableMonths, setAvailableMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // Get token and decode it
  const token = localStorage.getItem("token");
  const decodeTokenData = decodeToken(token);
  const id = decodeTokenData.id;
  const { language } = useContext(LanguageContext);
  const getTopSocieties = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${PORT}/getAffiliateTopBillingSociety/${id}`
      );
      if (response.data.success) {
        const months = Object.keys(response.data.monthly);
        setAvailableMonths(months);

        const societiesData = response.data.monthly[selectedMonth].map(
          (society, index) => ({
            name: society.society_name,
            value: society.totalCommission === 0 ? 0.01 : society.totalCommission,
            color: getColorByIndex(index),
          })
        );
        setTopSocieties(societiesData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching top societies:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTopSocieties();
  }, [selectedMonth]); // Re-fetch data when selectedMonth changes

  const getColorByIndex = (index) => {
    const colors = ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"];
    return colors[index % colors.length]; // Cycle through the color array
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
  // Custom Bar Shape that removes hover effect
  const customBarShape = (props) => {
    const { fill, x, y, width, height } = props;
    return <rect x={x} y={y} width={width} height={height} fill={fill} />;
  };
  return (
    <div className="card z-index-2">
      <div className="card-header pb-0 pt-3 bg-transparent h-100 mb-2">
        {/* Dropdown for month selection */}
        <div className="label-title-div-inner">
          <div>
            <label className="">
              {language === "english"
                ? `महीना और वर्ष चुनें :`
                : `Select Month and Year :`}
            </label>
            &nbsp;
          </div>
          <select onChange={handleMonthChange} value={selectedMonth}>
            {availableMonths.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>
        <h6
          className="all-user-count-heading mt-2"
          style={{ textAlign: "center" }}
        >
          {language === "hindi"
            ? "Top   Agencies Overview "
            : "शीर्ष एजेंसियों का अवलोकन"}
        </h6>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "100px",
              height: "33vh",
            }}
          >
            <SmallLoader size={50} />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              layout="vertical"
              data={topSocieties}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" hide />
              <YAxis dataKey="name" type="category" width={100} />
              {/* <Tooltip /> */}
              <Tooltip
                content={({ payload }) => {
                  if (payload && payload.length) {
                    return (
                      <div className="custom-tooltip">
                        <p>{`Society: ${payload[0].payload.name}`}</p>
                        <p>{`Total Revenue: ₹${payload[0].payload.value === 0 ? "0" : payload[0].payload.value}`}</p>{" "}
                        {/* Showing 'Revenue' */}
                      </div>
                    );
                  }
                  return null;
                }}
              />

              <Bar dataKey="value" barSize={50} shape={customBarShape}>
                {topSocieties.map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default AffiliateTopSocieties;
