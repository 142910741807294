import React, { useEffect, useState } from "react";
import axios from "axios";
import { PORT } from "../../../../../../Api/api";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { TiExport } from "react-icons/ti";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ThreeCircles } from "react-loader-spinner";
import Papa from "papaparse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdOutlineModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PermissionContext } from "../../../../../../lib/PermissionContext";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
import { useContext } from "react";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { RiSearchLine } from "react-icons/ri";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Tooltip, Typography } from "@mui/material";
import { TbSelectAll } from "react-icons/tb";
import { AiOutlineSortAscending } from "react-icons/ai";
import { TbSortDescendingLetters } from "react-icons/tb";
import { ToastContainer, toast } from "react-toastify";
import swalAlert from "../../../../../../lib/alert";
import { useRef } from "react";
import decodeToken from "../../../../corporateLib/decodeToken/DecodeToken";
function CorporatePurposeView() {
  const { language } = useContext(LanguageContext);
  const [purposeData, setPurposeData] = useState([]);
  const [purposeDataByOwner, setPurposeDataByOwner] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageBySociety, setCurrentPageBySociety] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesBySociety, setTotalPagesBySoceity] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryBySociety, setSearchQueryBySociety] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataBySociety, setFilteredDataBySociety] = useState([]);
  const perPage = 10;
  const [updatePage, setUpdatePage] = useState(false);
  const [updatePageBySociety, setUpdatePageBySociety] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingPermissionBySociety, setLoadingPermissionBySociety] =
    useState(true);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyPurpose, setCompanyPurpose] = useState([]);
  const accordionRef = useRef(null);
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  console.log(tokenData, "----tokenData");
  const company_id = tokenData?.data;
  const roleType = tokenData?.roleData?.title;
  const roleLevel = tokenData?.data.level;
  const permissions = tokenData?.roleData?.permissions;
  // company handling
  //handle Purpose By owner
  const handleFetchPurpose = async () => {
    try {
      const result = await axios.get(`${PORT}/getUserNonVerfiedPrupose`);
      const responseData = result.data.data;
      const filterData = responseData.filter(
        (item) =>
          item.defaultPermissionLevel === "1" &&
          item.purposeCategory.includes("Corporate")
      );
      setPurposeData(filterData?.reverse());
      setLoadingPermission(false);
      setTotalPages(Math.ceil(filterData?.length / perPage));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingPermission(false);
    }
  };
  ///fetch puspose with companyId
  const handleFetchPurposeByCompany = async () => {
    try {
      const result = await axios.get(
        `${PORT}/getCorporateById/${tokenData.data.corporate_id}`
      );
      const filterData = result.data.purposeArray;
      setCompanyPurpose(filterData?.reverse());
      setTotalPages(Math.ceil(filterData?.length / perPage));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  //handle to add purpose by dropdown
  const addEntryByDropDown = async (items) => {
    try {
      setOpen(false);
      const entriesArray = Array.isArray(items) ? items : [items];
      const companyId = tokenData?.data?.corporate_id;

      const purposeArray = entriesArray.map((id) => ({
        id, // Entry ID
        companyId, // Associated companyId
      }));

      // Create the request body
      const requestBody = {
        purposeArray,
        companyId,
      };
      const response = await axios.post(
        `${PORT}/addCompanyPurpose`,
        requestBody
      );
      handleFetchPurposeByCompany();
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  //handle page chnage by super admin
  // Calculate pagination indexes
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, filteredData?.length);
  const currentPageData = filteredData.slice(startIndex, endIndex);
  // Handle search functionality
  const handleSearchInput = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filteredData = companyPurpose.filter((item) =>
      item.purpose.toLowerCase().includes(query)
    );
    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData?.length / perPage));
    setCurrentPage(1); // Reset to first page
  };
  // function locking
  // Fetch data on mount
  useEffect(() => {
    handleFetchPurposeByCompany();
  }, []);
  // Update pagination when data changes
  useEffect(() => {
    setFilteredData(companyPurpose);
    setTotalPages(Math.ceil(companyPurpose?.length / perPage));
  }, [companyPurpose]);

  // Update pagination when search is cleared
  useEffect(() => {
    if (!searchQuery) {
      setFilteredData(companyPurpose);
      setTotalPages(Math.ceil(companyPurpose.length / perPage));
    }
  }, [searchQuery]);

  // Handle page change
  const handleChangePage = (event, page) => {
    setCurrentPage(page);
  };

  // Owner Handling
  //handle data By Society Admin
  const handleOwnerPurpose = async () => {
    setLoadingPermissionBySociety(true);
    try {
      const result = await axios.get(`${PORT}/getUserNonVerfiedPrupose`);
      const responseData = result.data.data;
      const filterData = responseData.filter(
        (item) => item.corporate_id === tokenData.data.corporate_id
      );
      setPurposeDataByOwner(filterData.reverse());
      setTotalPagesBySoceity(Math.ceil(filterData.length / perPage));
      setLoadingPermissionBySociety(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingPermissionBySociety(false);
    }
  };
  //handle page change by society admin
  const handleChangePageBySociety = (event, value) => {
    setCurrentPageBySociety(value);
  };
  //handle pagination logic by society admin
  const startIndexBySociety = (currentPageBySociety - 1) * perPage;
  const endIndexBySociety = Math.min(
    startIndexBySociety + perPage,
    purposeDataByOwner.length
  );
  const currentPageDataByOwner =
    filteredDataBySociety.length > 0
      ? filteredDataBySociety.slice(startIndexBySociety, endIndexBySociety)
      : purposeDataByOwner.slice(startIndexBySociety, endIndexBySociety);
  //handle search functionlaity by society admin
  const handleSearchInputBySociety = (e) => {
    const query = e.target.value.trim();
    setSearchQueryBySociety(query);
    const filteredData = purposeDataByOwner.filter((item) =>
      item.purpose.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredDataBySociety(filteredData);
    setTotalPagesBySoceity(Math.ceil(filteredData.length / perPage));
    setCurrentPageBySociety(1);
  };
  //handle delete functionality company delete Purpose
  const handleDeletePurposeCompany = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure you want to Delete this Data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
        customClass: {
          container: "my-swal",
        },
      });
      if (result.isConfirmed) {
        await axios.delete(`${PORT}/deleteCompanyPurpose`, {
          data: {
            companyId: tokenData.data.corporate_id,
            purposeId: id,
          },
        });
        setUpdatePage((prevState) => !prevState);

        Swal.fire({
          title: "Deleted!",
          text: "Your Purpose Is Deleted",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        });
        handleFetchPurposeByCompany();
      } else {
        console.log("Your Data Is Not Deleted ");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const handleDeletePurposeOwner = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure you want to Delete this Data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
        customClass: {
          container: "my-swal",
        },
      });

      if (result.isConfirmed) {
        await axios.delete(`${PORT}/delPurposeBySuperAdmin/${id}`);
        handleOwnerPurpose();
        setUpdatePage((prevState) => !prevState);
        Swal.fire({
          title: "Deleted!",
          text: "Your Purpose Is Deleted",
          icon: "success",
          timer: 1500,
          timerProgressBar: true,
        });
      } else {
        console.log("Your Data Is Not Deleted ");
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  //handle to add ppurpose
  const handleAddPurpose = () => {
    navigate("/corporate/corporatePurposeAdd");
  };
  const handleEditPurpose = async (id) => {
    navigate(`/corporate/corporatePurposeEdit/${id}`);
  };
  //Add Open Modal Functionlaity
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setAllSelected(false);
    setOpen(false);
    setFilterType("");
    setQuerySearchByModalEntries("");
    setSelectedEntries("");
  };
  // Search Functionality By Modal Entries
  const [querySearchByModalEntries, setQuerySearchByModalEntries] =
    useState("");
  const filteredDataByModalEntries = purposeData?.filter((item) =>
    item.purpose
      ?.toLowerCase()
      .includes(querySearchByModalEntries.toLowerCase())
  );
  //Open Menu Filter  Functionality
  const handleFilterChange = (type) => {
    setFilterType(type);
  };
  //sorting purpose logic
  const filteredEntries = filteredDataByModalEntries?.sort((a, b) => {
    const aValue = a.purpose.replace(/[^0-9.-]+/g, "");
    const bValue = b.purpose.replace(/[^0-9.-]+/g, "");

    const aValueAsNumber = parseFloat(aValue);
    const bValueAsNumber = parseFloat(bValue);
    if (!isNaN(aValueAsNumber) && !isNaN(bValueAsNumber)) {
      return filterType === "ascending"
        ? aValueAsNumber - bValueAsNumber
        : bValueAsNumber - aValueAsNumber;
    }
    return filterType === "ascending"
      ? a.purpose.localeCompare(b.purpose)
      : b.purpose.localeCompare(a.purpose);
  });
  //handle to clear filter
  const clearFilter = () => {
    setFilterType("");
    setQuerySearchByModalEntries("");
    setSelectedEntries("");
  };
  //handle to click particular purpose
  const handleEntryClick = (item) => {
    const itemId = item._id.toString();
    const isSelected = selectedEntries.includes(itemId);

    if (isSelected) {
      // Deselect the entry
      setSelectedEntries(selectedEntries.filter((id) => id !== itemId));
    } else {
      // Select the entry
      setSelectedEntries([...selectedEntries, itemId]);
    }
    const allEntries = filteredEntries.map((entry) => entry._id.toString());
    setAllSelected(selectedEntries.length === allEntries.length);
  };
  //handle to select all purpose
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedEntries([]);
    } else {
      const allEntryIds = filteredEntries.map((entry) => entry._id.toString());
      setSelectedEntries(allEntryIds);
    }
    setAllSelected(!allSelected);
  };
  //handle to add all purpose
  const handleAddAllEntries = async () => {
    const entriesToAdd = selectedEntries
      .map((entryId) =>
        filteredEntries.find((item) => item._id.toString() === entryId)
      )
      .filter((item) => item !== undefined);
    if (entriesToAdd.length > 0) {
      const result = await Swal.fire({
        title: "Are you sure you want to add these Purpose?",
        text: "This will add all selected entries.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, add them!",
        customClass: {
          container: "my-swal-modal",
        },
      });

      if (result.isConfirmed) {
        try {
          setLoadingPermissionBySociety(true);
          const entryIds = entriesToAdd.map((entry) => entry._id);
          addEntryByDropDown(entryIds);
          setTimeout(() => {
            setValue(null);
            setInputValue("");
          }, 1300);
          handleOwnerPurpose();
          setOpen(false);
          setSelectedEntries([]);
          setFilterType("");
          setQuerySearchByModalEntries("");
        } catch (error) {
          console.error("Error adding entries:", error);
        } finally {
          setLoadingPermissionBySociety(false);
        }
      } else {
        setOpen(false);
      }
    }
  };
  //handle CLick Outside  accordian close
  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  //function lock
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  //function lock
  useEffect(() => {
    setFilteredDataBySociety(purposeDataByOwner);
  }, []);

  useEffect(() => {
    if (!searchQueryBySociety) {
      setFilteredDataBySociety(purposeDataByOwner);
      setTotalPagesBySoceity(Math.ceil(purposeDataByOwner.length / perPage));
    }
  }, [searchQueryBySociety, purposeDataByOwner, updatePageBySociety]);
  useEffect(() => {
    handleFetchPurpose();
    handleFetchPurposeByCompany();
    handleOwnerPurpose();
  }, []);
  return (
    <>
      <CorporateLayout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12  ">
              {/* owner view */}
              {/* {roleLevel === "1" || roleLevel === "2" ? (
                <div class="card mb-4">
                  <div class="card-header pb-0">
                    <div className="filtered-div">
                      <div>
                        <div className="add-item-button_purpose">
                          <button
                            className="add-item-button"
                            onClick={handleAddPurpose}
                          >
                            {language === "hindi" ? "Add +" : "+ जोड़ें"}
                          </button>
                        </div>
                      </div>
                      <div className="search-filter-box">
                        <Box
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: " ##5e72e4",
                                borderWidth: "1px",
                                borderRadius: "5px",
                              },
                              "&:hover fieldset": {
                                borderColor: " ##5e72e4",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: " ##5e72e4",
                              },

                              "& .MuiInputLabel-root.Mui-error": {
                                color: "#5e72e4",
                              },
                            },
                          }}
                        >
                          <TextField
                            id="search-input"
                            label={
                              language === "hindi" ? "Search..." : "खोज..."
                            }
                            variant="outlined"
                            value={searchQueryBySociety}
                            onChange={handleSearchInputBySociety}
                            className="search-input"
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div class="card-body px-0 pt-0 pb-2 w-100">
                    <div class="table-responsive p-0">
                      {loadingPermissionBySociety ? (
                        <div className="three_circle_loader">
                          <ThreeCircles
                            visible={true}
                            height={100}
                            width={100}
                            color="#5e72e4"
                            ariaLabel="three-circles-loading"
                          />
                        </div>
                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className=" text-dark text-center  text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "   Purpose Icon"
                                  : "   उद्देश्य चिह्न"}
                              </th>
                              <th className=" text-dark  text-center  text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "  Purpose Type"
                                  : "उद्देश्य प्रकार"}
                              </th>

                              <th className=" text-dark  text-center  text-sm font-weight-bolder opacity-7">
                                {language === "hindi"
                                  ? "  Action"
                                  : " कार्रवाई"}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {filteredDataBySociety?.length > 0 ? (
                              currentPageDataByOwner.map((item, index) => (
                                <tr key={index}>
                                  <td className="align-middle text-center  ">
                                    {item?.purposeIcon?.length > 0 ? (
                                      item.purposeIcon && (
                                        <div className="align-middle text-center">
                                          <img
                                            src={`/${item.purposeIcon.replace(
                                              "public/",
                                              ""
                                            )}`}
                                            alt=""
                                            className="purpose_image_icon"
                                          />
                                        </div>
                                      )
                                    ) : (
                                      <h6>
                                        {
                                          <>
                                            {(() => {
                                              let words;

                                              words = item.purpose?.split(" ");

                                              const initials = words[0]
                                                .substring(0, 1)
                                                .toUpperCase();

                                              return (
                                                <>
                                                  <div className="align-middle text-center purpose_icon_title ">
                                                    <h5 className="initialss ">
                                                      {initials}
                                                    </h5>
                                                  </div>
                                                </>
                                              );
                                            })()}
                                          </>
                                        }
                                      </h6>
                                    )}
                                  </td>
                                  <td className="align-middle text-center">
                                    <Tooltip
                                      title={item.purpose}
                                      placement="top"
                                      arrow
                                    >
                                      {item.purpose.length > 30
                                        ? item.purpose.substring(0, 25) + "..."
                                        : item.purpose}
                                    </Tooltip>
                                  </td>

                                  <td className="align-middle text-center">
                                    <button
                                      className="edit-btn"
                                      onClick={() =>
                                        handleEditPurpose(item._id)
                                      }
                                    >
                                      <MdOutlineModeEdit
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Edit"
                                            : "संपादित करें"
                                        }
                                      />
                                    </button>

                                    <button
                                      className="edit-btn"
                                      onClick={() =>
                                        handleDeletePurposeOwner(
                                          item && item._id
                                        )
                                      }
                                    >
                                      <MdDelete
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Delete"
                                            : "हटाएं"
                                        }
                                      />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={4}>
                                  <div className="no_data_entry">No data</div>
                                </td>
                              </tr>
                            )}
                            <td colSpan={4}>
                              {totalPagesBySociety > 1 && (
                                <div className="table-pagination">
                                  <Stack spacing={2}>
                                    <Pagination
                                      count={totalPagesBySociety}
                                      page={currentPageBySociety}
                                      onChange={handleChangePageBySociety}
                                    />
                                  </Stack>
                                </div>
                              )}
                            </td>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              ) : ( */}
              {/* // company View */}
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="filtered-div">
                    <div>
                      {permissions[4]?.actions?.includes("create") && (
                        <div className="add-item-button_purpose">
                          <button
                            className="add-item-button"
                            onClick={handleClickOpen}
                          >
                            {language === "hindi" ? "Add +" : "+ जोड़ें"}
                          </button>
                        </div>
                      )}

                      {/* modalview */}
                      <Dialog
                        onClose={handleClose}
                        className="details-dialog"
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        maxWidth="sm"
                        fullWidth={true}
                      >
                        <DialogTitle
                          sx={{ m: 0, p: 2 }}
                          id="customized-dialog-title"
                        >
                          {language === "english"
                            ? "उद्देश्य जोड़ें"
                            : " Add Purpose"}
                        </DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={(theme) => ({
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                          })}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogContent dividers>
                          <div
                            className="filter_div "
                            id="customized-dialog-title"
                          >
                            <div className="filter-clear">
                              <div>
                                <DropdownButton
                                  className=" mt-3"
                                  title={
                                    <span>
                                      {language === "english"
                                        ? "फ़िल्टर"
                                        : "Filter"}
                                    </span>
                                  }
                                >
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFilterChange("ascending");
                                    }}
                                  >
                                    <label className="label_modal">
                                      <AiOutlineSortAscending className="ci_filter" />
                                      &nbsp;
                                      {language === "english"
                                        ? "आरोही"
                                        : "Ascending"}
                                    </label>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFilterChange("descending");
                                    }}
                                  >
                                    <label className="label_modal">
                                      <TbSortDescendingLetters className="ci_filter" />
                                      &nbsp;
                                      {language === "english"
                                        ? "अवरोही"
                                        : "Descending"}
                                    </label>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="div"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSelectAll();
                                    }}
                                  >
                                    <label className="label_modal">
                                      <TbSelectAll className="ci_filter" />
                                      &nbsp;
                                      {allSelected === true
                                        ? language === "english"
                                          ? "चयनित"
                                          : "Selected"
                                        : language === "english"
                                        ? "सभी चयन करें"
                                        : " Select all"}
                                    </label>
                                  </Dropdown.Item>
                                </DropdownButton>
                              </div>
                              <div className="clear-filter">
                                <button
                                  className="dropdown_btn mt-3"
                                  onClick={clearFilter}
                                >
                                  {language === "english" ? "साफ़" : "Clear"}
                                </button>
                              </div>
                            </div>
                            <div className="calender-search-entry">
                              <div className="search_filter_calender_content">
                                <div className="search-input-wrapper">
                                  <input
                                    type="text"
                                    placeholder={
                                      language === "english"
                                        ? "नाम से खोजें"
                                        : "Search by name"
                                    }
                                    id="Customer"
                                    value={querySearchByModalEntries}
                                    onChange={(e) =>
                                      setQuerySearchByModalEntries(
                                        e.target.value
                                      )
                                    }
                                    name="name"
                                    autoComplete="off"
                                  />
                                  <RiSearchLine className="search-icon-entry" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <Typography gutterBottom>
                            <div className="modal-entries">
                              {loadingPermissionBySociety ? (
                                <div className="three_circle_loader">
                                  <ThreeCircles
                                    visible={true}
                                    height={100}
                                    width={100}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : filteredEntries.length > 0 ? (
                                filteredEntries &&
                                filteredEntries.map((item, index) => {
                                  return (
                                    <>
                                      <div
                                        key={item._id.toString()}
                                        className={`modal-entry  ${
                                          selectedEntries.includes(
                                            item._id.toString()
                                          )
                                            ? "selected"
                                            : ""
                                        }`}
                                        onClick={() => handleEntryClick(item)}
                                        id="modal-entry"
                                      >
                                        <div className="modal-image">
                                          {item.purposeIcon ? (
                                            <img
                                              src={`/${item.purposeIcon.replace(
                                                "public/",
                                                ""
                                              )}`}
                                              alt=""
                                            />
                                          ) : (
                                            <div className="modal-placeholder">
                                              <div className="modal-image-div">
                                                <b className="modal-word">
                                                  {item.purpose
                                                    ?.charAt(0)
                                                    .toUpperCase() || ""}
                                                </b>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <p className="modal-name">
                                          {item.purpose}
                                        </p>
                                      </div>
                                    </>
                                  );
                                })
                              ) : (
                                <div className="no-data-modal">No Data</div>
                              )}
                            </div>
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          {selectedEntries && (
                            <button
                              onClick={handleAddAllEntries}
                              className="modal-button"
                              type="submit"
                              disabled={selectedEntries.length === 0}
                            >
                              {language === "hindi"
                                ? "Submit"
                                : " प्रस्तुत करें "}
                            </button>
                          )}
                        </DialogActions>
                      </Dialog>
                    </div>
                    {permissions[4]?.actions?.includes("read") && (
                      <div className="search-filter-box">
                        <Box
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: " ##5e72e4",
                                borderWidth: "1px",
                                borderRadius: "5px",
                              },
                              "&:hover fieldset": {
                                borderColor: " ##5e72e4",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: " ##5e72e4",
                              },

                              "& .MuiInputLabel-root.Mui-error": {
                                color: "#5e72e4",
                              },
                            },
                          }}
                        >
                          <TextField
                            id="search-input"
                            label={
                              language === "hindi" ? "Search..." : "खोज..."
                            }
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchInput}
                            className="search-input"
                          />
                        </Box>
                      </div>
                    )}
                  </div>
                </div>
                <div class="card-body px-0 pt-0 pb-2 w-100">
                  <div class="table-responsive p-0">
                    {loading ? (
                      <div className="three_circle_loader">
                        <ThreeCircles
                          visible={true}
                          height={100}
                          width={100}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : permissions[4]?.actions?.includes("read") ? (
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className=" text-dark text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "   Purpose Icon"
                                : "   उद्देश्य चिह्न"}
                            </th>
                            <th className=" text-dark  text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "  Purpose Type"
                                : "उद्देश्य प्रकार"}
                            </th>

                            <th className=" text-dark  text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "  Action" : " कार्रवाई"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData?.length > 0 ? (
                            currentPageData.map((item, index) => (
                              <tr key={index}>
                                <td className="align-middle text-center  ">
                                  {item?.purposeIcon?.length > 0 ? (
                                    item.purposeIcon && (
                                      <div className="align-middle text-center">
                                        <img
                                          src={`/${item.purposeIcon.replace(
                                            "public/",
                                            ""
                                          )}`}
                                          alt=""
                                          className="purpose_image_icon"
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <h6>
                                      {
                                        <>
                                          {(() => {
                                            let words;

                                            words = item.purpose?.split(" ");

                                            const initials = words[0]
                                              .substring(0, 1)
                                              .toUpperCase();

                                            return (
                                              <>
                                                <div className="align-middle text-center purpose_icon_title ">
                                                  <h5 className="initialss ">
                                                    {initials}
                                                  </h5>
                                                </div>
                                              </>
                                            );
                                          })()}
                                        </>
                                      }
                                    </h6>
                                  )}
                                </td>
                                <td className="align-middle text-center">
                                  <Tooltip
                                    title={item.purpose}
                                    placement="top"
                                    arrow
                                  >
                                    {item.purpose.length > 30
                                      ? item.purpose.substring(0, 25) + "..."
                                      : item.purpose}
                                  </Tooltip>
                                </td>

                                <td className="align-middle text-center">
                                  {permissions[4]?.actions?.includes(
                                    "delete"
                                  ) && (
                                    <button
                                      className="edit-btn"
                                      onClick={() =>
                                        handleDeletePurposeCompany(
                                          item && item._id
                                        )
                                      }
                                    >
                                      <MdDelete
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Delete"
                                            : "हटाएं"
                                        }
                                      />
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                <div className="no_data_entry">No data</div>
                              </td>
                            </tr>
                          )}
                          <td colSpan={4}>
                            {totalPages > 1 && (
                              <div className="table-pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                  />
                                </Stack>
                              </div>
                            )}
                          </td>
                        </tbody>
                      </table>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <ToastContainer />
      </CorporateLayout>
    </>
  );
}

export default CorporatePurposeView;
