exports.formatDate = (dateString) => {
  const [day, month, year] = dateString?.split("-");
  const date = new Date(`${year}-${month}-${day}`);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);
  // Convert the month to uppercase and format the string
  const [formattedDay, formattedMonth, formattedYear] =
    formattedDate.split(" ");
  return `${formattedDay}-${formattedMonth.toUpperCase()}-${formattedYear}`;
};
