
export const encodeText = (text) => {
    return text
      .split("")
      .map((char) => String.fromCharCode(char.charCodeAt(0) + 11))
      .join("");
  };
// export const encodeText = (text) => {
//     const random=(Math.ceil(Math.random()*10)+9)
//     const encoded = text
//       .split("")
//       .map((char) => String.fromCharCode(char.charCodeAt(0) + random))
//       .join("")
//       return `${encoded}+/+${random}`
//   };

  // Simple decoding logic: shift characters back by 2
export  const decodeText = (text) => {
    return text
      .split("")
      .map((char) => String.fromCharCode(char.charCodeAt(0) - 11))
      .join("");
  };

// export const decodeText = (text) => {
//     const arr=text.split('+/+')
//     console.log(arr[0])
//     return arr[0]
//       .split("")
//       .map((char) => String.fromCharCode(char.charCodeAt(0) - arr[1]))
//       .join("");
//   };