import React from "react";
import CorporateGuardNavbar from "../../corporateGuardComponents/CorporateGuardNavbar/CorporateGuardNavbar";
import "./style.css";
import CorporateGuardPurposeScreen from "../corporateGuardPurposeScreen/CorporateGuardPurposeScreen";
const CorporateGuardHomeScreen = () => {
  return (
    <div>
      <CorporateGuardNavbar></CorporateGuardNavbar>
    <CorporateGuardPurposeScreen></CorporateGuardPurposeScreen>
    </div>
  );
};

export default CorporateGuardHomeScreen;
