// import React, { useState, useEffect, useContext } from "react";
// import { Line } from "react-chartjs-2";
// import axios from "axios";
// import SmallLoader from "../Loading/SmallLoader";
// import { PORT } from "../Api/api";
// import { LanguageContext } from "../lib/LanguageContext";
// const SuperAdminAfiliateDashboard = () => {
//   const { language } = useContext(LanguageContext);
//   const [isLoading, setIsLoading] = useState(true);
//   const [chartData, setChartData] = useState(null);
//   const [timePeriod, setTimePeriod] = useState("monthly"); // Default is monthly
//   const [paymentStatus, setPaymentStatus] = useState("paid"); // Default is 'paid'
//   // Function to fetch commission data based on time period
//   const fetchCommissionData = async (period, paymentStatus) => {
//     setIsLoading(true); // Show loading indicator

//     try {
//       // Make API request to backend with time period filter
//       const response = await axios.get(
//         `${PORT}/retrieveAffiliateCommissionsSummary`
//       );
//       const data = response.data.data; // Assuming API returns the data here
//   console.log(data,"Data--->")
//       // Initialize labels and commission data based on time period
//       let labels = [];
//       let datasets = [];

//       if (period === "monthly") {
//         // Process monthly data
//         labels = Object.keys(data[Object.keys(data)[0]].monthlyCommission); // Get months from the first affiliate's data

//         // Iterate through each affiliate and prepare their commission data
//         datasets = Object.keys(data).map((affiliateId) => {
//           const affiliate = data[affiliateId];
//           const commissionData = labels.map(
//             (month) => affiliate.monthlyCommission[month] || 0 // Sum up monthly commission
//           );

//           return {
//             label: affiliate.agencyName, // Using agencyName as the label
//             data: commissionData,
//             borderColor: getRandomColor(), // To differentiate each line with a random color
//             fill: false,
//           };
//         });
//       } else if (period === "weekly") {
//         // Process weekly data
//         labels = Object.keys(data[Object.keys(data)[0]].weeklyCommission); // Get weeks from the first affiliate's data

//         // Iterate through each affiliate and prepare their commission data
//         datasets = Object.keys(data).map((affiliateId) => {
//           const affiliate = data[affiliateId];
//           const commissionData = labels.map(
//             (week) => affiliate.weeklyCommission[week] || 0 // Sum up weekly commission
//           );

//           return {
//             label: affiliate.agencyName, // Using agencyName as the label
//             data: commissionData,
//             borderColor: getRandomColor(), // To differentiate each line with a random color
//             fill: false,
//           };
//         });
//       } else if (period === "yearly") {
//         // Process yearly data
//         labels = Object.keys(data[Object.keys(data)[0]].yearlyCommission); // Get years from the first affiliate's data
//         // Iterate through each affiliate and prepare their commission data
//         datasets = Object.keys(data).map((affiliateId) => {
//           const affiliate = data[affiliateId];
//           const commissionData = labels.map(
//             (year) => affiliate.yearlyCommission[year] || 0 // Sum up yearly commission
//           );
//           return {
//             label: affiliate.agencyName, // Using agencyName as the label
//             data: commissionData,
//             borderColor: getRandomColor(), // To differentiate each line with a random color
//             fill: false,
//           };
//         });
//       }
//       // Set chart data
//       setChartData({
//         labels,
//         datasets,
//       });
//     } catch (error) {
//       console.error("Error fetching data", error);
//     } finally {
//       setIsLoading(false); // Hide loading indicator
//     }
//   };

//   // Helper function to generate a random color for each line
//   const getRandomColor = () => {
//     const letters = "0123456789ABCDEF";
//     let color = "#";
//     for (let i = 0; i < 6; i++) {
//       color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
//   };

//   // Fetch data when component loads or when timePeriod changes
//   useEffect(() => {
//     fetchCommissionData(timePeriod, paymentStatus);
//   }, [timePeriod, paymentStatus]);

//   return (
//     <>
//       <div className="container-fluid py-4">
//         <div className="row">
//           <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4"></div>
//           <div className="row mt-4 ">
//             <br />
//             <br />
//             <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
//               <div className="card z-index-2 h-100">
//                 <div className="mx-3 mt-3">
//                   <label className="me-2">
//                     {language === "hindi"
//                       ? ` Select Year : `
//                       : ` वर्ष चुनें : `}
//                   </label>
//                   {/* <select value={selectedYear} onChange={handleYearChange}>
//                 <option value={previousToPreviousYear}>
//                   {previousToPreviousYear}
//                 </option>
//                 <option value={previousYear}>{previousYear}</option>
//                 <option value={currentYear}>{currentYear}</option>
//               </select> */}
//                   <div>
//                     <button onClick={() => setTimePeriod("weekly")}>
//                       Weekly
//                     </button>
//                     <button onClick={() => setTimePeriod("monthly")}>
//                       Monthly
//                     </button>
//                     <button onClick={() => setTimePeriod("yearly")}>
//                       Yearly
//                     </button>
//                   </div>
//                   {/* Payment Status Filter */}
//                   <div>
//                     <button onClick={() => setPaymentStatus("paid")}>
//                       Paid
//                     </button>
//                     <button onClick={() => setPaymentStatus("unpaid")}>
//                       Unpaid
//                     </button>
//                   </div>
//                 </div>
//                 <div className="card-header pb-0 pt-3 bg-transparent">
//                   <h6 className="text-capitalize">
//                     {/* {language === "hindi" ? "Society" : " सोसाइटी"} */}
//                   </h6>
//                   <p className="text-sm mb-0">
//                     <i className="fa fa-arrow-up text-success"></i>
//                     {/* <span className="font-weight-bold"></span> in{" "}
//         {societyYear} */}
//                   </p>
//                   {isLoading ? (
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         margin: "100px",
//                       }}
//                     >
//                       <SmallLoader size={50} />
//                     </div>
//                   ) : chartData ? (
//                     <Line data={chartData} options={{ responsive: true }} />
//                   ) : (
//                     <div
//                       style={{
//                         height: "250px",
//                         textAlign: "center",
//                         alignContent: "center",
//                       }}
//                     >
//                       No Data Found
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SuperAdminAfiliateDashboard;
import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import SmallLoader from "../Loading/SmallLoader";
import { PORT } from "../Api/api";
import { LanguageContext } from "../lib/LanguageContext";

const SuperAdminAfiliateDashboard = () => {
  const { language } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [timePeriod, setTimePeriod] = useState("monthly"); // Default is monthly
  const [paymentStatus, setPaymentStatus] = useState("paid"); // Default is 'paid'
  // Handle change in the select dropdown
  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value); // Update timePeriod based on selected option
  };
  const handlePaymentStatus = (e) => {
    setPaymentStatus(e.target.value);
  };
  // Function to fetch commission data based on time period and payment status
  const fetchCommissionData = async (period, paymentStatus) => {
    setIsLoading(true); // Show loading indicator

    try {
      // Make API request to backend with time period filter
      const response = await axios.get(
        `${PORT}/retrieveAffiliateCommissionsSummary`
      );
      const data = response.data.data; // Assuming API returns the data here

      let labels = [];
      let datasets = [];

      // Process monthly data
      if (period === "monthly") {
        labels = Object.keys(
          data[Object.keys(data)[0]].monthlyCommission[paymentStatus]
        ); // Get months from the first affiliate's data

        datasets = Object.keys(data).map((affiliateId) => {
          const affiliate = data[affiliateId];
          const commissionData = labels.map(
            (month) => affiliate.monthlyCommission[paymentStatus][month] || 0
          );

          return {
            label: affiliate.agencyName, // Using agencyName as the label
            data: commissionData,
            borderColor: getRandomColor(), // To differentiate each line with a random color
            fill: false,
            tension: 0.4,
          };
        });
      }

      // Process weekly data (if needed)
      if (period === "weekly") {
        labels = Object.keys(
          data[Object.keys(data)[0]].weeklyCommission[paymentStatus]
        ); // Get weeks from the first affiliate's data

        datasets = Object.keys(data).map((affiliateId) => {
          const affiliate = data[affiliateId];
          const commissionData = labels.map(
            (week) => affiliate.weeklyCommission[paymentStatus][week] || 0
          );

          return {
            label: affiliate.agencyName, // Using agencyName as the label
            data: commissionData,
            borderColor: getRandomColor(), // To differentiate each line with a random color
            fill: false,
            tension: 0.4,
          };
        });
      }

      // Process yearly data (if needed)
      if (period === "yearly") {
        labels = Object.keys(
          data[Object.keys(data)[0]].yearlyCommission[paymentStatus]
        ); // Get years from the first affiliate's data

        datasets = Object.keys(data).map((affiliateId) => {
          const affiliate = data[affiliateId];
          const commissionData = labels.map(
            (year) => affiliate.yearlyCommission[paymentStatus][year] || 0
          );
          return {
            label: affiliate.agencyName, // Using agencyName as the label
            data: commissionData,
            borderColor: getRandomColor(), // To differentiate each line with a random color
            fill: false,
            tension: 0.4,
          };
        });
      }

      setChartData({
        labels,
        datasets,
      });
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };
  // Helper function to generate a random color for each line
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Fetch data when component loads or when timePeriod or paymentStatus changes
  useEffect(() => {
    fetchCommissionData(timePeriod, paymentStatus);
  }, [timePeriod, paymentStatus]);

  return (
    <>
      <div className="card z-index-2 h-100">
        {chartData ? (
          <div className="mx-3 mt-3 label-title-div">
            <div className="label-title-div-inner">
              <div>
                <label className="">
                  {language === "hindi"
                    ? ` Select Time Period : `
                    : ` समय अवधि चुनें : `}
                </label>
                &nbsp;
              </div>
              <div>
                {/* <button onClick={() => setTimePeriod("weekly")}>
                      Weekly
                    </button> */}

                <select value={timePeriod} onChange={handleTimePeriodChange}>
                  <option value="monthly">
                    {language === "english" ? "माहिक" : "Monthly"}
                  </option>
                  <option value="yearly">
                    {language === "english" ? "वार्षिक" : "Yearly"}
                  </option>
                </select>
              </div>
            </div>
            {/* Payment Status Filter */}
            <div className="label-title-div-inner">
              <div>
                <label className="">
                  {language === "english"
                    ? `भुगतान स्थिति :`
                    : `Payment Status :`}
                </label>
                &nbsp;
              </div>
              <div>
                <select value={paymentStatus} onChange={handlePaymentStatus}>
                  <option value="paid">
                    {language === "english" ? "भुगतान हुआ" : "Paid"}
                  </option>
                  <option value="unpaid">
                    {language === "english" ? "अवैतनिक" : "Unpaid"}
                  </option>
                </select>
              </div>
            </div>
          </div>
        ) : null}
        <div className="card-header pb-0 pt-3 bg-transparent">
          <h6 className="text-capitalize"></h6>
          <p className="text-sm mb-0">
            <i className="fa fa-arrow-up text-success"></i>
          </p>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "100px",
              }}
            >
              <SmallLoader size={50} />
            </div>
          ) : chartData ? (
            <>
              <h6 style={{ textAlign: "center" }}>
                {language === "english"
                  ? " एफिलिएट कमीशन"
                  : " Affiliate Commission"}
              </h6>
              <Line
                data={chartData}
                options={{
                  scales: {
                    x: {
                      ticks: {
                        color: "black",
                      },
                    },
                    y: {
                      min: 0, // Ensure Y-axis starts from 0
                      ticks: {
                        color: "black",
                        stepSize: 1,
                        beginAtZero: true,
                        callback: function (value) {
                          return Number.isInteger(value) ? value : "";
                        },
                      },
                      suggestedMin: 0,
                      suggestedMax: 10,
                    },
                  },
                  responsive: true,
                }}
              />
            </>
          ) : (
            <div
              style={{
                height: "250px",
                textAlign: "center",
                alignContent: "center",
              }}
            >
              No Data Found
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SuperAdminAfiliateDashboard;
