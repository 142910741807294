import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { PORT } from "../Api/api";
import "./style.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import PreviewImage from "./PreviewImage";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { TiExport } from "react-icons/ti";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ThreeCircles } from "react-loader-spinner";
import Papa from "papaparse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./style.css";
import Layout from "../lib/Layout";
import { PermissionContext } from "../lib/PermissionContext";
import { useContext } from "react";
import { LanguageContext } from "../lib/LanguageContext";
import { GoArrowDownLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
import { formatDate } from "../lib/FormattedDate";
import swalAlert from "../lib/alert";
function GetData() {
  const { language } = useContext(LanguageContext);
  const { permissions } = useContext(PermissionContext);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const perPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);
  // fetch Entries
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${PORT}/getGuestEntriesBySociety/${society_id}`
      );
      const res = await response.data.guest;
      const filterDataAccSociety = await res.filter(
        (item) => item?.status === "approved"
      );

      setData(filterDataAccSociety.reverse());
      setTimeout(() => {
        setLoadingPermission(false);
      }, 1000);

      setTotalPages(Math.ceil(filterDataAccSociety.length / perPage));

      if (filterDataAccSociety) {
        setLoading(false);
      }
      setTotalPages(Math.ceil(filterDataAccSociety.length / perPage));
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setLoadingPermission(false);
    }
  };
  // handleDelete
  const handleDelete = async (id) => {
    if (!id) return;

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (!result.isConfirmed) return;

    try {
      await axios.delete(`${PORT}/delUser/${id}`);
      setData(data.filter((item) => item._id !== id));
      fetchData();
      Swal.fire({
        title: "Deleted!",
        text: "Your entry has been deleted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  //handle to preview the image
  const handlePreviewImage = (id) => {
    localStorage.setItem("previewImageId", JSON.stringify(id));
  };
  //handle to page change
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  // export to Excel
  const exportToExcel = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData = data.map(
      ({
        entryType,
        purposeType,
        houseDetails,
        submitedDate,
        submitedTime,
        clockOut,
        status,
      }) => ({
        "Entry Type": entryType,
        "Purpose Type": purposeType,
        "House No": JSON.parse(houseDetails).houseNo,
        Owner: JSON.parse(houseDetails).ownerName,
        Status: status.toUpperCase(),
        Date: formatDate(submitedDate),
        "Clock-In": formatTimeWithoutSeconds(submitedTime),
        "Clock-Out": clockOut ? clockOut : "In Society",
      })
    );

    const ws = XLSX.utils.json_to_sheet(filteredData);

    const wscols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    ws["!cols"] = wscols;
    // Set row height for all rows (example: setting all rows to height 30)
    if (!ws["!rows"]) ws["!rows"] = [];

    // Set height for each row
    const rowHeight = 30; // You can adjust this height as needed
    for (let i = 0; i < filteredData.length + 1; i++) {
      // Include the header row as well
      ws["!rows"][i] = { hpt: rowHeight };
    }
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
  };
  // export to csv
  const exportToCSV = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData = data.map(
      ({
        entryType,
        purposeType,
        houseDetails,
        submitedDate,
        submitedTime,
        clockOut,
        status,
      }) => ({
        "Entry Type": entryType,
        "Purpose Type": purposeType,
        "House No": JSON.parse(houseDetails).houseNo,
        Owner: JSON.parse(houseDetails).ownerName,
        Status: status.toUpperCase(),
        Date: formatDate(submitedDate),
        "Clock-In": formatTimeWithoutSeconds(submitedTime),
        "Clock-Out": clockOut ? clockOut : "In Society",
      })
    );

    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.csv`);
  };
  //handle pagination logic
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, data.length);
  const currentPageData =
    filteredData.length > 0
      ? filteredData.slice(startIndex, endIndex)
      : data.slice(startIndex, endIndex);
  //handle search functionality
  const handleSearchInput = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredData = data.filter((item) => {
      return item.entryType
        ?.toLowerCase()
        .trim()
        .includes(searchQuery.toLowerCase().trim());
    });
    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData.length / perPage));
    setCurrentPage(1);
  };
  //handle Date Range Functionality
  useEffect(() => {
    let filteredData = data;

    if (startDate) {
      filteredData = filteredData.filter((item) => {
        const [day, month, year] = item.submitedDate.split("-");
        const formattedDate = new Date(`${year}-${month}-${day}`);
        // Check if the date is greater than or equal to startDate
        return formattedDate >= new Date(startDate);
      });
    }

    if (endDate) {
      filteredData = filteredData.filter((item) => {
        const [day, month, year] = item.submitedDate.split("-");
        const formattedDate = new Date(`${year}-${month}-${day}`);
        // Check if the date is less than or equal to endDate
        return formattedDate <= new Date(endDate);
      });
    }

    if (searchQuery) {
      filteredData = filteredData.filter((item) => {
        return item.entryType
          ?.toLowerCase()
          .trim()
          .includes(searchQuery.toLowerCase().trim());
      });
    }

    setFilteredData(filteredData);
    setTotalPages(Math.ceil(filteredData.length / perPage));
  }, [data, searchQuery, startDate, endDate]);
  // Validation logic to ensure endDate is not before startDate
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
      // Check if the end date is empty
  if (!selectedEndDate) {
    setEndDate(""); // Clear the end date if it's empty
    return;
  }
    if (selectedEndDate < startDate) {
      // setError("End date can't be before the start date.");
      alert("End date can't be before the start date.");
      setEndDate("");
      return; // Don't update endDate if it's invalid
    }
    setEndDate(selectedEndDate);
  };
  //formatTimeWithoutSeconds
  function formatTimeWithoutSeconds(timeString) {
    // Split time into hours, minutes, and seconds
    const [hours, minutes] = timeString.split(":"); // Ignore seconds part
    const suffix = timeString.includes("pm") ? "pm" : "am";

    // Return formatted time as hh:mm AM/PM
    return `${hours}:${minutes} ${suffix}`;
  }
  //handle CLick Outside  accordian close
  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  //function lock
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setFilteredData(data);
  }, []);
  useEffect(() => {
    if (!searchQuery) {
      fetchData();
      setTotalPages(Math.ceil(data.length / perPage));
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <Layout>
        <div className="container-fluid  ">
          <div className="row">
            <div className="col-12  ">
              <div className="card mb-4">
                <div className="card-header pb-0">
                  <div className="filtered-div">
                    {permissions[1]?.actions.read ? (
                      <div
                        ref={accordionRef}
                        className="p-export-btn export-dropdown"
                      >
                        <Accordion expanded={isOpen}>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={handleToggle}
                          >
                            <Typography>
                              {" "}
                              <TiExport className="export_icon" />
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <div className="export-btns">
                                <button
                                  className="export_btn_data"
                                  onClick={() =>
                                    exportToExcel(
                                      filteredData,
                                      "Guest Entries Requests"
                                    )
                                  }
                                >
                                  Excel
                                </button>
                                <button
                                  className="export_btn_data"
                                  onClick={() =>
                                    exportToCSV(
                                      filteredData,
                                      "Guest Entries Requests"
                                    )
                                  }
                                >
                                  CSV
                                </button>
                              </div>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ) : (
                      ""
                    )}

                    <Box
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: " #5e72e4",
                            borderWidth: "1px",
                            borderRadius: "5px",
                          },
                          "&:hover fieldset": {
                            borderColor: " #5e72e4",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: " #5e72e4",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: " #5e72e4",
                        },
                        "& .MuiInputBase-input": {
                          color: " #5e72e4",
                        },
                        "& .MuiInputLabel-root.Mui-error": {
                          color: " #5e72e4",
                        },
                      }}
                    >
                      {permissions[1]?.actions.read ? (
                        <div className="date-filter">
                          <TextField
                            id="start-date"
                            label={
                              language === "hindi"
                                ? "Start Date"
                                : "आरंभ करने की तिथि"
                            }
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              style: { color: " #5e72e4" },
                            }}
                            InputProps={{
                              style: { color: " #5e72e4" },
                            }}
                            variant="outlined"
                          />
                          <TextField
                            id="end-date"
                            label={
                              language === "hindi" ? "End Date" : "अंतिम तिथि"
                            }
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                              shrink: true,
                              style: { color: " #5e72e4" },
                            }}
                            InputProps={{
                              style: { color: " #5e72e4" },
                            }}
                            variant="outlined"
                            min={startDate}
                            disabled={!startDate}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </Box>
                    <div className="search-filter-box">
                      <Box
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: " #5e72e4",
                              borderWidth: "1px",
                              borderRadius: "5px",
                            },
                            "&:hover fieldset": {
                              borderColor: "  #5e72e4",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "  #5e72e4",
                            },

                            "& .MuiInputLabel-root.Mui-error": {
                              color: " #5e72e4",
                            },
                          },
                        }}
                      >
                        {permissions[1]?.actions.read ? (
                          <div className="text-field">
                            <TextField
                              id="search-input"
                              label={
                                language === "hindi"
                                  ? "Search ...."
                                  : "खोज ...."
                              }
                              variant="outlined"
                              value={searchQuery}
                              onChange={handleSearchInput}
                              className="search-input"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="card-body px-0 pt-0 pb-2 w-100">
                  <div className="table-responsive p-0   overflow-auto">
                    {loadingPermission ? (
                      <div className="three_circle_loader">
                        <ThreeCircles
                          visible={true}
                          height={100}
                          width={100}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : permissions[1]?.actions.read ? (
                      <table class="table align-items-center mb-0  overflow-auto">
                        <thead>
                          <tr>
                            <th class="text-center  text-dark text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "Entry Type"
                                : "प्रवेश प्रकार"}
                            </th>

                            <th class="text-center text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? " House Details"
                                : "घर का विवरण"}
                            </th>

                            <th class="text-center  text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Date" : "तारीख"}
                            </th>
                            <th class="text-center  text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Clock-In" : "कार्य आरंभ"}
                            </th>
                            <th class="text-center  text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "Clock-Out"
                                : "कार्य समाप्ति"}
                            </th>
                            <th class="text-center  text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Status" : "स्थिति"}
                            </th>
                            <th class="text-center  text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Actions" : "कार्रवाई"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredData.length > 0 ? (
                            currentPageData.map((item, index) => (
                              <tr key={index}>
                                <td className="entry-type align-middle text-center text-sm   ">
                                  {item.entryType.length > 20
                                    ? item.entryType.substring(0, 15) + "..."
                                    : item.entryType}
                                </td>

                                <td className="house-details align-middle text-center text-sm">
                                  <b className="guest-house-details">
                                    {language === "hindi"
                                      ? "House No:"
                                      : "मकान नं:"}
                                  </b>{" "}
                                  {item?.house_id?.houseNo}
                                  <br />
                                  {item?.house_id?.ownerName && (
                                    <b className="guest-house-details">
                                      {language === "hindi"
                                        ? "Owner:"
                                        : "मालिक:"}
                                    </b>
                                  )}
                                  {item?.house_id?.ownerName}
                                </td>
                                <td className="purpose-type align-middle text-center text-sm">
                                  {/* {formatDate(item.submitedDate)} */}
                                  {item.previousDay ? (
                                    <div style={{ color: "red" }}>
                                      {formatDate(item.previousDay)}
                                    </div>
                                  ) : null}{" "}
                                  &nbsp;
                                  {formatDate(item.submitedDate)}
                                </td>
                                <td className="purpose-type align-middle text-center text-sm">
                                  <GoArrowDownLeft className="down_icon" />{" "}
                                  {formatTimeWithoutSeconds(item.submitedTime)}
                                </td>
                                <td className="purpose-type align-middle text-center text-sm">
                                  <GoArrowUpRight className="up_icon" />{" "}
                                  {item.clockOut === "null" || !item.clockOut
                                    ? "In Society"
                                    : item.clockOut}
                                </td>
                                <td className="purpose-type align-middle text-center text-sm">
                                  <span
                                    className={`status-badge ${item.status.toLowerCase()}`}
                                  >
                                    {item.status.toUpperCase()}
                                  </span>
                                </td>
                                <td className="align-middle text-center  d-flex justify-content-center ">
                                  <PreviewImage
                                    item={item}
                                    onClick={() => handlePreviewImage(item._id)}
                                  />
                                  {permissions[1]?.actions.delete ? (
                                    <button
                                      className="dlt-btn"
                                      type="button"
                                      onClick={() => handleDelete(item._id)}
                                    >
                                      <MdDelete
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          language === "hindi"
                                            ? "Click to Delete"
                                            : "हटाएं"
                                        }
                                      />
                                    </button>
                                  ) : (
                                    <p></p>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                class="align-middle text-center text-sm"
                                colSpan={8}
                              >
                                <div className="no_data_entry">No data</div>
                              </td>
                            </tr>
                          )}

                          <td colSpan={8}>
                            {totalPages > 1 && (
                              <div className="table-pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                  />
                                </Stack>
                              </div>
                            )}
                          </td>
                        </tbody>
                      </table>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default GetData;
