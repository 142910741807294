import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../../../../lib/Layout";
import { LanguageContext } from "../../../../lib/LanguageContext";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import { useNavigate } from "react-router-dom";
import { TextField, Stack, Pagination, Tooltip } from "@mui/material";
import "./style.css";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import Loading from "../../../../Loading/Loading";
import Swal from "sweetalert2";
import { PermissionContext } from "../../../../lib/PermissionContext";
import SubscriptionModal from "../subscriptionModal/SubscriptionModal";
import swalAlert from "../../../../lib/alert";
import { formatDate } from "../../../../lib/FormattedDate";
import FileSaver from "file-saver";
import Papa from "papaparse";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx";
import { TiExport } from "react-icons/ti";
const ViewSubscription = () => {
  const { permissions } = useContext(PermissionContext);
  const { language } = useContext(LanguageContext);
  const [subscription, setSubscription] = useState([]);
  const [filteredSubscription, setFilteredSubscription] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [subscriptionsPerPage] = useState(10); // Number of items per page
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);
  const navigate = useNavigate();
  // Handle Create Subscription
  const handleCreateSubscription = () => {
    navigate("/admin/addSubscription");
  };

  // Get Subscription Data
  const fetchSubscription = async () => {
    try {
      const res = await axios.get(`${PORT}/getSubscription`);
      setSubscription(res.data);
      setFilteredSubscription(res.data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching subscriptions", error);
      setLoading(false);
    }
  };

  // Handle Search Input Change
  const handleSearchInput = (e) => {
    const value = e.target.value.trim();
    setSearchQuery(value);

    // Filter subscriptions based on search query
    const filteredData = subscription.filter(
      (sub) =>
        sub.name.toLowerCase().includes(value.toLowerCase()) ||
        sub.price.toString().includes(value)
    );
    setFilteredSubscription(filteredData);
    setCurrentPage(1); // Reset to the first page after search query change
  };

  // Handle Delete Subscription
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;

    try {
      const response = await axios.delete(`${PORT}/deleteSubscription/${id}`);
      console.log(response.data.msg);
      fetchSubscription();
      Swal.fire({
        title: "Deleted!",
        text: "Subscription has been deleted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Handle Edit Subscription
  const handleEdit = (id) => {
    navigate(`/admin/editSubscription/${id}`);
  };

  // Handle Page Change
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination logic to get data for the current page
  const indexOfLastSubscription = currentPage * subscriptionsPerPage;
  const indexOfFirstSubscription =
    indexOfLastSubscription - subscriptionsPerPage;
  const currentSubscriptions = filteredSubscription.slice(
    indexOfFirstSubscription,
    indexOfLastSubscription
  );
  //export to excel
  const exportToExcel = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData = data.map(
      ({
        name,
        price,
        interval,
        commissionCurrency,
        societyHouseList,
        description,
        date,
        time,
        gstType,
        gstRate,
      }) => ({
        "Plan Name": name,
        "Price Per household": price,
        Interval: interval,
        "Payment Currency" :commissionCurrency,
        "GST Type":gstType,
        "GST Rate":gstRate
      })
    );

    const ws = XLSX.utils.json_to_sheet(filteredData);

    const wscols = [{ wch: 30 }, { wch: 30 }, { wch: 30 },{ wch: 30 }, { wch: 30 }, { wch: 30 },{ wch: 30 }, { wch: 30 }, { wch: 30 }];
    ws["!cols"] = wscols;
    // Set row heights
    const rowHeights = [{ hpt: 30 }, ...filteredData.map(() => ({ hpt: 20 }))];
    ws["!rows"] = rowHeights;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
  };
  //export to csv
  const exportToCSV = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData = data.map(
      ({
        name,
        price,
        interval,
        commissionCurrency,
        societyHouseList,
        description,
        date,
        time,
        gstType,
        gstRate,
      }) => ({
        "Plan Name": name,
        "Price Per household": price,
        Interval: interval,
        "Payment Currency" :commissionCurrency,
        "GST Type":gstType,
        "GST Rate":gstRate
      })
    )

    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename}${dateTime}.csv`);
  };
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  // Fetch subscriptions on mount
  useEffect(() => {
    fetchSubscription();
  }, []);
 //function lock
   //handle CLick Outside  accordian close
   const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <Layout>
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card mb-4">
                <div className="card-header pb-0">
                  <div className="filtered-div">
                    <div
                      ref={accordionRef}
                      className="p-export-btn export-dropdown"
                    >
                      <Accordion expanded={isOpen}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          onClick={handleToggle}
                        >
                          <TiExport className="export_icon" />
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="export-btns">
                            <button
                              className="export_btn_data"
                              onClick={() =>
                                exportToExcel(currentSubscriptions, "Subscription")
                              }
                            >
                              Excel
                            </button>
                            <button
                              className="export_btn_data"
                              onClick={() =>
                                exportToCSV(currentSubscriptions, "Subscription ")
                              }
                            >
                              CSV
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                    {permissions[12]?.actions.create ? (
                      <div className="add-item-button_purpose">
                        <button
                          className="add-item-button"
                          onClick={handleCreateSubscription}
                        >
                          {language === "english" ? "+ जोड़ें" : "+ Add"}
                        </button>
                      </div>
                    ) : (
                      <p></p>
                    )}
                    {permissions[12]?.actions.read ? (
                      <div className="search-filter-box">
                        <TextField
                          id="search-input"
                          label={
                            language === "english" ? "खोज..." : "Search..."
                          }
                          variant="outlined"
                          value={searchQuery}
                          onChange={handleSearchInput}
                          className="search-input"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#5e72e4",
                                borderWidth: "1px",
                                borderRadius: "5px",
                              },
                              "&:hover fieldset": {
                                borderColor: "#5e72e4",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#5e72e4",
                              },
                            },
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="card-body px-0 pt-0 pb-2 w-100">
                  <div className="table-responsive p-0 bg-black">
                    {loading ? (
                      <Loading />
                    ) : permissions[12]?.actions.read ? (
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english"
                                ? "योजना का नाम"
                                : "Plan Name"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7"></th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7">
                              {language === "english"
                                ? "प्रति घर की कीमत"
                                : "Price Per household"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7 ps-2">
                              {language === "english" ? "अंतराल" : "Interval"}
                            </th>
                            <th className="text-dark text-center text-sm font-weight-bolder opacity-7 ps-2">
                              {language === "english"
                                ? "भुगतान मुद्रा"
                                : " Payment Currency"}
                            </th>
                            <th className="text-center text-dark text-sm font-weight-bolder opacity-7">
                              {language === "english" ? "कार्रवाई" : "Actions"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentSubscriptions.length > 0 ? (
                            currentSubscriptions
                              .reverse()
                              .map((item, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle">
                                    <span
                                      className={
                                        item.name === "Standard"
                                          ? "plan-basic"
                                          : item.name === "Elite"
                                          ? "plan-premium"
                                          : item.name === "Supreme"
                                          ? "plan-advanced"
                                          : ""
                                      }
                                    >
                                      {item.name.toUpperCase()}
                                    </span>
                                  </td>
                                  <td className="align-middle text-center"></td>
                                  <td className="align-middle text-center">
                                    {item.price}
                                  </td>
                                  <td className="align-middle text-center">
                                    <span
                                      className={
                                        item.interval === "monthly"
                                          ? "interval-monthly"
                                          : item.interval === "annual"
                                          ? "interval-annual"
                                          : item.interval === "quarterly"
                                          ? "interval-quarterly"
                                          : ""
                                      }
                                    >
                                      {item.interval === "monthly"
                                        ? language === "english"
                                          ? "मासिक"
                                          : "Monthly"
                                        : item.interval === "annual"
                                        ? language === "english"
                                          ? "वार्षिक"
                                          : "Annual"
                                        : item.interval === "quarterly"
                                        ? language === "english"
                                          ? "त्रैमासिक"
                                          : "Quarterly"
                                        : ""}
                                    </span>
                                  </td>
                                  <td className="align-middle text-center">
                                    <span
                                      className={
                                        item.commissionCurrency === "INR"
                                          ? "currency-inr"
                                          : item.commissionCurrency === "USD"
                                          ? "currency-usd"
                                          : ""
                                      }
                                    >
                                      {item.commissionCurrency === "INR"
                                        ? "₹"
                                        : item.commissionCurrency === "USD"
                                        ? "$"
                                        : ""}
                                    </span>
                                  </td>
                                  <td className="actions align-middle text-center ">
                                    <div className=" d-flex justify-content-center">
                                      <SubscriptionModal data={item} />

                                      {permissions[12]?.actions.edit ? (
                                        <Tooltip
                                          title={
                                            language === "english"
                                              ? "संपादित करें"
                                              : "Click to Edit"
                                          }
                                        >
                                          <button
                                            className="edit-btn"
                                            onClick={() => handleEdit(item._id)}
                                          >
                                            <MdOutlineModeEdit />
                                          </button>
                                        </Tooltip>
                                      ) : (
                                        <p></p>
                                      )}
                                      {permissions[12]?.actions.delete ? (
                                        <Tooltip
                                          title={
                                            language === "english"
                                              ? "हटाएं"
                                              : "Click to Delete"
                                          }
                                        >
                                          <button
                                            className="dlt-btn"
                                            onClick={() =>
                                              handleDelete(item._id)
                                            }
                                          >
                                            <MdDelete />
                                          </button>
                                        </Tooltip>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <div className="no_data_entry">
                                  No data available
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                    {filteredSubscription.length > subscriptionsPerPage && (
                      <div className="table-pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={Math.ceil(
                              filteredSubscription.length / subscriptionsPerPage
                            )}
                            page={currentPage}
                            onChange={handleChangePage}
                          />
                        </Stack>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ViewSubscription;
