import React, { useState, useEffect, useContext } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
// Import necessary Chart.js modules
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { PORT } from "../Api/api";
import { ThreeCircles } from "react-loader-spinner";
import { LanguageContext } from "../lib/LanguageContext";
import "./style.css"
const SocietyAdminDashboard = ({ society_id }) => {
  const [apiData, setApiData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const thisYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(thisYear);
  const societyId = JSON.parse(localStorage.getItem("society_id"));
  const { language } = useContext(LanguageContext);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const currentYear = new Date().getFullYear(); // Get the current year
  const previousYear = currentYear - 1; // Previous year
  const previousToPreviousYear = currentYear - 2;

  const fetchEntriesCounts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${PORT}/fetchYearlyRegularAndOccasionalVisitor/${society_id}`,
        {
          params: { selectedYear }, // Automatically appends `?selectedYear=value` to the URL
        }
      );
      const data = response.data;
      //  console.log('data',data);
      setApiData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching society data:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (societyId) {
      fetchEntriesCounts();
    }
  }, [selectedYear]);

  let SelectedYearEntryCount;
  if (selectedYear) {
    SelectedYearEntryCount = apiData?.yearlyVisitorData || {};
    // console.log('SelectedYearEntryCount',SelectedYearEntryCount)
  }

  const data = {
    labels: [
      language == "english"
        ? `रेगुलर दौरा (${SelectedYearEntryCount?.regular || 0})`
        : `Regular Visits (${SelectedYearEntryCount?.regular || 0})`,
      language == "english"
        ? `प्रासंगिक दौरा (${SelectedYearEntryCount?.occasional || 0})`
        : `Occasional Visits  (${SelectedYearEntryCount?.occasional || 0})`,
    ],
    datasets: [
      {
        // label: "User Data",
        data: [
          SelectedYearEntryCount?.regular || 0,
          SelectedYearEntryCount?.occasional || 0,
        ],
        backgroundColor: [
          "#2196f3", // Blue
          "#ff9800", // Orange
        ],
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="row mt-4 ">
      <br />
      <br />
      <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
        <div className="card z-index-2 h-100">
          <div className="mx-3 mt-3">
            <label className="me-2">
              {language === "hindi" ? ` Select Year : ` : ` वर्ष चुनें : `}
            </label>
            <select value={selectedYear} onChange={handleYearChange}>
              <option value={previousToPreviousYear}>
                {previousToPreviousYear}
              </option>
              <option value={previousYear}>{previousYear}</option>
              <option value={currentYear}>{currentYear}</option>
            </select>
          </div>
          <div className="card-header pb-0 pt-3 bg-transparent">
            <h6 className="text-capitalize">
              {/* {language === "hindi" ? "Society" : " सोसाइटी"} */}
            </h6>
            <p className="text-sm mb-0">
              <i className="fa fa-arrow-up text-success"></i>
              {/* <span className="font-weight-bold"></span> in{" "}
                            {societyYear} */}
            </p>
            {isLoading ? (
              <div className="chartdata_loader">
                <ThreeCircles
                  visible={true}
                  height={50}
                  width={50}
                  color="#5e72e4"
                  ariaLabel="three-circles-loading"
                />
              </div>
            ) : SelectedYearEntryCount.occasional != 0 ||
              SelectedYearEntryCount.regular != 0 ? (
              <div style={{ width: "50%", margin: "auto" }}>
                <h6 style={{ textAlign: "center" }}>
                  {language === "hindi"
                    ? ` Yearly Regular / Occasional Visits   `
                    : ` वार्षिक रेगुलर / प्रासंगिक दौरा `}
                </h6>
                <Pie data={data} options={options} />
              </div>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyAdminDashboard;
