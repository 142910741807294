import React, { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GuardLayout from "../lib/GuardLayout";
import { LanguageContext } from "../lib/LanguageContext";
import Navbar from "../Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import useCountdown from "../lib/useCountdown";
import axios from "axios";
import Swal from "sweetalert2";
import { PORT } from "../Api/api";
import { Hourglass } from "react-loader-spinner";
import { Button } from "react-bootstrap";
import ButtonLoader from "../Loading/ButtonLoader";
function SendOtpMail() {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);
  const [user, setUser] = useState(null);
  const { start, secondsLeft, minsLeft, ms } = useCountdown(null);
  const [timeup, setTimeup] = useState(false);
  const location = useLocation();
  const affiliateUser = location.state?.affiliateUser;
  const societyUser = location.state?.societyUser;
  const corporateUser = location.state?.corporateUser; //21/01/2025
  const images = ["/societyimage.jpg", "/society2.jpg", "/society.jpg"];
  const [currentImage, setCurrentImage] = useState(images[0]); // Default image
  // Validation Schema affiliateUser
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(language === "english" ? "अमान्य ईमेल" : "Invalid email")
      .required(
        language === "english" ? "ईमेल आवश्यक है" : "Email is required"
      ),
    OTP: Yup.string()
      .length(
        4,
        language === "english"
          ? "ओटीपी 4 अंक का होना चाहिए"
          : "OTP must be 4 digits"
      )
      .required(language === "english" ? "ओटीपी आवश्यक है" : "OTP is required"),
  });

  // Formik Setup
  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("forgotEmail"),
      OTP: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (emailFlag) {
        checkOTP();
      } else {
        sendOTP();
      }
    },
  });

  // OTP Session Expired
  if (secondsLeft === 1) {
    Swal.fire({
      position: "center",
      icon: "error",
      title: language === "english" ? "समय समाप्त !" : "Time Up !",
      showConfirmButton: false,
      timer: 1500,
    });
    setTimeout(() => {
      setTimeup(true);
    }, 1500);
  }

  // Generate OTP
  const sendOTP = async () => {
    setTimeup(false);
    setLoading(true);
    try {
      const url = `${PORT}sendOtpEmail`;
      const response = await axios.post(url, {
        query: formik.values.email,
        affiliateUser,
        societyUser,
        corporateUser,
      });
      setLoading(false);
      setUser(response.data.payload);
      if (response.status === 200) {
        setEmailFlag(true);
        Swal.fire({
          position: "center",
          icon: "success",
          title:
            language === "english"
              ? "ओटीपी ईमेल सफलतापूर्वक भेज दिया गया है."
              : "OTP email sent.",
          timer: 1500,
        });
        start(120);
      }
    } catch (error) {
      setLoading(false);
      const errorMsg =
        language === "english"
          ? "कृपया बाद में पुनः प्रयास करें। आंतरिक सर्वर त्रुटि"
          : "Please try again later. Internal server error.";
      if (error.response?.status === 500) {
        Swal.fire("Error!", errorMsg, "error");
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: error.response?.data || errorMsg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      setEmailFlag(false);
    } finally {
      setLoading(false);
    }
  };

  // Check OTP
  const checkOTP = async () => {
    setLoading(true);
    try {
      if (timeup) {
        return Swal.fire("OTP Expired !", "Please get new OTP ", "error");
      }
      const url = `${PORT}MatchEmailOtp`;
      const payload = { otp: formik.values.OTP };
      if (user.affiliateUser) payload.affiliateUser = user.affiliateUser;
      if (user.societyUser) payload.societyUser = user.societyUser;
      if (user.corporateUser) payload.corporateUser = user.corporateUser;
      const response = await axios.post(url, payload);
      setLoading(false);
      if (response.status === 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: language === "english" ? "ओटीपी सत्यापित" : "OTP Verified",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/changePassword", { state: payload });
      }
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: language === "english" ? "अमान्य ओटीपी" : "Invalid OTP",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // console.log(formik.errors);
  //background image update
  useEffect(() => {
    if (images.length === 0) return;

    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        const currentIndex = images.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % images.length;
        return images[nextIndex];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [images]);
  return (
    // <div className="background_image" style={{
    //   backgroundImage: `url(${currentImage})`

    // }}>
    <GuardLayout>
      <Navbar />

      <div className="login-form-main">
        <div className="login-form-left">
          <div className="main-form-affiliate">
            <div
              className="form-container-affiliate-login"
              style={{ height: "auto", minHeight: "200px" }}
            >
              <h2 className="affiliate-login_title">
                {language === "english" ? "पासवर्ड रीसेट" : "Reset Password"}
              </h2>
              <form onSubmit={formik.handleSubmit}>
                {/* Email Input Section */}
                {!emailFlag && (
                  <div className="form-group">
                    <input
                      type="email"
                      className={`form-control mt-2 ${
                        formik.touched.email && formik.errors.email
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder={
                        language === "english"
                          ? "ईमेल पता दर्ज करें"
                          : "Enter email"
                      }
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="invalid-feedback text-light">
                        <span className="text-light">
                          {formik.errors.email}
                        </span>
                      </div>
                    )}
                    <button
                      className="forgot-submit-button"
                      onClick={sendOTP}
                      disabled={loading}
                    >
                      {/* {loading && <Hourglass visible={true} height="20" width="20" />} */}
                      {loading ? (
                        <ButtonLoader color="green" />
                      ) : language === "english" ? (
                        "ओटीपी भेजें"
                      ) : (
                        "Send OTP"
                      )}
                    </button>
                  </div>
                )}

                {/* OTP Input Section */}
                {emailFlag && (
                  <div className="form-group">
                    <p className="text-sm text-center">
                      {language === "english"
                        ? "हमने कोड भेज दिया है"
                        : "We've sent code to"}{" "}
                      <strong>{formik.values.email}</strong>
                    </p>
                    {/* <input
                    type="number"
                    className={`form-control mt-2 ${
                      formik.touched.OTP && formik.errors.OTP ? "is-invalid" : ""
                    }`}
                    placeholder={
                      language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
                    }
                    name="OTP"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.OTP}
                    disabled={timeup}
                    maxLength={4}
                  /> */}

                    <input
                      type="text"
                      id="OTP"
                      name="OTP"
                      className={`form-control mt-2 ${
                        formik.touched.OTP && formik.errors.OTP
                          ? "is-invalid"
                          : ""
                      }`}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Restrict to numeric input
                      }}
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        formik.handleChange(e); // Use Formik's handleChange
                        formik.setFieldValue("OTP", numericValue); // Update the value correctly
                      }}
                      onBlur={formik.handleBlur} // Trigger Formik's touched state
                      placeholder={
                        language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
                      }
                      maxLength="4"
                      aria-required="true"
                      aria-label={
                        language === "english" ? "ओटीपी दर्ज करें" : "Enter OTP"
                      }
                    />
                    {formik.touched.OTP && formik.errors.OTP && (
                      <div className="invalid-feedback">
                        <span className="text-light">{formik.errors.OTP}</span>
                      </div>
                    )}
                    <button
                      className="forgot-submit-button"
                      type="submit"
                      disabled={timeup || loading}
                    >
                      {/* {loading && <Hourglass visible={true} height="20" width="20" />} */}
                      {loading ? (
                        <ButtonLoader color="green" />
                      ) : language === "english" ? (
                        "ओटीपी सबमिट करें"
                      ) : (
                        "Submit OTP"
                      )}
                    </button>
                  </div>
                )}
              </form>
              <div
                className="d-flex justify-content-end "
                style={{ marginBottom: "-10px" }}
              >
                {timeup && (
                  <span onClick={sendOTP} style={{ cursor: "pointer" }}>
                    {language === "english"
                      ? `कोड नहीं मिला ? `
                      : `Didn't get a Code ? `}
                    <strong>
                      {language === "english"
                        ? `पुनः भेजें.`
                        : `Click to resend.`}
                    </strong>
                  </span>
                )}
                {secondsLeft !== null && secondsLeft > 0 && !timeup && (
                  <p className="text-light mt-2 text-center">
                    <strong>
                      {language === "english"
                        ? `शेष समय : `
                        : `Time Remaining : `}
                    </strong>{" "}
                    0{minsLeft}:{ms < 10 ? `0${ms}` : ms}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="login-form-right">
          <div
            className="background_image"
            style={{
              backgroundImage: `url(${currentImage})`,
            }}
          >
            {" "}
          </div>
        </div>
      </div>
    </GuardLayout>
    // </div>
  );
}

export default SendOtpMail;
