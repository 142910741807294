import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { PORT } from "../Api/api";
import "./style.css";
import SmallLoader from "../Loading/SmallLoader";
import { LanguageContext } from "../lib/LanguageContext";
const SuperAdminTotalRevenueChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const [revenueData, setRevenueData] = useState({
    totalPaidRevenue: 0,
    totalUnpaidRevenue: 0,
    totalRevenue: 0,
  });

  useEffect(() => {
    const fetchRevenueData = async () => {
      try {
        const response = await axios.get(`${PORT}/getPaidAndUnpaidRevenue`);
        setRevenueData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching revenue data:", error);
      }
    };
    fetchRevenueData();
  }, []);

  // Ensure total revenue is not zero to prevent division errors
  const totalRevenue = revenueData.totalRevenue || 1;
  const paidPercentage = (revenueData.totalPaidRevenue / totalRevenue) * 100;

  // Chart Data
  const chartData = [
    {
      name: "Paid Revenue",
      value: revenueData.totalPaidRevenue,
      color: "#4CAF50",
    },
    {
      name: "Unpaid Revenue",
      value: revenueData.totalUnpaidRevenue,
      color: "#FF7043",
    },
  ];

  // SVG width & height (important for alignment)
  const width = 400;
  const height = 400;

  // Find the center of the pie chart
  const cx = width / 1.9; // Center X
  const cy = height / 1.5; // Lowering center Y for semicircle

  // Needle calculations
  const needleLength = 100;
  const needleAngle = 180 - paidPercentage * 1.8; // Convert percentage to an angle
  const needleX = cx + needleLength * Math.cos((needleAngle * Math.PI) / 180);
  const needleY = cy - needleLength * Math.sin((needleAngle * Math.PI) / 180);

  return (
    <div className="card z-index-2 h-100">
      <div className="card-header pb-0 pt-3 bg-transparent">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            // Full height to center vertically
            textAlign: "center",
          }}
          className="revenue-main-div"
        >
          <h6 className="revenue-title-heading">
            {/* Total Revenue {revenueData.totalRevenue.toLocaleString()} ₹ */}
          </h6>

          {isLoading ? (
            <div className="chart-revenue-loader">
              {" "}
              <SmallLoader size={50} />{" "}
            </div>
          ) : chartData.length > 0 ? (
            <div style={{ width: "100%" }}>
              <h6
                className="all-user-count-heading"
                style={{ textAlign: "center" }}
              >
                {language === "hindi" ? "Revenue Overview" : "राजस्व का अवलोकन"}
              </h6>
              <ResponsiveContainer width="100%" height={400}>
                <PieChart width={width} height={height}>
                  {/* Pie Chart with Smooth Animation */}
                  <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={chartData}
                    cx={cx} // Center X
                    cy={cy} // Center Y
                    innerRadius={100}
                    outerRadius={180}
                    fill="#8884d8"
                    stroke="none"
                    animationDuration={3000} // Slow animation (3 seconds)
                    animationBegin={200} // Delay before animation starts (0.5 seconds)
                    isAnimationActive={true} // Enable smooth transition
                    labelLine={false}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>

                  {/* Default Tooltip */}
                  <Tooltip />

                  {/* Needle (Blue Line) */}
                  <svg
                    width={width}
                    height={height}
                    style={{ position: "absolute", top: 0, left: 0 }}
                  >
                    <line
                      x1={cx}
                      y1={cy}
                      x2={needleX}
                      y2={needleY}
                      stroke="#0000FF"
                      strokeWidth="5"
                      strokeLinecap="round"
                    />
                    {/* Needle Base (Circle) */}
                    <circle cx={cx} cy={cy} r="5" fill="#000000" />
                    {/* <text
                      x={cx}
                      y={cy}
                      textAnchor="middle"
                      fontSize="18"
                      fontWeight="bold"
                      fill="#000"
                    >
                      ₹{revenueData.totalRevenue.toLocaleString()}
                    </text> */}
                  </svg>
                </PieChart>
                <div className="bottom-total-revenue">
                  <h6>
                    {language === "hindi" ? "Total Revenue" : "कुल राजस्व"}
                    &nbsp;<b>
                      ₹{revenueData.totalRevenue.toLocaleString()}
                    </b>{" "}
                  </h6>
                </div>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="no-data-found-chart">No Data Found</div>
          )}
        </div>{" "}
      </div>
    </div>
  );
};

export default SuperAdminTotalRevenueChart;
