import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup"; // Import Yup for validation
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { useFormikContext } from "formik";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
import "./style.css";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
import decodeToken from "../../../../corporateLib/decodeToken/DecodeToken";
import "./style.css";
import EditImageViewModal from "./EditImgaeViewModal";
import Swal from "sweetalert2";
// Validation schema using Yup
const validationSchema = Yup.object({
  userDetails: Yup.object({
    fullName: Yup.string()
      .required("Full name is required")
      .min(2, "Full name must be at least 2 characters long"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be 10 digits"),
    address: Yup.string().required("Address is required"),
  }),
  loginCredentials: Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
  }),
  role: Yup.string().required("Role is required"),
  //   userImage: Yup.mixed().required("User image is required"),
});

const CorporateUserEdit = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  const [file, setFile] = useState("");
  const { language } = useContext(LanguageContext);
  const [rolesTitle, setRolesTitle] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedRoleType, setSelectedRoleType] = useState("");
  const ADMINLevel = localStorage.getItem("ADMINLevel");
  const GUARDLevel = localStorage.getItem("GUARDLevel");
  const params = useParams();
  const id = params.id;
  // /getUserById/:id
  const getUserById = async () => {
    try {
      const response = await axios.get(`${PORT}/getUserById/${id}`);
      console.log(response);
      setUserData(response.data.user);
    } catch (error) {
      console.log(error);
    }
  };
  // Add corporate user
  const handleSubmit = async (values, { setSubmitting }) => {
    const selectedRole = roles.find((role) => role.title === values.role);
    const roleLevel =
      selectedRole.roleType == "guard" ? GUARDLevel : ADMINLevel;
    try {
      const formData = new FormData();
      formData.append("name", values.userDetails.fullName);
      formData.append("email", values.userDetails.email);
      formData.append("phone", values.userDetails.phone);
      formData.append("fullName", values.userDetails.fullName);
      formData.append("address", values.userDetails.address);
      formData.append("password", values.loginCredentials.password);
      formData.append("username", values.loginCredentials.username);
      formData.append("role", values.role);
      formData.append("level", roleLevel);
      formData.append("createdBy", tokenData.id);
      if (file) {
        formData.append("userImage", file);
      }
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to update the User",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!",
      });

      if (!result.isConfirmed) return;
      await axios.put(`${PORT}editCorporateUser/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("User Updated Successfully!");
      setTimeout(() => {
        navigate("/corporate/corporateUserView");
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.errors[0]);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split("/")[0];
      if (fileType === "image") {
        setFile(e.target.files[0]);
        setFieldValue("userImage", file);
      }
    }
  };
  //getRoleData
  // funciton lock
  useEffect(() => {
    const getRoleData = async () => {
      try {
        let response = await axios.get(`${PORT}/getCorporateRole`);
        const apiRoles = await response.data.roles;
        setRoles(apiRoles);
        const filterDataRole = apiRoles.filter(
          (item) => item.createdBy === tokenData.id
        );

        // const rolesWithLessDefaultLevel = apiRoles.filter(
        //   (item) => item.level > ADMINLevel
        // );
        const rolesWithLessDefaultLevel = apiRoles.filter((item) => {
          switch (tokenData.data.level) {
            case "1":
              return item.level > 1 && item.level <= 3;
            case "2":
              return item.level > 2 && item.level <= 3;
            case "4":
              return item.level > 4 && item.level <= 6;
            case "5":
              return item.level > 5 && item.level <= 6;
            default:
              return false;
          }
        });
        const extractedData = rolesWithLessDefaultLevel.map((role) => ({
          id: role._id, // Assuming role has an id field
          title: role.title, // Assuming role has a title field
          level: role.level,
        }));

        const filteredRolesData = filterDataRole.map((role) => ({
          id: role._id,
          title: role.title,
          level: role.level,
        }));
        // Combine the data while avoiding duplicates using a Set
        const roleIdSet = new Set();
        const combinedData = [...extractedData, ...filteredRolesData].filter(
          (role) => {
            if (roleIdSet.has(role.id)) {
              return false;
            } else {
              roleIdSet.add(role.id);
              return true;
            }
          }
        );
        setRolesTitle(combinedData);
      } catch (error) {
        console.error("Error fetching role data:", error);
      }
    };
    getRoleData();
  }, []);
  useEffect(() => {
    getUserById();
  }, []);

  return (
    <>
      <CorporateLayout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? "Add User" : "उपयोगकर्ता जोड़ें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <div className="society_form_div">
          <Formik
            initialValues={{
              role: userData.role, // Default value for 'role'
              userDetails: {
                fullName: userData?.userDetails?.fullName || "", // Fallback to an empty string if undefined
                email: userData?.userDetails?.email || "", // Same for email
                phone: userData?.userDetails?.phone || "", // Same for phone
                address: userData?.userDetails?.address || "", // Same for address
              },
              loginCredentials: {
                username: userData?.loginCredentials?.username || "", // Fallback to empty string for username
                password: userData?.loginCredentials?.password || "", // Same for password
              },
              userImage: null, // Initialize with null if there is no image
            }}
            validationSchema={validationSchema} // Add validation schema
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form encType="multipart/form-data">
                <div className="society_main">
                  {/* User Details */}
                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi"
                        ? "User Details"
                        : "उपयोगकर्ता विवरण"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="planType">
                        {language === "hindi" ? "Name " : " नाम"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="userDetails.fullName"
                        placeholder="Full Name"
                        className="edit-input "
                      ></Field>
                      <ErrorMessage
                        name="userDetails.fullName"
                        component="div"
                        className="corporatError"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Email" : " ईमेल "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="email"
                        name="userDetails.email"
                        placeholder="Email"
                        className="edit-input "
                      ></Field>
                      <ErrorMessage
                        name="userDetails.email"
                        component="div"
                        className="corporatError"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Phone No." : "फोन नंबर"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="userDetails.phone"
                        placeholder="Phone Number"
                        className="edit-input "
                        maxLength={10}
                        pattern="\d{10}"
                        title="Please enter a valid 10-digit phone number"
                      ></Field>
                      <ErrorMessage
                        name="userDetails.phone"
                        component="div"
                        className="corporatError"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Address" : "पता"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="userDetails.address"
                        placeholder="Address"
                        className="edit-input "
                      ></Field>
                      <ErrorMessage
                        name="userDetails.address"
                        component="div"
                        className="corporatError"
                      />
                    </div>
                    <div>
                      <label htmlFor="role" className="edit_society_lable">
                        {language === "hindi" ? " Role" : "भूमिका "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field as="select" name="role" className="edit-input">
                        <option value="">
                          {language === "hindi"
                            ? "Select role"
                            : "भूमिका चुनें"}
                        </option>
                        {rolesTitle?.map((role) => (
                          <option key={role.id} value={role.title}>
                            {role.title}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="corporatError"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Image" : "छवि"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <div className="input-container">
                        <input
                          type="file"
                          placeholder="Image"
                          name="userImage"
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                          className="edit-input"
                          accept="image/*"
                        />
                        <button
                          type="button"
                          className="eye-button"
                          // Assuming `toggleImageModal` is your function for the Eye Button action
                        >
                          <EditImageViewModal data={userData} />
                        </button>
                      </div>

                      {/* <ErrorMessage
                        name="userImage"
                        component="div"
                        className="corporatError"
                      /> */}
                    </div>
                  </div>

                  {/* Login Credentials */}
                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi" ? "Login Details" : "लॉगिन विवरण"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="username">
                        {language === "hindi" ? "Username" : "उपयोगकर्ता नाम"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="loginCredentials.username"
                        placeholder="Username"
                        className="edit-input"
                      />
                      <ErrorMessage
                        name="loginCredentials.username"
                        component="div"
                        className="corporatError"
                      />
                    </div>

                    <div>
                      <label className="edit_society_lable" htmlFor="password">
                        {language === "hindi" ? "Password" : "पासवर्ड"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="password"
                        name="loginCredentials.password"
                        placeholder="Password"
                        className="edit-input"
                      />
                      <ErrorMessage
                        name="loginCredentials.password"
                        component="div"
                        className="corporatError"
                      />
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="society_bottom_btn_div">
                    <button
                      className="society_btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <ButtonLoader />
                      ) : language === "hindi" ? (
                        "    Submit"
                      ) : (
                        "  जमा करना"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </CorporateLayout>
      <ToastContainer />
    </>
  );
};

export default CorporateUserEdit;
