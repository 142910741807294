import React, { useContext, useEffect, useState } from "react";
import CorporateGuardNavbar from "../../corporateGuardComponents/CorporateGuardNavbar/CorporateGuardNavbar";
import {
  TextField,
  Container,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import Box from "@mui/material/Box";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import decodeToken from "../../../corporatePanel/corporateLib/decodeToken/DecodeToken";
import Swal from "sweetalert2";
import getCurrentTime from "../../../../lib/CurrentTime";
import getCurrentDate from "../../../../lib/CurrentData";
import { GoArrowDownLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
const CorporateGuardVisitorDetails = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const purpose = JSON.parse(localStorage.getItem("purpose"));
  const token = localStorage.getItem("corporateGuardToken");
  const [corporateCompany, setCorporateCompany] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState(""); // To store selected corporate
  console.log(selectedCorporate, "selectedCorporate=");
  const tokenData = decodeToken(token);
  const handleAddVisitorEntry = async () => {
    const data = {
      visitorName: name,
      visitorPhone: phoneNumber,
      visitorPurposeId: purpose,
      guardId: tokenData?.data?._id,
      owner_id: tokenData?.data?.owner_id,
      companyId: selectedCorporate,
      clockIn: getCurrentTime(),
      clockInDate: getCurrentDate(),
      corporate_id: tokenData.data.corporate_id,
      status: "Approved",
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.post(`${PORT}/createVisitor`, data);
      console.log(response);
      Swal.fire({
        title: "Submitted!",
        text: "Entry has been Submitted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      navigate("/corporateGuardPurpose");
    } catch (error) {}
  };
  const handleBack = () => {
    navigate(-1);
  };
  // fetchCorporate
  const fetchCorporate = async () => {
    try {
      const response = await axios.get(`${PORT}/getAllCorporates`);
      const res = await response.data.filter(
        (item) =>
          item.corporate_id === tokenData?.data.corporate_id ||
          item.createdBy === tokenData?.data.corporate_id
      );
      console.log(res, "Corporate Compnayu");
      setCorporateCompany(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCorporate();
  }, []);
  return (
    <div>
      <CorporateGuardNavbar />
      <div className="entry-heading">
        <h1 className="m-font">
          {language === "english" ? "आगंतुक विवरण" : "Visitor Details"}
        </h1>
      </div>
      {/* <div className="add_more_data_main"> */}
      <div className="">
        <form
          // onSubmit={handleSubmitFormData}
          className="add_more_data_inner_form"
        >
          <Box sx={{ width: "400px" }}>
            <TextField
              label={language === "english" ? "नाम" : "Name"}
              name="name"
              variant="outlined"
              fullWidth
              required
              value={name} // Bind the value of the input to the state
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>{" "}
          <br /> <br />
          <Box sx={{ width: "400px" }}>
            <TextField
              label={language === "english" ? "फ़ोन नंबर" : "Phone Number"}
              name="phoneNumber"
              variant="outlined"
              fullWidth
              required
              value={phoneNumber} // Bind the value of the input to the state
              onChange={(e) => {
                const value = e.target.value;
                // Allow only digits and enforce 10 digits
                if (/^\d{0,10}$/.test(value)) {
                  setPhoneNumber(value); // Only update if it's a valid 10-digit number
                }
              }} // Update state when value changes
              InputLabelProps={{
                shrink: true,
              }}
              type="tel"
            />
          </Box>
          <br />
          <br />
          {/* Corporate Dropdown */}
          <Box sx={{ width: "400px" }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>
                {language === "hindi" ? "Corporate Company" : "कॉर्पोरेट कंपनी"}
              </InputLabel>
              <Select
                value={selectedCorporate}
                onChange={(e) => setSelectedCorporate(e.target.value)}
                required
                label={
                  language === "hindi" ? "Corporate Company" : "कॉर्पोरेट कंपनी"
                }
              >
                {corporateCompany.map((company) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company?.companyDetails?.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </form>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="submit-btn">
        <button onClick={handleAddVisitorEntry}>
          {language === "english" ? "सबमिट करें" : "Submit"}
        </button>
      </div>
      <div>
        <Tooltip
          title={language === "english" ? "  पिछला पृष्ठ" : "Previous Page"}
          placement="top"
          arrow
        >
          <div className="back-btn">
            <button onClick={handleBack}>
              <IoIosArrowBack />
            </button>
            &nbsp;
            <b className="back_text">
              {language === "english" ? "  पिछला" : "Back"}
            </b>
          </div>
        </Tooltip>
      </div>
    </div>

    // </div>
  );
};

export default CorporateGuardVisitorDetails;
