import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { useNavigate } from "react-router-dom";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import { ThreeCircles } from "react-loader-spinner";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import ViewModalCorporate from "../../../../corporatePages/";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
import decodeToken from "../../../../../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
// import ViewCompanyModal from "../modalCompanyView/ViewCompanyModal";
const CorporateUserView = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [corporateData, setCorporateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  const permissions = tokenData?.roleData?.permissions;
  //   //handleAddCompany
  const handleAddUser = () => {
    navigate("/corporate/corporateUserAdd");
  };
  //handleEditCorporate
  const handleEditCorporateUser = (id) => {
    navigate(`/corporate/corporateUserEdit/${id}`);
  };
  //handleDeleteCorporate
  const handleDeleteCorporate = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.delete(`${PORT}/deleteCorporateUser/${id}`);
      Swal.fire({
        title: "Deleted!",
        text: "Corporate has been deleted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      fetchCorporate();
    } catch (error) {
      console.log(error);
    }
  };
  // fetchCorporate
  const fetchCorporate = async () => {
    try {
      const response = await axios.get(`${PORT}/getCorporateUser`);
      const res = await response.data.user.filter(
        (item) => item.createdBy == tokenData.id
      );
      setCorporateData(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  //formatDate1 timestap
  function formatDate1(dateString) {
    const date = new Date(dateString); // Create a Date object from the string

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day is single digit
    const month = date
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase(); // Get the month in short form (e.g., 'FEB')
    const year = date.getFullYear(); // Get the full year

    // Format the date in the desired format: DD-MMM-YYYY
    return `${day}-${month}-${year}`;
  }
  // Search filter and Pagination Functionlaity

  //handle page change functionality
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
  };
  //pagination functionlaity
  useEffect(() => {
    const filtered = corporateData.filter((item) =>
      item.role?.toLowerCase().includes(searchQuery.trim().toLowerCase())
    );
    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / perPage));
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, corporateData]);

  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, filteredData.length);
  const currentPageData = filteredData.slice(startIndex, endIndex);
  useEffect(() => {
    fetchCorporate();
  }, []);

  // function lock
  useEffect(() => {
    fetchCorporate();
  }, []);
  return (
    <>
      <CorporateLayout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="filtered-div">
                    <div>
                      {permissions[1]?.actions?.includes("create") && (
                        <div className="society_add_btn">
                          <button className="" onClick={handleAddUser}>
                            {language === "hindi" ? "  Add +" : "+ जोड़ें"}
                          </button>
                        </div>
                      )}
                    </div>
                    {permissions[1]?.actions?.includes("read") && (
                      <div className="search-filter-box">
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "25ch" },
                          }}
                        >
                          <TextField
                            id="search-input"
                            label={
                              language === "hindi" ? "Search..." : "खोज..."
                            }
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchInput}
                            className="search-input"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: " #5e72e4",
                                  borderWidth: "1px",
                                  borderRadius: "5px",
                                },
                                "&:hover fieldset": {
                                  borderColor: " #5e72e4",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: " #5e72e4",
                                },

                                "& .MuiInputLabel-root.Mui-error": {
                                  color: "red",
                                },
                              },
                            }}
                          />
                        </Box>
                      </div>
                    )}
                  </div>{" "}
                </div>
                <div class="card-body px-0 pt-0 pb-2 w-100">
                  <div class="table-responsive p-0">
                    {loading ? (
                      <div className="three_circle_loader">
                        <ThreeCircles
                          visible={true}
                          height={100}
                          width={100}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : permissions[1]?.actions?.includes("read") ? (
                      <table className="table align-items-center mb-0  ">
                        <thead>
                          <tr>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Name" : "नाम"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "Username"
                                : "उपयोगकर्ता नाम"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Password" : "पासवर्ड"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Role" : "भूमिका"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Status" : "स्थिति"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "   Date" : "तारीख"}
                            </th>

                            <th className="text-center  text-center text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Actions" : "कार्रवाई"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentPageData.length > 0 ? (
                            currentPageData.reverse().map((item, index) => (
                              <tr key={index}>
                                <td className=" text-center align-middle ">
                                  {item.userDetails.fullName || "Not Added"}
                                </td>
                                <td className="align-middle text-center">
                                  {item.loginCredentials.username}
                                </td>
                                <td className="align-middle text-center">
                                  {item.loginCredentials.password}
                                </td>
                                <td className="align-middle text-center">
                                  {item.role}
                                </td>
                                <td className="align-middle text-center">
                                  <span
                                    style={{
                                      color: item.status ? "green" : "red",
                                    }}
                                  >
                                    {item.status == true
                                      ? "Active"
                                      : "Inactive"}
                                  </span>
                                </td>
                                <td className="align-middle text-center">
                                  {formatDate1(item.createdAt)}
                                </td>

                                <td className="actions align-middle text-center">
                                  <div className="d-flex justify-content-center">
                                    {/* <ViewCompanyModal data={item} /> */}
                                    {permissions[1]?.actions?.includes(
                                      "edit"
                                    ) && (
                                      <button
                                        className="edit-btn"
                                        onClick={() =>
                                          handleEditCorporateUser(item._id)
                                        }
                                      >
                                        {" "}
                                        <MdOutlineModeEdit
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            language === "hindi"
                                              ? "Click to Edit"
                                              : "संपादित करें"
                                          }
                                        />{" "}
                                      </button>
                                    )}
                                    {permissions[1]?.actions?.includes(
                                      "delete"
                                    ) && (
                                      <button
                                        className="dlt-btn"
                                        onClick={() =>
                                          handleDeleteCorporate(item._id)
                                        }
                                      >
                                        <MdDelete
                                          data-placement="top"
                                          title={
                                            language === "hindi"
                                              ? "Click to Delete"
                                              : "हटाएं"
                                          }
                                        />
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>
                                <div className="no_data_entry"> No data</div>
                              </td>
                            </tr>
                          )}
                          <td colSpan={7}>
                            {totalPages > 1 && (
                              <div className="table-pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                  />
                                </Stack>
                              </div>
                            )}
                          </td>
                        </tbody>
                      </table>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CorporateLayout>
    </>
  );
};

export default CorporateUserView;
