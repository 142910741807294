import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { PORT } from "../Api/api";
import { LanguageContext } from "../lib/LanguageContext";
import SmallLoader from "../Loading/SmallLoader";

const SuperAdminTopAgency = () => {
  const [topAgencies, setTopAgencies] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [availableMonths, setAvailableMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = useContext(LanguageContext);

  // Memoized function for color assignment
  const getColorByIndex = useCallback((index) => {
    const colors = ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"];
    return colors[index % colors.length];
  }, []);

  // Fetch data from API
  const getTopAgencies = useCallback(async (month) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${PORT}/getTopBillingAgency`);
      if (response.data.success) {
        const months = Object.keys(response.data.monthly);
        setAvailableMonths(months);

        // Select default month if none is set
        const defaultMonth = month || months[0];
        setSelectedMonth(defaultMonth);

        const agenciesData =
          response.data.monthly[defaultMonth]?.map((agency, index) => ({
            name: agency.agencyName,
            value: agency.total === 0 ? 0.01 : agency.total,
            color: getColorByIndex(index),
          })) || [];

        setTopAgencies(agenciesData);
      }
    } catch (error) {
      console.error("Error fetching top agencies:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getColorByIndex]);

  useEffect(() => {
    getTopAgencies(selectedMonth);
  }, [selectedMonth, getTopAgencies]);

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month); // Update selected month
    getTopAgencies(month);   // Fetch data for the selected month immediately
  };
  // Custom Bar Shape (Removes Hover Effect)
  const CustomBarShape = (props) => {
    const { fill, x, y, width, height } = props;
    return <rect x={x} y={y} width={width} height={height} fill={fill} />;
  };

  return (
    <div className="card z-index-2">
      <div className="card-header pb-0 pt-3 bg-transparent h-100 mb-2">
        <div className="label-title-div-inner">
          <label>
            {language === "english" ? "महीना और वर्ष चुनें :" : "Select Month and Year :"}
          </label>
          <select onChange={handleMonthChange} value={selectedMonth}>
            {availableMonths.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <h6 className="all-user-count-heading mt-2" style={{ textAlign: "center" }}>
          {language === "english" ? "शीर्ष एजेंसियों का अवलोकन" : "Top Agencies Overview"}
        </h6>

        {isLoading ? (
          <div className="loader-container" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "100px",
            height: "33vh",
          }}>
            <SmallLoader size={50} />
          </div>
        ) : topAgencies.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart layout="vertical" data={topAgencies} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <XAxis type="number" hide />
              <YAxis dataKey="name" type="category" width={120} />
              <Tooltip
                content={({ payload }) =>
                  payload?.length ? (
                    <div className="custom-tooltip">
                      <p>{`Agency: ${payload[0].payload.name}`}</p>
                      <p>{`Total Revenue: ₹${payload[0].payload.value === 0 ? "0" : payload[0].payload.value}`}</p>

                    </div>
                  ) : null
                }
              />
              <Bar dataKey="value" barSize={50} shape={CustomBarShape}>
                {topAgencies.map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="no-data-found-chart">No Data Found</div>
        )}
      </div>
    </div>
  );
};

export default SuperAdminTopAgency;
