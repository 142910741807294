import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import {  Form, Field, ErrorMessage } from "formik";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
import "./style.css";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
const CompanyEdit = () => {
  const [corporateData, setCorporateData] = useState([]);
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const params = useParams();
  const id = params.id;
  // Yup Validation Schema
  const validationSchema = Yup.object({
    ownerDetails: Yup.object({
      fullName: Yup.string().required("Full Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .required("Phone number is required"),
      address: Yup.string().required("Address is required"),
    }),
    companyDetails: Yup.object({
      companyName: Yup.string().required("Company name is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Company address is required"),
      companyPhone: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
        .required("Company phone number is required"),
      GSTNumber: Yup.string().required("GST number is required"),
    }),
    loginCredentials: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
  });
  //   getCorporateWithId
  const fetchCorporateWithId = async () => {
    try {
      const response = await axios.get(`${PORT}/getCorporateById/${id}`);
      console.log(response);
      setCorporateData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  //edit corporate
  const handleEditSubmit = async (values, { setSubmitting }) => {
    try {
      await axios.put(`${PORT}/editCorporate/${id}`, values);
      toast.success("Company Edit Successfully!");
      setTimeout(() => {
        navigate("/corporate/corporateCompanyView");
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.errors[0]);
    } finally {
      setSubmitting(false);
    }
  };
  //function lock
  useEffect(() => {
    fetchCorporateWithId();
  }, []);

  return (
    <>
      <CorporateLayout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi"
              ? " Edit Company"
              : "कंपनी संपादित करें"}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <div className="society_form_div">
          <Formik
            initialValues={{
              name: corporateData.name || "",
              role: corporateData.role || "owner",
              ownerDetails: corporateData.ownerDetails || {
                fullName: "",
                email: "",
                phone: "",
                address: "",
              },
              companyDetails:
                corporateData.role === "company"
                  ? corporateData.companyDetails
                  : {
                      companyName: "",
                      state: "",
                      city: "",
                      address: "",
                      companyPhone: "",
                      GSTNumber: "",
                    },
              loginCredentials: corporateData.loginCredentials || {
                username: "",
                password: "",
              },
            }}
            enableReinitialize={true}
            onSubmit={handleEditSubmit}
            validationSchema={validationSchema}
         >

         {({ isSubmitting, setFieldValue, values, errors }) => {
              return (
                <Form enctype="multipart/form-data">
                  <div className="society_main">
                    {/* Owner Details */}
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi" ? "Owner Details" : "मालिक विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="planType"
                        >
                          {language === "hindi" ? "Name " : "नाम"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="ownerDetails.fullName"
                          placeholder="Full Name"
                          className="edit-input "
                        />
                        <ErrorMessage
                          name="ownerDetails.fullName"
                          component="div"
                          className="error_msg_society"
                        />
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "Email" : "ईमेल "}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="email"
                          name="ownerDetails.email"
                          placeholder="Email"
                          className="edit-input "
                        />
                        <ErrorMessage
                          name="ownerDetails.email"
                          component="div"
                          className="error_msg_society"
                        />
                      </div>

                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "Phone No." : "फोन नंबर"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="number"
                          name="ownerDetails.phone"
                          placeholder="Phone Number"
                          maxLength={10}
                          pattern="\d{10}"
                          className="edit-input "
                        />
                        <ErrorMessage
                          name="ownerDetails.phone"
                          component="div"
                          className="error_msg_society"
                        />
                      </div>

                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "Address" : "पता"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="ownerDetails.address"
                          placeholder="Address"
                          className="edit-input "
                        />
                        <ErrorMessage
                          name="ownerDetails.address"
                          component="div"
                          className="error_msg_society"
                        />
                      </div>
                    </div>

                    {/* Company Details */}
                    {values.role === "company" && (
                      <div className="society_main_div">
                        <div className="society_detail_heading">
                          {language === "hindi"
                            ? "Company Details"
                            : "कंपनी विवरण"}
                        </div>
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="planType"
                          >
                            {language === "hindi"
                              ? "Company Name "
                              : "कंपनी का नाम"}
                            <span className="Star_color">*</span>
                          </label>
                          <br />
                          <Field
                            type="text"
                            name="companyDetails.companyName"
                            placeholder="Company Name"
                            className="edit-input "
                          />
                          <ErrorMessage
                            name="companyDetails.companyName"
                            component="div"
                            className="error_msg_society"
                          />
                        </div>
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="intervalType"
                          >
                            {language === "hindi" ? "State" : "राज्य"}
                            <span className="Star_color">*</span>
                          </label>
                          <br />
                          <Field
                            type="text"
                            name="companyDetails.state"
                            placeholder="State"
                            className="edit-input "
                          />
                          <ErrorMessage
                            name="companyDetails.state"
                            component="div"
                            className="error_msg_society"
                          />
                        </div>
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="intervalType"
                          >
                            {language === "hindi" ? "City" : "शहर"}
                            <span className="Star_color">*</span>
                          </label>
                          <br />
                          <Field
                            type="text"
                            name="companyDetails.city"
                            placeholder="City"
                            className="edit-input "
                          />
                          <ErrorMessage
                            name="companyDetails.city"
                            component="div"
                            className="error_msg_society"
                          />
                        </div>
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="intervalType"
                          >
                            {language === "hindi"
                              ? "Company Address"
                              : "कंपनी का पता"}
                            <span className="Star_color">*</span>
                          </label>
                          <br />
                          <Field
                            type="text"
                            name="companyDetails.address"
                            placeholder="Company Address"
                            className="edit-input "
                          />
                          <ErrorMessage
                            name="companyDetails.address"
                            component="div"
                            className="error_msg_society"
                          />
                        </div>
                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="intervalType"
                          >
                            {language === "hindi"
                              ? "Company Phone No."
                              : "कंपनी फोन नंबर"}
                            <span className="Star_color">*</span>
                          </label>
                          <br />

                          <Field
                            type="number"
                            name="companyDetails.companyPhone"
                            placeholder="Company Phone"
                            className="edit-input "
                          ></Field>
                          <ErrorMessage
                            name="companyDetails.companyPhone"
                            component="div"
                            className="error_msg_society"
                          />
                        </div>

                        <div>
                          <label
                            className="edit_society_lable"
                            htmlFor="intervalType"
                          >
                            {language === "hindi" ? "GST No." : "जीएसटी नंबर"}
                            <span className="Star_color">*</span>
                          </label>
                          <br />

                          <Field
                            type="text"
                            name="companyDetails.GSTNumber"
                            placeholder="GST Number"
                            className="edit-input "
                          ></Field>
                          <ErrorMessage
                            name="companyDetails.GSTNumber"
                            component="div"
                            className="error_msg_society"
                          />
                        </div>
                      </div>
                    )}

                    {/* Login Credentials */}
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi"
                          ? "Login Details "
                          : "लॉगिन विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="username"
                        >
                          {language === "hindi" ? "Username" : "उपयोगकर्ता नाम"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="loginCredentials.username"
                          placeholder="Username"
                          className="edit-input"
                        />
                        <ErrorMessage
                          name="loginCredentials.username"
                          component="div"
                          className="error_msg_society"
                        />
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="password"
                        >
                          {language === "hindi" ? "Password" : "पासवर्ड"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="password"
                          name="loginCredentials.password"
                          placeholder="Password"
                          className="edit-input"
                        />
                        <ErrorMessage
                          name="loginCredentials.password"
                          component="div"
                          className="error_msg_society"
                        />
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div className="society_bottom_btn_div">
                      <button
                        className="society_btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <ButtonLoader />
                        ) : language === "hindi" ? (
                          "Submit"
                        ) : (
                          "जमा करना"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </CorporateLayout>
      <ToastContainer />
    </>
  );
};

export default CompanyEdit;
