import React, { useState, useEffect, useRef, useContext } from "react";
import { MdQrCodeScanner } from "react-icons/md";
import { QrReader } from "react-qr-reader";
import jsQR from "jsqr";
import "./style.css";
import { MdPhotoLibrary } from "react-icons/md";
import { LanguageContext } from "../../../../lib/LanguageContext";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { IoMdClose } from "react-icons/io";
import { GoArrowDownLeft } from "react-icons/go";
import { GoArrowUpRight } from "react-icons/go";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FaHouseUser } from "react-icons/fa";
import {
  X,
  Building2,
  MapPin,
  Phone,
  FileSpreadsheet,
  Calendar,
  Clock,
} from "lucide-react";
import getCurrentTime from "../../../../lib/CurrentTime";
import getCurrentDate from "../../../../lib/CurrentData";
import decodeToken from "../../../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
import Swal from "sweetalert2";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "70%", // Ensures the dialog takes full width
    maxWidth: "100%", // Ensures it doesn't exceed the screen width
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const QRScanner = () => {
  const [data, setData] = useState(""); // To store the scanned result
  const [showScanner, setShowScanner] = useState(false); // To toggle the scanner modal
  const [error, setError] = useState(""); // To handle errors
  const videoRef = useRef(null); // To store the video element reference
  const { language } = useContext(LanguageContext);
  const [openDialog, setOpenDialog] = useState(false); // Modal visibility
  const [visitor, setVisitor] = useState([]);
  const [value, setValue] = React.useState(0);
  const [state, setState] = useState({
    right: false,
  });
  const token = localStorage.getItem("corporateGuardToken");
  const tokenData = decodeToken(token);
  const handleClickOpen = (visitor) => {
    setOpenDialog(true); // Open the dialog/modal
  };
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog/modal
    setVisitor("");
    setData("");
    setShowScanner(false);
  };
  // Function to stop the camera
  const stopCamera = () => {
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null; // Clear video source
    }
  };

  const handleCloseScanner = () => {
    stopCamera(); // Stop the camera
    setShowScanner(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // Ensure cleanup when the scanner modal is unmounted
    return () => stopCamera();
  }, []);

  const handleGalleryUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        const image = new Image();
        image.src = imageData;
        image.onload = () => {
          // Create a canvas to extract QR code data from the image
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, canvas.width, canvas.height);

          // Get image data for QR code processing
          const imageData = context.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          const code = jsQR(imageData.data, canvas.width, canvas.height);

          if (code) {
            handleCloseScanner();
            setData(code.data); // QR Code content
            fetchVisitor(code.data);
          } else {
            setError("No QR code found in the uploaded image.");
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
  const fetchVisitor = async (data) => {
    try {
      const res = await axios.get(`${PORT}/getVisitor`);
      console.log(res.data.user);
      const filterUser = await res?.data?.user?.filter(
        (item) => item.visitorId === data
      );
      console.log(filterUser, "filterUser");
      setVisitor(filterUser[0]);
      if (filterUser[0]) {
        handleClickOpen();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleStatusChange = async (status, id) => {
    try {
      const data = {
        guardId: tokenData?.data?._id,
        clockIn: getCurrentTime(),
        clockInDate: getCurrentDate(),
        status: status,
      };
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        customClass: {
          container: "my-swal-modal",
        },
      });

      if (!result.isConfirmed) return;
      const res = await axios.put(`${PORT}/updateVisitorStatus/${id}`, data);
      console.log(res);
      handleCloseDialog();
      Swal.fire({
        title: "Submitted!",
        text: "Entry has been Submitted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="qr-scanner-container">
      <div className="qr-button" onClick={() => setShowScanner(true)}>
        <MdQrCodeScanner size={24} className="qr-icon" />

        {language === "english" ? "क्यूआर कोड स्कैन करें" : "Scan QR code"}
      </div>

      {showScanner && (
        <div className="qr-modal-overlay">
          <div className="qr-modal-content">
            {/* Scanner Header */}
            <div className="qr-modal-header">
              <h2>
                {language === "english"
                  ? "क्यूआर कोड स्कैन करें"
                  : "Scan QR code"}
              </h2>
              <button className="qr-close-button" onClick={handleCloseScanner}>
                {language === "english" ? "बंद करें" : "Close"}
              </button>
            </div>

            {/* QR Code Scanner */}
            <div className="qr-scanner-box">
              <QrReader
                videoId="qr-video"
                videoRef={videoRef} // Reference for stopping the camera
                onResult={(result, error) => {
                  if (result) {
                    console.log(result);
                    handleCloseScanner();
                    setData(result.text);
                    fetchVisitor(result.text);
                  }
                  if (error) {
                    setError("Error scanning QR Code");
                  }
                }}
                constraints={{
                  facingMode: "environment",
                  width: 640,
                  height: 480,
                }}
                style={{ width: "100%", height: "100%" }}
              />
            </div>

            {/* Error Message */}
            {error && <p className="qr-error-message">{error}</p>}

            {/* Upload from Gallery */}
            <div className="qr-gallery-upload">
              <input
                type="file"
                accept="image/*"
                className="qr-hidden-input"
                id="gallery-upload"
                onChange={handleGalleryUpload}
              />
              <label htmlFor="gallery-upload" className="qr-upload-label">
                <MdPhotoLibrary size={20} /> &nbsp;
                {language === "english"
                  ? "गैलरी से अपलोड करें"
                  : "   Upload from Gallery"}
              </label>
            </div>
          </div>
        </div>
      )}

      {/* Modal/Dialog for Full Visitor Details */}
      <BootstrapDialog
        style={{
          zIndex: 99,
          width: "100%",
        }}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="visitor-dialog-title"
      >
        <DialogTitle
          id="visitor-dialog-title"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              <b>
                {language === "english"
                  ? "पूरी आगंतुक जानकारी"
                  : "Full Visitor Details"}
              </b>
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {visitor && (
            <>
              {/* Action Buttons */}
              <div className="sticky-buttons-container">
                <button
                  className="custom-approve-btn"
                  onClick={() => handleStatusChange("Approved", visitor._id)}
                >
                  Approved
                </button>
                <button
                  className="custom-reject-btn"
                  onClick={() => handleStatusChange("Rejected", visitor._id)}
                >
                  Rejected
                </button>
              </div>
              <div>
                <div className="section-card">
                  <h3 className="section-title">
                    {language === "english"
                      ? "कंपनी की जानकारी"
                      : "Company Information"}
                  </h3>
                  <div className="section-grid">
                    <div className="info-item">
                      <Building2
                        className="w-5 h-5"
                        style={{ color: "#6b7280" }}
                      />
                      <div>
                        <p className="info-label">
                          {language === "english"
                            ? "कंपनी का नाम"
                            : "Company Name"}
                        </p>
                        <p className="info-value">
                          {visitor?.companyId?.companyDetails?.companyName}
                        </p>
                      </div>
                    </div>
                    <div className="info-item">
                      <MapPin
                        className="w-5 h-5"
                        style={{ color: "#6b7280" }}
                      />
                      <div>
                        <p className="info-label">
                          {" "}
                          {language === "english" ? "पता" : "Address"}{" "}
                        </p>
                        <p className="info-value">
                          {visitor?.companyId?.companyDetails?.address}
                        </p>
                        <p className="text-small">
                          {visitor?.companyId?.companyDetails.city},{" "}
                          {visitor?.companyId?.companyDetails?.state}
                        </p>
                      </div>
                    </div>
                    <div className="info-item">
                      <Phone className="w-5 h-5" style={{ color: "#6b7280" }} />
                      <div>
                        <p className="info-label">
                          {language === "english"
                            ? "फ़ोन नंबर"
                            : "Phone Number"}
                        </p>
                        <p className="info-value">
                          {visitor?.companyId?.companyDetails?.companyPhone}
                        </p>
                      </div>
                    </div>
                    <div className="info-item">
                      <FileSpreadsheet
                        className="w-5 h-5"
                        style={{ color: "#6b7280" }}
                      />
                      <div>
                        <p className="info-label">
                          {" "}
                          {language === "english"
                            ? "जीएसटी नंबर"
                            : "GST Number "}
                        </p>
                        <p className="info-value">
                          {visitor?.companyId?.companyDetails?.GSTNumber}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Timestamps */}
                <div className="section-card">
                  <h3 className="section-title">
                    {" "}
                    {language === "english"
                      ? "आगंतुक विवरण"
                      : "Visitor Details  "}
                  </h3>
                  <div className="section-grid">
                    <div className="info-item">
                      <Calendar
                        className="w-5 h-5"
                        style={{ color: "#6b7280" }}
                      />
                      <div>
                        <p className="info-label">
                          {" "}
                          {language === "english" ? "नाम" : "Name"}
                        </p>
                        <p className="info-value">{visitor.visitorName}</p>
                      </div>
                    </div>
                    <div className="info-item">
                      <Clock className="w-5 h-5" style={{ color: "#6b7280" }} />
                      <div>
                        <p className="info-label">
                          {" "}
                          {language === "english" ? "फ़ोन नंबर" : "Phone no."}
                        </p>
                        <p className="info-value">{visitor.visitorPhone}</p>
                      </div>
                    </div>
                    <div className="info-item">
                      <Clock className="w-5 h-5" style={{ color: "#6b7280" }} />
                      <div>
                        <p className="info-label">
                          {" "}
                          {language === "english" ? "तस्वीरें" : "Pictures"}
                        </p>
                        <div>
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                              <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                className="society-view-details-tabs"
                              >
                                <Tab
                                  label={
                                    <div className="top-heading-label-title">
                                      <FaHouseUser /> &nbsp;{" "}
                                      {language === "english"
                                        ? "तस्वीरें"
                                        : "Pictures"}
                                    </div>
                                  }
                                  {...a11yProps(0)}
                                />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                              {/* visitor */}
                              <div className="mt-1 view-society-logo-div">
                                <div className="regular-view-images-div">
                                  {visitor?.visitorImages?.length > 0 ? (
                                    Array.isArray(visitor.visitorImages) &&
                                    visitor.visitorImages.map(
                                      (imgPath, index) => {
                                        const modifiedPath = imgPath.substring(
                                          imgPath.indexOf("public/") +
                                            "public/".length
                                        );
                                        const imageUrl = `${PORT}/${modifiedPath}`;

                                        return (
                                          <img
                                            key={index}
                                            src={imageUrl}
                                            alt=""
                                          />
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="no-data">No Data Found</div>
                                  )}
                                </div>
                              </div>
                            </CustomTabPanel>
                          </Box>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default QRScanner;
