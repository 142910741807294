import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { FaUserPlus } from "react-icons/fa";
import "./style.css";
import decodeToken from "../../../../corporate/corporatePanel/corporateLib/decodeToken/DecodeToken";
import { FaUser, FaBuilding, FaClipboardList } from "react-icons/fa";
import {
  AiOutlineBuild,
  AiOutlineBulb,
  AiOutlineFileText,
  AiOutlineUserSwitch,
} from "react-icons/ai";
const AffiliateSidebar = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");
  const decodedToken = decodeToken(token);
  const permissions = decodedToken?.roleData?.permissions;

  // correct code
  useEffect(() => {
    const resetSession = () => {
      const now = new Date().getTime();
      localStorage.setItem("lastActivity", now); // Update last activity on user interaction
    };
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      const lastActivity = localStorage.getItem("lastActivity");
      const lastLogout = localStorage.getItem("lastLogout");
      const now = new Date().getTime();
      const TIMEOUT_DURATION = 3 * 60 * 60 * 1000; // 3 hours in milliseconds for better testing
      // const TIMEOUT_DURATION = 25 * 1000; // 25 seconds in milliseconds
      if (token) {
        // If it's the first login (no lastLogout value), handle it differently
        if (!lastLogout) {
          // If there is no `lastLogout`, consider it as the first login, so no timeout.
          localStorage.setItem("lastLogout", now); // Set lastLogout on first login
        }
        // Check if last activity or last logout time exceeds the inactivity duration
        if (
          (lastActivity && now - parseInt(lastActivity) >= TIMEOUT_DURATION) ||
          (lastLogout && now - parseInt(lastLogout) >= TIMEOUT_DURATION)
        ) {
          expireSession(); // Call the expire session function
        }
      }
    };

    const expireSession = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("lastActivity");
      localStorage.removeItem("lastLogout");
      Swal.fire({
        icon: "warning",
        title: "Session Expired",
        text: "Your session has expired due to inactivity. Please log in again.",
        confirmButtonText: "OK",
      }).then(() => {
        localStorage.clear();
        navigate("/affiliateLogin"); // Redirect to login after session expiration
      });
    };

    // Set lastActivity on page load if not already set
    if (!localStorage.getItem("lastActivity")) {
      localStorage.setItem("lastActivity", new Date().getTime());
    }

    // Listen for user activity and reset session timer
    window.addEventListener("click", resetSession);
    window.addEventListener("keydown", resetSession);
    window.addEventListener("scroll", resetSession);
    window.addEventListener("mousemove", resetSession);

    // Store logout time when tab is closed
    const handleBeforeUnload = () => {
      localStorage.setItem("lastLogout", new Date().getTime());
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Check token expiration every minute (for quicker detection during development)
    const interval = setInterval(checkTokenExpiration, 60000);

    return () => {
      clearInterval(interval);
      window.removeEventListener("click", resetSession);
      window.removeEventListener("keydown", resetSession);
      window.removeEventListener("scroll", resetSession);
      window.removeEventListener("mousemove", resetSession);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);
  return (
    <>
      <aside
        className="sidenav bg-white navbar navbar-vertical  border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a className="navbar-brand m-0 text-center" target="_blank">
            <div className="side-nav-top-logo ">
              <img src="/newLogoGuardx.png" alt="" className="" />
            </div>
            <span className="ms-1 font-weight-bold"></span>
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="affiliate-side-nav-main-div">
          <ul className="navbar-nav">
            {/* Dashboard */}
            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link "
                to="/corporate/corporateDashboard"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <MdDashboard className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "hindi" ? " Dashboard" : " डैशबोर्ड"}
                  </span>
                </span>
              </NavLink>
            </li>
            {/* Companey */}
            {permissions[2]?.actions?.includes("module") && (
              <li className="nav-item">
                <NavLink
                  className="affiliate-nav-link "
                  to="/corporate/corporateCompanyView"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="affiliate-nav-link-text text-start">
                    <FaBuilding className="icon_font_size" />

                    <span className="nav-module-right">
                      &nbsp; {language === "hindi" ? " Company" : "कंपनी"}
                    </span>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Security Guard(User) */}
            {permissions[1]?.actions.includes("module") && (
              <li className="nav-item">
                <NavLink
                  className="affiliate-nav-link "
                  to="/corporate/corporateUserView"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="affiliate-nav-link-text text-start">
                    <FaUser className="icon_font_size" />

                    <span className="nav-module-right">
                      &nbsp; {language === "hindi" ? "Users" : "उपयोगकर्ता"}
                    </span>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Role*/}
            {permissions[0]?.actions.includes("module") && (
              <li className="nav-item">
                <NavLink
                  className="affiliate-nav-link "
                  to="/corporate/corporatePurposeView"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="affiliate-nav-link-text text-start">
                    <AiOutlineBulb className="icon_font_size" />

                    <span className="nav-module-right">
                      &nbsp;{" "}
                      {language === "hindi"
                        ? "Purpose of Occasional"
                        : "अतिथि प्रविष्टि अनुरोध"}
                    </span>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Purpose Of Visit */}
            {permissions[3]?.actions.includes("module") && (
              <li className="nav-item">
                <NavLink
                  className="affiliate-nav-link "
                  to="/corporate/corporateRoleView"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="affiliate-nav-link-text text-start">
                    <AiOutlineUserSwitch className="icon_font_size" />

                    <span className="nav-module-right">
                      &nbsp; {language === "hindi" ? "Roles" : "भूमिकाएँ"}
                    </span>
                  </span>
                </NavLink>
              </li>
            )}
            {/* Visitor Data */}
            {permissions[3]?.actions.includes("module") && (
              <li className="nav-item">
                <NavLink
                  className="affiliate-nav-link "
                  to="/corporate/corporateVisitorView"
                >
                  <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                    <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                  </div>
                  <span className="affiliate-nav-link-text text-start">
                    <AiOutlineFileText className="icon_font_size" />

                    <span className="nav-module-right">
                      &nbsp;{" "}
                      {language === "hindi"
                        ? "Visitor Record "
                        : "आगंतुक रिकॉर्ड"}
                    </span>
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default AffiliateSidebar;
