import React, { useEffect } from "react";
import AffiliateLayout from "../../affiliateLib/affiliateLayout/AffiliateLayout";
import Swal from "sweetalert2";
import decodeToken from "../../affiliateLib/decodeToken/DecodeToken";
import { PORT } from "../../../../Api/api";
import axios from "axios";
import "./style.css";
const UnApprovedUser = () => {
  const token = localStorage.getItem("token");
  const CheckAccount = decodeToken(token);
  useEffect(() => {
    // Show the alert when the component is mounted
    unApprovedUser();
  }, []);
  const unApprovedUser = () => {
    Swal.fire({
      title: "Application Under Review",
      text: "Your application is under review and will be processed within 24 to 72 hours. Access will be granted upon verification.",
      icon: "info",
      confirmButtonText: "OK",
      showConfirmButton: false,
      allowOutsideClick: false, // Prevents closing by clicking outside
      allowEscapeKey: false, // Prevents closing with the Escape key
      customClass: {
        popup: "custom-swal-popup",
        title: "custom-swal-title",
        confirmButton: "custom-swal-button",
      },
      buttonsStyling: false, // Disables default styling for buttons
    });
  };
  return (
    <div>
      <AffiliateLayout
        style={{ pointerEvents: "none", opacity: 0.5 }}
      ></AffiliateLayout>
    </div>
  );
};

export default UnApprovedUser;
