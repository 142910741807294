import React, { useContext, useEffect, useMemo, useState } from "react";
import { PermissionContext } from "../../../../lib/PermissionContext";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { ThreeCircles } from "react-loader-spinner";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import "./style.css";
import { formatDate } from "../../../../lib/FormattedDate";
import { FaEdit } from "react-icons/fa";
import { FaCheckCircle, FaDollarSign } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import { FaLevelDownAlt } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import ModalViewBillingDetails from "../modalViewBillingDetails/ModalViewBillingDetails";
import Loading from "../../../../Loading/Loading";
import getCurrentDate from "../../../../lib/CurrentData";
import getCurrentTime from "../../../../lib/CurrentTime";
const SuperAdminViewBillingDetails = () => {
  const role_level = JSON.parse(localStorage.getItem("role")) || null;
  const { permissions } = useContext(PermissionContext);
  const { language } = useContext(LanguageContext);
  const [societyData, setSocietyData] = useState([]);
  const [societyFilterData, setSocietyFilterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSocietyId, setSelectedSocietyId] = useState(null);
  const [selectedSociety, setSelectedSociety] = useState(null);
  const [billsData, setBillsData] = useState([]);
  const [showAllBills, setShowAllBills] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showAllFilteredBills, setShowAllFilteredBills] = useState([]);
  const [selectedUpdatedSocietyId, selectSelectedUpdatedSocietyId] =
    useState("");
  const [showAllBillsLoading, setShowAllBillsLoading] = useState(false);
  //fetch society biils
  const fetchSocietyData = async () => {
    try {
      const response = await axios.get(`${PORT}/getAllBills`);
      const res = (await response.data) || [];
      const filtered = res.filter((b) => b.bills.length > 0);
      setSocietyData(filtered.reverse());
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);
    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  // Handle selection changes
  const handleSocietyChange = (event, value) => {
    if (showAllBills && value) {
      const filteredBills = showAllFilteredBills.filter(
        (bill) =>
          bill.society_id && bill.society_id._id === value.society_id._id
      );
      setBillsData(filteredBills);
    }
    if (value) {
      setSelectedSociety(value); // Update selected value
      setSelectedSocietyId(value.society_id?._id); // Update selected ID
      const data = societyData?.filter(
        (item) => item?.society_id?._id === value?.society_id?._id
      );
      setSocietyFilterData(data[0]); // Update filtered data
    }
  };
  //fetchSocietyBillsDetails
  const fetchSocietyBillsDetails = async () => {
    try {
      setShowAllBillsLoading(true);
      const response = await axios.get(`${PORT}/getAllBills`);
      const res = await response.data;
      const allBills = res
        .flatMap((billing) => billing.bills)
        .filter((billing) => billing.society_id?.isActive === true);
      setBillsData(allBills);
      setShowAllFilteredBills(allBills);
      setLoading(false);
      setShowAllBillsLoading(false);
    } catch (error) {
      console.log("Error fetching society data:", error);
      setLoading(false);
      setShowAllBillsLoading(false);
    }
  };
  //handleAllFilter
  const handleAllFilter = () => {
    setShowAllBills(true);
    fetchSocietyBillsDetails();
    // setSelectedSocietyId("")
    setSelectedSociety(null);
    setStartDate("");
    setEndDate("");

    // selectSelectedUpdatedSocietyId("");
  };
  //handleClearAllFilter
  const handleClearAllFilter = () => {
    setShowAllBills(false);
    setSearchQuery("");
    setSelectedMonth("");
    updateFirstSociety();
    setStartDate("");
    setEndDate("");
    setShowAllBills(false);
  };
  //
  const handleClearInput = () => {
    if (showAllBills) {
      setSelectedSociety("");
      setBillsData(showAllFilteredBills);
    } else {
      if (societyData && societyData.length > 0) {
        const firstSociety = societyData[0];
        setSelectedSociety(firstSociety);
        setSelectedSocietyId(firstSociety.society_id._id);
        setSocietyFilterData(firstSociety);
      }
    }
  };
  //handle payment status
  const handlePaymentStatus = async (
    society_id,
    billId,
    paymentStatus,
    paymentDate,
    paymentTime
  ) => {
    selectSelectedUpdatedSocietyId(society_id);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      // showAllBillsLoading(true)
      const data = {
        paymentStatus: paymentStatus,
        paymentDate: paymentDate ? paymentDate : getCurrentDate(),
        paymentTime: paymentTime ? paymentTime : getCurrentTime(),
      };
      const res = await axios.put(
        `${PORT}/updatePaymentStatus/${society_id}/${billId}`,
        data
      );

      Swal.fire({
        title: "Updated!",
        text: `${res.data.message}`,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      fetchSocietyData();
      fetchSocietyBillsDetails();
      const societyDataUpdatedSociety = () => {
        return societyData.find((item) => item.society_id._id === society_id);
      };
      setSelectedSociety((prevState) => ({
        ...prevState,
        bills: societyDataUpdatedSociety(),
      }));
      // showAllBillsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  //month logic
  const generateMonthOptions = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const currentYear = new Date().getFullYear();
    return months.map((month, index) => ({
      name: `${month} ${currentYear}`, // Format as "Jan 2024"
      value: `${month} ${currentYear}`, // Store as value too
    }));
  };
  const options = generateMonthOptions();
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    return new Date(`${year}-${month}-${day}`);
  };
  // Function to filter bills based on the selected month
  const filteredBills = useMemo(() => {
    // If neither selectedMonth nor searchQuery is provided, return all bills
    if (!selectedMonth && !searchQuery) return billsData;
    // Handle month filtering
    let filteredData = billsData;
    if (selectedMonth) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const [month, year] = selectedMonth.split(" ");
      const selectedDate = new Date(
        `${year}-${monthNames.indexOf(month) + 1}-01`
      );

      filteredData = filteredData.filter((bill) => {
        const billDate = parseDate(bill.submitedDate);
        return (
          billDate.getMonth() === selectedDate.getMonth() &&
          billDate.getFullYear() === selectedDate.getFullYear()
        );
      });
    }
    // Handle search query filtering
    if (searchQuery) {
      const lowerSearchQuery = searchQuery.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return (
          (item.paymentStatus &&
            item.paymentStatus.toLowerCase() === lowerSearchQuery) || // Exact match for paymentStatus
          (item.society_id?.name &&
            item.society_id.name.toLowerCase().includes(lowerSearchQuery)) || // Partial match for society name
          (item.society_id?.planType &&
            item.society_id.planType
              .toLowerCase()
              .includes(lowerSearchQuery)) ||
          (item.society_id?.address &&
            item.society_id.address.toLowerCase().includes(lowerSearchQuery)) || // Partial match for planType
          item.invoiceNo.toLowerCase().includes(lowerSearchQuery)
        );
      });
    }

    return filteredData;
  }, [selectedMonth, searchQuery, billsData]);
  //extract date
  //formated Date in Day
  function extractDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  //search filter
  const handleSearchInput = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query.trim() === "") {
      setSocietyFilterData(selectedSociety);
    } else {
      if (societyFilterData && Array.isArray(societyFilterData?.bills)) {
        const filteredBillss = societyFilterData?.bills?.filter(
          (item) =>
            item.paymentStatus.toLowerCase().includes(query) ||
            item.invoiceNo.toLowerCase().includes(query)
        );
        // Update only the 'bills' key in the state
        setSocietyFilterData((prevState) => ({
          ...prevState,
          bills: filteredBillss,
        }));
      }
    }
  };
  // Validation logic to ensure endDate is not before startDate
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    // Check if the end date is empty
    if (!selectedEndDate) {
      setEndDate(""); // Clear the end date if it's empty
      return;
    }
    if (selectedEndDate < startDate) {
      // setError("End date can't be before the start date.");
      alert("End date can't be before the start date.");
      setEndDate("");
      return; // Don't update endDate if it's invalid
    }
    setEndDate(selectedEndDate);
  };
  //function lock
  useEffect(() => {
    fetchSocietyData();
    fetchSocietyBillsDetails();
  }, []);
  // Automatically pick the first entry
  useEffect(() => {
    if (showAllBills) {
      const filteredBillsAccToSociety = showAllFilteredBills.filter(
        (bill) =>
          bill.society_id && bill.society_id._id === selectedUpdatedSocietyId
      );
      // console.log(filteredBillsAccToSociety, "filteredBillsAccToSociety---?>>");
      setBillsData(filteredBillsAccToSociety);
    } else if (selectedUpdatedSocietyId) {
      const findSociety = societyData.find(
        (item) => item.society_id._id === selectedUpdatedSocietyId
      );
      const firstSociety = findSociety;
      setSelectedSociety(firstSociety);
      setSelectedSocietyId(firstSociety?.society_id?._id);
      setSocietyFilterData(firstSociety);
      // selectSelectedUpdatedSocietyId("");
    } else if (societyData.length > 0 && !showAllBills) {
      const firstSociety = societyData[0];
      setSelectedSociety(firstSociety);
      setSelectedSocietyId(firstSociety?.society_id?._id);
      setSocietyFilterData(firstSociety);
    }
  }, [societyData, showAllBills]);
  const updateFirstSociety = () => {
    if (societyData && societyData.length > 0) {
      const firstSociety = societyData[0];
      setSelectedSociety(firstSociety);
      setSelectedSocietyId(firstSociety?.society_id._id);
      setSocietyFilterData(firstSociety);
    }
  };
  // Disable Button After 72 Hours
  const isPaymentExpired = (paymentDate, paymentTime) => {
    if (!paymentDate || !paymentTime) return false;
    // Convert "04-02-2025 10:15 am" => "2025-02-04T10:15:00"
    const [day, month, year] = paymentDate.split("-"); // Extract day, month, year
    const formattedDate = `${year}-${month}-${day}`; // Convert to "YYYY-MM-DD"
    const paymentDateTime = new Date(`${formattedDate} ${paymentTime}`); // Create Date object
    const currentTime = new Date();
    // Calculate difference in hours
    const hoursDifference = (currentTime - paymentDateTime) / (1000 * 60 * 60); // Convert ms to hours
    return hoursDifference >= 72; // Disable if 72 hours passed
  };
  //data range filter
  useEffect(() => {
    if (!societyFilterData?.bills) return;
    let data = [...societyFilterData?.bills]; // Clone to avoid mutation.
    if (startDate && endDate && !showAllBills) {
      data = data.filter((item) => {
        const startBillingDate = new Date(item.billing_period_startDate);
        const endBillingDate = new Date(item.billing_period_endDate);
        return (
          startBillingDate <= new Date(endDate) &&
          endBillingDate >= new Date(startDate)
        );
      });
      setSocietyFilterData((prevState) => ({
        ...prevState,
        bills: data,
      }));
    }
    if (showAllBills && startDate) {
      let filterDataAccToDate = [...showAllFilteredBills]; // Original data ki copy banayi
      filterDataAccToDate = filterDataAccToDate.filter((item) => {
        const startBillingDate = new Date(item.billing_period_startDate);
        const endBillingDate = new Date(item.billing_period_endDate);
        return (
          startBillingDate <= new Date(endDate) &&
          endBillingDate >= new Date(startDate)
        );
      });

      // Filtered data ko set karna
      setBillsData(filterDataAccToDate);
    }
  }, [societyFilterData, startDate, endDate]);

  return (
    <div className="card-body px-0 pt-0 pb-2 w-100 ">
      {loading ? (
        <Loading />
      ) : permissions[14]?.actions.read ? (
        <div className="table-responsive p-0 bg-black">
          <div className="top-heading-billing-dropdown-allFilter">
            {/* inputSelected Society */}
            <div>
              <Autocomplete
                id="country-select-demo"
                sx={{
                  width: 250,
                  margin: "10px",
                  "&.Mui-disabled": {
                    backgroundColor: "#f5f5f5", // Set background color for the disabled state
                    cursor: "not-allowed", // Change the cursor to indicate it's disabled
                    opacity: 0.6, // Make it less opaque when disabled
                  },
                }}
                options={societyData}
                value={selectedSociety}
                autoHighlight
                getOptionLabel={(option) =>
                  option?.society_id?.name ? option?.society_id?.name : ""
                }
                onChange={handleSocietyChange}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;

                  // Safely handle option.code with a fallback to 'default'
                  const flagCode = option.code
                    ? option.code.toLowerCase()
                    : "default";

                  return (
                    <Box
                      key={key}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...optionProps}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${flagCode}.png 2x`} // Use flagCode here
                        src={`https://flagcdn.com/w20/${flagCode}.png`} // Use flagCode here
                        alt=""
                      />
                      {option?.society_id?.name}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a society"
                    slotProps={{
                      htmlInput: {
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                          {/* Custom clear button */}
                          {selectedSociety && (
                            <IconButton onClick={handleClearInput} edge="end">
                              <ClearIcon />
                            </IconButton>
                          )}
                        </React.Fragment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: "#5e72e4" }, // Label color
                    }}
                  />
                )}
                disableClearable
              />
            </div>
            {/* search filter */}
            <div>
              <Box
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
              >
                <TextField
                  id="search-input"
                  label={language === "hindi" ? "Search..." : "खोज..."}
                  variant="outlined"
                  value={searchQuery}
                  onChange={handleSearchInput}
                  className="search-input"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        // borderColor: " #5e72e4",
                        borderWidth: "1px",
                        borderRadius: "5px",
                      },
                      "&:hover fieldset": {
                        // borderColor: " #5e72e4",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: " #5e72e4",
                      },

                      "& .MuiInputLabel-root.Mui-error": {
                        color: "red",
                      },
                    },
                  }}
                />
              </Box>
            </div>
            {/* all filter */}
            <div className="top-heading-all-filter-button">
              <button onClick={handleAllFilter}>
                {language === "hindi" ? "All" : "सभी"}
              </button>
            </div>
            {/* clear filter */}
            <div className="top-heading-clear-all-filter-button">
              <button onClick={handleClearAllFilter}>
                {language === "hindi" ? "Clear Filter" : "फिल्टर हटाये"}
              </button>
            </div>
            {/* month filter */}
            <div>
              <div
                className={`filterItemWrapper ${
                  showAllBills === false ? "disabled" : ""
                }`}
              >
                <div
                  className="filterItemButton"
                  onClick={() => setIsOpen((prev) => !prev)}
                >
                  <FaChevronDown
                    style={
                      isOpen
                        ? {
                            transform: "rotate(180deg)",
                            transition: "all 300ms ease-out",
                            color: "white",
                          }
                        : { color: "white" }
                    }
                  />
                  <span
                    style={{ color: "white" }}
                    className="selectedMonthLabel"
                  >
                    {selectedMonth ? (
                      selectedMonth
                    ) : (
                      <span className="select-month-font-size">
                        {language === "hindi" ? "Select Month" : "महीना चुनिए"}
                      </span>
                    )}
                  </span>
                </div>

                {isOpen && (
                  <div
                    className="filterItemDropdown"
                    style={{ minWidth: "150px" || "120px" }}
                  >
                    <div className="filterItemDropdownArrow"></div>
                    <ul className="filterItemList">
                      {options?.map((option, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setSelectedMonth(option.name);
                            setIsOpen(false);
                          }}
                          className={`filterItemListItem ${
                            selectedMonth === option.name
                              ? "filterItemListItemSelected"
                              : ""
                          }`}
                        >
                          {option.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {/* date range filter */}
            <div>
              <div>
                <TextField
                  className="m-1"
                  id="start-date"
                  label={
                    language === "hindi" ? "Start Date" : "आरंभ करने की तिथि"
                  }
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "#5e72e4" }, // Label color
                  }}
                  InputProps={{
                    style: { color: "#5e72e4" }, // Text color inside input
                  }}
                  variant="outlined"
                />
                <TextField
                  className="m-1"
                  id="end-date"
                  label={language === "hindi" ? "End Date" : "अंतिम तिथि"}
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  InputLabelProps={{
                    shrink: true,
                    style: { color: "#5e72e4" }, // Label color
                  }}
                  InputProps={{
                    style: { color: "#5e72e4" }, // Text color inside input
                  }}
                  variant="outlined"
                  disabled={!startDate}
                />
              </div>
            </div>
          </div>
          {showAllBills === true ? (
            <>
              <div className="billing-container">
                <div className="section-wrapper">
                  {/* Current Plan Summary */}
                  <div className="card-billing-wrapper">
                    <div className="card-billing-heading2">
                      <div className="card-billing-heading-title">
                        {language === "hindi"
                          ? "Billing Details"
                          : "बिलिंग विवरण"}{" "}
                      </div>
                    </div>
                    <div className="card-billing-body">
                      <div className="billing-container">
                        {permissions[14]?.actions.read ? (
                          <div className="parent-container">
                            {showAllBillsLoading ? (
                              <td className="no-data-billing-td" colSpan={7}>
                                <div className="billing-loading">
                                  <ThreeCircles
                                    visible={true}
                                    height={60}
                                    width={60}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              </td>
                            ) : (
                              <div className="table-container1">
                                <table className="billing-table">
                                  <thead className="billing-table-scroll">
                                    <tr>
                                      <th>Society Name</th>
                                      <th>Address</th>
                                      <th>Contact No.</th>
                                      <th>Plan Type</th>
                                      <th>Interval Type</th>
                                      <th>Society Status</th>

                                      <th>Billing Cycle</th>
                                      <th>Billing Period Start Date</th>
                                      <th>Billing Period End Date</th>
                                      <th>Bill Created Date</th>
                                      <th>Invoice No.</th>
                                      <th>Total Houses</th>
                                      <th>Amount</th>
                                      <th>Discount Amount</th>
                                      <th>GST Type</th>
                                      <th>GST Rate</th>
                                      <th>Net Payval</th>
                                      <th>Due Date</th>
                                      <th>Payment Status</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  {filteredBills?.length > 0 ? (
                                    filteredBills?.map((item) => {
                                      return (
                                        <>
                                          <tbody>
                                            <tr>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {item
                                                    ? item.society_id?.name
                                                    : "" || "No Data"}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {item
                                                    ? item.society_id?.address
                                                    : "" || "No Data"}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {item
                                                    ? item.society_id
                                                        ?.societyContactNumber
                                                    : "" || "No Data"}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong>
                                                  {item
                                                    ? item.society_id?.planType
                                                    : "" || "No Data"}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  className={
                                                    item
                                                      ? item.society_id
                                                          ?.intervalType
                                                      : "" === "monthly"
                                                      ? "interval-monthly"
                                                      : item
                                                      ? item.society_id
                                                          ?.intervalType
                                                      : "" === "annual"
                                                      ? "interval-annual"
                                                      : item
                                                      ? item.society_id
                                                          ?.intervalType
                                                      : "" === "quarterly"
                                                      ? "interval-quarterly"
                                                      : ""
                                                  }
                                                >
                                                  {item
                                                    ? item.society_id?.intervalType?.toUpperCase()
                                                    : "" || "No Data"}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    color:
                                                      item.society_id
                                                        ?.isActive === true
                                                        ? "green"
                                                        : item.society_id
                                                            ?.isActive === false
                                                        ? "red"
                                                        : "orange",
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {item
                                                    ? item.society_id?.isActive
                                                      ? "Active"
                                                      : "Inactive"
                                                    : ""}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  className={
                                                    item.billingCycle ===
                                                    "monthly"
                                                      ? "interval-monthly"
                                                      : item.billingCycle ===
                                                        "annual"
                                                      ? "interval-annual"
                                                      : item.billingCycle ===
                                                        "quarterly"
                                                      ? "interval-quarterly"
                                                      : ""
                                                  }
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    item?.billingCycle?.toUpperCase() ||
                                                    "monthly"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                <strong
                                                  style={{
                                                    // backgroundColor:
                                                    //   bgColorBillingPeriodStartDate,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    formatDateToCustom(
                                                      item.billing_period_startDate
                                                    )
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                <strong
                                                  style={{
                                                    // backgroundColor:
                                                    //   bgColorBillingPeriodStartDate,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    formatDateToCustom(
                                                      item.billing_period_endDate
                                                    )
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                <strong
                                                  style={{
                                                    // backgroundColor:
                                                    //   bgColorBillingPeriodStartDate,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : (
                                                    formatDate(
                                                      item.submitedDate
                                                    )
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorInvoiceNo,
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",

                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.invoiceNo ? (
                                                    item.invoiceNo
                                                  ) : (
                                                    "NO Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorTotalHouse,
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.totalHouse ? (
                                                    item.totalHouse
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <Tooltip
                                                  placement="top"
                                                  title={
                                                    <div>
                                                      Total Houses:{" "}
                                                      {item.totalHouse}
                                                      <br />
                                                      Per House Cost(
                                                      {item.currencyType}):{" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.pricePerHouse ||
                                                        "10"}
                                                      <br />
                                                      Bill Amount (
                                                      {item.currencyType}
                                                      ):{" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.totalHouse *
                                                        (item.pricePerHouse ||
                                                          10)}
                                                    </div>
                                                  }
                                                  arrow
                                                >
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.amount ? (
                                                      item.amount
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </Tooltip>
                                              </td>

                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorDiscountAmount,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.discountAmount ? (
                                                    ` ${
                                                      item.currencyType == "INR"
                                                        ? "₹"
                                                        : "$"
                                                    } ${
                                                      item.discountType ===
                                                      "fixed"
                                                        ? item.discountAmount
                                                        : item.pricePerHouse *
                                                          item.totalHouse *
                                                          (item.discountAmount /
                                                            100)
                                                    }`
                                                  ) : (
                                                    "NA"
                                                  )}
                                                </strong>
                                              </td>
                                              {/* GST TYPE */}
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  {item.gstType}
                                                </strong>
                                              </td>
                                              {/* GST RATE */}
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000",
                                                  }}
                                                >
                                                  {item.currencyType == "INR"
                                                    ? "₹"
                                                    : "$"}
                                                  {item.gstRate}
                                                </strong>
                                              </td>
                                              <td>
                                                <Tooltip
                                                  placement="top"
                                                  title={
                                                    <div>
                                                      Total Houses ={" "}
                                                      {item.totalHouse}
                                                      <br />
                                                      Per House Cost(
                                                      {item.currencyType}) ={" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.pricePerHouse ||
                                                        "10"}
                                                      <br />
                                                      Sub Total = {item.amount}
                                                      <br />
                                                      Discount Amount ={" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.discountType ===
                                                      "fixed"
                                                        ? item.discountAmount
                                                        : item.pricePerHouse *
                                                          item.totalHouse *
                                                          (item.discountAmount /
                                                            100)}
                                                      <br />
                                                      GstRate ={" "}
                                                      {item.currencyType ==
                                                      "INR"
                                                        ? "₹"
                                                        : "$"}
                                                      {item.gstRate}
                                                      <br />
                                                      Net Payval (
                                                      {item.currencyType}) ={" "}
                                                      {item.total}
                                                    </div>
                                                  }
                                                  arrow
                                                >
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorNetPayval,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.total ? (
                                                      item.total
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </Tooltip>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    // backgroundColor: bgColorDiscountAmount,
                                                    padding: "5px 10px",
                                                    borderRadius: "5px",
                                                    color: "#000", // Black text for contrast
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item && item.dueDate ? (
                                                    formatDate(item.dueDate)
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <strong
                                                  style={{
                                                    borderRadius: "5px",
                                                    padding: "5px 10px",

                                                    color:
                                                      item.paymentStatus ===
                                                      "paid"
                                                        ? "green"
                                                        : "red",
                                                  }}
                                                >
                                                  {loading ? (
                                                    <div className="">
                                                      <ThreeCircles
                                                        visible={true}
                                                        height={30}
                                                        width={30}
                                                        color="#5e72e4"
                                                        ariaLabel="three-circles-loading"
                                                      />
                                                    </div>
                                                  ) : item.amount ? (
                                                    item.paymentStatus.toUpperCase() ||
                                                    "unPaid"
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </strong>
                                              </td>
                                              <td>
                                                {" "}
                                                <div className="action-download-bill">
                                                  <div>
                                                    <Tooltip
                                                      disabled={
                                                        item.paymentStatus ===
                                                          "paid" ||
                                                        isPaymentExpired(
                                                          item.paymentDate,
                                                          item.paymentTime
                                                        )
                                                          ? "disabledPaymentButton"
                                                          : ""
                                                      }
                                                      onClick={(e) => {
                                                        // Allow the onClick to proceed only if the paymentStatus is not "paid"
                                                        if (
                                                          item.paymentStatus !==
                                                          "paid"
                                                        ) {
                                                          handlePaymentStatus(
                                                            item?.society_id
                                                              ?._id || "null",
                                                            item._id,
                                                            "paid",
                                                            item.paymentDate,
                                                            item.paymentTime
                                                          );
                                                        }
                                                        e.preventDefault(); // Prevents the click event from triggering for disabled state
                                                      }}
                                                      placement="top"
                                                      title={
                                                        item.paymentStatus ===
                                                        "paid"
                                                          ? language === "hindi"
                                                            ? "Paid"
                                                            : "भुगतान किया"
                                                          : language === "hindi"
                                                          ? "Update Payment Status"
                                                          : "भुगतान स्थिति अपडेट करें"
                                                      }
                                                      arrow
                                                    >
                                                      <span
                                                        className={`${
                                                          item.paymentStatus ===
                                                            "paid" ||
                                                          isPaymentExpired(
                                                            item.paymentDate,
                                                            item.paymentTime
                                                          )
                                                            ? "disabledPaymentButton"
                                                            : "action-to-updated-payment-status"
                                                        }`}
                                                      >
                                                        <FaCheckCircle />
                                                      </span>
                                                    </Tooltip>
                                                  </div>
                                                  <div>
                                                    <Tooltip
                                                      disabled={
                                                        item.paymentStatus ===
                                                          "paid" ||
                                                        isPaymentExpired(
                                                          item.paymentDate,
                                                          item.paymentTime
                                                        )
                                                          ? "disabledPaymentButton"
                                                          : ""
                                                      }
                                                      onClick={(e) => {
                                                        // Allow the onClick to proceed only if the paymentStatus is not "paid"
                                                        if (
                                                          item.paymentStatus ==
                                                          "paid"
                                                        ) {
                                                          handlePaymentStatus(
                                                            item?.society_id
                                                              ?._id || "null",
                                                            item._id,
                                                            "unpaid",
                                                            item.paymentDate,
                                                            item.paymentTime
                                                          );
                                                        }
                                                      }}
                                                      placement="top"
                                                      title={
                                                        item.paymentStatus ===
                                                        "unpaid"
                                                          ? language === "hindi"
                                                            ? "Unpaid"
                                                            : "बिना भुगतान किया गया बिल"
                                                          : language === "hindi"
                                                          ? "Update Payment Status"
                                                          : "भुगतान स्थिति अपडेट करें"
                                                      }
                                                      arrow
                                                    >
                                                      <span
                                                        className={`${
                                                          item.paymentStatus ===
                                                            "unpaid" ||
                                                          isPaymentExpired(
                                                            item.paymentDate,
                                                            item.paymentTime
                                                          )
                                                            ? "disabledPaymentButton"
                                                            : "action-to-updated-payment-status"
                                                        }`}
                                                      >
                                                        <FaTimesCircle />
                                                      </span>
                                                    </Tooltip>
                                                  </div>
                                                  <div>
                                                    <ModalViewBillingDetails
                                                      data={item}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>{" "}
                                        </>
                                      );
                                    })
                                  ) : (
                                    <td
                                      colSpan="17"
                                      className="no-data-row"
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {language === "english"
                                        ? "कोई डेटा नहीं"
                                        : "No Data"}
                                    </td>
                                  )}
                                </table>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="permission">
                            <h2>
                              {language === "hindi"
                                ? " You do not have permission to read this data"
                                : "आपको इस डेटा को पढ़ने की अनुमति नहीं है"}
                            </h2>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="card-billing-progress-body">
                      <div className="card-billing-progress-details"></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="billing-container">
                {/* Top Section */}
                <div className="section-wrapper">
                  {/* Current Plan Summary */}
                  <div className="card-billing-wrapper">
                    <div className="card-billing-heading1">
                      <div className="card-billing-heading-title">
                        Society Details
                      </div>
                      <div className="card-billing-heading-button">
                        {/* <button>Upgrade</button> */}
                      </div>
                    </div>
                    {societyData ? (
                      <div className="card-billing-body">
                        <div className="card-billing-body-details">
                          <p>
                            <h6>Society Name</h6>
                            <strong
                              style={{
                                // backgroundColor: bgColorPlanType,
                                padding: "5px 10px",
                                borderRadius: "5px",
                                color: "#000", // Black text for contrast
                              }}
                            >
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : (
                                societyFilterData?.society_id?.name || ""
                              )}
                            </strong>
                          </p>
                        </div>
                        <div className="card-billing-body-details">
                          <p>
                            <h6>Address</h6>

                            <strong
                              style={{
                                // backgroundColor: bgColorHouseCount,
                                padding: "5px 10px",
                                borderRadius: "5px",
                                color: "#000", // Black text for contrast
                              }}
                            >
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : (
                                societyFilterData?.society_id?.address || ""
                              )}
                            </strong>
                          </p>
                        </div>
                        <div className="card-billing-body-details">
                          <p>
                            <h6>Contact No.</h6>

                            <strong
                              style={{
                                // backgroundColor: bgColorHouseCount,
                                padding: "5px 10px",
                                borderRadius: "5px",
                                color: "#000", // Black text for contrast
                              }}
                            >
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : (
                                societyFilterData?.society_id
                                  ?.societyContactNumber || ""
                              )}
                            </strong>
                          </p>
                        </div>
                        <div className="card-billing-body-details">
                          <p>
                            <h6>Plan Type</h6>

                            <strong
                              style={{
                                // backgroundColor: bgColorHouseCount,
                                padding: "5px 10px",
                                borderRadius: "5px",
                                color: "#000", // Black text for contrast
                              }}
                            >
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : (
                                societyFilterData?.society_id?.planType || ""
                              )}
                            </strong>
                          </p>
                        </div>
                        <div className="card-billing-body-details">
                          <p>
                            <h6>Interval Type</h6>

                            <strong
                              style={{
                                // backgroundColor: bgColorHouseCount,
                                padding: "5px 10px",
                                borderRadius: "5px",
                                color: "#000", // Black text for contrast
                              }}
                            >
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : (
                                societyFilterData?.society_id?.intervalType.toUpperCase() ||
                                ""
                              )}
                            </strong>
                          </p>
                        </div>
                        <div className="card-billing-body-details">
                          <p>
                            <h6>Society Status</h6>

                            <strong
                              style={{
                                color:
                                  societyFilterData?.society_id?.isActive ===
                                  true
                                    ? "green"
                                    : societyFilterData?.society_id
                                        ?.isActive === false
                                    ? "red"
                                    : "orange",
                                fontWeight: "bold",
                              }}
                            >
                              {loading ? (
                                <div className="">
                                  <ThreeCircles
                                    visible={true}
                                    height={30}
                                    width={30}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              ) : societyFilterData?.society_id?.isActive ? (
                                "Active"
                              ) : (
                                "Inactive"
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="billing-no-data"></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="billing-container">
                <div className="section-wrapper">
                  {/* Current Plan Summary */}
                  <div className="card-billing-wrapper">
                    <div className="card-billing-heading2">
                      <div className="card-billing-heading-title">
                        Billing Details{" "}
                      </div>
                      <div className="card-billing-heading-title"></div>
                    </div>
                    <div className="card-billing-body">
                      <div className="billing-container">
                        {/* <div className="parent-container"> */}
                        <div className="table-container1">
                          <table className="billing-table">
                            <thead>
                              <tr>
                                <th>Billing Cycle</th>
                                <th>Billing Period Start Date</th>
                                <th>Billing Period End Date</th>
                                <th>Invoice No.</th>
                                <th>Total Houses</th>
                                <th>Amount</th>
                                <th>Discount Amount</th>
                                <th>GST Type</th>
                                <th>GST Rate</th>
                                <th>Net Payable</th>
                                <th>Due Date</th>
                                <th>Payment Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            {loading ? (
                              <td className="no-data-billing-td" colSpan={6}>
                                <div className="billing-loading">
                                  <ThreeCircles
                                    visible={true}
                                    height={60}
                                    width={60}
                                    color="#5e72e4"
                                    ariaLabel="three-circles-loading"
                                  />
                                </div>
                              </td>
                            ) : societyFilterData?.bills?.length > 0 ? (
                              societyFilterData?.bills?.map((item) => {
                                return (
                                  <>
                                    <tbody>
                                      <tr>
                                        <td>
                                          {" "}
                                          <strong
                                            className={
                                              item.billingCycle === "monthly"
                                                ? "interval-monthly"
                                                : item.billingCycle === "weekly"
                                                ? "interval-annual"
                                                : item.billingCycle ===
                                                  "quarterly"
                                                ? "interval-quarterly"
                                                : ""
                                            }
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : (
                                              item.billingCycle.toUpperCase() ||
                                              "monthly"
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          <strong
                                            style={{
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              color: "#000",
                                            }}
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : (
                                              formatDateToCustom(
                                                item.billing_period_startDate
                                              )
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          <strong
                                            style={{
                                              // backgroundColor:
                                              //   bgColorBillingPeriodStartDate,
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              color: "#000", // Black text for contrast
                                            }}
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : (
                                              formatDateToCustom(
                                                item.billing_period_endDate
                                              )
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              // backgroundColor: bgColorInvoiceNo,
                                              borderRadius: "5px",
                                              padding: "5px 10px",

                                              color: "#000", // Black text for contrast
                                            }}
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : item.invoiceNo ? (
                                              item.invoiceNo
                                            ) : (
                                              "No Data"
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              // backgroundColor: bgColorTotalHouse,
                                              borderRadius: "5px",
                                              padding: "5px 10px",
                                              color: "#000", // Black text for contrast
                                            }}
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : item.totalHouse ? (
                                              item.totalHouse
                                            ) : (
                                              "No Data"
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          {" "}
                                          <Tooltip
                                            placement="top"
                                            title={
                                              <div>
                                                Total Houses: {item.totalHouse}
                                                <br />
                                                Per House Cost(
                                                {item.currencyType}):{" "}
                                                {item.currencyType == "INR"
                                                  ? "₹"
                                                  : "$"}
                                                {item.pricePerHouse || "10"}
                                                <br />
                                                Bill Amount ({item.currencyType}
                                                ):{" "}
                                                {item.currencyType == "INR"
                                                  ? "₹"
                                                  : "$"}
                                                {item.totalHouse *
                                                  (item.pricePerHouse || 10)}
                                              </div>
                                            }
                                            arrow
                                          >
                                            <strong
                                              style={{
                                                // backgroundColor: bgColorAmount,
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                color: "#000", // Black text for contrast
                                              }}
                                            >
                                              {loading ? (
                                                <div className="">
                                                  <ThreeCircles
                                                    visible={true}
                                                    height={30}
                                                    width={30}
                                                    color="#5e72e4"
                                                    ariaLabel="three-circles-loading"
                                                  />
                                                </div>
                                              ) : item.amount ? (
                                                item.amount
                                              ) : (
                                                "No Data"
                                              )}
                                            </strong>
                                          </Tooltip>
                                        </td>
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              // backgroundColor: bgColorDiscountAmount,
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              color: "#000",
                                            }}
                                          >
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}

                                            {item.discountType === "fixed"
                                              ? item.discountAmount
                                              : item.pricePerHouse *
                                                item.totalHouse *
                                                (item.discountAmount / 100)}
                                          </strong>
                                        </td>

                                        {/* GST TYPE */}
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              color: "#000",
                                            }}
                                          >
                                            {item.gstType}
                                          </strong>
                                        </td>
                                        {/* GST RATE */}
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              color: "#000",
                                            }}
                                          >
                                            {item.currencyType == "INR"
                                              ? "₹"
                                              : "$"}
                                            {item.gstRate}
                                          </strong>
                                        </td>
                                        <td>
                                          <Tooltip
                                            placement="top"
                                            title={
                                              <div>
                                                Total Houses = {item.totalHouse}
                                                <br />
                                                Per House Cost(
                                                {item.currencyType}) ={" "}
                                                {item.currencyType == "INR"
                                                  ? "₹"
                                                  : "$"}
                                                {item.pricePerHouse || "10"}
                                                <br />
                                                Sub Total = {item.amount}
                                                <br />
                                                Discount Amount ={" "}
                                                {item.currencyType == "INR"
                                                  ? "₹"
                                                  : "$"}
                                                {item.discountType === "fixed"
                                                  ? item.discountAmount
                                                  : item.pricePerHouse *
                                                    item.totalHouse *
                                                    (item.discountAmount / 100)}
                                                <br />
                                                GstRate ={" "}
                                                {item.currencyType == "INR"
                                                  ? "₹"
                                                  : "$"}
                                                {item.gstRate}
                                                <br />
                                                Net Payval ({item.currencyType})
                                                = {item.total}
                                              </div>
                                            }
                                            arrow
                                          >
                                            <strong
                                              style={{
                                                // backgroundColor: bgColorNetPayval,
                                                padding: "5px 10px",
                                                borderRadius: "5px",
                                                color: "#000",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {loading ? (
                                                <div className="">
                                                  <ThreeCircles
                                                    visible={true}
                                                    height={30}
                                                    width={30}
                                                    color="#5e72e4"
                                                    ariaLabel="three-circles-loading"
                                                  />
                                                </div>
                                              ) : item.total ? (
                                                item.total
                                              ) : (
                                                "No Data"
                                              )}
                                            </strong>
                                          </Tooltip>
                                        </td>
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              // backgroundColor: bgColorDiscountAmount,
                                              padding: "5px 10px",
                                              borderRadius: "5px",
                                              color: "#000", // Black text for contrast
                                            }}
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : item.dueDate ? (
                                              formatDate(item.dueDate)
                                            ) : (
                                              "No Data"
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          {" "}
                                          <strong
                                            style={{
                                              borderRadius: "5px",
                                              padding: "5px 10px",

                                              color:
                                                item.paymentStatus === "paid"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {loading ? (
                                              <div className="">
                                                <ThreeCircles
                                                  visible={true}
                                                  height={30}
                                                  width={30}
                                                  color="#5e72e4"
                                                  ariaLabel="three-circles-loading"
                                                />
                                              </div>
                                            ) : item.amount ? (
                                              item.paymentStatus.toUpperCase() ||
                                              "unPaid"
                                            ) : (
                                              "No Data"
                                            )}
                                          </strong>
                                        </td>
                                        <td>
                                          {" "}
                                          <div className="action-download-bill">
                                            <div>
                                              <Tooltip
                                                disabled={
                                                  item.paymentStatus === "paid"
                                                    ? "disabledPaymentButton"
                                                    : "" ||
                                                      isPaymentExpired(
                                                        item.paymentDate,
                                                        item.paymentTime
                                                      )
                                                }
                                                onClick={(e) => {
                                                  if (
                                                    item.paymentStatus !==
                                                    "paid"
                                                  ) {
                                                    handlePaymentStatus(
                                                      item?.society_id?._id ||
                                                        "null",
                                                      item._id,
                                                      "paid",
                                                      item.paymentDate,
                                                      item.paymentTime
                                                    );
                                                  }
                                                  e.preventDefault();
                                                }}
                                                placement="top"
                                                title={
                                                  item.paymentStatus === "paid"
                                                    ? language === "hindi"
                                                      ? "Paid"
                                                      : "भुगतान किया"
                                                    : language === "hindi"
                                                    ? "Update Payment Status"
                                                    : "भुगतान स्थिति अपडेट करें"
                                                }
                                                arrow
                                              >
                                                <span
                                                  className={`${
                                                    item.paymentStatus ===
                                                      "paid" ||
                                                    isPaymentExpired(
                                                      item.paymentDate,
                                                      item.paymentTime
                                                    )
                                                      ? "disabledPaymentButton"
                                                      : "action-to-updated-payment-status"
                                                  }`}
                                                >
                                                  <FaCheckCircle />
                                                </span>
                                              </Tooltip>
                                            </div>
                                            <div>
                                              <Tooltip
                                                disabled={
                                                  item.paymentStatus ===
                                                    "paid" ||
                                                  isPaymentExpired(
                                                    item.paymentDate,
                                                    item.paymentTime
                                                  )
                                                    ? "disabledPaymentButton"
                                                    : ""
                                                }
                                                onClick={(e) => {
                                                  if (
                                                    item.paymentStatus == "paid"
                                                  ) {
                                                    handlePaymentStatus(
                                                      item?.society_id?._id ||
                                                        "null",
                                                      item._id,
                                                      "unpaid",
                                                      item.paymentDate,
                                                      item.paymentTime
                                                    );
                                                  }
                                                }}
                                                placement="top"
                                                title={
                                                  item.paymentStatus ===
                                                  "unpaid"
                                                    ? language === "hindi"
                                                      ? "Unpaid"
                                                      : "बिना भुगतान किया गया बिल"
                                                    : language === "hindi"
                                                    ? "Update Payment Status"
                                                    : "भुगतान स्थिति अपडेट करें"
                                                }
                                                arrow
                                              >
                                                <span
                                                  className={`${
                                                    item.paymentStatus ===
                                                      "unpaid" ||
                                                    isPaymentExpired(
                                                      item.paymentDate,
                                                      item.paymentTime
                                                    )
                                                      ? "disabledPaymentButton"
                                                      : "action-to-updated-payment-status"
                                                  }`}
                                                >
                                                  <FaTimesCircle />
                                                </span>
                                              </Tooltip>
                                            </div>
                                          </div>
                                          <div className="action-download-bill"></div>
                                        </td>
                                      </tr>
                                    </tbody>{" "}
                                  </>
                                );
                              })
                            ) : (
                              <td
                                colSpan="17"
                                className="no-data-row"
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {language === "english"
                                  ? "कोई डेटा नहीं"
                                  : "No Data"}
                              </td>
                            )}
                          </table>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                    <div className="card-billing-progress-body">
                      <div className="card-billing-progress-details"></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="permission">
          <h2>You do not have permission to read this data</h2>
        </div>
      )}
    </div>
  );
};
export default SuperAdminViewBillingDetails;
