import React, { useContext, useEffect, useState } from "react";
import { FaUser, FaAddressCard } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FaHourglassStart } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { MdSubscriptions } from "react-icons/md";
import Tooltip from "@mui/material/Tooltip";
import { MdAccessTime } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaPercentage } from "react-icons/fa";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const SubscriptionModal = (data) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  //custom Function to convert the Date in month
  const [value, setValue] = React.useState(0);
  const { language } = useContext(LanguageContext);

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //function lock
  useEffect(() => {
    if (data) {
      setSubscriptionDetails(data?.data);
    } else {
      setSubscriptionDetails();
    }
  }, []);
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            title={language === "hindi" ? "view" : "देखना"}
            placement="top"
            arrow
          >
            <div onClick={toggleDrawer(anchor, true)}>
              <h5 className=" mt-3 house-view-icon ">
                <IoEyeSharp
                  data-toggle="tooltip"
                  className="eyes_view"
                  data-placement="top"
                />
              </h5>
            </div>
          </Tooltip>
          <Drawer
            className="regular_drawer   show-top"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            {/* <div className="heading_user"> */}
            {/* <div> */}
            <div className="close_btn" onClick={toggleDrawer(anchor, false)}>
              <IoMdClose className="close_icons_house" />
            </div>

            <hr className="hr_line" />
            <List>
              <div className="regular-view-main-div">
                <div className="regular-images-view-title">
                  {" "}
                  {language === "hindi"
                    ? "Subscription Details"
                    : " सदस्यता विवरण"}
                </div>
                <div className="main_content_div">
                  <div className="content1_left_div ">
                    <h3 className="house_title_left">
                      <MdSubscriptions />
                      &nbsp; {language === "hindi" ? "Name " : "नाम"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaRupeeSign />
                      &nbsp;{language === "hindi" ? "Price" : "मूल्य"}
                    </h3>
                    <h3 className="house_title_left">
                      <MdAccessTime />
                      &nbsp;{language === "hindi" ? "Interval" : "Interval"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaMoneyBillWave />
                      &nbsp;
                      {language === "hindi"
                        ? "Commission Currency"
                        : "आयोग मुद्रा"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaHome />
                      &nbsp;{" "}
                      {language === "hindi"
                        ? "No. of Houses in Society"
                        : "समाज में घरों की संख्या"}
                    </h3>

                    <h3 className="house_title_left">
                      <FaFileAlt />
                      &nbsp;
                      {language === "hindi" ? "Description" : "विवरण"}
                    </h3>
                  </div>
                  <div className="content1_right_div">
                    <React.Fragment>
                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.name
                          : "Not Added"}
                      </h3>

                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.price
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.interval
                          : "Not Added"}
                      </h3>

                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.commissionCurrency
                          : "Not Added"}
                      </h3>

                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.societyHouseList
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.description
                          : "Not Added"}
                      </h3>
                    </React.Fragment>
                  </div>
                </div>
                <br />
                <div className="regular-images-view-title">
                  {" "}
                  {language === "hindi" ? "GST Details" : " जीएसटी विवरण"}
                </div>
                <div className="main_content_div">
                  <div className="content1_left_div ">
                    <h3 className="house_title_left">
                      <FaFileInvoiceDollar />
                      &nbsp;
                      {language === "hindi" ? " GST type " : "जीएसटी प्रकार"}
                    </h3>
                    <h3 className="house_title_left">
                      <FaPercentage />
                      &nbsp;
                      {language === "hindi"
                        ? "GST rate"
                        : "जीएसटी दर"}{" "}
                    </h3>
                  </div>
                  <div className="content1_right_div">
                    <React.Fragment>
                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.gstType
                          : "Not Added"}
                      </h3>
                      <h3 className="user_images_heading">
                        {subscriptionDetails
                          ? subscriptionDetails?.gstRate
                          : "Not Added"}
                      </h3>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default SubscriptionModal;
