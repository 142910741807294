import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./style.css";
import Layout from "../../../../lib/Layout";
import { PORT } from "../../../../Api/api";
import axios from "axios";
import Loading from "../../../../Loading/Loading";
import { ThreeCircles } from "react-loader-spinner";
import { formatDate } from "../../../../lib/FormattedDate";
import { TiExport } from "react-icons/ti";
import Dropdown from "react-bootstrap/Dropdown";
import FileSaver from "file-saver";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Pagination,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { FaFilePdf } from "react-icons/fa6";
import { FaFileExcel } from "react-icons/fa6";
import { FaFileCsv } from "react-icons/fa6";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { jsPDF } from "jspdf";
import pdfLogo from "../../assets/pdfLogo.png";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { DataContext } from "../../../../lib/DataContext";
import { PermissionContext } from "../../../../lib/PermissionContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import swalAlert from "../../../../lib/alert";
import SuperAdminViewBillingDetails from "../superAdminViewBillingDetails/SuperAdminViewBillingDetails";
// import html2pdf from "html2pdf.js";
const ViewBillingDetails = () => {
  const society_id = JSON.parse(localStorage.getItem("society_id"));
  const [billDetails, setBillDetails] = useState([]);
  const [houseTotalLength, setHouseTotalLength] = useState("");
  const [showBillsDetails, setShowBillsDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const { societyDetails } = useContext(DataContext);
  const { permissions } = useContext(PermissionContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPageByBilling, setCurrentPageByBilling] = useState(1);
  const [userDetails, setUserDetails] = useState();
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const role_level = JSON.parse(localStorage.getItem("role")) || null;
  const usersPerPage = 10;
  const logo = pdfLogo;
  // Generate a random light color
  const generateLightColor = () => {
    const r = Math.floor(Math.random() * 56) + 200;
    const g = Math.floor(Math.random() * 56) + 200;
    const b = Math.floor(Math.random() * 56) + 200;
    return `rgb(${r}, ${g}, ${b})`;
  };
  const [bgColorBillingPeriodStartDate, setBgColorBillingPeriodStartDate] =
    useState(generateLightColor());
  const [bgColorHouseCount, setBgColorHouseCount] = useState(
    generateLightColor()
  );
  const [bgColorInvoiceNo, setBgColorInvoiceNo] = useState(
    generateLightColor()
  );
  const [bgColorAmount, setBgColorAmount] = useState(generateLightColor());
  const [bgColorTotalHouse, setBgColorTotalHouse] = useState(
    generateLightColor()
  );
  const [bgColorPaymentStatus, setBgColorPaymentStatus] = useState(
    generateLightColor()
  );
  const [billingPeriodStartDate, setBillingPeriodStartDate] = useState("");
  const [bgColorDiscountAmount, setBgColorDiscountAmount] = useState(
    generateLightColor()
  );
  const [bgColorNetPayval, setBgColorNetPayval] = useState(
    generateLightColor()
  );
  const [bgColorTotal, setBgColorTotal] = useState(generateLightColor());
  const [bgColorPlanType, setBgColorPlanType] = useState(generateLightColor());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  //fetchUserDetails
  const fetchUserDetails = () => {
    try {
      const details = JSON.parse(localStorage.getItem("societyLogo"));
      setUserDetails(details);
    } catch (error) {}
  };
  //fetchBiling
  const fetchBilling = async () => {
    if (society_id) {
      try {
        const response = await axios.get(
          `${PORT}/getBillBySociety/${society_id}`
        );

        setShowBillsDetails(response?.data?.billing[0]?.bills);
        setTimeout(() => {
          setLoading(false);
        }, 1000);

        setBillDetails(response?.data?.billing[0]);
      } catch (error) {
        console.log(error);
      }
    }
  };
  //fetchTotalHouse
  const handleData = async () => {
    if (society_id) {
      try {
        const response = await axios.get(
          `${PORT}/getHousesBySociety/${society_id}`
        );
        const filterData = await response?.data?.house;
        setHouseTotalLength(filterData.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  //formated Date in Day
  function extractDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
  //formated amount
  const formattedAmount = (value) => {
    if (typeof value !== "string") {
      console.error("Input must be a string");
      return;
    }
    const result = value.split("₹");

    return result[1] === "NaN" ? "NA" : result[1];
  };
  //downloadPDF
  const downloadPDF = async (data) => {
    const itemData = data[0];
    const doc = new jsPDF({
      orientation: "portrait", // Portrait layout
      unit: "mm",
      format: "a4", // Standard A4 size
    });

    // Add header
    doc.setFontSize(20);
    // doc.text("INVOICE", 15, 20);
    // Set color to blue only for "INVOICE"
    doc.setTextColor(0, 0, 255);
    doc.text("INVOICE", 11, 20);

    // Reset text color to black (optional for subsequent text)
    doc.setTextColor(0, 0, 0);
    // Add logo to the top-right
    if (logo) {
      const imgProps = logo; // Image data (base64 or URL)
      doc.addImage(imgProps, "PNG", 160, 10, 45, 12); // Adjust size and position
    }

    doc.setFontSize(12);
    // Add "Issue Date" in bold
    doc.setFont("helvetica", "bold");
    doc.text("Issue Date:", 11, 35);

    // Add the corresponding data in normal font
    doc.setFont("helvetica", "normal");
    doc.text(formatDate(itemData.submitedDate), 40, 35); // Adjust position as needed

    // Add "Invoice No" in bold
    doc.setFont("helvetica", "bold");
    doc.text("Invoice No:", 11, 40);

    // Add the corresponding data in normal font
    doc.setFont("helvetica", "normal");
    doc.text(itemData.invoiceNo, 40, 40); // Adjust position as needed

    // Add "Due Date" in bold
    doc.setFont("helvetica", "bold");
    doc.text("Due Date:", 11, 45);

    // Add the corresponding data in normal font
    doc.setFont("helvetica", "normal");
    doc.text(formatDate(itemData.dueDate), 40, 45); // Adjust position as needed

    // Optionally, revert to normal font for subsequent text
    doc.setFont("helvetica", "normal");
    //middle section
    // Define common settings
    const padding = 5; // Padding around the text
    const lineSpacing = 8; // Space between lines

    // Draw "Bill to:" section with border
    const billToStartX = 15;
    const billToStartY = 60;
    const billToWidth = 93; // Width of the "Bill to:" box
    const billToLines = 4; // Number of lines in the "Bill to:" section
    const billToHeight = billToLines * lineSpacing + 2 * padding;

    doc.setDrawColor(0, 0, 0); // Set border color to black
    doc.rect(
      billToStartX - padding,
      billToStartY - padding,
      billToWidth,
      billToHeight
    );

    doc.setFontSize(13);
    doc.setFont("helvetica", "bold"); // Set the font to Helvetica and bold
    doc.setTextColor(0, 0, 255);
    doc.text("Bill to:", billToStartX, billToStartY);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12); // Reset to regular font
    doc.text(
      `${societyDetails.name}`,
      billToStartX,
      billToStartY + lineSpacing
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      `+91 ${societyDetails.societyContactNumber}`,
      billToStartX,
      billToStartY + 2 * lineSpacing
    );
    doc.text(
      `${societyDetails.address}, ${societyDetails.city}, ${societyDetails.state}`,
      billToStartX,
      billToStartY + 3 * lineSpacing
    );

    // Draw "Payable to:" section with border
    const payableToStartX = 110;
    const payableToStartY = 60;
    const payableToWidth = 95; // Width of the "Payable to:" box
    const payableToLines = 4; // Number of lines in the "Payable to:" section
    const payableToHeight = payableToLines * lineSpacing + 2 * padding;

    doc.setDrawColor(0, 0, 0); // Set border color to black
    doc.rect(
      payableToStartX - padding,
      payableToStartY - padding,
      payableToWidth,
      payableToHeight
    );

    doc.setFontSize(13);
    doc.setFont("helvetica", "bold"); // Set the font to Helvetica and bold
    doc.setTextColor(0, 0, 255);
    doc.text("Payable to:", payableToStartX, payableToStartY);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);
    // Reset to regular font

    doc.text("GuardX", payableToStartX, payableToStartY + lineSpacing);
    doc.setFont("helvetica", "normal");
    doc.text(
      "+123 456 7890",
      payableToStartX,
      payableToStartY + 2 * lineSpacing
    );
    doc.text(
      "575c, Sec 82 Mohali, Punjab 140308, India",
      payableToStartX,
      payableToStartY + 3 * lineSpacing
    );

    // Table setup (No borders for the cells)
    const headers = [
      [
        "No.",
        "Item",
        "Total Houses",
        `Per Unit(${itemData.currencyType})`,
        `Total(${itemData.currencyType})`,
      ],
    ];
    const body = data.map((item, index) => [
      index + 1,
      item.itemName || "GuardX App",
      item.totalHouse || 0,
      item.pricePerHouse,
      `${formattedAmount(item.amount)}`,
    ]);
    doc.autoTable({
      head: headers,
      body: body,
      startY: 100,
      theme: "plain", // No border for cells
      styles: {
        fontSize: 12,
        halign: "center",

        lineColor: [0, 0, 0], // Set border color to black
        lineWidth: 0.1, // Set border thickness
        cellPadding: 3, // Padding inside the cells
        overflow: "linebreak", // Handle long text in cells
      },
      headStyles: {
        lineColor: [0, 0, 0], // Black border for header cells
        textColor: [0, 0, 255],
        // fillColor: [220, 220, 220], // Optional: Header background color
      },
      columnStyles: {
        0: { cellWidth: 20 }, // Adjust column widths as needed
        1: { cellWidth: 70 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
        4: { cellWidth: 40 },
      },
      tableWidth: "wrap", // Set table width to fit content within the page
      margin: { left: 10, right: 15, top: 10 }, // Add margins to avoid overflow
    });

    // Add footer with payment details
    const finalY = doc.lastAutoTable.finalY + 10; // Position footer below the table
    const pageWidth = doc.internal.pageSize.width; // Get the page width

    // Calculate the x position for right alignment
    const rightAlignX = pageWidth - 25; // 15 is the padding from the right edge

    doc.setFontSize(12);
    const spadding = 8;
    // Total line with padding
    doc.text(
      `Total(${itemData.currencyType})${"    "}     ${formattedAmount(
        itemData.amount
      )}`,
      rightAlignX,
      finalY,
      { align: "right" }
    );

    // Discount line with padding
    doc.text(
      `Discount(${itemData.currencyType}) ${"   "}    ${
        itemData.discountType === "fixed"
          ? itemData.discountAmount
          : itemData.pricePerHouse *
            itemData.totalHouse *
            (itemData.discountAmount / 100)
      }`,
      rightAlignX,
      finalY + spadding, // Added padding for spacing
      { align: "right" }
    );

    // Payable Amount line with padding
    doc.setFont("helvetica", "bold");
    doc.text(
      `Net Payable Amount(${itemData.currencyType})${"  "} ${formattedAmount(
        itemData.total
      )}`,
      rightAlignX,
      finalY + spadding * 2, // Added padding for spacing
      { align: "right" }
    );
    // Payment Information
    const finalYY = doc.lastAutoTable.finalY + 50;
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 255);
    doc.text("Payment information", 11, finalYY);
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.text("Bank: HDFC", 11, finalYY + padding);
    doc.text("Name: GuardX", 11, finalYY + padding * 2);
    doc.text("Account: 0123 4567 8901", 11, finalYY + padding * 3);
    doc.text("IFSC: HDFCXXXXX", 11, finalYY + padding * 4);

    // Terms and Conditions
    // Terms and Conditions
    const finalYYY = doc.lastAutoTable.finalY + 21;
    const termsStartY = finalYYY + padding * 6;
    // Define the X position for the Terms and Conditions section (on the right side)
    const termsStartX = 90; // Adjust this value as needed (this is the starting point for the right-side content)
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 255);
    doc.text("Terms and Conditions", termsStartX, termsStartY);
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");

    const termsText = [
      "1. Payments are due 30 days from the issue date.",
      "2. Late payments will incur a 10% interest charge per month.",
      "3. All invoices are subject to the terms and conditions outlined.",
    ];

    termsText.forEach((line, index) => {
      doc.text(line, termsStartX, termsStartY + padding * (index + 1));
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    doc.save(`Billing-details ${dateTime}.pdf`);
  };
  const downloadExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        "Billing Cycle": item.billingCycle.toUpperCase(),
        "Billing Period Start Date": formatDateToCustom(
          item.billing_period_startDate
        ),
        "Billing Period End Date": formatDateToCustom(
          item.billing_period_endDate
        ),
        "Invoice No.": item.invoiceNo || "No Data",
        "Total Houses": item.totalHouse || "No Data",
        "Amount(INR)": formattedAmount(item.amount) || "No Data",
        "Due Date": formatDate(item.dueDate) || "No Data",
        [`Discount Amount(${item.currencyType})`]: `${
          item.discountType === "fixed"
            ? item.discountAmount
            : item.pricePerHouse * item.totalHouse * (item.discountAmount / 100)
        }`,
        [`Net Payable(${item.currencyType})`]: item.total || "NA",

        "Payment Status": item.paymentStatus.toUpperCase() || "unPaid",
      }))
    );
    // Set custom column widths
    ws["!cols"] = [
      { wch: 30 }, // Width for 'Billing Cycle'
      { wch: 30 }, // Width for 'Start Date'
      { wch: 30 }, // Width for 'End Date'
      { wch: 30 }, // Width for 'Invoice No.'
      { wch: 30 }, // Width for 'Amount'
      { wch: 30 }, // Width for 'Due Date'
      { wch: 30 }, // Width for 'Payment Status'
      { wch: 30 }, // Width for 'Amount'
      { wch: 30 }, // Width for 'Due Date'
      { wch: 30 }, // Width for 'Payment Status'
    ];
    // Set row heights
    ws["!rows"] = [
      { hpx: 25 }, // Row height for the first row (header)
      ...data.map(() => ({ hpx: 20 })), // Row heights for data rows
    ];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Billing Details");
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    // FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
    XLSX.writeFile(wb, `Billing-details ${dateTime}.xlsx`);
  };
  //Search Functionaity
  // Filter and search logic combined
  const filteredData = useMemo(() => {
    let data = showBillsDetails;
    // Date range filter
    if (startDate) {
      data = data.filter((item) => {
        const startBillingDate = extractDate(item.billing_period_startDate);
        const formattedDate = new Date(startBillingDate);
        return formattedDate >= new Date(startDate);
      });
    }

    if (endDate) {
      data = data.filter((item) => {
        const endBillingDate = extractDate(item.billing_period_endDate);
        const formattedDate = new Date(endBillingDate);
        return formattedDate <= new Date(endDate);
      });
    }

    // Search filter
    if (searchTerm) {
      data = data.filter((user) => {
        const paymentStatusMatch = user?.paymentStatus
          ?.toLowerCase()
          ?.includes(searchTerm.toLowerCase());
        const billingCycleMatch = user?.billingCycle
          ?.toString()
          ?.includes(searchTerm);
        return paymentStatusMatch || billingCycleMatch;
      });
    }
    return data;
  }, [showBillsDetails, searchTerm, startDate, endDate]);
  // Validation logic to ensure endDate is not before startDate
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    // Check if the end date is empty
    if (!selectedEndDate) {
      setEndDate(""); // Clear the end date if it's empty
      return;
    }
    if (selectedEndDate < startDate) {
      // setError("End date can't be before the start date.");
      alert("End date can't be before the start date.");
      setEndDate("");
      return; // Don't update endDate if it's invalid
    }
    setEndDate(selectedEndDate);
  };
  // Pagination logic
  const indexOfLastUser = currentPageByBilling * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentBills = filteredData?.slice(indexOfFirstUser, indexOfLastUser);
  const totalPagesByBilling = Math.ceil(filteredData?.length / usersPerPage);
  const handleChangePageByBilling = (event, value) => {
    setCurrentPageByBilling(value);
  };
  //dropdown  functionality Excel and Csv
  //export to excel
  const exportToExcel = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData = data.map(
      ({
        billingCycle,
        billing_period_startDate,
        billing_period_endDate,
        invoiceNo,
        totalHouse,
        amount,
        discountAmount,
        total,
        dueDate,
        pricePerHouse,
        paymentStatus,
        discountType,
        gstRate,
        gstType,
        currencyType,
      }) => ({
        "Billing Cycle": billingCycle.toUpperCase(),
        "Billing Period StartDate": formatDateToCustom(
          billing_period_startDate
        ),
        "Billing Period EndDate": formatDateToCustom(billing_period_endDate),
        "Invoice No.": invoiceNo,
        "Total Houses": totalHouse,
        Amount: amount,
        [`Discount Amount(${currencyType})`]: `${
          discountType === "fixed"
            ? discountAmount
            : pricePerHouse * totalHouse * (discountAmount / 100)
        }`,
         [`GST Type(${currencyType})`]: gstType ||"--",
         [`GST Rate(${currencyType})`]: gstRate ||"--",
        [`Net Payable(${currencyType})`]: total || "NA",
        "Payment Status": paymentStatus.toUpperCase(),
        "Due Date	": formatDate(dueDate),
      })
    );

    const ws = XLSX.utils.json_to_sheet(filteredData);

    const wscols = [
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];
    ws["!cols"] = wscols;
    // Set row heights
    const rowHeights = [{ hpt: 30 }, ...filteredData.map(() => ({ hpt: 20 }))];
    ws["!rows"] = rowHeights;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
  };
  //export to csv
  const exportToCSV = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData = data.map(
      ({
        billingCycle,
        billing_period_startDate,
        billing_period_endDate,
        invoiceNo,
        totalHouse,
        amount,
        discountAmount,
        dueDate,
        paymentStatus,
        pricePerHouse,
        
        currencyType,
        discountType,
        total,
        gstType,
        gstRate
      }) => ({
        "Billing Cycle": billingCycle.toUpperCase(),
        "Billing Period StartDate": formatDateToCustom(
          billing_period_startDate
        ),
        "Billing Period EndDate": formatDateToCustom(billing_period_endDate),
        "Invoice No.": invoiceNo,
        "Total Houses": totalHouse,
        Amount: amount,
        [`Discount Amount(${currencyType})`]: `${
          discountType === "fixed"
            ? discountAmount
            : pricePerHouse * totalHouse * (discountAmount / 100)
        }`,
        [`GST Type(${currencyType})`]: gstType ||"--",
        [`GST Rate(${currencyType})`]: gstRate ||"--",
        [`Net Payable(${currencyType})`]: total || "NA",
        "Payment Status": paymentStatus.toUpperCase(),
        "Due Date	": formatDate(dueDate),
      })
    );

    const csv = Papa.unparse(filteredData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.csv`);
  };

  //function lock
  useEffect(() => {
    setCurrentPageByBilling(1);
  }, [searchTerm]);
  useEffect(() => {
    setBgColorBillingPeriodStartDate(generateLightColor());
    setBgColorHouseCount(generateLightColor());
    setBgColorInvoiceNo(generateLightColor());
    setBgColorAmount(generateLightColor());
    setBgColorTotalHouse(generateLightColor());
    setBgColorPaymentStatus(generateLightColor());
    setBgColorDiscountAmount(generateLightColor());
    setBgColorNetPayval(generateLightColor());
    setBgColorTotal(generateLightColor());
    setBgColorPlanType(generateLightColor());
    handleData();
    fetchBilling();
    // addBill();
    fetchUserDetails();
  }, []);
  return (
    <div>
      <Layout>
        <div class="container-fluid py-4 ">
          <div class="row">
            {(role_level === 1 || role_level === 2) && (
              <div class="col-12 ">
                <div class="card mb-4">
                  <div class="card-header pb-0">
                    <SuperAdminViewBillingDetails />
                  </div>
                </div>
              </div>
            )}
            {(role_level === 4 || role_level === 5) && (
              <div class="col-12 ">
                <div class="card mb-4">
                  <div class="card-header pb-0">
                    {loading ? (
                      <div className="">
                        <Loading />
                      </div>
                    ) : permissions[14]?.actions.read ? (
                      <div className="billing-container">
                        {/* Top Section */}
                        <div className="section-wrapper">
                          {/* Current Plan Summary */}
                          <div className="card-billing-wrapper">
                            <div className="card-billing-heading1">
                              <div className="card-billing-heading-title">
                                {language === "english"
                                  ? "वर्तमान योजना का सारांश"
                                  : "Current Plan Summary"}
                              </div>
                              <div className="card-billing-heading-button">
                                {/* <button>Upgrade</button> */}
                              </div>
                            </div>
                            <div className="card-billing-body">
                              <div className="card-billing-body-details">
                                <p>
                                  <h6>
                                    {language === "english"
                                      ? "योजना का नाम"
                                      : "Plan Name"}
                                  </h6>

                                  <strong
                                    style={{
                                      backgroundColor: bgColorPlanType,
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      color: "#000", // Black text for contrast
                                    }}
                                  >
                                    {loading ? (
                                      <div className="">
                                        <ThreeCircles
                                          visible={true}
                                          height={30}
                                          width={30}
                                          color="#5e72e4"
                                          ariaLabel="three-circles-loading"
                                        />
                                      </div>
                                    ) : (
                                      billDetails?.society_id.planType ||
                                      "Basic"
                                    )}
                                  </strong>
                                </p>
                              </div>

                              <div className="card-billing-body-details">
                                <p>
                                  <h6>
                                    {language === "english"
                                      ? "घरों की संख्या"
                                      : "Houses Count"}
                                  </h6>

                                  <strong
                                    style={{
                                      backgroundColor: bgColorHouseCount,
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      color: "#000", // Black text for contrast
                                    }}
                                  >
                                    {loading ? (
                                      <div className="">
                                        <ThreeCircles
                                          visible={true}
                                          height={30}
                                          width={30}
                                          color="#5e72e4"
                                          ariaLabel="three-circles-loading"
                                        />
                                      </div>
                                    ) : (
                                      billDetails?.society_id
                                        .societyHouseList || "0-250"
                                    )}
                                  </strong>
                                </p>
                              </div>
                              <div className="card-billing-body-details">
                                <p>
                                  <h6>
                                    {language === "english"
                                      ? "योजना बनाई गई"
                                      : "Plan Created"}
                                  </h6>

                                  <strong
                                    style={{
                                      backgroundColor:
                                        bgColorBillingPeriodStartDate,
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      color: "#000", // Black text for contrast
                                    }}
                                  >
                                    {loading ? (
                                      <div className="">
                                        <ThreeCircles
                                          visible={true}
                                          height={30}
                                          width={30}
                                          color="#5e72e4"
                                          ariaLabel="three-circles-loading"
                                        />
                                      </div>
                                    ) : (
                                      formatDate(
                                        billDetails?.society_id?.submitedDate ||
                                          "31-12-2024"
                                      )
                                    )}
                                  </strong>
                                </p>
                              </div>
                              <div className="card-billing-body-details">
                                <p>
                                  <h6>
                                    {" "}
                                    {language === "english"
                                      ? "प्रति घर की कीमत"
                                      : "Price Per household"}
                                  </h6>

                                  <strong
                                    style={{
                                      backgroundColor:
                                        bgColorBillingPeriodStartDate,
                                      padding: "5px 10px",
                                      borderRadius: "5px",
                                      color: "#000", // Black text for contrast
                                    }}
                                  >
                                    {loading ? (
                                      <div className="">
                                        <ThreeCircles
                                          visible={true}
                                          height={30}
                                          width={30}
                                          color="#5e72e4"
                                          ariaLabel="three-circles-loading"
                                        />
                                      </div>
                                    ) : (
                                      billDetails?.society_id.pricePerHouse ||
                                      "0-250"
                                    )}
                                  </strong>
                                </p>
                              </div>
                            </div>
                            <div className="card-billing-progress-body">
                              <div className="card-billing-progress-details"></div>
                            </div>
                          </div>
                        </div>
                        <div className="section-wrapper">
                          {/* Current Plan Summary */}
                          <div className="card-billing-wrapper">
                            <div className="card-billing-heading">
                              <div
                                className="card-billing-heading-title "
                                style={{ width: "23%" }}
                              >
                                {language === "english"
                                  ? "भुगतान सारांश"
                                  : "    Payment Summary"}
                              </div>

                              <div>
                                <Dropdown className="custom-dropdown">
                                  <Dropdown.Toggle
                                    className="custom-dropdown-toggle"
                                    // variant="success"
                                  >
                                    <TiExport className="dropdown-icon" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className="custom-dropdown-menu">
                                    <Dropdown.Item
                                      onClick={() =>
                                        exportToExcel(
                                          currentBills,
                                          "Billing and Invoice"
                                        )
                                      }
                                    >
                                      {language === "english"
                                        ? "एक्सेल"
                                        : "Excel"}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        exportToCSV(
                                          currentBills,
                                          "Billing and Invoice"
                                        )
                                      }
                                    >
                                      {language === "english"
                                        ? "सीएसवी"
                                        : " CSV"}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>

                              <div>
                                <Box
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "white",
                                        borderWidth: "1px",
                                        borderRadius: "5px",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "white",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: " white",
                                      },
                                    },
                                    "& .MuiInputLabel-root": {
                                      color: " white",
                                    },
                                    "& .MuiInputBase-input": {
                                      color: " white",
                                    },
                                    "& .MuiInputLabel-root.Mui-error": {
                                      color: " white",
                                    },
                                  }}
                                >
                                  <div className="date-filter">
                                    <TextField
                                      id="start-date"
                                      label={
                                        language === "hindi"
                                          ? "Start Date"
                                          : "आरंभ करने की तिथि"
                                      }
                                      type="date"
                                      value={startDate}
                                      onChange={(e) =>
                                        setStartDate(e.target.value)
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: " white" },
                                      }}
                                      InputProps={{
                                        style: { color: " white" },
                                      }}
                                      variant="outlined"
                                    />
                                    <TextField
                                      id="end-date"
                                      label={
                                        language === "hindi"
                                          ? "End Date"
                                          : "अंतिम तिथि"
                                      }
                                      type="date"
                                      value={endDate}
                                      onChange={handleEndDateChange}
                                      InputLabelProps={{
                                        shrink: true,
                                        style: { color: "white" },
                                      }}
                                      InputProps={{
                                        style: { color: "white" },
                                      }}
                                      variant="outlined"
                                      min={startDate}
                                      disabled={!startDate}
                                    />
                                  </div>
                                </Box>
                              </div>
                              {/* </div> */}
                              <div className="card-billing-heading-button">
                                {/* <button>Upgrade</button> */}
                                <input
                                  type="text"
                                  placeholder={
                                    language === "hindi"
                                      ? "Search..."
                                      : "खोजें..."
                                  }
                                  value={searchTerm}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  className="billing-list-search-bar "
                                />
                              </div>
                            </div>
                            <div className="card-billing-body">
                              <div className="billing-container">
                                <div className="parent-container">
                                  <table className="billing-table">
                                    <thead>
                                      <tr>
                                        <th>
                                          {language === "hindi"
                                            ? "Billing Cycle"
                                            : "बिलिंग चक्र"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "  Billing Period Start Date"
                                            : "बिलिंग अवधि प्रारंभ तिथि"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "   Billing Period End Date"
                                            : "बिलिंग अवधि समाप्ति तिथि"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "  Invoice No."
                                            : "चालान संख्या"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "Total Houses"
                                            : "कुल घर"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? " Amount"
                                            : "राशि"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "  Discount Amount"
                                            : "छूट राशि"}
                                        </th>

                                        <th>
                                          {language === "hindi"
                                            ? "GST Type"
                                            : "जीएसटी प्रकार"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "GST Rate"
                                            : "जीएसटी दर"}
                                        </th>

                                        <th>
                                          {language === "hindi"
                                            ? "  Net Payable"
                                            : "नेट देय"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? " Due Date"
                                            : "नियत तारीख"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? " Payment Status"
                                            : "भुगतान स्थिति"}
                                        </th>
                                        <th>
                                          {language === "hindi"
                                            ? "Action"
                                            : "कार्यवाही"}
                                        </th>
                                      </tr>
                                    </thead>
                                    {loading ? (
                                      <td
                                        className="no-data-billing-td"
                                        colSpan={6}
                                      >
                                        <div className="billing-loading">
                                          <ThreeCircles
                                            visible={true}
                                            height={60}
                                            width={60}
                                            color="#5e72e4"
                                            ariaLabel="three-circles-loading"
                                          />
                                        </div>
                                      </td>
                                    ) : currentBills?.length > 0 ? (
                                      currentBills?.map((item) => {
                                        return (
                                          <>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    className={
                                                      item.billingCycle ===
                                                      "monthly"
                                                        ? "interval-monthly"
                                                        : item.billingCycle ===
                                                          "weekly"
                                                        ? "interval-annual"
                                                        : item.billingCycle ===
                                                          "quarterly"
                                                        ? "interval-quarterly"
                                                        : ""
                                                    }
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : (
                                                      item.billingCycle.toUpperCase() ||
                                                      "monthly"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorBillingPeriodStartDate,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : (
                                                      formatDateToCustom(
                                                        item.billing_period_startDate
                                                      )
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorBillingPeriodStartDate,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : (
                                                      formatDateToCustom(
                                                        item.billing_period_endDate
                                                      )
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorInvoiceNo,
                                                      borderRadius: "5px",
                                                      padding: "5px 10px",

                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.invoiceNo ? (
                                                      item.invoiceNo
                                                    ) : (
                                                      "NO Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorTotalHouse,
                                                      borderRadius: "5px",
                                                      padding: "5px 10px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.totalHouse ? (
                                                      item.totalHouse
                                                    ) : (
                                                      "0"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Tooltip
                                                    placement="top"
                                                    title={
                                                      <div>
                                                        Total Houses:{" "}
                                                        {item.totalHouse}
                                                        <br />
                                                        Per House Cost(
                                                        {
                                                          item.currencyType
                                                        }):{" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.pricePerHouse ||
                                                          "10"}
                                                        <br />
                                                        Bill Amount (
                                                        {
                                                          item.currencyType
                                                        }):{" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.totalHouse *
                                                          (item.pricePerHouse ||
                                                            10)}
                                                      </div>
                                                    }
                                                    arrow
                                                  >
                                                    <strong
                                                      style={{
                                                        backgroundColor:
                                                          bgColorAmount,
                                                        padding: "5px 10px",
                                                        borderRadius: "5px",
                                                        color: "#000", // Black text for contrast
                                                      }}
                                                    >
                                                      {loading ? (
                                                        <div className="">
                                                          <ThreeCircles
                                                            visible={true}
                                                            height={30}
                                                            width={30}
                                                            color="#5e72e4"
                                                            ariaLabel="three-circles-loading"
                                                          />
                                                        </div>
                                                      ) : item.amount ? (
                                                        item.amount
                                                      ) : (
                                                        "No Data"
                                                      )}
                                                    </strong>
                                                  </Tooltip>
                                                </td>

                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorDiscountAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {item.currencyType == "INR"
                                                      ? "₹"
                                                      : "$"}
                                                    {item.discountType ===
                                                    "fixed"
                                                      ? item.discountAmount
                                                      : item.pricePerHouse *
                                                        item.totalHouse *
                                                        (item.discountAmount /
                                                          100)}
                                                    <br />
                                                  </strong>
                                                </td>

                                                <td>
                                                  {" "}
                                                  <strong>
                                                    {item.gstType
                                                      ? item.gstType.toUpperCase()
                                                      : "--"}
                                                    <br />
                                                  </strong>
                                                </td>

                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorDiscountAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {item.currencyType == "INR"
                                                      ? "₹"
                                                      : "$"}
                                                    {item.gstRate}
                                                    <br />
                                                  </strong>
                                                </td>

                                                <td>
                                                  <Tooltip
                                                    placement="top"
                                                    title={
                                                      <div>
                                                        Total Houses ={" "}
                                                        {item.totalHouse || 0}
                                                        <br />
                                                        Per House Cost(
                                                        {item.currencyType}) ={" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.pricePerHouse ||
                                                          "10"}
                                                        <br />
                                                        Sub Total ={" "}
                                                        {item.amount == "₹NaN"
                                                          ? "0"
                                                          : item.amount}
                                                        <br />
                                                        Discount Amount ={" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.discountType ===
                                                        "fixed"
                                                          ? item.discountAmount
                                                          : item.pricePerHouse *
                                                            item.totalHouse *
                                                            (item.discountAmount /
                                                              100)}
                                                        <br />
                                                        GST Rate =
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.gstRate}
                                                        <br />
                                                        Net Payable (
                                                        {item.currencyType}) ={" "}
                                                        {item.total || "NA"}
                                                      </div>
                                                    }
                                                    arrow
                                                  >
                                                    <strong
                                                      style={{
                                                        backgroundColor:
                                                          bgColorNetPayval,
                                                        padding: "5px 10px",
                                                        borderRadius: "5px",
                                                        color: "#000",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {item.total || "NA"}
                                                    </strong>
                                                  </Tooltip>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      backgroundColor:
                                                        bgColorDiscountAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.dueDate ? (
                                                      formatDate(item.dueDate)
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      borderRadius: "5px",
                                                      padding: "5px 10px",

                                                      color:
                                                        item.paymentStatus ===
                                                        "paid"
                                                          ? "green"
                                                          : "red",
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.amount ? (
                                                      item.paymentStatus.toUpperCase() ||
                                                      "unPaid"
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <div className="action-download-bill">
                                                    <Tooltip
                                                      placement="top"
                                                      title={
                                                        language === "hindi"
                                                          ? "Download bill invoice in PDF"
                                                          : " पीडीएफ में बिल चालान डाउनलोड करें"
                                                      }
                                                      arrow
                                                    >
                                                      <div
                                                        className="action-download-pdf"
                                                        onClick={() =>
                                                          downloadPDF([item])
                                                        }
                                                      >
                                                        <FaFilePdf />
                                                      </div>
                                                    </Tooltip>
                                                    <Tooltip
                                                      placement="top"
                                                      title={
                                                        language === "hindi"
                                                          ? "Download bill invoice in EXCEL"
                                                          : "एक्सेल  में बिल चालान डाउनलोड करें"
                                                      }
                                                      arrow
                                                    >
                                                      <div
                                                        className="action-download-excel"
                                                        onClick={() =>
                                                          downloadExcel([item])
                                                        }
                                                      >
                                                        <FaFileExcel />
                                                      </div>
                                                    </Tooltip>
                                                    {/* <Tooltip
                                                placement="top"
                                                title={
                                                  language === "hindi"
                                                    ? "Download bill in CSV"
                                                    : "बिल को सीएसवी में डाउनलोड करें"
                                                }
                                                arrow
                                              >
                                                <div
                                                  className="action-download-csv"
                                                  onClick={() =>
                                                    downloadCSV([item])
                                                  }
                                                >
                                                  <FaFileCsv />
                                                </div>
                                              </Tooltip> */}
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>{" "}
                                          </>
                                        );
                                      })
                                    ) : (
                                      <td
                                        className="no-data-billing-td"
                                        colSpan={6}
                                      >
                                        No Data
                                      </td>
                                    )}
                                    <td
                                      className="rwa-user-list-pagination-main-div"
                                      colSpan={13}
                                    >
                                      {totalPagesByBilling > 1 && (
                                        <div className="table-pagination">
                                          <Stack spacing={2}>
                                            <Pagination
                                              count={totalPagesByBilling}
                                              page={currentPageByBilling}
                                              onChange={
                                                handleChangePageByBilling
                                              }
                                              color="primary"
                                            />
                                          </Stack>
                                        </div>
                                      )}
                                    </td>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div className="card-billing-progress-body">
                              <div className="card-billing-progress-details"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ViewBillingDetails;
