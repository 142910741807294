import React, { useContext, useEffect, useState } from "react";
import { MdInput } from "react-icons/md";
import { MdAssignment } from "react-icons/md";
import { FaHome, FaImage, FaImages } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TbListDetails, TbPasswordUser } from "react-icons/tb";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FaHouseUser } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { FaBuilding, FaUserTie } from "react-icons/fa";
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { FaMap, FaCity, FaFileInvoiceDollar } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { AiOutlineBuild, AiOutlineUserSwitch } from "react-icons/ai";
import { formatDate } from "../../../../../../lib/FormattedDate";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { GoArrowDownLeft, GoArrowDownRight } from "react-icons/go";
import { PORT } from "../../../../../../Api/api";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ViewVisitorModal = ({ data }) => {
  const [value, setValue] = React.useState(0);
  const { language } = useContext(LanguageContext);
  const [state, setState] = useState({
    right: false,
  });
  const [userData, setUserData] = useState([]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //formatDate1 timestap
  function formatDate1(dateString) {
    const date = new Date(dateString); // Create a Date object from the string

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day is single digit
    const month = date
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase(); // Get the month in short form (e.g., 'FEB')
    const year = date.getFullYear(); // Get the full year

    // Format the date in the desired format: DD-MMM-YYYY
    return `${day}-${month}-${year}`;
  }
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            title={language === "hindi" ? "view" : "देखना"}
            placement="top"
            arrow
          >
            <div onClick={toggleDrawer(anchor, true)}>
              <h5 className=" mt-3 house-view-icon ">
                <IoEyeSharp
                  data-toggle="tooltip"
                  className="eyes_view"
                  data-placement="top"
                />
              </h5>
            </div>
          </Tooltip>
          <Drawer
            className="regular_drawer   show-top"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            <div className="close_btn" onClick={toggleDrawer(anchor, false)}>
              <IoMdClose className="close_icons_house" />
            </div>
            <hr className="hr_line" />
            {/* ownerDetails */}
            <div className="society-view-details-main-div">
              <div className="society-view-details-top-heading">
                <div className="top-society-images-heading-icon-div">
                  <TbListDetails />
                  &nbsp;
                  {language === "hindi" ? "Visitor Details" : "मालिक विवरण"}
                </div>
              </div>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp; {language === "hindi" ? "Name " : "नाम"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaPhone />
                    &nbsp; {language === "hindi" ? "Phone No." : "फोन नंबर"} :
                  </h3>
                  <h3 className="house_title_left">
                    <AiOutlineBuild />
                    &nbsp; {language === "hindi" ? "Purpose" : "उद्देश्य"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaMapMarkerAlt />
                    &nbsp; {language === "hindi" ? "Date" : "तारीख"} :
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={data.id}>
                    <h3 className="house_title_left">
                      {data.visitorName || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {data.visitorPhone || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {data.visitorPurposeId?.purpose || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {formatDate1(data.createdAt) || "Not Added"}
                    </h3>
                  </React.Fragment>
                </div>
              </div>
            </div>
            {/* timelin/e */}
            {data?.clockIn ? (
              <div className="society-view-details-main-div">
                <div className="society-view-details-top-heading">
                  <div className="top-society-images-heading-icon-div">
                    <TbListDetails />
                    &nbsp;
                    {language === "hindi"
                      ? "Timeline Details"
                      : "टाइमलाइन विवरण"}
                  </div>
                </div>
                <div className="main_content_div">
                  <div className="content2_left_div">
                    <h3 className="house_title_left">
                      <FaUser />
                      &nbsp;{" "}
                      {language === "hindi"
                        ? "ClockInDate "
                        : "क्लॉक इन तिथि"}{" "}
                      :
                    </h3>
                    <h3 className="house_title_left">
                      <FaPhone />
                      &nbsp; {language === "hindi" ? "CLockIn" : "क्लॉक इन"} :
                    </h3>
                    <h3 className="house_title_left">
                      <AiOutlineBuild />
                      &nbsp;{" "}
                      {language === "hindi"
                        ? "ClockOutDate"
                        : "क्लॉक आउट तिथि"}{" "}
                      :
                    </h3>
                    <h3 className="house_title_left">
                      <FaMapMarkerAlt />
                      &nbsp; {language === "hindi" ? "ClockOut" : "क्लॉक आउट"} :
                    </h3>
                  </div>
                  <div className="content2_right_div">
                    <React.Fragment key={data.id}>
                      <h3 className="house_title_left">
                        {data.clockInDate
                          ? formatDate(data.clockInDate)
                          : "In Company"}
                      </h3>
                      <h3 className="house_title_left">
                        <GoArrowDownLeft style={{ color: "green" }} />
                        {data.clockIn ? data.clockIn : "In Company"}
                      </h3>
                      <h3 className="house_title_left">
                        {data.clockOutDate
                          ? formatDate(data.clockOutDate)
                          : "In Company"}
                      </h3>
                      <h3 className="house_title_left">
                        <GoArrowDownRight style={{ color: "red" }} />{" "}
                        {data.clockOut || "In Company"}
                      </h3>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            ) : null}
            {/* company details */}
            <div className="society-view-details-main-div">
              <div className="society-view-details-top-heading">
                <div className="top-society-images-heading-icon-div">
                  <TbListDetails />
                  &nbsp;
                  {language === "hindi" ? "Company Details" : "कंपनी विवरण"}
                </div>
              </div>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <FaBuilding />
                    &nbsp;{" "}
                    {language === "hindi" ? "Company Name  " : "कंपनी का नाम"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaMap />
                    &nbsp; {language === "hindi" ? "State" : "राज्य"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaCity />
                    &nbsp; {language === "hindi" ? "City" : "शहर"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaMapMarkerAlt />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? "Company Address"
                      : "कंपनी का पता"}{" "}
                    :
                  </h3>
                  <h3 className="house_title_left">
                    <FaPhone />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? "Company Phone No."
                      : "कंपनी फोन नंबर"}{" "}
                    :
                  </h3>
                  <h3 className="house_title_left">
                    <FaFileInvoiceDollar />
                    &nbsp; {language === "hindi" ? " GST No." : "जीएसटी नंबर"} :
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={data.id}>
                    <h3 className="house_title_left">
                      {data?.companyId
                        ? data?.companyId?.companyDetails?.companyName
                        : "Company details does not exist"}
                    </h3>
                    <h3 className="house_title_left">
                      {data?.companyId
                        ? data?.companyId?.companyDetails?.state
                        : "Company details does not exist"}
                    </h3>
                    <h3 className="house_title_left">
                      {data?.companyId
                        ? data?.companyId?.companyDetails?.city
                        : "Company details does not exist"}
                    </h3>
                    <h3 className="house_title_left">
                      {data?.companyId
                        ? data?.companyId?.companyDetails.address
                        : "Company details does not exist"}
                    </h3>
                    <h3 className="house_title_left">
                      {data?.companyId
                        ? data?.companyId?.companyDetails.companyPhone
                        : "Company details does not exist"}
                    </h3>
                    <h3 className="house_title_left">
                      {data?.companyId
                        ? data?.companyId?.companyDetails.GSTNumber
                        : "Company details does not exist"}
                    </h3>
                  </React.Fragment>
                </div>
              </div>
            </div>
            {/* image section */}
            <div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="society-view-details-tabs"
                  >
                    <Tab
                      label={
                        <div className="top-heading-label-title">
                          <FaHouseUser /> &nbsp;{" "}
                          {language === "english" ? "तस्वीरें" : "Pictures"}
                        </div>
                      }
                      {...a11yProps(0)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  {/* visitor */}
                  <div className="mt-1 view-society-logo-div">
                    <div className="regular-view-images-div">
                      {data?.visitorImages?.length > 0 ? (
                        Array.isArray(data.visitorImages) &&
                        data.visitorImages.map((imgPath, index) => {
                          const modifiedPath = imgPath.substring(
                            imgPath.indexOf("public/") + "public/".length
                          );
                          const imageUrl = `${PORT}/${modifiedPath}`;

                          return <img key={index} src={imageUrl} alt="" />;
                        })
                      ) : (
                        <div className="no-data">No Data Found</div>
                      )}
                    </div>
                  </div>
                </CustomTabPanel>
              </Box>
            </div>{" "}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ViewVisitorModal;
