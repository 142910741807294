import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { PORT } from "../../../../../../Api/api";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { Tooltip } from "@mui/material";
import Loading from "../../../../../../Loading/Loading";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
import decodeToken from "../../../../corporateLib/decodeToken/DecodeToken";
const CorporateRoleEdit = () => {
  const { language } = useContext(LanguageContext);
  const roleType = 1;
  //RoleFunctiolaity handle
  const [selectedRoleType, setSelectedRoleType] = useState("");
  console.log();
  const [societyLevelRole, setSocietyLevelRole] = useState("");
  //handle permission checked
  const [selectedActionsSaas, setSelectedActionsSaas] = useState({});
  const [selectedActionsSociety, setSelectedActionsSociety] = useState({});
  const [selectedActionsSocietyLevel, setSelectedActionsSocietyLevel] =
    useState({});
  const [selectedActionsGuardAccess, setSelectedActionsGuardAccess] = useState(
    {}
  );
  const [rolesTitle, setRolesTitle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [roleData, setRoleData] = useState([]);
  //get token id
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  const roleLevel = tokenData.data.level;
  const ADMINLevel = localStorage.getItem("ADMINLevel");
  const GUARDLevel = localStorage.getItem("GUARDLevel");
  const params = useParams();
  const id = params.id;
  //getCorporateRoleById
  const getCorporateRoleById = async () => {
    try {
      const response = await axios.get(`${PORT}/getCorporateRoleById/${id}`);
      console.log(response.data.user);
      setRoleData(response.data.user);
      const data = response.data.user;
      formik.resetForm({
        values: {
          title: data.title || "",
          desc: data.desc || "",
          permissions: data.permissions || [],
          roleType: data.roleType,
          level: selectedRoleType === "saas" ? ADMINLevel : GUARDLevel,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    permissions: Yup.array()
      .of(
        Yup.object().shape({
          moduleName: Yup.string().required("Module name is required"),
          actions: Yup.array()
            .of(Yup.string())
            .required("At least one action is required"),
        })
      )
      .required("Permissions are required"),
  });
  //role initialModules
  const initialModules = [
    { moduleName: "Roles", actions: [] },
    { moduleName: "Users", actions: [] },
    { moduleName: "Company", actions: [] },
    { moduleName: "Visitor Record", actions: [] },
    { moduleName: "Public access", actions: [] },
    { moduleName: "Purpose of Occasional", actions: [] },
  ];
  const formik = useFormik({
    initialValues: {
      title: "",
      desc: "",
      permissions: initialModules,
      roleType: selectedRoleType,
    },

    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      setDisabled(true);
      try {
        const updatedValues = {
          ...values,
          level: values.roleType === "saas" ? ADMINLevel : GUARDLevel,
        };
        await axios
          .put(`${PORT}/editCorporateRole/${id}`, updatedValues)
          .then((res) => {
            toast.success(`${res.data.msg}`);
            setSubmitting(false);
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          });
      } catch (error) {
        toast.error(`${error.response.data.msg}`);
        setSubmitting(false);
        setDisabled(false);
      }
    },
  });
  const handleCheckboxChange = (moduleName, action) => {
    //add selected actionn
    // Update the state based on whether the checkbox is checked or unchecked
    const updatedPermissions = formik.values.permissions.map((permission) => {
      if (permission.moduleName === moduleName) {
        if (permission.actions.includes(action)) {
          return {
            ...permission,
            actions: permission.actions.filter((a) => a !== action),
          };
        } else {
          return {
            ...permission,
            actions: [...permission.actions, action],
          };
        }
      }
      // Check if any SAAS modules are still checked
      // setSaasChecked(anyChecked);  // Update state based on the checkbox change
      return permission;
    });
    formik.setFieldValue("permissions", updatedPermissions);
  };
  const navigate = useNavigate();
  const defaultActions = ["Module", "Create", "Read", "Edit", "Delete"];
  const [filteredModules, setFilteredModules] = useState([]);
  const filterPermissions = () => {
    return formik.values.permissions.filter((permission) => {
      switch (roleLevel) {
        case "1":
          // For roleType 1 (SaaS or Guard)
          switch (selectedRoleType) {
            case "saas":
              formik.setFieldValue("roleType", "saas");
              // For "saas", show specific modules like Roles, Users, Company, etc.
              return [
                "Roles",
                "Users",
                "Company",
                "Visitor Record",
                "Purpose of Occasional",
              ].includes(permission.moduleName);

            case "guard":
              formik.setFieldValue("roleType", "guard");
              // For "guard", only show "Public access"
              return permission.moduleName === "Public access";

            default:
              return false;
          }
        case "2":
          // For roleType 1 (SaaS or Guard)
          switch (selectedRoleType) {
            case "guard":
              formik.setFieldValue("roleType", "guard");
              // For "guard", only show "Public access"
              return permission.moduleName === "Public access";

            default:
              return false;
          }
        case "4":
          switch (selectedRoleType) {
            case "saas":
              formik.setFieldValue("roleType", "saas");
              // For "saas", show specific modules like Roles, Users, Company, etc.
              return [
                "Roles",
                "Users",

                "Visitor Record",
                "Purpose of Occasional",
              ].includes(permission.moduleName);

            case "guard":
              formik.setFieldValue("roleType", "guard");
              // For "guard", only show "Public access"
              return permission.moduleName === "Public access";

            default:
              return false;
          }
        case "5":
          switch (selectedRoleType) {
            case "guard":
              formik.setFieldValue("roleType", "guard");
              // For "guard", only show "Public access"
              return permission.moduleName === "Public access";

            default:
              return false;
          }
        // If you have more cases for other roles, add them here...
        default:
          if (selectedRoleType === "saas") {
            return true; // If it's a SaaS role, show all modules
          }
          return false;
      }
    });
  };
  useEffect(() => {
    const modules = filterPermissions();
    setFilteredModules(modules);
    //saas level check
    setSelectedActionsSaas(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
    //society level check
    setSelectedActionsSociety(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
    //society level check
    setSelectedActionsSocietyLevel(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
    //guard level check
    setSelectedActionsGuardAccess(
      modules.map((module) => {
        return {
          isActionAvailable: module.actions.length > 0, // Check the length of 'action' array
        };
      })
    );
  }, [formik.values.permissions, roleType, selectedRoleType, societyLevelRole]);
  //lock roles
  useEffect(() => {
    if (roleData) {
      if (roleData.roleType == "saas") {
        setLoading(true);
        setSelectedRoleType("saas");
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
      if (roleData.roleType == "guard") {
        setLoading(true);
        setSelectedRoleType("guard");
        setTimeout(() => {
          setLoading(false);
        }, 800);
      }
    }
  }, [roleData]);
  useEffect(() => {
    getCorporateRoleById();
  }, []);
  return (
    <>
      <CorporateLayout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? " Add Roles" : "भूमिकाएँ जोड़ें "}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="roles_main_div">
          <form onSubmit={formik.handleSubmit} className="form_roles">
            {/* title */}
            <div>
              <label htmlFor="title" className="editLabel">
                {language === "hindi" ? " Title" : "शीर्षक "}{" "}
                <span className="Star_color">*</span>
              </label>
              <br />
              <input
                type="text"
                id="title"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Title"
                className="edit-input-role"
                required
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="error-roles-title"> {formik.errors.title}</div>
              ) : null}
            </div>
            <br />
            {/* discription */}
            <div>
              <label htmlFor="desc" className="editLabel">
                {language === "hindi" ? " Description" : "विवरण  "}{" "}
                <span className="Star_color">*</span>
              </label>
              <br />
              <textarea
                id="desc"
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Description"
                className="edit-input-role"
                required
              />
              {formik.touched.desc && formik.errors.desc ? (
                <div className="error-roles-des">{formik.errors.desc}</div>
              ) : null}
            </div>
            {/* Radio buttons for role type selection */}
            {roleLevel === "1" && (
              <div>
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <Tooltip
                  title={
                    selectedRoleType === "guard" &&
                    selectedActionsSociety.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "आप सास भूमिका का चयन तब तक नहीं कर सकते जब तक आप समाज की अनुमतियों को अनचेक नहीं करते।"
                        : "You cannot select the SaaS role until you uncheck the Guard  permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="saas"
                      required
                      checked={selectedRoleType === "saas"}
                      disabled={
                        selectedRoleType === "guard" &&
                        selectedActionsSociety.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      onChange={() => setSelectedRoleType("saas")}
                    />
                    {language === "hindi"
                      ? " SAAS Level Role"
                      : "समाज स्तर पर भूमिका "}
                  </label>
                </Tooltip>
                <Tooltip
                  title={
                    selectedRoleType === "saas" &&
                    selectedActionsSaas.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "जब तक आप सास की अनुमति को अनचेक नहीं करेंगे, तब तक आप समाज की भूमिका नहीं देख सकते"
                        : "You cannot select the Guard role until you uncheck the SaaS permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="guard"
                      required
                      disabled={
                        selectedRoleType === "saas" &&
                        selectedActionsSaas.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={selectedRoleType === "guard"}
                      onChange={() => setSelectedRoleType("guard")}
                    />
                    {language === "hindi"
                      ? " Guard Level Role"
                      : " गार्ड स्तर की भूमिका"}
                  </label>
                </Tooltip>
              </div>
            )}
            {/* owner admin */}
            {roleLevel === "2" && (
              <div>
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <Tooltip
                  title={
                    selectedRoleType === "saas" &&
                    selectedActionsSaas.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "जब तक आप सास की अनुमति को अनचेक नहीं करेंगे, तब तक आप समाज की भूमिका नहीं देख सकते"
                        : "You cannot select the Guard role until you uncheck the SaaS permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="guard"
                      required
                      disabled={
                        selectedRoleType === "saas" &&
                        selectedActionsSaas.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={selectedRoleType === "guard"}
                      onChange={() => setSelectedRoleType("guard")}
                    />
                    {language === "hindi"
                      ? " Guard Level Role"
                      : " गार्ड स्तर की भूमिका"}
                  </label>
                </Tooltip>
              </div>
            )}
            {/* company */}
            {roleLevel === "4" && (
              <div>
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <Tooltip
                  title={
                    selectedRoleType === "guard" &&
                    selectedActionsSociety.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "आप सास भूमिका का चयन तब तक नहीं कर सकते जब तक आप समाज की अनुमतियों को अनचेक नहीं करते।"
                        : "You cannot select the SaaS role until you uncheck the Guard  permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="saas"
                      required
                      checked={selectedRoleType === "saas"}
                      disabled={
                        selectedRoleType === "guard" &&
                        selectedActionsSociety.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      onChange={() => setSelectedRoleType("saas")}
                    />
                    {language === "hindi"
                      ? " SAAS Level Role"
                      : "समाज स्तर पर भूमिका "}
                  </label>
                </Tooltip>
                <Tooltip
                  title={
                    selectedRoleType === "saas" &&
                    selectedActionsSaas.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "जब तक आप सास की अनुमति को अनचेक नहीं करेंगे, तब तक आप समाज की भूमिका नहीं देख सकते"
                        : "You cannot select the Guard role until you uncheck the SaaS permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="guard"
                      required
                      disabled={
                        selectedRoleType === "saas" &&
                        selectedActionsSaas.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={selectedRoleType === "guard"}
                      onChange={() => setSelectedRoleType("guard")}
                    />
                    {language === "hindi"
                      ? " Guard Level Role"
                      : " गार्ड स्तर की भूमिका"}
                  </label>
                </Tooltip>
              </div>
            )}
            {/* company admin */}
            {roleLevel === "5" && (
              <div>
                <label htmlFor="roleType" className="editLabel">
                  {language === "hindi" ? " Role Type" : "भूमिका प्रकार  "}
                  <span className="Star_color">*</span>
                </label>
                <br />
                <Tooltip
                  title={
                    selectedRoleType === "saas" &&
                    selectedActionsSaas.some(
                      (module) => module.isActionAvailable
                    )
                      ? language === "english"
                        ? "जब तक आप सास की अनुमति को अनचेक नहीं करेंगे, तब तक आप समाज की भूमिका नहीं देख सकते"
                        : "You cannot select the Guard role until you uncheck the SaaS permissions"
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <label>
                    <input
                      type="radio"
                      name="roleType"
                      value="guard"
                      required
                      disabled={
                        selectedRoleType === "saas" &&
                        selectedActionsSaas.some(
                          (module) => module.isActionAvailable
                        )
                      }
                      checked={selectedRoleType === "guard"}
                      onChange={() => setSelectedRoleType("guard")}
                    />
                    {language === "hindi"
                      ? " Guard Level Role"
                      : " गार्ड स्तर की भूमिका"}
                  </label>
                </Tooltip>
              </div>
            )}
            <br /> <br /> <br /> <br />
            <div className="main_permission">
              <table border="1">
                <thead>
                  <tr>
                    <th className="module-th-name">
                      {" "}
                      {language === "hindi"
                        ? "Module Name"
                        : "  मोड्यूल का नाम"}
                    </th>
                    <th>
                      {" "}
                      {language === "hindi"
                        ? " Module Actions"
                        : " मॉड्यूल क्रियाएँ"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={2}>
                        <div
                          className="role-edit-loader "
                          style={{ marginTop: "-215px" }}
                        >
                          {" "}
                          <Loading />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    filteredModules.map((permission, index) => (
                      <tr key={index}>
                        <td>{permission.moduleName}</td>
                        <td>
                          <div className="module-action-div">
                            {permission.moduleName === "Public access" ? (
                              <h4 key="Public">
                                <input
                                  type="checkbox"
                                  name={`${permission.moduleName}Public`}
                                  id={`${permission.moduleName}Public`}
                                  checked={permission.actions.includes(
                                    "public"
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(
                                      permission.moduleName,
                                      "public"
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`${permission.moduleName}Public`}
                                >
                                  Public
                                </label>
                              </h4>
                            ) : (
                              // Render other modules with default actions
                              defaultActions.map((action) => {
                                if (
                                  permission.moduleName ===
                                    "Purpose of Occasional" &&
                                  action.toLowerCase() === "edit" &&
                                  roleLevel == "4"
                                ) {
                                  return null; // Skip rendering the Edit button
                                }
                                if (
                                  permission.moduleName === "Visitor Record" &&
                                  (
                                    action.toLowerCase() === "edit")
                                ) {
                                  if (
                                    roleLevel === "1" ||
                                    roleLevel === "2" ||
                                    roleLevel === "4" ||
                                    roleLevel === "5"
                                  ) {
                                    return null; // Skip for these roles
                                  }
                                }

                                return (
                                  <td key={action} className="td_permission">
                                    <input
                                      type="checkbox"
                                      name={`${permission.moduleName}${action}`}
                                      id={`${permission.moduleName}${action}`}
                                      checked={permission.actions.includes(
                                        action.toLowerCase()
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          permission.moduleName,
                                          action.toLowerCase()
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${permission.moduleName}${action}`}
                                    >
                                      {action}
                                    </label>
                                  </td>
                                );
                              })
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="role_submit_btn">
              <button
                className="edit-button edit_btn_create_role"
                type="submit"
                disabled={isDisabled}
              >
                {isSubmitting ? (
                  <ButtonLoader />
                ) : language === "hindi" ? (
                  " Create Role"
                ) : (
                  " भूमिका बनाएँ "
                )}
              </button>
            </div>
          </form>
        </div>
      </CorporateLayout>
      <ToastContainer />
    </>
  );
};

export default CorporateRoleEdit;
