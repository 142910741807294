import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { PORT } from "../../../../Api/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../../../lib/Layout";
import AddBackbtn from "../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { useFormikContext } from "formik";
import getCurrentDate from "../../../../lib/CurrentData";
import getCurrentTime from "../../../../lib/CurrentTime";
import ButtonLoader from "../../../../Loading/ButtonLoader";
import "./style.css";
const AddCorporateUser = () => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  //add corporate
  const handleSubmit = async (values, { setSubmitting }) => {
    const updatedValues = {
      ...values,
      level: values.role === "owner" ? "1" : "4",
    };
    try {
      await axios.post(`${PORT}/addCorporate`, updatedValues);
      toast.success("Corporate Added Successfully!");
      setTimeout(() => {
        navigate("/admin/viewCorporateUser");
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.errors[0]);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? " Add Corporate" : "कॉर्पोरेट जोड़ें"}
          </h5>

          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <div className="society_form_div">
          <Formik
            initialValues={{
              name: "",
              role: "owner",
              ownerDetails: { fullName: "", email: "", phone: "", address: "" },
              companyDetails: {
                companyName: "",
                state: "",
                city: "",
                address: "",
                companyPhone: "",
                GSTNumber: "",
              },
              loginCredentials: { username: "", password: "" },
            }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form enctype="multipart/form-data">
                <div className="society_main">
                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi"
                        ? " Add Client Details"
                        : "ग्राहक विवरण जोड़ें"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="name">
                        {language === "hindi"
                          ? "  Client Name"
                          : "ग्राहक का नाम"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />

                      <Field
                        type="text"
                        className="edit-input"
                        name="name"
                        placeholder="Client Name"
                        maxLength="45"
                        required
                        autoComplete="off"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="societyLogo"
                      >
                        {language === "hindi" ? "Role" : "भूमिका  "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        as="select"
                        className="edit-input"
                        id="role"
                        name="role"
                        required
                      >
                        <option value="">
                          {" "}
                          {language === "hindi"
                            ? "Select House Range"
                            : "हाउस रेंज चुनें"}{" "}
                        </option>
                        <option value="owner">Owner</option>
                        <option value="company">Company</option>
                      </Field>
                      <ErrorMessage
                        name="societyLogo"
                        component="div"
                        className="error_msg_society"
                      />
                    </div>
                  </div>
                  {/* Owner Details */}
                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi" ? "Owner Details" : "मालिक विवरण"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="planType">
                        {language === "hindi" ? "Name " : " नाम"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="ownerDetails.fullName"
                        placeholder="Full Name"
                        required
                        className="edit-input "
                      ></Field>
                    </div>
                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Email" : " ईमेल "}
                        <span className="Star_color">*</span>
                      </label>
                      <br />

                      <Field
                        type="email"
                        name="ownerDetails.email"
                        placeholder="Email"
                        required
                        className="edit-input "
                      ></Field>
                    </div>

                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Phone No." : "फोन नंबर"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />

                      <Field
                        type="number"
                        name="ownerDetails.phone"
                        placeholder="Phone Number"
                        required
                        className="edit-input "
                      ></Field>
                    </div>

                    <div>
                      <label
                        className="edit_society_lable"
                        htmlFor="intervalType"
                      >
                        {language === "hindi" ? "Address" : "पता"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />

                      <Field
                        type="text"
                        name="ownerDetails.address"
                        placeholder="Address"
                        required
                        className="edit-input "
                      ></Field>
                    </div>
                  </div>
                  {/* companey Details */}
                  {values.role === "company" && (
                    <div className="society_main_div">
                      <div className="society_detail_heading">
                        {language === "hindi"
                          ? "Company Details"
                          : "कंपनी विवरण"}
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="planType"
                        >
                          {language === "hindi"
                            ? "Company Name "
                            : "कंपनी का नाम"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="companyDetails.companyName"
                          placeholder="Company Name"
                          required
                          className="edit-input "
                        ></Field>
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "State" : "राज्य"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />

                        <Field
                          type="text"
                          name="companyDetails.state"
                          placeholder="State"
                          required
                          className="edit-input "
                        ></Field>
                      </div>
                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "City" : "शहर"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />

                        <Field
                          type="text"
                          name="companyDetails.city"
                          placeholder="City"
                          required
                          className="edit-input "
                        ></Field>
                      </div>

                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi"
                            ? "Company Address"
                            : "कंपनी का पता"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />

                        <Field
                          type="text"
                          name="companyDetails.address"
                          placeholder="Company Address"
                          required
                          className="edit-input "
                        ></Field>
                      </div>

                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi"
                            ? "Company Phone No."
                            : "कंपनी फोन नंबर"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />

                        <Field
                          type="number"
                          name="companyDetails.companyPhone"
                          placeholder="Company Phone"
                          required
                          className="edit-input "
                        ></Field>
                      </div>

                      <div>
                        <label
                          className="edit_society_lable"
                          htmlFor="intervalType"
                        >
                          {language === "hindi" ? "GST No." : "जीएसटी नंबर"}
                          <span className="Star_color">*</span>
                        </label>
                        <br />

                        <Field
                          type="text"
                          name="companyDetails.GSTNumber"
                          placeholder="GST Number"
                          required
                          className="edit-input "
                        ></Field>
                      </div>
                    </div>
                  )}
                  {/* Login Credentials  */}

                  <div className="society_main_div">
                    <div className="society_detail_heading">
                      {language === "hindi" ? "Login Details " : "लॉगिन विवरण"}
                    </div>
                    <div>
                      <label className="edit_society_lable" htmlFor="username">
                        {language === "hindi" ? "Username" : "उपयोगकर्ता नाम"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="text"
                        name="loginCredentials.username"
                        placeholder="Username"
                        required
                        className="edit-input"
                      />
                    </div>

                    <div>
                      <label className="edit_society_lable" htmlFor="password">
                        {language === "hindi" ? "Password" : "पासवर्ड"}
                        <span className="Star_color">*</span>
                      </label>
                      <br />
                      <Field
                        type="password"
                        name="loginCredentials.password"
                        placeholder="Password"
                        required
                        className="edit-input"
                      />
                    </div>
                  </div>

                  {/* Submit buttton */}
                  <div className="society_bottom_btn_div">
                    <button
                      className="society_btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <ButtonLoader />
                      ) : language === "hindi" ? (
                        "    Submit"
                      ) : (
                        "  जमा करना"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default AddCorporateUser;
