import React, { useContext, useEffect, useState } from "react";
import { MdInput } from "react-icons/md";
import { MdAssignment } from "react-icons/md";
import { FaHome, FaImage, FaImages } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TbListDetails, TbPasswordUser } from "react-icons/tb";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FaHouseUser } from "react-icons/fa";
import Tooltip from "@mui/material/Tooltip";
import { FaBuilding, FaUserTie } from 'react-icons/fa';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import {  FaMap, FaCity ,FaFileInvoiceDollar} from 'react-icons/fa';
import {  FaLock } from 'react-icons/fa';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ViewCompanyModal = ({ data }) => {
  const [value, setValue] = React.useState(0);
  const { language } = useContext(LanguageContext);
  const [state, setState] = useState({
    right: false,
  });
  const [userData, setUserData] = useState([]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            title={language === "hindi" ? "view" : "देखना"}
            placement="top"
            arrow
          >
            <div onClick={toggleDrawer(anchor, true)}>
              <h5 className=" mt-3 house-view-icon ">
                <IoEyeSharp
                  data-toggle="tooltip"
                  className="eyes_view"
                  data-placement="top"
                />
              </h5>
            </div>
          </Tooltip>
          <Drawer
            className="regular_drawer   show-top"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            <div className="close_btn" onClick={toggleDrawer(anchor, false)}>
              <IoMdClose className="close_icons_house" />
            </div>
            <hr className="hr_line" />
            {/* ownerDetails */}
            <div className="society-view-details-main-div">
              <div className="society-view-details-top-heading">
                <div className="top-society-images-heading-icon-div">
                  <TbListDetails />
                  &nbsp;
                  {language === "hindi" ? "Owner Details" : "मालिक विवरण"}
                </div>
              </div>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp; {language === "hindi" ? "Name " : "नाम"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaEnvelope />
                    &nbsp; {language === "hindi" ? "Email" : "ईमेल"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaPhone />
                    &nbsp; {language === "hindi" ? "Phone No." : "फोन नंबर"} :
                  </h3>
                  <h3 className="house_title_left">
                    <FaMapMarkerAlt />
                    &nbsp; {language === "hindi" ? "Address" : "पता"} :
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={data.id}>
                    <h3 className="house_title_left">
                      {data.ownerDetails.fullName || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {data.ownerDetails.email || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {data.ownerDetails.phone || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {data.ownerDetails.address || "Not Added"}
                    </h3>
                  </React.Fragment>
                </div>
              </div>
            </div>
            {/* companey Details */}
            {data.role === "company" && (
              <div className="society-view-details-main-div">
                <div className="society-view-details-top-heading">
                  <div className="top-society-images-heading-icon-div">
                    <TbListDetails />
                    &nbsp;
                    {language === "hindi" ? "Company Details" : "कंपनी विवरण"}
                  </div>
                </div>
                <div className="main_content_div">
                  <div className="content2_left_div">
                    <h3 className="house_title_left">
                      <FaBuilding />
                      &nbsp; {language === "hindi" ? "Company Name  " : "कंपनी का नाम"} :
                    </h3>
                    <h3 className="house_title_left">
                      <FaMap />
                      &nbsp; {language === "hindi" ? "State" : "राज्य"} :
                    </h3>
                    <h3 className="house_title_left">
                      <FaCity />
                      &nbsp; {language === "hindi" ? "City" : "शहर"} :
                    </h3>
                    <h3 className="house_title_left">
                      <FaMapMarkerAlt />
                      &nbsp; {language === "hindi" ? "Company Address" : "कंपनी का पता"} :
                    </h3>
                    <h3 className="house_title_left">
                      <FaPhone />
                      &nbsp; {language === "hindi" ? "Company Phone No." : "कंपनी फोन नंबर"} :
                    </h3>
                    <h3 className="house_title_left">
                      <FaFileInvoiceDollar />
                      &nbsp; {language === "hindi" ? " GST No." : "जीएसटी नंबर"} :
                    </h3>
                
                  </div>
                  <div className="content2_right_div">
                    <React.Fragment key={data.id}>
                      <h3 className="house_title_left">
                        {data.companyDetails.companyName || "Not Added"}
                      </h3>
                      <h3 className="house_title_left">
                        {data.companyDetails.state || "Not Added"}
                      </h3>
                      <h3 className="house_title_left">
                        {data.companyDetails.city || "Not Added"}
                      </h3>
                      <h3 className="house_title_left">
                        {data.companyDetails.address || "Not Added"}
                      </h3>
                      <h3 className="house_title_left">
                        {data.companyDetails.companyPhone || "Not Added"}
                      </h3>
                      <h3 className="house_title_left">
                        {data.companyDetails.GSTNumber || "Not Added"}
                      </h3>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            )}
            {/* login details */}
            <div className="society-view-details-main-div">
              <div className="society-view-details-top-heading">
                <div className="top-society-images-heading-icon-div">
                  <TbListDetails />
                  &nbsp;
                  {language === "hindi"
                    ? " Login Details"
                    : "लॉगिन विवरण"}
                </div>
              </div>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? "Username"
                      : "उपयोगकर्ता नाम"}{" "}
                    :
                  </h3>
                  <h3 className="house_title_left">
                    <FaLock />
                    &nbsp; {language === "hindi" ? "Password" : "पासवर्ड"} :
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={data.id}>
                    <h3 className="house_title_left">
                      {data.loginCredentials.username || "Not Added"}
                    </h3>
                    <h3 className="house_title_left">
                      {data.loginCredentials.password || "Not Added"}
                    </h3>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ViewCompanyModal;
