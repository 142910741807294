import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { PORT } from "../Api/api";
import { LanguageContext } from "../lib/LanguageContext";
import SmallLoader from "../Loading/SmallLoader";

const SuperAdminTopSociety = () => {
  const [topSocieties, setTopSocieties] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [availableMonths, setAvailableMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = useContext(LanguageContext);

  const getColorByIndex = useCallback((index) => {
    const colors = ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"];
    return colors[index % colors.length];
  }, []);

  const getTopSocieties = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${PORT}/getTopSocieties`);
      if (response.data.success) {
        const months = Object.keys(response.data.monthly);
        setAvailableMonths(months);

        // Set default month only if not already selected
        const defaultMonth = selectedMonth || months[0];
        setSelectedMonth(defaultMonth);

        // Fetch the data for the selected month
        const societiesData = response.data.monthly[defaultMonth]?.map(
          (society, index) => ({
            name: society.society_name,
            value: society.total === 0 ? 0.01 : society.total, // Assign a small value for zero
            color: getColorByIndex(index),
          })
        ) || [];

        setTopSocieties(societiesData);
      } else {
        console.error("Error: Data not found or success flag is false");
        setTopSocieties([]);
      }
    } catch (error) {
      console.error("Error fetching top societies:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedMonth, getColorByIndex]);

  useEffect(() => {
    getTopSocieties();
  }, []);

  useEffect(() => {
    if (selectedMonth) getTopSocieties();
  }, [selectedMonth, getTopSocieties]);

  return (
    <div className="card z-index-2">
      <div className="card-header pb-0 pt-3 bg-transparent h-100 mb-2">
        <div className="label-title-div-inner">
          <div>
            <label>
              {language === "hindi"
                ? `Select Month and Year :`
                : `महीना और वर्ष चुनें :`}
            </label>
            &nbsp;
          </div>
          <select onChange={(e) => setSelectedMonth(e.target.value)} value={selectedMonth}>
            {availableMonths.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>

        <h6 className="all-user-count-heading mt-2" style={{ textAlign: "center" }}>
          {language === "hindi"
            ? "Top Societies Overview"
            : "सर्वोत्तम समाजों का अवलोकन"}
        </h6>

        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "100px",
              height: "33vh",
            }}
          >
            <SmallLoader size={50} />
          </div>
        ) : topSocieties.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              layout="vertical"
              data={topSocieties}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" hide />
              <YAxis dataKey="name" type="category" width={100} />
              <Tooltip
                content={({ payload }) =>
                  payload?.length ? (
                    <div className="custom-tooltip">
                      <p>{`Society: ${payload[0].payload.name}`}</p>
                      <p>{`Total Revenue: ₹${payload[0].payload.value === 0 ? "0" : payload[0].payload.value}`}</p>
                    </div>
                  ) : null
                }
              />
              <Bar dataKey="value" barSize={50}>
                {topSocieties.map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="no-data-found-chart">No Data Found</div>
        )}
      </div>
    </div>
  );
};

export default SuperAdminTopSociety;
