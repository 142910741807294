import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { ThreeCircles } from "react-loader-spinner";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { useContext } from "react";
import { DataContext } from "../../../../lib/DataContext";
import Tooltip from "@mui/material/Tooltip";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import "./style.css";
import { PORT } from "../../../../Api/api";
import decodeToken from "../../../corporatePanel/corporateLib/decodeToken/DecodeToken";
import { useNavigate } from "react-router-dom";
function CorporateGuardPurposeScreen() {
  const [loading, setLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [purposeData, setPurposeData] = useState();
  const [filteredPurpose, setFilteredPurpose] = useState();
  const token = localStorage.getItem("corporateGuardToken");
  const tokenData = decodeToken(token);
  console.log(tokenData, "tokenData--");
  const id = tokenData?.data.corporate_id;
  const navigate = useNavigate();
  //setPurposeData Get Purpose
  const handleFetchPurpose = async () => {
    try {
      // const response = await axios.get(`${PORT}/getCorporatePurposeById`);
      // console.log(response, "RESPONDE");
      const result = await axios.get(
        `${PORT}/getCorporateById/${tokenData.data.corporate_id}`
      );
      console.log(result, "result");
      const filterData = result.data.purposeArray;

      setPurposeData(filterData);
      setFilteredPurpose(filterData);
      setLoading(false);
    } catch (error) {}
  };

  const handleSearchInput = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = purposeData?.filter((item) => {
      return item?.purposeId?.purpose?.toLowerCase().includes(query.trim());
    });
    setFilteredPurpose(filtered);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    setFilteredPurpose(purposeData);
  };
  const handleNavigate = (index) => {
    localStorage.setItem("purpose", JSON.stringify(purposeData[index]?._id));
    // JSON.stringify(purposeData[index].purpose)
    navigate("/corporateGuardVisitorInformation");
  };
  useEffect(() => {
    setSearchQuery("");
    handleFetchPurpose();
  }, []);
  return (
    <>
      <div className="entry_heading_div">
        <div className="entry-heading">
          <h1 className="m-font">
            {language === "english" ? "आने का उद्देश्य" : "Purpose of visit"}
          </h1>
        </div>
        <div className="entry_search_filter">
          <h5>
            <Box sx={{ "& > :not(style)": { m: 1, width: "30ch" } }}>
              <TextField
                id="search-input"
                label={
                  language === "english" ? "उद्देश्य द्वारा खोजें" : "Search..."
                }
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchInput}
                className="search-input"
                InputProps={{
                  endAdornment: searchQuery && (
                    <IconButton onClick={handleClearSearch} size="small">
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#5e72e4;",
                      borderWidth: "1px",
                      borderRadius: "5px",
                    },
                    "&:hover fieldset": {
                      borderColor: "#5e72e4;",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#5e72e4;",
                    },
                    "& .MuiInputLabel-root.Mui-error": {
                      color: "red",
                    },
                  },
                }}
              />
            </Box>
          </h5>
        </div>
      </div>
      <div className="purpose-main-div">
        <div className="purpose-sec m-font">
          {loading ? (
            <div className="three_circle_loader">
              <ThreeCircles
                visible={true}
                height={100}
                width={100}
                color="#5e72e4"
                ariaLabel="three-circles-loading"
              />
            </div>
          ) : filteredPurpose?.length > 0 ? (
            filteredPurpose.map((item, index) => (
              <div
                className="specific-person"
                onClick={() => handleNavigate(index)}
                key={index}
              >
                <div className="entry_image">
                  {item?.length > 0 ? (
                    <img src={`/${item?.replace("public/", "")}`} alt="" />
                  ) : (
                    <div className="entry_placeholder">
                      {(() => {
                        let words;

                        words = item?.purpose?.split(" ");
                        console.log(words, "words?");
                        const initials = words[0]
                          ?.substring(0, 1)
                          ?.toUpperCase();

                        return (
                          <div className="entry_image_div">
                            <b className="first_word">{initials}</b>
                          </div>
                        );
                      })()}
                    </div>
                  )}
                </div>
                <p className="entry_name_purpose">{item.purpose}</p>
              </div>
            ))
          ) : (
            <div className="data_not regular_entry_not_found">
              <img
                src="https://cdn.iconscout.com/icon/free/png-256/free-data-not-found-1965034-1662569.png"
                alt="Data not found"
              />
              <h1>
                {language === "english"
                  ? "उद्देश्य नहीं मिला"
                  : "Purpose not found"}
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CorporateGuardPurposeScreen;
