import React, { useContext, useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
import { TbListDetails } from "react-icons/tb";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { IoMdClose } from "react-icons/io";
import "./style.css";
import { PORT } from "../../../../Api/api";
import { FaUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import axios from "axios";
import { GoArrowDownLeft } from "react-icons/go";
import { formatDate } from "../../../../lib/FormattedDate";
import { FaCalendarDay } from "react-icons/fa";
import { GoArrowUpRight } from "react-icons/go";
import {
  X,
  Building2,
  MapPin,
  Phone,
  FileSpreadsheet,
  Calendar,
  Clock,
} from "lucide-react";
import decodeToken from "../../../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100%", // Ensures the dialog takes full width
    maxWidth: "100%", // Ensures it doesn't exceed the screen width
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CorporateVisitorViewScreen = () => {
  const { language } = useContext(LanguageContext);
  const [visitorData, setVisitorData] = useState([]);
  const token = localStorage.getItem("corporateGuardToken");
  const tokenData = decodeToken(token);
  const [state, setState] = useState({
    right: false,
  });
  const [openDialog, setOpenDialog] = useState(false); // Modal visibility
  const [selectedVisitor, setSelectedVisitor] = useState([]); // Selected visitor details
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleClickOpen = (visitor) => {
    setSelectedVisitor(visitor); // Set the selected visitor details
    setOpenDialog(true); // Open the dialog/modal
  };
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog/modal
  };
  // fetch Visitor
  const fetchVisitor = async () => {
    try {
      const response = await axios.get(`${PORT}/getVisitor`);
      const res = response.data.user.filter(
        (item) => item.guardId === tokenData.id && item.status === "Approved"
      );
      setVisitorData(res);
    } catch (error) {}
  };
  //handleClockOut
  const handleClockOut = async (id) => {
    try {
      const response = await axios.post(`${PORT}/clockOutVisitor/${id}`);
      fetchVisitor();
    } catch (error) {}
  };
  useEffect(() => {
    fetchVisitor();
  }, []);

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div onClick={toggleDrawer(anchor, true)}>
            <Tooltip
              title={language === "hindi" ? "view" : "देखना"}
              placement="top"
              arrow
            >
              <h5 className="house-view-icon ">
                <IoEyeSharp
                  data-toggle="tooltip"
                  className="eyes_view"
                  data-placement="top"
                />
              </h5>
            </Tooltip>
          </div>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className="show-top"
            sx={{
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            <div className="heading_user">
              <div
                className="society-view-details-close-icon"
                onClick={toggleDrawer(anchor, false)}
              >
                <IoMdClose />
              </div>
            </div>
            <hr className="hr_line" />
            <List>
              <div className="society-view-details-main-div">
                <div className="society-view-details-top-heading">
                  <div className="top-society-images-heading-icon-div">
                    <TbListDetails />
                    &nbsp;
                    {language === "hindi" ? "Visitor" : "आगंतुक"}
                  </div>
                </div>
                <div className="visitor-card-view">
                  <div className="visitor-card-container">
                    {visitorData.map((item) => {
                      return (
                        <div className="visitor-card-main" key={item.id}>
                          <div className="visitor-card-heading">
                            <h2>Visitor Details</h2>
                            <div>
                              <button onClick={() => handleClickOpen(item)}>
                                View Full Details
                              </button>
                            </div>
                          </div>

                          <div className="grid">
                            <div className="info-item">
                              <FaUser
                                className="w-5 h-5"
                                style={{ color: "#6b7280" }}
                              />
                              <div>
                                <p className="info-label">
                                  {language === "hindi" ? "Name" : "नाम"}{" "}
                                </p>
                                <p className="info-value">{item.visitorName}</p>
                              </div>
                            </div>
                            <div className="info-item">
                              <FaPhoneAlt
                                className="w-5 h-5"
                                style={{ color: "#6b7280" }}
                              />
                              <div>
                                <p className="info-label">
                                  {language === "hindi"
                                    ? "Phone no."
                                    : "फोन नंबर"}
                                </p>
                                <p className="info-value">
                                  {item.visitorPhone}
                                </p>
                              </div>
                            </div>
                            <div className="info-item">
                              <FaCalendarDay
                                className="w-5 h-5"
                                style={{ color: "#6b7280" }}
                              />
                              <div>
                                <p className="info-label">
                                  {language === "hindi"
                                    ? "Clock In Date"
                                    : "क्लॉक इन तिथि"}
                                </p>
                                <p className="info-value">
                                  {formatDate(item.clockInDate)}
                                </p>
                              </div>
                            </div>
                            <div className="info-item">
                              <FaClock
                                className="w-5 h-5"
                                style={{ color: "#6b7280" }}
                              />
                              <div>
                                <p className="info-label">
                                  {language === "hindi"
                                    ? "Clock In"
                                    : "क्लॉक इन"}
                                </p>
                                <p className="info-value">
                                  <GoArrowDownLeft style={{ color: "green" }} />{" "}
                                  {item.clockIn}
                                </p>
                              </div>
                            </div>
                            {item.clockOutDate ? (
                              <div className="info-item">
                                <FaCalendarDay
                                  className="w-5 h-5"
                                  style={{ color: "#6b7280" }}
                                />
                                <div>
                                  <p className="info-label">
                                    {language === "hindi"
                                      ? "Clock Out Date"
                                      : "क्लॉक आउट तिथि"}
                                  </p>
                                  <p className="info-value">
                                    {item.clockOutDate
                                      ? formatDate(item.clockOutDate)
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="info-item">
                              <FaClock
                                className="w-5 h-5"
                                style={{ color: "#6b7280" }}
                              />
                              {/* <div className="visitor-clock-out-div"> */}
                              <p className="info-label">
                                {language === "hindi"
                                  ? "Clock Out"
                                  : "क्लॉक आउट"}
                              </p>
                              <p className="info-value">
                                {item.clockOut == null ? (
                                  <>
                                    <button
                                      onClick={() => handleClockOut(item._id)}
                                      className="visitor-clock-out-button"
                                    >
                                      Clock Out
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <GoArrowDownLeft
                                      style={{ color: "red" }}
                                    />{" "}
                                    {item.clockOut}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        // </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </List>
          </Drawer>
        </React.Fragment>
      ))}

      {/* Modal/Dialog for Full Visitor Details */}
      <BootstrapDialog
        style={{
          zIndex: 999999999,
          width: "100%",
        }}
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="visitor-dialog-title"
      >
        <DialogTitle
          id="visitor-dialog-title"
          style={{ width: "100%", maxWidth: "100%" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6">
              <b>Full Visitor Details</b>
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {selectedVisitor && (
            <div>
              <div className="section-card">
                <h3 className="section-title">Company Information</h3>
                <div className="section-grid">
                  <div className="info-item">
                    <Building2
                      className="w-5 h-5"
                      style={{ color: "#6b7280" }}
                    />
                    <div>
                      <p className="info-label">Company Name</p>
                      <p className="info-value">
                        {
                          selectedVisitor?.companyId?.companyDetails
                            ?.companyName
                        }
                      </p>
                    </div>
                  </div>
                  <div className="info-item">
                    <MapPin className="w-5 h-5" style={{ color: "#6b7280" }} />
                    <div>
                      <p className="info-label">Address</p>
                      <p className="info-value">
                        {selectedVisitor?.companyId?.companyDetails?.address}
                      </p>
                      <p className="text-small">
                        {selectedVisitor?.companyId?.companyDetails.city},{" "}
                        {selectedVisitor?.companyId?.companyDetails?.state}
                      </p>
                    </div>
                  </div>
                  <div className="info-item">
                    <Phone className="w-5 h-5" style={{ color: "#6b7280" }} />
                    <div>
                      <p className="info-label">Phone Number</p>
                      <p className="info-value">
                        {
                          selectedVisitor?.companyId?.companyDetails
                            ?.companyPhone
                        }
                      </p>
                    </div>
                  </div>
                  <div className="info-item">
                    <FileSpreadsheet
                      className="w-5 h-5"
                      style={{ color: "#6b7280" }}
                    />
                    <div>
                      <p className="info-label">GST Number</p>
                      <p className="info-value">
                        {selectedVisitor?.companyId?.companyDetails?.GSTNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Timestamps */}
              <div className="section-card">
                <h3 className="section-title">Timeline</h3>
                <div className="section-grid">
                  <div className="info-item">
                    <Calendar
                      className="w-5 h-5"
                      style={{ color: "#6b7280" }}
                    />
                    <div>
                      <p className="info-label">Clock In</p>
                      <p className="info-value">
                        <GoArrowDownLeft style={{ color: "green" }} />
                        {selectedVisitor.clockIn}
                      </p>
                    </div>
                  </div>
                  <div className="info-item">
                    <Clock className="w-5 h-5" style={{ color: "#6b7280" }} />
                    <div>
                      <p className="info-label">CLock Out</p>
                      <p className="info-value">
                        <GoArrowUpRight style={{ color: "red" }} />

                        {selectedVisitor.clockOut || "In Company"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default CorporateVisitorViewScreen;
