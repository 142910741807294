import React, { useContext, useEffect, useRef, useState } from "react";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import { useNavigate } from "react-router-dom";
import { PORT } from "../../../../../../Api/api";
import axios from "axios";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import { ThreeCircles } from "react-loader-spinner";
import Swal from "sweetalert2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
// import ViewModalCorporate from "../../../../corporatePages/";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
import decodeToken from "../../../../../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
import ViewVisitorModal from "../viewVisitorModal/ViewVisitorModal";
import { GoArrowDownLeft, GoArrowDownRight } from "react-icons/go";
import AddVisitorModal from "../addVisitorModal/AddVisitorModal";
import { toast, ToastContainer } from "react-toastify";
import { FaDownload } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TiExport } from "react-icons/ti";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import swalAlert from "../../../../../../lib/alert";
import { formatDate } from "../../../../../../lib/FormattedDate";
import Papa from "papaparse";
const CorporateVisitorView = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [corporateData, setCorporateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const perPage = 10;
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  const roleLevel = tokenData.data.level;
  const permissions = tokenData?.roleData?.permissions;
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);
  //   //handleAddCompany
  const handleAddUser = () => {
    navigate("/corporate/corporateUserAdd");
  };
  //handleEditCorporate
  const handleEditCorporateUser = (id) => {
    navigate(`/corporate/corporateUserEdit/${id}`);
  };
  //handleDeleteCorporate
  const handleDeleteVisitor = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "my-swal",
      },
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.delete(`${PORT}/deleteVisitor/${id}`);
      Swal.fire({
        title: "Deleted!",
        text: "Corporate has been deleted.",
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      if (roleLevel === "1" || roleLevel === "2") {
        fetchCorporateOwnerVisitor();
      } else {
        fetchCorporateVisitor();
      }
    } catch (error) {
      console.log(error);
    }
  };
  // fetchCorporate Visitor Data
  const fetchCorporateVisitor = async () => {
    try {
      const response = await axios.get(`${PORT}/getVisitor`);
      const res = response.data.user.filter(
        (item) => item.corporate_id === tokenData.data.corporate_id
      );
      console.log(res);
      setCorporateData(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  //  fetchCorporate owner Visitor Data
  const fetchCorporateOwnerVisitor = async () => {
    try {
      const response = await axios.get(`${PORT}/getVisitor`);
      const res = response.data.user.filter(
        (item) => item.owner_id == tokenData.data.owner_id
      );
      console.log(res, "owner_id");
      setCorporateData(res);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  //formatDate1 timestap
  function formatDate1(dateString) {
    const date = new Date(dateString); // Create a Date object from the string

    // Get the day, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if day is single digit
    const month = date
      .toLocaleString("en-US", { month: "short" })
      .toUpperCase(); // Get the month in short form (e.g., 'FEB')
    const year = date.getFullYear(); // Get the full year

    // Format the date in the desired format: DD-MMM-YYYY
    return `${day}-${month}-${year}`;
  }
  // Search filter and Pagination Functionlaity

  //handle page change functionality
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
  };

  //handleAddVisitor
  const handleAddVisitor = async (formData) => {
    try {
      console.log("Visitor Data Submitted:", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const response = await axios.post(`${PORT}/createVisitor`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setModalOpen(false); // Close the modal after submission
      toast.success(response.data.message);
      if (roleLevel === "1" || roleLevel === "2") {
        fetchCorporateOwnerVisitor();
      } else {
        fetchCorporateVisitor();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //handleDownloadQrCode
  const handleDownloadQrCode = async (qrCodeUrl) => {
    try {
      const imageLink = `${PORT}${qrCodeUrl.replace("public/", "")}`;
      // Fetch the image as a blob
      const response = await fetch(imageLink);
      if (!response.ok) {
        throw new Error("Failed to fetch the QR Code image");
      }
      const blob = await response.blob();
      // Create a downloadable link from the blob
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "QRCode.png"; // Set the download file name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object
    } catch (error) {
      console.error("Failed to download QR Code:", error);
    }
  };
  //export to excel
  const exportToExcel = (data, filename) => {
    if (!filteredData || filteredData.length === 0) {
      swalAlert();
      return;
    }
    const filteredData1 = filteredData.map(
      ({
        visitorName,
        visitorPhone,
        status,
        clockIn,
        clockOut,
        companyId,
        clockInDate,
        clockOutDate,
        visitorPurposeId,
      }) => ({
        Name: visitorName,
        "Phone no.": visitorPhone,
        Status: status,
        Purpose: visitorPurposeId.purpose,
        "Clock In": clockIn ? clockIn : "--",
        "Clock Out": clockIn ? clockOut : "--",
        "Clock In date": clockInDate ? formatDate(clockInDate) : "--",
        "Clock Out date": clockOutDate ? formatDate(clockOutDate) : "--",
        "Company Name": companyId?.companyDetails?.companyName,
        State: companyId?.companyDetails?.state,
        City: companyId?.companyDetails?.city,
        Address: companyId?.companyDetails?.address,
        "Company Phone no.": companyId?.companyDetails?.companyPhone,
        GSTNumber: companyId?.companyDetails?.GSTNumber,
      })
    );
    const ws = XLSX.utils.json_to_sheet(filteredData1);
    const wscols = [{ wch: 30 }, { wch: 30 }, { wch: 30 }];
    ws["!cols"] = wscols;
    // Set row heights
    const rowHeights = [{ hpt: 30 }, ...filteredData1.map(() => ({ hpt: 20 }))];
    ws["!rows"] = rowHeights;
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
  };
  //export to csv
  const exportToCSV = (data, filename) => {
    if (!data || data.length === 0) {
      swalAlert();
      return;
    }
    const filteredData1 = filteredData.map(
      ({
        visitorName,
        visitorPhone,
        status,
        clockIn,
        clockOut,
        companyId,
        clockInDate,
        clockOutDate,

        visitorPurposeId,
      }) => ({
        Name: visitorName,
        "Phone no.": visitorPhone,
        Status: status,
        Purpose: visitorPurposeId.purpose,
        "Clock In": clockIn ? clockIn : "--",
        "Clock Out": clockIn ? clockOut : "--",
        "Clock In date": clockInDate ? formatDate(clockInDate) : "--",
        "Clock Out date": clockOutDate ? formatDate(clockOutDate) : "--",
        "Company Name": companyId?.companyDetails?.companyName,
        State: companyId?.companyDetails?.state,
        City: companyId?.companyDetails?.city,
        Address: companyId?.companyDetails?.address,
        "Company Phone no.": companyId?.companyDetails?.companyPhone,
        GSTNumber: companyId?.companyDetails?.GSTNumber,
      })
    );
    const csv = Papa.unparse(filteredData1);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename}${dateTime}.csv`);
  };
  //handle CLick Outside  accordian close
  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  //pagination functionlaity
  useEffect(() => {
    const filtered = corporateData.filter((item) =>
      item.visitorName?.toLowerCase().includes(searchQuery.trim().toLowerCase())
    );
    setFilteredData(filtered);
    setTotalPages(Math.ceil(filtered.length / perPage));
    setCurrentPage(1); // Reset to first page when search query changes
  }, [searchQuery, corporateData]);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, filteredData.length);
  const currentPageData = filteredData.slice(startIndex, endIndex);
  // function local
  useEffect(() => {
    if (roleLevel === "1" || roleLevel === "2") {
      fetchCorporateOwnerVisitor();
    } else {
      fetchCorporateVisitor();
    }
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <CorporateLayout>
        <div class="container-fluid py-4 ">
          <div class="row">
            <div class="col-12 ">
              <div class="card mb-4">
                <div class="card-header pb-0">
                  <div className="filtered-div">
                    {permissions[3]?.actions?.includes("read") && (
                      <div
                        ref={accordionRef}
                        className="p-export-btn export-dropdown"
                      >
                        <Accordion expanded={isOpen}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            onClick={handleToggle}
                          >
                            <TiExport className="export_icon" />
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="export-btns">
                              <button
                                className="export_btn_data"
                                onClick={() =>
                                  exportToExcel(filteredData, "Visitor Records")
                                }
                              >
                                Excel
                              </button>
                              <button
                                className="export_btn_data"
                                onClick={() =>
                                  exportToCSV(filteredData, "Visitor Records ")
                                }
                              >
                                CSV
                              </button>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                    {permissions[3]?.actions?.includes("create") && (
                      <div className="society_add_btn">
                        <button className="" onClick={() => setModalOpen(true)}>
                          {language === "hindi" ? "  Add +" : "+ जोड़ें"}
                        </button>
                      </div>
                    )}
                    {permissions[3]?.actions?.includes("read") && (
                      <div className="search-filter-box">
                        <Box
                          sx={{
                            "& > :not(style)": { m: 1, width: "25ch" },
                          }}
                        >
                          <TextField
                            id="search-input"
                            label={
                              language === "hindi" ? "Search..." : "खोज..."
                            }
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchInput}
                            className="search-input"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: " #5e72e4",
                                  borderWidth: "1px",
                                  borderRadius: "5px",
                                },
                                "&:hover fieldset": {
                                  borderColor: " #5e72e4",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: " #5e72e4",
                                },

                                "& .MuiInputLabel-root.Mui-error": {
                                  color: "red",
                                },
                              },
                            }}
                          />
                        </Box>
                      </div>
                    )}
                  </div>{" "}
                </div>
                <div class="card-body px-0 pt-0 pb-2 w-100">
                  <div class="table-responsive p-0">
                    {loading ? (
                      <div className="three_circle_loader">
                        <ThreeCircles
                          visible={true}
                          height={100}
                          width={100}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : permissions[3]?.actions?.includes("read") ? (
                      <table className="table align-items-center mb-0  ">
                        <thead>
                          <tr>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Name" : "नाम"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi"
                                ? "Phone number"
                                : "फ़ोन नंबर"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Status" : "स्थिति"}
                            </th>
                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Clock In" : "क्लॉक इन "}
                            </th>

                            <th className="text-dark   text-center  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Clock Out" : "क्लॉक आउट"}
                            </th>

                            <th className="text-center  text-center text-dark  text-sm font-weight-bolder opacity-7">
                              {language === "hindi" ? "Actions" : "कार्रवाई"}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {currentPageData.length > 0 ? (
                            currentPageData.reverse().map((item, index) => (
                              <tr key={index}>
                                <td className=" text-center align-middle ">
                                  {item.visitorName || "Not Added"}
                                </td>
                                <td className="align-middle text-center">
                                  {item.visitorPhone}
                                </td>
                                <td className="align-middle text-center">
                                  {item.status}
                                </td>
                                <td className="align-middle text-center">
                                  {item.clockIn ? (
                                    <GoArrowDownLeft
                                      style={{ color: "green" }}
                                    />
                                  ) : null}{" "}
                                  {item.clockIn || "--"}
                                </td>
                                <td className="align-middle text-center">
                                  {item.clockOut ? (
                                    <GoArrowDownRight
                                      style={{ color: "red" }}
                                    />
                                  ) : null}{" "}
                                  {item.clockOut ? item.clockOut : "--"}
                                </td>

                                <td className="actions align-middle text-center">
                                  <div className="d-flex justify-content-center">
                                    <ViewVisitorModal data={item} />
                                    {permissions[3]?.actions?.includes(
                                      "delete"
                                    ) && (
                                      <button
                                        className="dlt-btn"
                                        onClick={() =>
                                          handleDeleteVisitor(item._id)
                                        }
                                      >
                                        <MdDelete
                                          data-placement="top"
                                          title={
                                            language === "hindi"
                                              ? "Click to Delete"
                                              : "हटाएं"
                                          }
                                        />
                                      </button>
                                    )}
                                    {permissions[3]?.actions?.includes(
                                      "read"
                                    ) &&
                                      item.status == "Pending" && (
                                        <Tooltip
                                          title={
                                            language === "hindi"
                                              ? "Download Qr code"
                                              : "क्यूआर कोड डाउनलोड करें"
                                          }
                                          placement="top"
                                          arrow
                                        >
                                          <button
                                            className="dlt-btn"
                                            onClick={() =>
                                              handleDownloadQrCode(item.qrCode)
                                            }
                                          >
                                            <FaDownload />
                                          </button>
                                        </Tooltip>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6}>
                                <div className="no_data_entry"> No data</div>
                              </td>
                            </tr>
                          )}
                          <td colSpan={7}>
                            {totalPages > 1 && (
                              <div className="table-pagination">
                                <Stack spacing={2}>
                                  <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                  />
                                </Stack>
                              </div>
                            )}
                          </td>
                        </tbody>
                      </table>
                    ) : (
                      <div className="permission">
                        <h2>You do not have permission to read this data</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddVisitorModal
          open={modalOpen} // Pass modal visibility state
          handleClose={() => setModalOpen(false)} // Function to close the modal
          handleAddVisitor={handleAddVisitor} // Form submission handler
        />
        <ToastContainer />
      </CorporateLayout>
    </>
  );
};

export default CorporateVisitorView;
