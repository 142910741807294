// export default SuperAdminAffiliateCountPie;
import React, { useState, useEffect, useContext } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
// Import necessary Chart.js modules
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { PORT } from "../Api/api";
import { ThreeCircles } from "react-loader-spinner";
import { LanguageContext } from "../lib/LanguageContext";

// Register modules with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const SuperAdminAffiliateCountPie = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = useContext(LanguageContext);
  const [timePeriod, setTimePeriod] = useState("monthly");
  const [selectedMonth, setSelectedMonth] = useState("January"); // Default month
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Get current year
  const [noData, setNoData] = useState(false); // Track if there is no data for selected month

  // Fetch data from the API
  const fetchUserCounts = async () => {
    try {
      const response = await axios.get(`${PORT}/getAllAffiliateSocietyCounts`);
      const data = response.data;
      setApiData(data); // Set the response data here
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching society data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserCounts();
  }, []);

  // Handle time period change (monthly or yearly)
  const handleTimePeriodChange = (e) => {
    setTimePeriod(e.target.value);
    if (e.target.value === "monthly") {
      setSelectedMonth("January"); // Default to January when switching to monthly
    }
  };

  // Handle month selection change
  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  // Check if all values are zero (for "No Data" condition)
  const checkForNoData = () => {
    const values = data.datasets[0].data;
    const hasData = values.some((value) => value > 0);
    setNoData(!hasData); // Set the no data flag
  };
  // Prepare data for the Pie chart
  const data = {
    labels: apiData.map((item) => item.agencyDetails.agencyName), // Agency names as labels
    datasets: [
      {
        label:
          timePeriod === "monthly"
            ? `Society Count for ${selectedMonth}`
            : `Yearly Society Count for ${currentYear}`, // Display current year
        data: apiData.map((item) => {
          if (timePeriod === "monthly") {
            // Use the society count from the selected month
            return item.monthlySocietyCount[selectedMonth] || 0; // Dynamic selection for months
          } else {
            // Use the yearly count for the current year
            return item.yearlySocietyCount[currentYear] || 0; // Use dynamic current year
          }
        }),
        backgroundColor: [
          "#4caf50", // Green
          "#f44336", // Red
          "#2196f3", // Blue
          "#9c27b0", // Purple
          "#ffeb3b", // Yellow
          "#ff9800", // Orange
          "#795548", // Brown
        ],
        borderWidth: 1,
      },
    ],
  };
  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //function lock
  // Call the check after the data is set
  useEffect(() => {
    if (apiData.length > 0) {
      checkForNoData();
    }
  }, [apiData, timePeriod, selectedMonth]);
  return (
    <div className="card z-index-2 h-100 ">
      <div className="card-header pb-0 pt-3 bg-transparent h-75">
        {apiData.length > 0 ? (
          <div className="label-title-div-inner ">
            <div>
              <label className="">
                {language === "hindi" ? "Select  Period:" : " अवधि चुनें:"}
              </label>
            </div>
            &nbsp;{" "}
            <div>
              <select value={timePeriod} onChange={handleTimePeriodChange}>
                <option value="monthly">
                  {language === "hindi" ? "Monthly" : "माहिक"}
                </option>
                <option value="yearly">
                  {language === "hindi" ? "Yearly" : "वार्षिक"}
                </option>
              </select>
            </div>
            &nbsp;
            {timePeriod === "monthly" && (
              <div>
                <label>
                  {language === "hindi" ? "Select Month:" : "महीना चुनें:"}
                </label>
                &nbsp;{" "}
                <select value={selectedMonth} onChange={handleMonthChange}>
                  {monthNames.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {isLoading ? (
          <div className="chartdata_loader" style={{ height: "30vh" }}>
            <ThreeCircles
              visible={true}
              height={50}
              width={50}
              color="#5e72e4"
              ariaLabel="three-circles-loading"
            />
          </div>
        ) : apiData.length > 0 ? (
          <div style={{ width: "100%" }} className="h-100">
            <h6 style={{ textAlign: "center" }} className="mb-1 mt-1">
              {language === "english"
                ? "एजेंसी के अनुसार सहकारी समाज संख्या"
                : "Affiliate Society Count by Agency"}
            </h6>

            <Pie data={data} options={options} />
          </div>
        ) : (
          <div className="no-data-chart">
            <p> No Data Found</p>
          </div>
        )}
      </div>
    </div>
    // </div>
  );
};

export default SuperAdminAffiliateCountPie;
