import React from "react";
import CorporateLayout from "../../corporateLib/corporateLayout/CorporateLayout";
import decodeToken from "../../corporateLib/decodeToken/DecodeToken";

const CorporateDashboard = () => {
    const token=localStorage.getItem("corporateToken")
    const tokenDecodeData=decodeToken(token)
    console.log(tokenDecodeData)
  return (
    <div>
      <CorporateLayout>
     Hy Dashboard

      </CorporateLayout>
    </div>
  );
};

export default CorporateDashboard;
