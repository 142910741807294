import { MdDashboard } from "react-icons/md";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { useContext } from "react";
import { FaUser, FaBuilding, FaClipboardList } from "react-icons/fa";
import {
  AiOutlineBulb,
  AiOutlineFileText,
  AiOutlineQuestionCircle,
  AiOutlineUserSwitch,
} from "react-icons/ai";
const TopNavPathTitle = () => {
  const { language } = useContext(LanguageContext);
  const titles = {
    hindi: {
      dashboard: "Dashboard",
      company: "Company",
      role: "Roles",
      user: "User",
      purposeOfOccasional: "Purpose of Occasional",
      visitor:"Visitor"
    },
    english: {
      dashboard: "डैशबोर्ड",
      company: "कंपनी",
      role: "भूमिकाएँ",
      user: "उपयोगकर्ता",
      purposeOfOccasional: "आवधिक उद्देश्य",
       visitor:"आगंतुक"
      
    },
  };

  //path
  const navPathTitles = [
    {
      path: "/corporate/corporateDashboard",
      title: titles[language].dashboard,
      icon: MdDashboard,
    },
    {
      path: "/corporate/corporateCompanyView",
      title: titles[language].company,
      icon: FaBuilding,
    },
    {
      path: "/corporate/corporateAddCompany",
      title: titles[language].company,
      icon: FaBuilding,
    },
    {
      path: "/corporate/corporateRoleView",
      title: titles[language].role,
      icon: AiOutlineUserSwitch,
    },
    {
      path: "/corporate/corporateRoleAdd",
      title: titles[language].role,
      icon: AiOutlineUserSwitch,
    },
    {
      path: "/corporate/corporateUserAdd",
      title: titles[language].user,
      icon: FaUser,
    },
    {
      path: "/corporate/corporateUserView",
      title: titles[language].user,
      icon: FaUser,
    },
    {
      path: "/corporate/corporateUserEdit/:id",
      title: titles[language].user,
      icon: FaUser,
    },
    {
      path: "/corporate/corporatePurposeView",
      title: titles[language].purposeOfOccasional,
      icon: AiOutlineBulb,
    },
    {
      path: "/corporate/corporatePurposeAdd",
      title: titles[language].purposeOfOccasional,
      icon: AiOutlineBulb,
    },
    {
      path: "/corporate/corporateVisitorView",
      title: titles[language].visitor,
      icon: AiOutlineFileText,
    },
  ];
  return navPathTitles;
};
export default TopNavPathTitle;
