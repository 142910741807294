import React, { useContext } from "react";
import { Modal, Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PORT } from "../Api/api";
import { LanguageContext } from "../lib/LanguageContext";
const ImagePreviewModal = ({ isOpen, onClose, data }) => {
  console.log(data, "disputeData--->");
  const {
    disputeDescription,
    disputeReason,
    screenshot,
    _id,
    status,
    resolutionNotes,
  } = data;
  const { language } = useContext(LanguageContext);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="image-preview-modal"
      aria-describedby="modal-to-preview-dispute-image"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-51%, -50%)",
          width: "80%",
          maxWidth: 800,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "8px",
          p: 2,
          outline: "none",
          overflow: "hidden",
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            zIndex: 10,
            color: "grey.600",
            "&:hover": { color: "black" },
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        {/* Image Preview Section */}
        <div>
          <p className="pt-2">
            <strong>
              {language === "english" ? "विवरण:" : "Description:"}
            </strong>{" "}
            {disputeDescription}
          </p>
          <p className="pt-2">
            <strong>
              {language === "english" ? "विवाद आईडी:" : "Dispute Id:"}
            </strong>{" "}
            {_id}
          </p>
          <p className="pt-2">
            {status !== "Pending" && (
              <p>
                <strong>
                  {language === "english" ? "समाधान नोट्स:" : "Resolution Notes:"}
                </strong>{" "}
                {resolutionNotes || "-"}
              </p>
            )}
          </p>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "400px",
            borderRadius: "8px",
            overflow: "hidden",
            backgroundColor: "#f5f5f5", // Light grey background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {screenshot ? (
            <img
              src={`/${screenshot?.replace("public/", "")}`}
              alt="Dispute"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
          ) : (
            <p style={{ color: "#888", fontStyle: "italic" }}>
              No image available
            </p>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ImagePreviewModal;
