import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import Swal from "sweetalert2";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import { PORT } from "../../../../Api/api";
import axios from "axios";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../../../lib/LanguageContext";
import CorporateVisitorViewScreen from "../../corporateGuardPages/corporateVisitorViewScreen/CorporateVisitorViewScreen";
import QRScanner from "../qrScan/QrScanner";
import GuardClockIn from "../../corporateGuardPages/corporateGuardClockIn/GuardClockIn";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
// modal functionality
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100%", // Ensures the dialog takes full width
    maxWidth: "100%", // Ensures it doesn't exceed the screen width
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CorporateGuardNavbar() {
  const { language, handleLanguageChange } = useContext(LanguageContext);
  //ProfileSetting
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = useState(false); // Modal visibility
  const handleClickOpen = (visitor) => {
    setOpenDialog(true); // Open the dialog/modal
  };
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog/modal
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [auth, setAuth] = React.useState(true);
  const [guardData, setGuardData] = useState({});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    const getGuardData = async () => {
      try {
        if (id) {
          const response = await axios.get(
            `${PORT}/getEditWithSocietyUnion/${id}`
          );
          setGuardData(response.data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching guard data:", error);
      }
    };
    getGuardData();
  }, []);

  const handleSettingFunctionality = () => {
    Swal.fire({
      title: "Coming Soon...",
      text: "Coming Soon...",
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const id = JSON.parse(localStorage.getItem("roleId"));
  const handleLogoutFunctionlaity = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      className: "swal-on-top",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("corporateGuardToken");
        navigate("/corporateLogin");
      }
    });
  };
  const handleAttendanceFunctionlaity = () => {
    Swal.fire({
      title: "Coming Soon...",
      text: "Coming Soon...",
      icon: "info",
      confirmButtonText: "OK",
    });
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleSideNavToggleFunctionality = () => {
    const sideNav = document.getElementById("sidenav-main");

    if (window.innerWidth <= 1024) {
      if (sideNav) {
        sideNav.classList.toggle("hidden");
      } else {
        console.error('Element with ID "sidenav-main" not found.');
      }
    }
  };
  // Optional: Add an event listener to handle resizing and adjust visibility
  window.addEventListener("resize", () => {
    const sideNav = document.getElementById("sidenav-main");

    if (window.innerWidth > 1024 && sideNav) {
      sideNav.classList.remove("hidden");
    }
  });
  useEffect(() => {
    handleClickOpen()
  }, [])
  return (
    <>
      <div className="corporateNav">
        <div className="dropdown-toggle-main">
          <div>
            <div className="lang">
              <div className="switch">
                <input
                  id="language-toggle"
                  checked={language === "hindi"}
                  onChange={() => handleLanguageChange()}
                  className="check-toggle check-toggle-round-flat-dashboard"
                  type="checkbox"
                />
                <label className="lang-cng" htmlFor="language-toggle"></label>
                <span className="on">HI</span>
                <span className="off">EN</span>
              </div>
            </div>
          </div>
          <div>
            <CorporateVisitorViewScreen />
          </div>
          <div>
            <QRScanner />
          </div>
          <div>
            {/* guard live locati
        on preview */}
            <div>
              {/* Modal/Dialog for Full Visitor Details */}
              {/* <BootstrapDialog
                style={{
                  zIndex: 99,
                  width: "100%",
                }}
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="visitor-dialog-title"
              >
                <DialogTitle
                  id="visitor-dialog-title"
                  style={{ width: "100%", maxWidth: "100%" }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6">
                      <b>
                        {language === "english"
                          ? "पूरी आगंतुक जानकारी"
                          : "Full Visitor Details"}
                      </b>
                    </Typography>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={handleCloseDialog}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </DialogTitle>
                <DialogContent>

                  <GuardClockIn />
                </DialogContent>
              </BootstrapDialog> */}
            </div>
          </div>
          <div>
            <Box>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{ width: 45, height: 45 }}
                    className="avatar-admin"
                  >
                    {/* // setImage Fucntionlaity */}
                    <div className="heading_profile_main"></div>
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <MenuItem onClick={handleSettingFunctionality}>
                <Avatar /> {language === "english" ? "प्रोफाइल" : " Profile "}
              </MenuItem>
              <MenuItem onClick={handleAttendanceFunctionlaity}>
                <Avatar />{" "}
                {language === "english" ? "मेरी उपस्थिति" : "  My Attendance  "}
              </MenuItem>
              <Divider />

              <MenuItem onClick={handleLogoutFunctionlaity}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                {language === "english" ? "लॉगआउट" : "  Logout "}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}

export default CorporateGuardNavbar;
