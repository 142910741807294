import React from "react";
import { useEffect } from "react";
import { CiEdit } from "react-icons/ci";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { PORT } from "../../../../../Api/api";
import { LanguageContext } from "../../../../../lib/LanguageContext";
import AffiliateLayout from "../../../affiliateLib/affiliateLayout/AffiliateLayout";
import decodeToken from "../../../../../corporate/corporatePanel/corporateLib/decodeToken/DecodeToken";
import { formatDate } from "../../../../../lib/FormattedDate";
import { FaRegUser } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import ButtonLoader from "../../../../../Loading/ButtonLoader";
import { FaLock } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import { MdDateRange } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import AddBackbtn from "../../../../../lib/AddBackbtn";

const AffiliateEditProfileSection = () => {
  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const decodePayload = decodeToken(token);
  const id = decodePayload.id
  //fetchAgencyDetails
  const fetchAgencyDetails = async () => {
    try {
      const response = await axios.get(`${PORT}/getAffiliateWithId/${id}`);
      setData(response.data.data);
      setLoadingPermission(false);
    } catch (error) {
      console.error("Error fetching guard data:", error);
      setLoadingPermission(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const res = await axios.put(`${PORT}/editAffiliateUser/${id}`, values);
      setData({ ...data, ...values });
      toast.success(res.data.message);

      toggleEditMode();
      setLoading(false);
    } catch (error) {
      toast.success(error.response.data.message);
      console.log(error);
    }
  };
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to upload this image?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Upload",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        uploadImage(selectedFile);
      } else {
        console.log("Upload cancelled");
      }
    });
    const uploadImage = async (file) => {
      const formData = new FormData();
      formData.append("documentation", file);
      await axios.put(`${PORT}/editAffiliateUser/${id}`, formData).then((data) => {
        Swal.fire("Success", "Image uploaded successfully!", "success");
      });
      fetchAgencyDetails().catch((error) => {
        console.error("Error uploading image", error);
        Swal.fire(
          "Error",
          "Failed to upload image. Please try again later.",
          "error"
        );
      });
    };
  };

  const image = data?.
    documentation

    ? data.
      documentation
      .replace("public", "")
    : "";

  //function lock
  useEffect(() => {
    if (location.pathname === "/profileSetting") {
      const guardname = JSON.parse(localStorage.getItem("guardName"));
      if (!guardname) {
        navigate("/login");
      }
    }
  }, [location.pathname, navigate]);
  useEffect(() => {
    fetchAgencyDetails();
  }, []);

  return (


    <>
      <AffiliateLayout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi"
              ? "Edit Section "
              : "अनुभाग संपादित करें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="container-fluid  py-4 w-100">
          <div className="row ">
            <div className="col-12  col-margin_top">
              <div className="card mb-4">
                <div className="card-header pb-0">

                  <div className="component-display">

                    <>
                      <div>
                        {loadingPermission ? (
                          <div className="three_circle_loader">
                            <ThreeCircles
                              visible={true}
                              height={100}
                              width={100}
                              color="#5e72e4"
                              ariaLabel="three-circles-loading"
                            />
                          </div>
                        ) : (
                          <>
                            <div>
                              <div className="edit_back">
                              </div>
                            </div>
                            <div className="personal-details-main-personal-section">
                              <div className="top-header-profile-photo-section">
                                <div className="top-left-section-div">
                                  <div className="top-header-image-div">
                                    {data?.
                                      documentation
                                      ? (
                                        <img src={`${image}`} alt="" />
                                      ) : (
                                        <>
                                          {(() => {
                                            let initials = "";

                                            if (data?.agencyName) {
                                              const words = data.agencyName.split(" ");
                                              if (words.length > 0) {
                                                initials = words[0]
                                                  .substring(0, 1)
                                                  .toUpperCase();
                                              }
                                            }
                                            return (
                                              <>
                                                <div className="">
                                                  <h1>{initials}</h1>
                                                </div>
                                              </>
                                            );
                                          })()}
                                        </>
                                      )}
                                  </div>

                                  <div className="top-header-content-div">
                                    <div className="top-header-content-div-h5">
                                      {data?.agencyName}
                                    </div>
                                    <div className="top-header-content-div-h6">
                                      {data?.status}
                                    </div>
                                  </div>
                                </div>
                                <div className="top-right-section-div">
                                  <div className="top-header-edit-div">
                                    <button onClick={toggleEditMode}>
                                      <CiEdit className="edit_setting_icon" />
                                      &nbsp;
                                      {language === "hindi"
                                        ? "Edit Profile"
                                        : "संपादित करें "}
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="user-content-personal-section">
                                {editMode ? (
                                  <Formik
                                    initialValues={{
                                      agencyName: data.agencyName,
                                      email: data.email,
                                      phone: data.phone,
                                      status: data.status,
                                      registrationDate: data.registrationDate,
                                      address: data.address,
                                      password:data.password,
                                      contactPerson:data.contactPerson,
                                      address:data.address
                                    }}

                                    onSubmit={handleSubmit}
                                  >
                                    {({ errors, touched }) => (
                                      <Form className="edit-personal-detail-section">
                                        <h4 className="form-heading">
                                          {" "}
                                          {language === "hindi"
                                            ? "Personal Information"
                                            : "क्तिगत जानकारी"}
                                        </h4>

                                        <div className="edit-personal-detail-inner-section">
                                          {/* //agency name */}
                                          <div className="input-container">
                                            <label>
                                              {" "}
                                              {language === "hindi"
                                                ? "Agency Name"
                                                : "एजेंसी का नाम"}
                                            </label>
                                            <div className="input-with-icon">
                                              <Field
                                                type="text"
                                                maxLength={35}
                                                name="agencyName"
                                                required
                                                placeholder="Enter your full name"
                                              />
                                              <FaRegUser className="input-icon" />
                                            </div>
                                          </div>
                                          {/* email  */}
                                          <div className="input-container">
                                            <label>
                                              {" "}
                                              {language === "hindi"
                                                ? "Email Address"
                                                : "ईमेल पता"}
                                            </label>
                                            <div className="input-with-icon">
                                              <Field
                                                type="email"
                                                maxLength={35}
                                                required
                                                name="email"
                                                placeholder="Enter your email"
                                              />
                                              <MdOutlineEmail className="input-icon" />
                                            </div>
                                          </div>
                                          {/* password */}
                                          <div className="input-container">
                                            <label>
                                              {" "}
                                              {language === "hindi"
                                                ? "Password"
                                                : "पासवर्ड"}
                                            </label>
                                            <div className="input-with-icon">
                                              <Field
                                                type="text"
                                                maxLength={35}
                                                required
                                                name="password"
                                                placeholder="Enter your Password"
                                              />
                                              <FaLock className="input-icon" />
                                            </div>
                                          </div>
                                          {/* phone */}
                                          <div className="input-container">
                                            <label>
                                              {" "}
                                              {language === "hindi"
                                                ? "Mobile Number"
                                                : "मोबाइल नंबर"}
                                            </label>
                                            <div className="input-with-icon">
                                              <Field
                                                type="tel"
                                                name="phone"
                                                pattern="[0-9]{10}"
                                                required
                                                maxLength={10}
                                                placeholder="Enter your phone number"
                                              />
                                              <FaPhoneAlt className="input-icon" />
                                            </div>
                                          </div>
                                          {/* contact person */}
                                          <div className="input-container">
                                            <label>
                                              {" "}
                                              {language === "hindi"
                                                ? "Contact Person"
                                                : "संपर्क व्यक्ति"}
                                            </label>
                                            <div className="input-with-icon">

                                              <Field
                                                type="text"
                                                name="contactPerson"

                                                required

                                                placeholder="Enter your Contact Person"
                                              />
                                              <MdPerson className="input-icon" />
                                            </div>
                                          </div>
                                          {/* address */}
                                            <div className="input-container">
                                            <label>
                                              {" "}
                                              {language === "hindi"
                                                ? "Address"
                                                : "पता"}
                                            </label>
                                            <div className="input-with-icon">

                                              <Field
                                                type="text"
                                                name="address"

                                                required

                                                placeholder="Enter your Address"
                                              />
                                              <FaMapMarkerAlt className="input-icon" />
                                            </div>
                                          </div>
                                        </div>
                                        <br />

                                        <div className="form-action-buttons">
                                          <button type="submit" className="save-button">
                                            {" "}

                                            {loading ? (
                                              <ButtonLoader />
                                            ) : language === "hindi" ? (
                                              "Save"
                                            ) : (
                                              " सहेजें"
                                            )}
                                          </button>
                                          <button
                                            type="button"
                                            className="cancel-button"
                                            onClick={toggleEditMode}
                                          >
                                            {" "}
                                            {language === "hindi" ? "Cancel" : " रद्द करें"}
                                          </button>
                                        </div>
                                      </Form>
                                    )}
                                  </Formik>
                                ) : (
                                  <div className="user-personal-details-section">
                                    <div className="user-personal-details-section-left">
                                      <span>
                                        <FaRegUser /> &nbsp;
                                        {language === "hindi" ? "Name " : "नाम"}
                                      </span>
                                      <br />
                                      <span>
                                        <MdOutlineEmail /> &nbsp;
                                        {language === "hindi" ? "Email address " : "ईमेल"}
                                      </span>
                                      <br />
                                      <span>
                                        <FaLock /> &nbsp;
                                        {language === "hindi" ? "Password " : "पासवर्ड"}
                                      </span>
                                      <br />
                                      <span>
                                        <FaPhoneAlt />
                                        &nbsp;{" "}
                                        {language === "hindi" ? "Phone number " : "फ़ोन नं"}
                                      </span>
                                      <br />
                                      <span>
                                        <MdPerson />
                                        &nbsp; {language === "hindi" ? "Contact Person " : "संपर्क व्यक्ति"}
                                      </span>
                                      <br />
                                      <span>
                                        <MdDateRange />
                                        &nbsp; {language === "hindi" ? "Registration Date " : "पंजीकरण तिथि"}
                                      </span>
                                      <br />
                                      <span>
                                        <FaMapMarkerAlt />
                                        &nbsp; {language === "hindi" ? "Address " : "पता"}
                                      </span>

                                    </div>
                                    <div className="user-personal-details-section-right">
                                      <b>{data.agencyName || "--"}</b>
                                      <br />
                                      <b>{data.email || "--"}</b>
                                      <br />
                                      <b>{data.password || "--"}</b>
                                      <br />

                                      <b>{data.phone || "--"}</b>
                                      <br />
                                      <b>{data.contactPerson || "--"}</b>
                                      <br />
                                      <b>{formatDate(data.registrationDate) || "--"}</b>
                                      <br />
                                      <b>{data.address || "--"}</b>
                                      <br />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <ToastContainer />
                        {loadingPermission ? (
                          ""
                        ) : (
                          <div className="top-header-edit-image-logo">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              id="profile-image-upload"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "10%",
                                height: "10%",
                                cursor: "pointer",
                              }}
                            />
                            <label
                              htmlFor="profile-image-upload"
                              className="top-header-edit-image-icon-label"
                            >
                              <CiEdit className="top-header-edit-image-icon" />
                            </label>
                          </div>
                        )}
                      </div>
                    </>

                  </div> </div> </div> </div> </div></div>
      </AffiliateLayout>
    </>
  );
};

export default AffiliateEditProfileSection;
