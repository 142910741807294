import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { PORT } from "../../../../../../Api/api";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import AddBackbtn from "../../../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
import CorporateLayout from "../../../../corporateLib/corporateLayout/CorporateLayout";
import decodeToken from "../../../../corporateLib/decodeToken/DecodeToken";
const CorporatePurposeAdd = (item) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [purposeIcon, setPurposeIcon] = useState();
  const fileInputRef = useRef(null);
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  console.log(tokenData,"------>")
  // Initialize Formik form
  // Validation Schema using Yup
  const validationSchema = Yup.object({
    purpose: Yup.string().required(
      language === "hindi" ? "उद्देश्य आवश्यक है" : "Purpose is required"
    ),
    createdBy: Yup.string().required(
      language === "hindi" ? "निर्माता आवश्यक है" : "Created By is required"
    ),
  });
  const formik = useFormik({
    initialValues: {
      hindiPurpose: "",
      purpose: "",
      createdBy: "",
      corporate_id:""
    },

    onSubmit: async (values) => {
      const result = await Swal.fire({
        title: "Are you sure you want to add this Purpose?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Add it!",
        customClass: {
          container: "my-swal",
        },
      });

      if (result.isConfirmed) {
        try {
          setSubmitting(true);
          const formData = new FormData();
          formData.append("purpose", values.purpose);
          formData.append("createdBy", tokenData.id);
          formData.append("companyId",tokenData.data.corporate_id)
          formData.append("corporate_id",tokenData.data.corporate_id)
      
          if (purposeIcon) {
            if (
              purposeIcon.type === "image/jpeg" ||
              purposeIcon.type === "image/png"
            ) {
              formData.append("purposeIcon", purposeIcon);
            } else if (purposeIcon.type === "video/mp4") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Please upload an image, not a video'",
                customClass: {
                  container: "my-swal",
                },
              });

              return;
            } else {
              return;
            }
          }
          await axios
            .post(`${PORT}/nonVerifiedPurpose`, formData)
            .then((res) => {
              formik.values.hindiPurpose = "";
              formik.values.purpose = "";
              toast.success("Purpose Added!");
              setPurposeIcon("");

              setTimeout(() => {
                navigate("/corporate/corporatePurposeView");
              }, 1000);
            });
        } catch (error) {
          toast.success("Error While Adding Purpose");
          console.error("Error updating entry:", error);
        } finally {
          setSubmitting(false);
        }
      } else {
        formik.values.hindiPurpose = "";
        formik.values.purpose = "";
        formik.values.createdBy = "";
      }
    },
  });
  const handlePurposeIcon = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split("/")[0];
      if (fileType === "image") {
        setPurposeIcon(e.target.files[0]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please upload an image file",
          customClass: {
            container: "my-swal",
          },
        });
        fileInputRef.current.value = "";
      }
    }
  };
  return (
    <>
      <CorporateLayout>
        <div className="table_heading">
          <h5 className="heading_top">Add Purpose</h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className=" edit-entry-form">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <label className="editLabel" htmlFor="titleEnglish">
                {language === "hindi"
                  ? "  Enter Purpose  "
                  : "उद्देश्य दर्ज करें"}
                <span className="important_field">*</span>
              </label>
              <br />
              <input
                type="text"
                id="purpose"
                name="purpose"
                value={formik.values.purpose}
                onChange={formik.handleChange}
                className="edit-entry-input-wrapper"
                required
              />
              {/* {formik.touched.purpose && formik.errors.purpose && (
                <div className="error-message">{formik.errors.purpose}</div>
              )} */}
            </div>

            <div>
              <label className="editLabel" htmlFor="titleHindi">
                {language === "hindi" ? "  Add Icon    " : "आइकन जोड़ें"}
              </label>
              <br />
              <input
                type="file"
                name="icon"
                required
                ref={fileInputRef}
                onChange={handlePurposeIcon}
                autoComplete="off"
                className="edit-entry-input-wrapper"
              />
          
            </div>

            <div className="main_button_div">
              <button
                className="edit-button"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <ButtonLoader />
                ) : language === "hindi" ? (
                  "Add"
                ) : (
                  "जोड़ें"
                )}
              </button>
            </div>
          </form>
        </div>
        <ToastContainer />
      </CorporateLayout>
    </>
  );
};

export default CorporatePurposeAdd;
