import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import { Close } from "@mui/icons-material";
import axios from "axios";
import { PORT } from "../../../../../../Api/api";
import ButtonLoader from "../../../../../../Loading/ButtonLoader";
import { LanguageContext } from "../../../../../../lib/LanguageContext";
import decodeToken from "../../../../../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
import "./style.css";
const ModalStyle = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  backgroundColor: "#fff",
  boxShadow: 24,
  padding: "16px",
  borderRadius: "8px",
  zIndex: 999999,
});

const ImagePreviewContainer = styled(Box)({
  display: "flex",
  flexWrap: "nowrap",
  gap: "8px",
  marginTop: "16px",
  overflowX: "auto",
  maxHeight: "100px",
});

const ImagePreview = styled(Box)({
  position: "relative",
  minWidth: "80px",
  height: "80px",
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
});

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

const DeleteButton = styled(IconButton)({
  position: "absolute",
  top: "4px",
  right: "4px",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
});

const AddVisitorModal = ({ open, handleClose, handleAddVisitor }) => {
  const [visitorName, setVisitorName] = useState("");
  const [visitorPhone, setVisitorPhone] = useState("");
  const [visitorPurposeId, setVisitorPurposeId] = useState("");
  const [companyId, setCompanyId] = useState("");
  console.log(companyId, "companyId");
  const [visitorImages, setVisitorImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [purposeData, setPurposeData] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const token = localStorage.getItem("corporateToken");
  const tokenData = decodeToken(token);
  const [corporateCompany, setCorporateCompany] = useState([]);
  console.log(corporateCompany, "corporateCompany");
  console.log(tokenData, "TOKENDATA");
  const validate = () => {
    const newErrors = {};
    if (!visitorName.trim())
      newErrors.visitorName = "Visitor name is required.";
    if (!visitorPhone.trim()) {
      newErrors.visitorPhone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(visitorPhone)) {
      newErrors.visitorPhone = "Invalid phone number. Must be 10 digits.";
    }
    if (!visitorPurposeId) newErrors.visitorPurposeId = "Purpose is required.";
    setErrors(newErrors);
    if (!companyId) newErrors.companyId = "Company is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setVisitorImages((prev) => [...prev, ...files]);
  };

  const handleDeleteImage = (index) => {
    setVisitorImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    if (!validate()) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("visitorName", visitorName);
    formData.append("visitorPhone", visitorPhone);
    formData.append("visitorPurposeId", visitorPurposeId);
    formData.append("status", "Pending");
    formData.append("companyId", companyId);
    formData.append("owner_id", tokenData.data.owner_id);
    formData.append("corporate_id", companyId);
    visitorImages.forEach((file) => {
      formData.append("visitorImages", file);
      console.log(visitorName, visitorPhone, visitorPurposeId, file);
    });
    handleAddVisitor(formData);
    setLoading(false);
    setVisitorName("");
    setVisitorPhone("");
    setVisitorPurposeId("");
    setCompanyId("");

    setVisitorImages([]);
    setErrors({});
    handleClose();
  };
  const handleFetchPurpose = async () => {
    try {
      const result = await axios.get(`${PORT}/getUserNonVerfiedPrupose`);
      const responseData = result.data.data;
      const filterData = responseData.filter(
        (item) =>
          item.defaultPermissionLevel === "1" &&
          item.purposeCategory.includes("Corporate")
      );
      setPurposeData(filterData?.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePurposeChange = (id) => {
    setVisitorPurposeId(id);
  };
  const handleCompanyChange = (id) => {
    setCompanyId(id);
  };
  // fetchCorporate
  const fetchCompany = async () => {
    try {
      const response = await axios.get(`${PORT}/getAllCorporates`);
      const companiesWithDetails = response.data.filter(
        (item) => item.companyDetails
      );
      console.log(companiesWithDetails, "VV");
      // Then, apply the additional filtering conditions
      const res = companiesWithDetails.filter(
        (item) =>
          item.corporate_id === tokenData?.data.corporate_id ||
          item.createdBy === tokenData?.data.corporate_id
      );
      setCorporateCompany(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);
  useEffect(() => {
    handleFetchPurpose();
  }, []);
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalStyle>
        <Typography variant="h6" component="h2" gutterBottom>
          Add Visitor
        </Typography>
        <div className="visitor-modal-div">
          <TextField
            sx={{ width: "350px" }}
            label="Visitor Name"
            variant="outlined"
            value={visitorName}
            onChange={(e) => setVisitorName(e.target.value)}
            margin="normal"
            error={!!errors.visitorName}
            helperText={errors.visitorName}
          />

          <TextField
            sx={{ width: "350px" }}
            label="Visitor Phone"
            variant="outlined"
            value={visitorPhone}
            onChange={(e) => setVisitorPhone(e.target.value)}
            margin="normal"
            error={!!errors.visitorPhone}
            helperText={errors.visitorPhone}
          />

          <FormControl
            sx={{ width: "350px" }}
            margin="normal"
            variant="outlined"
            error={!!errors.visitorPurposeId}
          >
            <InputLabel id="visitor-purpose-label">Visitor Purpose</InputLabel>
            <Select
              labelId="visitor-purpose-label"
              value={visitorPurposeId}
              onChange={(e) => handlePurposeChange(e.target.value)}
              label="Visitor Purpose"
            >
              {purposeData.map((option) => (
                <MenuItem key={option.id} value={option._id}>
                  {option.purpose}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="caption" color="error">
              {errors.visitorPurposeId}
            </Typography>
          </FormControl>

          <FormControl
            sx={{ width: "350px" }}
            margin="normal"
            variant="outlined"
            error={!!errors.companyId}
          >
            <InputLabel id="visitor-company-label">Select Company</InputLabel>
            <Select
              labelId="visitor-company-label"
              value={companyId}
              onChange={(e) => handleCompanyChange(e.target.value)}
              label="Company"
            >
              {corporateCompany?.map((option) => (
                <MenuItem key={option.id} value={option._id}>
                  {option?.companyDetails
                    ? option.companyDetails.companyName
                    : ""}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="caption" color="error">
              {errors.companyId}
            </Typography>
          </FormControl>
        </div>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          Upload Images
          <input
            type="file"
            hidden
            multiple
            accept="image/*"
            onChange={handleImageChange}
          />
        </Button>

        {/* Image Preview with Scroll */}
        <ImagePreviewContainer>
          {visitorImages.map((image, index) => (
            <ImagePreview key={index}>
              <Image
                src={URL.createObjectURL(image)}
                alt={`visitor-${index}`}
              />
              <DeleteButton
                size="small"
                onClick={() => handleDeleteImage(index)}
              >
                <Close fontSize="small" />
              </DeleteButton>
            </ImagePreview>
          ))}
        </ImagePreviewContainer>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ marginTop: 2 }}
        >
          {loading ? (
            <ButtonLoader />
          ) : language === "english" ? (
            "  जमा करना  "
          ) : (
            "Submit  "
          )}
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          sx={{ marginTop: 1 }}
        >
          Cancel
        </Button>
      </ModalStyle>
    </Modal>
  );
};

export default AddVisitorModal;
