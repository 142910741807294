import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PORT } from "../../../../Api/api";
import Layout from "../../../../lib/Layout";
import AddBackbtn from "../../../../lib/AddBackbtn";
import { LanguageContext } from "../../../../lib/LanguageContext";
import "./style.css";
import ButtonLoader from "../../../../Loading/ButtonLoader";
const EditSubscription = () => {
  const { language } = useContext(LanguageContext);
    const [isSubmitting,setSubmitting]=useState(false)
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;

  const [formData, setFormData] = useState({
    name: "",
    createdBy: getRoleId,
    price: "",
    societyHouseList: "",
    description: "",
    interval: "",
    commissionCurrency: "",
    gstType: "",
    gstRate: "",
  });
  // Fetch subscription data by ID
  const fetchSubscription = async () => {
    try {
      const response = await axios.get(`${PORT}getSubscriptionWithId/${id}`);
      const data = response.data.data;
      setFormData({
        name: data.name || "",
        price: data.price || "",
        societyHouseList: data.societyHouseList || "",
        description: data.description || "",
        interval: data.interval || "",
        commissionCurrency: data.commissionCurrency || "",
        gstType: data.gstType || "",
        gstRate: data.gstRate || "",
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to load subscription data.");
    }
  };
  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      await axios.put(`${PORT}editSubscription/${id}`, formData);
      toast.success("Subscription updated successfully");
      setTimeout(() => {
        setSubmitting(false);
        navigate("/admin/viewSubscription");
      }, 2000);
    } catch (error) {
      setSubmitting(false);
      toast.error("Error updating subscription");
    }
  };
//function lock
useEffect(() => {
  fetchSubscription();
},[]);
  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi"
              ? "Edit Subscription"
              : "सदस्यता संपादित करें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="subscription-main-div">
          <form onSubmit={handleSubmit} className="subscription-form">
            <div>
              <label htmlFor="name" className="editLabel">
                {language === "hindi" ? "Plan Name" : "योजना का नाम"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">
                  {language === "hindi" ? "Select Plan" : "योजना चुनें"}
                </option>
                <option value="Standard">
                  {language === "hindi" ? " Standard Plan" : "स्टैंडर्ड योजना "}
                </option>
                <option value="Elite">
                  {language === "hindi" ? "Elite Plan" : "एलीट योजना"}
                </option>
                <option value="Supreme">
                  {language === "hindi" ? "Supreme Plan" : " सुप्रीम योजना"}
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="price" className="editLabel">
                {language === "hindi"
                  ? " Price Per household"
                  : " प्रत्येक घर का मूल्य"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <input
                type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                placeholder={language === "hindi" ? "Enter price" : "मूल्य"}
                className="edit-input"
                min="0"
                required
              />
            </div>

            <div>
              <label className="editLabel" htmlFor="societyHouseList">
                {language === "hindi"
                  ? "No. of Houses in Society"
                  : "सोसायटी में घरों की संख्या"}
              </label>
              <br />
              <select
                id="societyHouseList"
                name="societyHouseList"
                value={formData.societyHouseList}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">Select House Range</option>
                <option value="0-250">0 - 250</option>
                <option value="250-500">250 - 500</option>
                <option value="500-1000">500 - 1000</option>
              </select>
            </div>

            <div>
              <label htmlFor="interval" className="editLabel">
                {language === "hindi" ? "Interval" : "अंतराल"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="interval"
                name="interval"
                value={formData.interval}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">Select Interval</option>
                <option value="monthly">
                  {language === "hindi" ? "Monthly" : "मासिक"}
                </option>
                <option value="annual" disabled={true}>
                  {language === "hindi" ? "Annual" : "वार्षिक"}
                </option>
                <option value="quarterly" disabled={true}>
                  {language === "hindi" ? "Quarterly" : "त्रैमासिक"}
                </option>
              </select>
            </div>

            <div>
              <label htmlFor="description" className="editLabel">
                {language === "hindi" ? "Description" : "विवरण"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder={
                  language === "hindi" ? "Enter plan description" : "विवरण"
                }
                className="edit-input"
                maxLength="200"
                required
              />
            </div>
            <div>
              <label htmlFor="gstType" className="editLabel">
                {language === "english" ? "जीएसटी प्रकार" : "Gst Type"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="gstType"
                name="gstType"
                value={formData.gstType}
                onChange={handleChange}
             
                className="edit-input"
                required
              >
                <option value="">
                  {language === "english" ? "योजना चुनें" : "Select Plan"}
                </option>
                <option value="inclusive">
                  {language === "english" ? "स्टैंडर्ड योजना" : "Inclusive"}
                </option>
                <option value="exclusive">
                  {language === "english" ? "एलीट योजना" : "Exclusive"}
                </option>
              </select>
            </div>
            {/* यदि gstType 'exclusive' है तो नया इनपुट फ़ील्ड दिखाएं */}
            {formData.gstType === "exclusive" && (
              <div>
                <label htmlFor="gstRate" className="editLabel">
                  {language === "english" ? "जीएसटी दर्ज करें(%)" : "Enter GST(%)"}
                  <span className="Star_color">*</span>
                </label>
                <input
                  type="number"
                  id="gstRate"
                  name="gstRate"
                  value={formData.gstRate}
                  onChange={handleChange}
               
                  className="edit-input"
                 
                  required
                />
              </div>
            )}

            {/* यदि gstType 'inclusive' है तो 18% जीएसटी दिखाएं */}
            {formData.gstType === "inclusive" && (
              <div>
                <label className="editLabel">
                  {language === "english"
                    ? "स्वचालित जीएसटी (%)"
                    : "Automatic GST (%)"}
                </label>
                <input
                  type="text"
                  value="18"
                  className="edit-input"
                  readOnly
                />
              </div>
            )}
            <div>
              <label htmlFor="commissionCurrency" className="editLabel">
              {language === "english" ? "भुगतान मुद्रा" : " Payment Currency"}
                <span className="Star_color">*</span>
              </label>
              <br />
              <select
                id="commissionCurrency"
                name="commissionCurrency"
                value={formData.commissionCurrency}
                onChange={handleChange}
                className="edit-input"
                required
              >
                <option value="">{language === "hindi"
                    ? "Select  Payment Currency "
                    : "भुगतान मुद्रा चुनें"}{" "}</option>
                <option value="INR">₹ (INR)</option>
                <option value="USD" disabled={true}>
                  $ (USD)
                </option>
              </select>
            </div>

            <div className="role_submit_btn">
              <button
                className="edit-button edit_btn_create_role"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting?<ButtonLoader/>:language === "hindi"
                  ? "Update Subscription Plan"
                  : "Update Subscription Plan"}
              </button>
            </div>
          </form>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
};

export default EditSubscription;
