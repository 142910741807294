import React, { useState } from "react";
import axios from "axios";
import { Modal, Box, Button, Typography } from "@mui/material";
import Papa from "papaparse";
import { PORT } from "../../Api/api";
import "./entriesCsv.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Layout from "../../lib/Layout";
import { FaDownload } from "react-icons/fa";
import AddBackbtn from "../../lib/AddBackbtn";
import { ToastContainer, toast } from "react-toastify";
import { DataContext } from "../../lib/DataContext";
import { useContext } from "react";
import * as FileSaver from "file-saver"; // If you're using a library like FileSaver.js
import ExcelJS from "exceljs";
import { LanguageContext } from "../../lib/LanguageContext";
const UsersImportCsv = () => {
  const { addItem, removeItem, updateItem } = useContext(DataContext);
  const [file, setFile] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [newXlxFile, setNewXlxFile] = useState([]);
  console.log(newXlxFile, "newXlxFile");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const { language } = useContext(LanguageContext);
  const createdBy = JSON.parse(localStorage.getItem("roleId"));
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const getRoleLevel = localStorage.getItem("roleLevel");
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (!selectedFile) {
      setError("No file selected");
      return;
    }

    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    // Check if the file is an Excel file
    if (fileExtension !== "xlsx") {
      setError("Only Excel files (.xlsx) are allowed.");
      return;
    }

    // If it's an Excel file, proceed with the logic
    handleXlsxFile(selectedFile);
  };

  const handleCsvParseComplete = (result) => {
    if (
      result.data.length === 0 ||
      result.data.every((row) =>
        Object.values(row).every((cell) => cell === "")
      )
    ) {
      setError("CSV file is empty");
      setCsvData([]);
    } else {
      const updatedData = result.data.map((row) => ({ ...row }));
      setCsvData(updatedData);
      setError("");
    }
  };
  const handleXlsxFile = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0]; // Assume the first sheet
      const sheet = workbook.Sheets[sheetName];

      // Read sheet into JSON, with the first row as headers (header: 1)
      const result = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Check if the file is empty
      if (
        result.length === 0 ||
        result.every((row) =>
          row.every((cell) => cell === "" || cell === undefined)
        )
      ) {
        setError("XLSX file is empty");
        setCsvData([]);
        return;
      }

      // Extract the first four headers
      const headers = result[1]?.slice(0, 5); // Only take the first 4 headers

      // Ensure headers are valid and exist
      if (!headers || headers.length === 0) {
        setError("No headers found in the file");
        setCsvData([]);
        return;
      }

      // Track unique entries
      const uniqueUsernames = new Set();
      const uniqueUserPhoneNos = new Set();
      let duplicateError = false;
      let passwordError = false;
      let emailError = false;
      let phoneNumberError = false;
      let errorRows = [];
      let validData = [];

      // Regular expressions for email validation
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Process rows and validate
      result.slice(2).forEach((row, index) => {
        // Skip empty rows where no column has data
        if (row.every((cell) => cell === "" || cell === undefined)) {
          return;
        }

        const rowObject = {};

        // Map only the first four headers to their corresponding row values
        headers.forEach((header, idx) => {
          rowObject[header] = row[idx];
        });

        const username = rowObject["username"];
        const userPhoneNo = rowObject["userPhoneNo"];
        const password = rowObject["password"];
        const email = rowObject["email"];

        // Check if at least one column in the row has data
        if (
          !Object.values(rowObject).some(
            (value) => value !== "" && value !== undefined
          )
        ) {
          return;
        }

        // Check if username is unique
        if (uniqueUsernames.has(username)) {
          duplicateError = true;
          errorRows.push(
            `Username '${username}' in row ${index + 2} is not unique`
          );
        } else {
          uniqueUsernames.add(username);
        }

        // Check if userPhoneNo is unique
        if (uniqueUserPhoneNos.has(userPhoneNo)) {
          duplicateError = true;
          errorRows.push(
            `Phone number '${userPhoneNo}' in row ${index + 2} is not unique`
          );
        } else {
          uniqueUserPhoneNos.add(userPhoneNo);
        }

        // Check if password length is at least 6 characters
        if (password && password.length < 6) {
          passwordError = true;
          errorRows.push(
            `Password in row ${index + 2} is shorter than 6 characters`
          );
        }

        // Validate email format
        if (email && !emailRegex.test(email)) {
          emailError = true;
          errorRows.push(`Email '${email}' in row ${index + 2} is not valid`);
        }

        // Validate phone number length (must be exactly 10 digits)
        if (userPhoneNo && userPhoneNo.length == 12) {
          phoneNumberError = true;
          errorRows.push(
            `Phone number '${userPhoneNo}' in row ${
              index + 2
            } must be exactly 10 digits`
          );
        }

        // If row has data, add it to the valid data array
        validData.push(rowObject);
      });

      // If there are duplicate, password, email, or phone number errors, set the error state and return
      if (duplicateError || passwordError || emailError || phoneNumberError) {
        setError(`Errors found: \n${errorRows.join("\n")}`);
        setCsvData([]);
        return;
      }

      // If no errors, update the state with valid data
      setCsvData(validData);
      setError(validData.length === 0 ? "No valid data found in the file" : "");

      // Create a new XLSX file from validData
      const newSheet = XLSX.utils.json_to_sheet(validData);
      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, newSheet, "Valid Data");

      // Convert the workbook to binary (array format)
      const wbout = XLSX.write(newWorkbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a blob from the array buffer and store it in state (without download)
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      setNewXlxFile(blob); // Store the blob in state without download
    };

    reader.readAsArrayBuffer(file); // Read the file as ArrayBuffer
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || csvData.length === 0) {
      setError("Please select CSV file");
      return;
    }
    const cleanedData = csvData
      .map((row) => {
        return Object.fromEntries(
          Object.entries(row).filter(
            ([key, value]) => typeof value === "string" && value.trim() !== ""
          )
        );
      })
      .filter((row) => Object.keys(row).length > 0);

    // Prepare data for upload
    const formData = new FormData();
    formData.append("usersCsv", newXlxFile);
    formData.append("createdBy", createdBy);
    formData.append("defaultPermissionLevel", getRoleLevel);
    formData.append("society_id", society_id);

    try {
      const response = await axios.post(`${PORT}usersImportFormCsv`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        addItem(response.data.result);
        setMessage(response.data.msg);
      } else {
        setMessage("Failed to import entries");
      }
      setCsvData([]);
      setFile(null);
      document.querySelector('input[type="file"]').value = null;
      toast.success(response.data.msg);
    } catch (error) {
      if (error.response && error.response.data) {
        const { msg, existingUsers } = error.response.data;

        if (existingUsers) {
          const existingUserMessages = Object.entries(existingUsers)
            .map(([username, count]) => `${username}: ${count} time(s)`)
            .join(", ");

          toast.error(`${msg}: ${existingUserMessages}`);
        } else {
          toast.error(msg);
        }
      } else {
        toast.error("An unexpected error occurred.");
      }

      console.error("Error uploading file:", error.response.data);
    }
  };

  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const getRole = localStorage.getItem("role");
  const handleDownload = async () => {
    let response = await axios.get(`${PORT}/roleGet`);
    const apiRoles = await response.data.roles;
    const filterDataRole = apiRoles.filter(
      (item) =>
        item.createdBy === getRoleId && item.defaultPermissionLevel !== 1
    );

    const rolesWithLessDefaultLevel = apiRoles.filter(
      (item) =>
        item.defaultPermissionLevel > getRole ||
        item.roleTypeLevelSociety === "guardAccess"
    );

    const extractedData = rolesWithLessDefaultLevel.map((role) => ({
      id: role._id,
      title: role.title,
      defaultPermissionLevel: role.defaultPermissionLevel,
    }));

    const filteredRolesData = filterDataRole.map((role) => ({
      id: role._id,
      title: role.title,
      defaultPermissionLevel: role.defaultPermissionLevel,
    }));
    const roleIdSet = new Set();
    const combinedData = [...extractedData, ...filteredRolesData].filter(
      (role) => {
        if (roleIdSet.has(role.id)) {
          return false;
        } else {
          roleIdSet.add(role.id);
          return true;
        }
      }
    );

    const rolesList = combinedData.map((role) => role.title);
    if (rolesList.length === 0) {
      console.error("No roles available for the dropdown.");
      return;
    }
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Users");
    worksheet.getColumn(1).width = 25; // Column A - "username"
    worksheet.getColumn(2).width = 25; // Column B - "userPhoneNo"
    worksheet.getColumn(3).width = 25; // Column C - "password"
    worksheet.getColumn(4).width = 25; // Column D - "role"
    worksheet.getColumn(5).width = 25; // Column E - "name"
    // Merge the first row across columns A to E
    worksheet.mergeCells("A1:E1"); // Merging A1 to E1 (5 columns)
    worksheet.getCell("A1").value =
      "Note: Please follow the instructions below.\n" +
      "1. To assign a user’s role, you need to copy the previous role, then select from the dropdown, and finally, assign the role.\n" +
      "2. The password length should be at least 6 characters.\n" +
      "3. The email and phone number should be unique.";
    worksheet.getCell("A1").style = {
      font: { bold: true, size: 12 },
      alignment: { horizontal: "center", vertical: "middle" },
    };
    console.log({ worksheet });
    worksheet.getRow(1).height = 60;
    // Set columns without headers at this point

    // Set headers starting from the second row (row 2)
    worksheet.getRow(2).values = [
      "username",
      "userPhoneNo",
      "password",
      "role",
      "name",
    ];

    // Data to be written starting from row 3
    const data = [
      {
        username: "amitkumar@gmail.com",
        userPhoneNo: "7876121490",
        password: "G@123",
        role: "GuardLevel",
        name: "Anil kumar",
      },
      {
        username: "rahulkumar@gmail.com",
        userPhoneNo: "6230434766",
        password: "J#4!123",
        role: "SocietySubAdmin",
        name: "Sumit kumar",
      },
    ];

    // Add data rows starting from row 3

    // Add data rows starting from row 3
    data.forEach((user, index) => {
      const rowIndex = index + 3; // Data starts from row 3, adjust if necessary
      const row = worksheet.getRow(rowIndex); // Get row by index
      row.values = [
        user.username,
        user.userPhoneNo,
        user.password,
        user.role,
        user.name,
      ];
      // Role dropdown validation
      worksheet.getCell(`D${rowIndex}`).dataValidation = {
        type: "list",
        allowBlank: true,
        formulae: [`=$Z$2:$Z$${rolesList.length + 1}`],
        showDropDown: true,
        errorStyle: "warning",
        errorTitle: "Invalid Selection",
        error: "Please select a role from the dropdown.",
      };
      worksheet.getCell(`D${rowIndex}`).style = { locked: false };
    });
    // Add roles to the Z column starting from Z2 (hidden column)
    rolesList.forEach((role, index) => {
      worksheet.getCell(`Z${index + 2}`).value = role;
    });
    worksheet.getColumn("Z").hidden = true;
    // Write to buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate}`;

    saveAs(blob, `${"Users Sample"}  ${dateTime}.xlsx`);
  };

  return (
    <div>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi" ? "Import Users" : "उपयोगकर्ता आयात करें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <div className="container-fluid py-4 ">
          <div className="row">
            <div className="col-12  col-margin_top">
              <div className="card mb-4">
                <div className="card-header pb-0 mt--5">
                  <div className="heading_import">
                    <h4> </h4>
                    <button
                      className="buttom_download"
                      onClick={handleDownload}
                    >
                      <FaDownload />
                      Download Sample
                    </button>
                  </div>
                  <hr />
                  <div classNameName="error_msg_csv">
                    {error && (
                      <Typography id="modal-description" color="error">
                        <div
                          className="alerts
                    alerts-danger
                    "
                          role="alert"
                        >
                          {error}
                        </div>
                      </Typography>
                    )}
                  </div>
                  <div className="drag-file-area">
                    <span className="material-icons-outlined upload-icon">
                      {" "}
                      Import Users{" "}
                    </span>
                    <h3 className="dynamic-message">
                      {" "}
                      Drag &amp; drop any file here{" "}
                    </h3>
                    <label className="label">
                      <span className="browse-files">
                        <div className="input_csv_input">
                          <input type="file" onChange={handleFileChange} />
                        </div>
                        <span className="browse-files-text">browse file</span>{" "}
                        <span>from device</span>{" "}
                      </span>{" "}
                    </label>
                  </div>
                  <span className="cannot-upload-message">
                    {csvData.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <h3 className="preview_heading_csv">
                          CSV Data Preview
                        </h3>
                        <table className="tabel_csv_data_preview">
                          <thead>
                            <tr>
                              {Object.keys(csvData[0]).map((key) => (
                                <th key={key}>{key}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {csvData
                              .filter((row) =>
                                Object.values(row).some(
                                  (value) =>
                                    typeof value === "string" &&
                                    value.trim() !== ""
                                )
                              )
                              .map((row, index) => (
                                <tr key={index}>
                                  {Object.values(row).map((value, i) => (
                                    <td key={i}>
                                      {typeof value === "string" &&
                                      value.trim() !== ""
                                        ? value
                                        : String(value).trim() !== ""
                                        ? String(value)
                                        : null}
                                    </td>
                                  ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Box>
                    )}
                  </span>
                  <form onSubmit={handleSubmit}>
                    <div className="upload_button_div">
                      <button
                        type="submit"
                        className="upload-button"
                        disabled={!file || error}
                      >
                        {" "}
                        Upload
                      </button>
                    </div>
                  </form>
                  {csvData.length > 0 ? (
                    ""
                  ) : (
                    <div className="main-content mt-custom">
                      <div className="title">Sample Users - CSV</div>
                      <div className="cell-content">
                        <div>fx</div>
                        <div></div>
                      </div>
                      <div className="user_cells">
                        <div className="cells__spacer"></div>
                        <div className="cells__alphabet">A</div>
                        <div className="cells__alphabet">B</div>
                        <div className="cells__alphabet">C</div>
                        <div className="cells__alphabet">D</div>
                        <div className="cells__alphabet">E</div>

                        <div className="cells__number">1</div>
                        <div className="cells__number">2</div>
                        <div className="cells__number">3</div>
                        <div className="cells__number">4</div>
                        <div className="cells__number">5</div>
                        <div className="cells__number">6</div>
                        <div className="cells__number">7</div>
                        <div className="cells__number">8</div>
                        <div className="cells__number">9</div>
                        <div className="cells__number">10</div>
                        <div className="cells__number">11</div>
                        <div className="cells__number">12</div>
                        <div className="cells__number">13</div>
                        <div className="cells__number">14</div>
                        <div className="cells__number">15</div>
                        <div className="cells__input">username</div>
                        <div className="cells__input">userPhoneNo</div>
                        <div className="cells__input">password</div>
                        <div className="cells__input">role</div>
                        <div className="cells__input">name</div>

                        <div className="cells__input">amitKumar@gmail.com</div>
                        <div className="cells__input">9812183764</div>
                        <div className="cells__input">As&!123</div>
                        <div className="cells__input">GuardLevel</div>
                        <div className="cells__input">Anil kumar</div>
                        <div className="cells__input">rahulKumar@gmail.com</div>
                        <div className="cells__input">8263068050</div>
                        <div className="cells__input">UsDe#$123</div>
                        <div className="cells__input">SocietySubAdmin</div>
                        <div className="cells__input">Sumit kumar</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </Layout>
    </div>
  );
};

export default UsersImportCsv;
