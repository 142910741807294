import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import Layout from "../../../../lib/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { PORT } from "../../../../Api/api";
import axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import { Tooltip } from "@mui/material";
import { formatDate } from "../../../../lib/FormattedDate";
import { FaCheckCircle, FaTimesCircle, FaHourglassHalf } from "react-icons/fa";
import { LanguageContext } from "../../../../lib/LanguageContext";
import Swal from "sweetalert2";
import { CiEdit } from "react-icons/ci";
import ButtonLoader from "../../../../Loading/ButtonLoader";
import ViewDisputeModal from "../../../../DisputeChat/ViewDisputeModal";
import { IoMdArrowBack } from "react-icons/io";
import Backbutton from "../../../../GoBack/Backbutton";
import AddBackbtn from "../../../../lib/AddBackbtn";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TiExport } from "react-icons/ti";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import Box from "@mui/material/Box";
import Papa from "papaparse";
import { useRef } from "react";
import getCurrentDate from "../../../../lib/CurrentData";
import getCurrentTime from "../../../../lib/CurrentTime";
const ViewAffiliateSocietyTransactions = () => {
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const [billsData, setBillsData] = useState([]);
  const [getId, setId] = useState();
  const [loading, setLoading] = useState(true);
  const [disputeStatusloading, setDisputeStatusloading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [rejectionMessage, setRejectionMessage] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [commissionEditView, setCommissionEditView] = useState(false);
  const [commissionEditCancelView, setCommissionEditCancelView] =
    useState(false);
  const [commissionValues, setCommissionValues] = useState({}); 
  const [show, setShow] = useState(false);
  const [disputeId, setDisputeId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const params = useParams();
  const id = params.id;
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);
  const perPage = 12;
  const handleClose = () => {
    setDisputeId("");
    fetchSocietyBills();
    setShow(false);
  };

  const handleShow = (item) => {
    fetchSocietyBills();
    if (item?.disputeId?._id) {
      setDisputeId(item?.disputeId?._id);
      setShow(true);
    }
  };

  //fetchSocietyBills
  const fetchSocietyBills = async () => {
    if (id) {
      try {
        const response = await axios.get(`${PORT}/getCommission/${id}`);
        const res = (await response.data.commission) || [];
        setBillsData(res[0]);
        setFilterData(res[0].transactions);
        setId(res[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("Error fetching society data:", error);
      }
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }

  //handlePaymentStatus
  const handlePaymentStatus = async (
    item,
    paymentStatus,
    paymentDate,
    paymentTime
  ) => {
    const data = {
      mainBillId: getId?._id,
      billId: item?._id,
      affiliateId: getId?.affiliateId?._id,
      affiliateCommission: item?.affiliateCommission,
      paymentStatus: paymentStatus,
      paymentDate: paymentDate ? paymentDate : getCurrentDate(),
      paymentTime: paymentTime ? paymentTime : getCurrentTime(),
    };
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to pay this?",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    });

    if (!result.isConfirmed) return;
    try {
      const response = await axios.post(
        `${PORT}/modifyAffiliateCommissionStatus`,
        data
      );
      Swal.fire({
        title: "Updated!",
        text: `${response.data.message}`,
        icon: "success",
        timer: 1500,
        timerProgressBar: true,
      });
      fetchSocietyBills();
    } catch (error) {
      console.log("Error fetching society data:", error);
    }
  };

  //handleCommissionEdit
  const handleCommissionEdit = (index, items) => {
    setCommissionEditView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
    setCommissionEditCancelView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
    // Initialize input value with the current commission if entering edit mode
    if (!commissionEditView[index]) {
      setCommissionValues((prev) => ({
        ...prev,
        [index]: items[index]?.affiliateCommission || 0,
      }));
    }
  };
  //handleInputChange
  const handleInputChange = (index, value) => {
    if (/^\d*\.?\d*$/.test(value)) {
      // Allow only numbers and decimals
      setCommissionValues((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };
  //handleCancel
  const handleCancel = (index, items) => {
    setCommissionEditView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
    setCommissionEditCancelView((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the edit view for the specific row
    }));
  };
  //function lock
  useEffect(() => {
    fetchSocietyBills();
  }, []);

  const handleDisputeStatus = async (item, disputeStatus) => {
    setSelectedItem(item._id);
    const result = await Swal.fire({
      title: `Are you sure you want to ${disputeStatus} this society?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${disputeStatus} it!`,
    });

    let resolutionNotes = "";
    if (result.isConfirmed) {
      setDisputeStatusloading(true);
      let result2;

      if (disputeStatus === "Rejected") {
        const options = [
          "Invalid Dispute Reason (The provided reason is not valid)",
          "Insufficient Evidence (The provided documentation is not sufficient)",
          "Dispute Already Resolved",
          "Incorrect Billing Details (The dispute is based on incorrect information)",
          "Policy Violation (The reason does not comply with GuardX policies)",
          "Duplicate Dispute (A dispute for the same bill has already been raised)",
          "Delayed Submission (The dispute was filed after the deadline)",
          "Fraudulent Claim (The provided reason and documentation seem fraudulent)",
          "Payment Already Processed",
          "No Valid Contract (The dispute does not align with contract terms)",
          "Invalid Invoice (The invoice is not valid or was not issued)",
          "Technical Error Resolved (The error has already been fixed)",
          "Other (Provide additional details)",
        ];

        result2 = await Swal.fire({
          title: "Cause of Rejection",
          html: `
                        <div class="form-group">
                          <label for="rejectionReason" class="form-label">Select a reason:</label>
                          <select id="rejectionReason" class="form-control">
                            ${options
                              .map(
                                (option, index) =>
                                  `<option value="${index}">${option}</option>`
                              )
                              .join("")}
                          </select>
                        </div>
                        <div class="form-group mt-3" id="customReasonGroup" style="display: none;">
                          <label for="customReason" class="form-label">Custom Reason:</label>
                          <input id="customReason" class="form-control" type="text" placeholder="Enter your reason" />
                        </div>
                      `,
          showCancelButton: true,
          confirmButtonText: "Save",
          focusConfirm: false,
          preConfirm: () => {
            const reasonSelect = document.getElementById("rejectionReason");
            const customReasonInput = document.getElementById("customReason");
            const selectedValue = reasonSelect.value;

            if (selectedValue === String(options.length - 1)) {
              // "Other" selected
              const customReason = customReasonInput.value.trim();
              if (!customReason) {
                Swal.showValidationMessage(
                  "You need to provide a cause of rejection!"
                );
                return null;
              }
              return customReason; // Return custom input value
            } else {
              return options[selectedValue]; // Return selected dropdown value
            }
          },
          didOpen: () => {
            const reasonSelect = document.getElementById("rejectionReason");
            const customReasonGroup =
              document.getElementById("customReasonGroup");

            // Show/hide custom input based on dropdown selection
            reasonSelect.addEventListener("change", () => {
              if (reasonSelect.value === String(options.length - 1)) {
                // "Other" selected
                customReasonGroup.style.display = "block";
              } else {
                customReasonGroup.style.display = "none";
                document.getElementById("customReason").value = ""; // Clear custom input
              }
            });
          },
        });

        if (result2.isConfirmed) {
          setRejectionMessage(result2.value);
          resolutionNotes = result2.value; // Set the selected or custom rejection message
        } else {
          setDisputeStatusloading(false);
        }
      }
      if (result2 && !result2.isConfirmed) {
        setDisputeStatusloading(false);
        return;
      }
      // console.log("sdsdsd", result2);
      if (disputeStatus === "Resolved") {
        const approvalInput = await Swal.fire({
          title: "Enter Resolution Remark",
          input: "textarea",
          inputLabel: "Provide a detailed remark for resolving this dispute:",
          inputPlaceholder: "Enter your remarks here...",
          showCancelButton: true,
          confirmButtonText: "Save",
          inputValidator: (value) => {
            if (!value || value.trim() === "") {
              return "You need to provide a resolution remark!";
            }
          },
        });

        if (approvalInput.isConfirmed) {
          resolutionNotes = approvalInput.value;
        } else {
          setDisputeStatusloading(false);
          return;
        }
      }

      const payload = {
        disputeReviewBy: getRoleId,
        societyId: billsData?.society_id?._id,
        affiliateId: billsData?.affiliateId?._id,
        billId: item?.invoiceNo,
        disputeID: item?.disputeId?._id,
        status: disputeStatus,
        resolutionNotes: resolutionNotes || "Na",
        month: item?.billing_period_startDate,
      };
      try {
        const response = await axios.patch(`${PORT}updateDispute`, payload);
        Swal.fire(
          `Successfully updated`,
          `Dispute ${disputeStatus} !`,
          `success`
        );
        fetchSocietyBills();
      } catch (error) {
        Swal.fire(`Error`, `${error.response.error}`, `error`);
        console.log(error);
      } finally {
        setDisputeStatusloading(false);
      }
    }
  };
  //custom Function to convert the Date in month
  function formatDateToCustom(dateString) {
    const date = new Date(dateString);

    // Map of month numbers to short names
    const monthShortNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // Extract date parts
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = monthShortNames[date.getMonth()]; // Converts month to short name
    const year = date.getFullYear(); // Gets the full year

    // Combine parts in the desired format
    return `${day}-${month}-${year}`;
  }
  const exportToExcel = (filterData, filename) => {
    if (!filterData || filterData.length === 0) {
      Swal.fire(`Error`, `No data to export!`, `error`);
      return;
    }
    const ws = XLSX.utils.json_to_sheet(
      filterData.map((item) => ({
        BillingCycle: item.billingCycle ? item.billingCycle : "Not Added",
        "Society Name": item?.society_id.name
          ? item?.society_id.name
          : "Not Added",
        BillStartDate: item.billing_period_startDate
          ? formatDateToCustom(item.billing_period_startDate)
          : "Not Added",
        BillEndDate: item.billing_period_endDate
          ? formatDateToCustom(item.billing_period_endDate)
          : "Not Added",
        InvoiceNo: item.invoiceNo ? item.invoiceNo : "Not Added",
        TotalHouses: item.totalHouse ? item.totalHouse : "Not Added",

        Amount: item.total ? item.total : "Not Added",
        DiscountAmount: item.discountAmount ? item.discountAmount : "0",

        "Net Payable": item.total || "NA",

        DueDate: item.dueDate ? formatDate(item.dueDate) : "Not Added",
        AffiliateCommission: item.affiliateCommission
          ? item.affiliateCommission
          : "Not Added",
        PaymentStatus: item.paymentStatus ? item.paymentStatus : "Not Added",
        DisputeStatus: item.disputeId
          ? item.disputeId.status
          : "No Dispute Raised",
      }))
    );

    // Set column widths
    const wscols = new Array(17).fill({ wch: 30 }); // All columns set to 30 width
    ws["!cols"] = wscols;

    // Highlight rows with specific conditions
    const lightOrange = { fill: { fgColor: { rgb: "FFECB3" } } }; // Light orange color
    Object.keys(ws).forEach((cell) => {
      if (cell[0] !== "!") {
        const rowNumber = parseInt(cell.match(/\d+/)[0], 10) - 2; // Adjust for header row
        if (
          filterData[rowNumber] &&
          (filterData[rowNumber].paymentStatus === "unpaid" ||
            (filterData[rowNumber].disputeId &&
              filterData[rowNumber].disputeId.status === "pending"))
        ) {
          ws[cell].s = lightOrange;
        }
      }
    });

    // Create workbook and append sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Write workbook and download
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.xlsx`);
  };

  const exportToCSV = (filterData, filename) => {
    if (!filterData || filterData.length === 0) {
      Swal.fire(`Error`, `No data to export!`, `error`);
      return;
    }

    // Map data into the desired CSV structure
    const csvData = filterData.map((item) => ({
      BillingCycle: item.billingCycle ? item.billingCycle : "Not Added",
      "Society Name": item?.society_id.name
        ? item?.society_id.name
        : "Not Added",
      BillStartDate: item.billing_period_startDate
        ? formatDateToCustom(item.billing_period_startDate)
        : "Not Added",
      BillEndDate: item.billing_period_endDate
        ? formatDateToCustom(item.billing_period_endDate)
        : "Not Added",
      InvoiceNo: item.invoiceNo ? item.invoiceNo : "Not Added",
      TotalHouses: item.totalHouse ? item.totalHouse : "Not Added",

      Amount: item.total ? item.total : "Not Added",
      DiscountAmount: item.discountAmount ? item.discountAmount : "0",

      "Net Payable": item.total || "NA",

      DueDate: item.dueDate ? formatDate(item.dueDate) : "Not Added",
      AffiliateCommission: item.affiliateCommission
        ? item.affiliateCommission
        : "Not Added",
      PaymentStatus: item.paymentStatus ? item.paymentStatus : "Not Added",
      DisputeStatus: item.disputeId
        ? item.disputeId.status
        : "No Dispute Raised",
    }));

    // Convert JSON data to CSV
    const csv = Papa.unparse(csvData);

    // Create a Blob and save as a CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const currentTimeAndDate = new Date();
    const dateTime = `${currentTimeAndDate} `;
    FileSaver.saveAs(blob, `${filename} ${dateTime}.csv`);
  };

  const handleSearchInput = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };
  // Validation logic to ensure endDate is not before startDate
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    // Check if the end date is empty
    if (!selectedEndDate) {
      setEndDate(""); // Clear the end date if it's empty
      return;
    }
    if (selectedEndDate < startDate) {
      // setError("End date can't be before the start date.");
      alert("End date can't be before the start date.");
      setEndDate("");
      return; // Don't update endDate if it's invalid
    }
    setEndDate(selectedEndDate);
  };
  // Disable Button After 72 Hours
  const isPaymentExpired = (paymentDate, paymentTime) => {
    if (!paymentDate || !paymentTime) return false;
    // Convert "04-02-2025 10:15 am" => "2025-02-04T10:15:00"
    const [day, month, year] = paymentDate.split("-"); // Extract day, month, year
    const formattedDate = `${year}-${month}-${day}`; // Convert to "YYYY-MM-DD"
    const paymentDateTime = new Date(`${formattedDate} ${paymentTime}`); // Create Date object
    const currentTime = new Date();
    // Calculate difference in hours
    const hoursDifference = (currentTime - paymentDateTime) / (1000 * 60 * 60); // Convert ms to hours
    return hoursDifference >= 72; // Disable if 72 hours passed
  };
  useEffect(() => {
    let filteredData = billsData?.transactions || []; // Make sure you're starting with the original data

    // Apply start date filter billsData.transactions
    if (startDate || endDate) {
      const start = startDate
        ? new Date(startDate).toISOString().split("T")[0]
        : null;
      const end = endDate
        ? new Date(endDate).toISOString().split("T")[0]
        : null;

      filteredData = filteredData.filter((item) => {
        const formattedStartDate = new Date(item.billing_period_startDate)
          .toISOString()
          .split("T")[0];
        const formattedEndDate = new Date(item.billing_period_endDate)
          .toISOString()
          .split("T")[0];

        // Check conditions based on available dates
        if (start && end) {
          return formattedStartDate >= start && formattedEndDate <= end;
        } else if (start) {
          return formattedStartDate >= start;
        } else if (end) {
          return formattedEndDate <= end;
        }
        return true;
      });
    }

    // Apply search query filter
    if (searchQuery && searchQuery.trim() !== "") {
      const query = searchQuery.toLowerCase();
      filteredData = filteredData.filter((item) => {
        return (
          item.paymentStatus.toLowerCase() === query || // Exact match for payment status
          item?.disputeId?.status?.toLowerCase().includes(query) || // Partial match for dispute status
          item.invoiceNo.toLowerCase().includes(query) // Partial match for invoice number
        );
      });
    }
    const filteredDataLatestEntryTop = [...filteredData].reverse();
    setFilterData(filteredDataLatestEntryTop); // Update state with filtered results
    setTotalPages(Math.ceil(filteredDataLatestEntryTop?.length / perPage)); // Update total pages
    setCurrentPage(1); // Reset to page 1 after filtering
  }, [searchQuery, startDate, endDate, billsData, perPage]);
  //handle CLick Outside  accordian close
  const handleClickOutside = (event) => {
    if (accordionRef.current && !accordionRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };
  //function lock
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <Layout>
        <div class="container-fluid ">
          <div class="row">
            <div
              className="back-btn"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "60px",
                position: "relative",
                top: "10px",
                left: "10px",
                zIndex: 20,
              }}
            >
              <AddBackbtn />
            </div>
            <div class="col-12 ">
              {/* Card */}
              <div
                className="card mb-4"
                style={{
                  marginTop: "30px",
                  position: "relative",
                  zIndex: 1,
                }}
              >
                <div class="card-header pb-0">
                  <div className="card-body px-0 pt-0 pb-2 w-100 ">
                    {/* Top Section */}

                    <div className="section-wrapper">
                      {/* Current Plan Summary */}
                      <div className="card-billing-wrapper">
                        <div className="card-billing-heading1">
                          <div className="card-billing-heading-title">
                            {language === "hindi"
                              ? "  Society Details"
                              : "सोसाइटी विवरण"}
                          </div>
                          <div className="card-billing-heading-button">
                            {/* <button>Upgrade</button> */}
                          </div>
                        </div>
                        {billsData ? (
                          <div className="card-billing-body">
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Society Name"
                                    : "सोसाइटी का नाम"}
                                </h6>
                                <strong
                                  style={{
                                    // backgroundColor: bgColorPlanType,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData?.society_id?.name || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Agency Name"
                                    : "एजेंसी का नाम"}
                                </h6>

                                <strong
                                  style={{
                                    // backgroundColor: bgColorHouseCount,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData.affiliateId?.agencyName || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="billing-no-data"></div>
                        )}
                      </div>
                    </div>
                    {/* Top Section */}
                    <div className="section-wrapper">
                      {/* Current Plan Summary */}
                      <div className="card-billing-wrapper">
                        <div className="card-billing-heading1">
                          <div className="card-billing-heading-title">
                            {language === "hindi"
                              ? "Commission to be Pay"
                              : "भुगतान करने के लिए कमीशन"}
                          </div>
                          <div className="card-billing-heading-button">
                            {/* <button>Upgrade</button> */}
                          </div>
                        </div>
                        {billsData ? (
                          <div className="card-billing-body">
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Total commission"
                                    : " कुल कमीशन"}
                                </h6>
                                <strong
                                  style={{
                                    // backgroundColor: bgColorPlanType,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData?.affiliateTotalCommission?.toFixed(
                                      2
                                    ) || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Paid commission"
                                    : " भुगतान की गई कमीशन"}
                                </h6>

                                <strong
                                  style={{
                                    // backgroundColor: bgColorHouseCount,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData.affiliatePaidEarnings?.toFixed(
                                      2
                                    ) || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                            <div className="card-billing-body-details">
                              <p>
                                <h6>
                                  {language === "hindi"
                                    ? "Pending commission"
                                    : "लंबित कमीशन"}
                                </h6>

                                <strong
                                  style={{
                                    // backgroundColor: bgColorHouseCount,
                                    padding: "5px 10px",
                                    borderRadius: "5px",
                                    color: "#000", // Black text for contrast
                                  }}
                                >
                                  {loading ? (
                                    <div className="">
                                      <ThreeCircles
                                        visible={true}
                                        height={30}
                                        width={30}
                                        color="#5e72e4"
                                        ariaLabel="three-circles-loading"
                                      />
                                    </div>
                                  ) : (
                                    billsData.affiliatePendingEarnings?.toFixed(
                                      2
                                    ) || ""
                                  )}
                                </strong>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="billing-no-data"></div>
                        )}
                      </div>
                    </div>

                    <div className="section-wrapper">
                      {/* Biils Detial*/}
                      <div className="card-billing-wrapper">
                        <div className="filtered-div">
                          <div
                            ref={accordionRef}
                            className="p-export-btn-society export-dropdown"
                          >
                            <Tooltip placement="top" title={"Export"}>
                              <Accordion expanded={isOpen}>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                  onClick={handleToggle}
                                >
                                  <TiExport className="export_icon" />
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className="export-btns">
                                    <button
                                      className="export_society_btn"
                                      onClick={() =>
                                        exportToExcel(
                                          filterData,
                                          `Affiliate Society Billing of ${billsData?.society_id?.name}`
                                        )
                                      }
                                    >
                                      {language === "english"
                                        ? "एक्सेल"
                                        : " Excel"}
                                    </button>
                                    <button
                                      className="export_society_btn "
                                      onClick={() =>
                                        exportToCSV(
                                          filterData,
                                          `Affiliate Society Billing of ${billsData?.society_id?.name}`
                                        )
                                      }
                                    >
                                      {language === "english"
                                        ? "सीएसवी"
                                        : "CSV"}
                                    </button>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Tooltip>
                          </div>
                          <div className="date-filter">
                            <TextField
                              id="start-date"
                              label={
                                language === "hindi"
                                  ? "Start Date"
                                  : "आरंभ करने की तिथि"
                              }
                              type="date"
                              value={startDate}
                              onChange={(e) => setStartDate(e.target.value)}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#5e72e4" },
                              }}
                              InputProps={{
                                style: { color: "#5e72e4" },
                              }}
                              variant="outlined"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: " #5e72e4",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#5e72e4",
                                  },
                                  "& .MuiInputLabel-root.Mui-error": {
                                    color: "#5e72e4",
                                  },
                                },
                              }}
                            />
                            <TextField
                              id="end-date"
                              label={
                                language === "hindi" ? "End Date" : "अंतिम तिथि"
                              }
                              type="date"
                              value={endDate}
                              onChange={handleEndDateChange}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#5e72e4" },
                              }}
                              InputProps={{
                                style: { color: "#5e72e4" },
                              }}
                              variant="outlined"
                              disabled={!startDate}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: "#5e72e4",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "& .MuiInputLabel-root.Mui-error": {
                                    color: "#5e72e4",
                                  },
                                },
                              }}
                            />
                            <div>
                              <button
                                className="view-transactions-clear-btn"
                                onClick={() => {
                                  setStartDate("");
                                  setEndDate("");
                                  setSearchQuery("");
                                }}
                              >
                                {language === "english"
                                  ? "फिल्टर हटाये"
                                  : "Clear Filter"}
                              </button>
                            </div>
                          </div>
                          <div className="">
                            <Box
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderColor: " #5e72e4",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: " #5e72e4",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: " #5e72e4",
                                  },

                                  "& .MuiInputLabel-root.Mui-error": {
                                    color: "red",
                                  },
                                },
                              }}
                            >
                              <TextField
                                id="search-input"
                                label={
                                  language === "hindi" ? "Search..." : "खोज..."
                                }
                                variant="outlined"
                                value={searchQuery}
                                onChange={handleSearchInput}
                                className="search-input_society"
                              />
                            </Box>
                          </div>
                        </div>

                        <div className="card-billing-body">
                          <div className="billing-container">
                            <div className="parent-container">
                              {loading ? (
                                <td className="no-data-billing-td" colSpan={4}>
                                  <div className="billing-loading">
                                    <ThreeCircles
                                      visible={true}
                                      height={60}
                                      width={60}
                                      color="#5e72e4"
                                      ariaLabel="three-circles-loading"
                                    />
                                  </div>
                                </td>
                              ) : (
                                <div className="table-container ">
                                  <table className="billing-table">
                                    <thead>
                                      <tr>
                                        <th>
                                          {language === "english"
                                            ? "बिलिंग चक्र "
                                            : "Billing Cycle"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "बिलिंग अवधि प्रारंभ तिथि "
                                            : "Billing Period Start Date"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "बिलिंग अवधि समाप्ति तिथि "
                                            : "Billing Period End Date"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "चालान नंबर "
                                            : "Invoice No."}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "कुल घर "
                                            : "Total Houses"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "राशि "
                                            : "Amount"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "डिस्काउंट राशि "
                                            : "Discount Amount"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "कुल देय "
                                            : "Net Payable"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "नियत तारीख "
                                            : "Due Date"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "संबद्ध आयोग "
                                            : "Affiliate Commission"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "भुगतान की स्थिति "
                                            : "Payment Status"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "कार्यवाही "
                                            : "Action"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "विवाद स्थिति "
                                            : "Dispute Status"}
                                        </th>
                                        <th>
                                          {language === "english"
                                            ? "विवाद कार्यवाही "
                                            : "Dispute Actions"}
                                        </th>
                                      </tr>
                                    </thead>
                                    {billsData && filterData.length > 0 ? (
                                      filterData?.map((item, index) => {
                                        return (
                                          <>
                                            <tbody>
                                              <tr
                                                className={
                                                  item?.disputeId?.status ===
                                                  "Pending"
                                                    ? "highlight-row-warning"
                                                    : item?.disputeId
                                                        ?.status === "Rejected"
                                                    ? "highlight-row-danger"
                                                    : item?.disputeId
                                                        ?.status === "Resolved"
                                                    ? "highlight-row-resolved "
                                                    : ""
                                                }
                                              >
                                                <td>
                                                  {" "}
                                                  <strong
                                                    className={
                                                      item.billingCycle ===
                                                      "monthly"
                                                        ? "interval-monthly"
                                                        : item.billingCycle ===
                                                          "annual"
                                                        ? "interval-annual"
                                                        : item.billingCycle ===
                                                          "quarterly"
                                                        ? "interval-quarterly"
                                                        : ""
                                                    }
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : (
                                                      item?.billingCycle?.toUpperCase() ||
                                                      "monthly"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  <strong
                                                    style={{
                                                      // backgroundColor:
                                                      //   bgColorBillingPeriodStartDate,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : (
                                                      formatDateToCustom(
                                                        item.billing_period_startDate
                                                      )
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  <strong
                                                    style={{
                                                      // backgroundColor:
                                                      //   bgColorBillingPeriodStartDate,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : (
                                                      formatDateToCustom(
                                                        item.billing_period_endDate
                                                      )
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorInvoiceNo,
                                                      borderRadius: "5px",
                                                      padding: "5px 10px",

                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.invoiceNo ? (
                                                      item.invoiceNo
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorTotalHouse,
                                                      borderRadius: "5px",
                                                      padding: "5px 10px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.totalHouse ? (
                                                      item.totalHouse
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <Tooltip
                                                    placement="top"
                                                    title={
                                                      <div>
                                                        Total Houses:{" "}
                                                        {item.totalHouse}
                                                        <br />
                                                        Per House Cost(
                                                        {
                                                          item.currencyType
                                                        }):{" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.pricePerHouse ||
                                                          "10"}
                                                        <br />
                                                        Bill Amount (
                                                        {
                                                          item.currencyType
                                                        }):{" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.totalHouse *
                                                          (item.pricePerHouse ||
                                                            10)}
                                                      </div>
                                                    }
                                                    arrow
                                                  >
                                                    <strong
                                                      style={{
                                                        // backgroundColor: bgColorAmount,
                                                        padding: "5px 10px",
                                                        borderRadius: "5px",
                                                        color: "#000", // Black text for contrast
                                                      }}
                                                    >
                                                      {loading ? (
                                                        <div className="">
                                                          <ThreeCircles
                                                            visible={true}
                                                            height={30}
                                                            width={30}
                                                            color="#5e72e4"
                                                            ariaLabel="three-circles-loading"
                                                          />
                                                        </div>
                                                      ) : item.amount ? (
                                                        item.amount
                                                      ) : (
                                                        "No Data"
                                                      )}
                                                    </strong>
                                                  </Tooltip>
                                                </td>

                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorDiscountAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000",
                                                    }}
                                                  >
                                                    {item.currencyType == "INR"
                                                      ? "₹"
                                                      : "$"}

                                                    {item.discountType ===
                                                    "fixed"
                                                      ? item.discountAmount
                                                      : item.pricePerHouse *
                                                        item.totalHouse *
                                                        (item.discountAmount /
                                                          100)}
                                                  </strong>
                                                </td>
                                                <td>
                                                  <Tooltip
                                                    placement="top"
                                                    title={
                                                      <div>
                                                        Total Houses ={" "}
                                                        {item.totalHouse}
                                                        <br />
                                                        Per House Cost(
                                                        {item.currencyType}) ={" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.pricePerHouse ||
                                                          "10"}
                                                        <br />
                                                        Sub Total ={" "}
                                                        {item.amount}
                                                        <br />
                                                        Discount Amount ={" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.discountType ===
                                                        "fixed"
                                                          ? item.discountAmount
                                                          : item.pricePerHouse *
                                                            item.totalHouse *
                                                            (item.discountAmount /
                                                              100)}
                                                        <br />
                                                        Net Payable (
                                                        {item.currencyType}) ={" "}
                                                        {item.currencyType ==
                                                        "INR"
                                                          ? "₹"
                                                          : "$"}
                                                        {item.total}
                                                      </div>
                                                    }
                                                    arrow
                                                  >
                                                    <strong
                                                      style={{
                                                        // backgroundColor: bgColorNetPayval,
                                                        padding: "5px 10px",
                                                        borderRadius: "5px",
                                                        color: "#000",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {loading ? (
                                                        <div className="">
                                                          <ThreeCircles
                                                            visible={true}
                                                            height={30}
                                                            width={30}
                                                            color="#5e72e4"
                                                            ariaLabel="three-circles-loading"
                                                          />
                                                        </div>
                                                      ) : item.total ? (
                                                        item.total
                                                      ) : (
                                                        "No Data"
                                                      )}
                                                    </strong>
                                                  </Tooltip>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorDiscountAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.dueDate ? (
                                                      formatDate(item.dueDate)
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      // backgroundColor: bgColorDiscountAmount,
                                                      padding: "5px 10px",
                                                      borderRadius: "5px",
                                                      color: "#000", // Black text for contrast
                                                    }}
                                                  >
                                                    <div className="affiliate-commission-edit-view">
                                                      <div>
                                                        {loading ? (
                                                          <div className="">
                                                            <ThreeCircles
                                                              visible={true}
                                                              height={30}
                                                              width={30}
                                                              color="#5e72e4"
                                                              ariaLabel="three-circles-loading"
                                                            />
                                                          </div>
                                                        ) : commissionEditView[
                                                            index
                                                          ] ? (
                                                          <div>
                                                            <input
                                                              type="text"
                                                              value={
                                                                commissionValues[
                                                                  index
                                                                ] || ""
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChange(
                                                                  index,
                                                                  e.target.value
                                                                )
                                                              }
                                                              className="commission-input"
                                                            />
                                                          </div>
                                                        ) : (
                                                          item.affiliateCommission?.toFixed(
                                                            2
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <strong
                                                    style={{
                                                      borderRadius: "5px",
                                                      padding: "5px 10px",

                                                      color:
                                                        item.paymentStatus ===
                                                        "paid"
                                                          ? "green"
                                                          : "red",
                                                    }}
                                                  >
                                                    {loading ? (
                                                      <div className="">
                                                        <ThreeCircles
                                                          visible={true}
                                                          height={30}
                                                          width={30}
                                                          color="#5e72e4"
                                                          ariaLabel="three-circles-loading"
                                                        />
                                                      </div>
                                                    ) : item.amount ? (
                                                      item.paymentStatus.toUpperCase() ||
                                                      "unPaid"
                                                    ) : (
                                                      "No Data"
                                                    )}
                                                  </strong>
                                                </td>
                                                <td>
                                                  {" "}
                                                  <div className="action-download-bill">
                                                    {/* 04/1/25 */}

                                                    <div>
                                                      <Tooltip
                                                        disabled={
                                                          item.paymentStatus ===
                                                            "paid" ||
                                                          isPaymentExpired(
                                                            item.paymentDate,
                                                            item.paymentTime
                                                          )
                                                            ? "disabledPaymentButton"
                                                            : ""
                                                        }
                                                        onClick={(e) => {
                                                          if (
                                                            item.paymentStatus !==
                                                            "paid"
                                                          ) {
                                                            handlePaymentStatus(
                                                              item,
                                                              "paid",
                                                              item.paymentDate,
                                                              item.paymentTime
                                                            );
                                                          }
                                                        }}
                                                        placement="top"
                                                        title={
                                                          item.paymentStatus ===
                                                          "paid"
                                                            ? language ===
                                                              "hindi"
                                                              ? "Paid"
                                                              : "भुगतान किया"
                                                            : language ===
                                                              "hindi"
                                                            ? "Update Payment Status"
                                                            : "भुगतान स्थिति अपडेट करें"
                                                        }
                                                        arrow
                                                      >
                                                        <span
                                                          className={`${
                                                            item.paymentStatus ===
                                                              "paid" ||
                                                            isPaymentExpired(
                                                              item.paymentDate,
                                                              item.paymentTime
                                                            )
                                                              ? "disabledPaymentButton"
                                                              : "action-to-updated-payment-status"
                                                          }`}
                                                        >
                                                          <FaCheckCircle />
                                                        </span>
                                                      </Tooltip>
                                                    </div>
                                                    <div>
                                                      <Tooltip
                                                        disabled={
                                                          item.paymentStatus ===
                                                            "paid" ||
                                                          isPaymentExpired(
                                                            item.paymentDate,
                                                            item.paymentTime
                                                          )
                                                            ? "disabledPaymentButton"
                                                            : ""
                                                        }
                                                        onClick={(e) => {
                                                          if (
                                                            item.paymentStatus ==
                                                            "paid"
                                                          ) {
                                                            handlePaymentStatus(
                                                              item,
                                                              "unpaid",
                                                              item.paymentDate,
                                                              item.paymentTime
                                                            );
                                                          }
                                                        }}
                                                        placement="top"
                                                        title={
                                                          item.paymentStatus ===
                                                          "unpaid"
                                                            ? language ===
                                                              "hindi"
                                                              ? "Unpaid"
                                                              : "बिना भुगतान किया गया बिल"
                                                            : language ===
                                                              "hindi"
                                                            ? "Update Payment Status"
                                                            : "भुगतान स्थिति अपडेट करें"
                                                        }
                                                        arrow
                                                      >
                                                        <span
                                                          className={`${
                                                            item.paymentStatus ===
                                                              "unpaid" ||
                                                            isPaymentExpired(
                                                              item.paymentDate,
                                                              item.paymentTime
                                                            )
                                                              ? "disabledPaymentButton"
                                                              : "action-to-updated-payment-status"
                                                          }`}
                                                        >
                                                          <FaTimesCircle />
                                                        </span>
                                                      </Tooltip>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  {item?.disputeId?.status ? (
                                                    <>
                                                      {item?.disputeId?.status}
                                                      <span
                                                        className="d-flow text-sm"
                                                        style={{
                                                          display: "block",
                                                          marginTop: "5px",
                                                          color: "blue",
                                                          fontWeight: "normal",
                                                        }}
                                                      >
                                                        <span
                                                          onClick={() =>
                                                            handleShow(item)
                                                          }
                                                        >
                                                          (View Dispute)
                                                          {item?.disputeId
                                                            ?.comments &&
                                                            item?.disputeId?.comments.filter(
                                                              (comment) =>
                                                                comment.read ===
                                                                false
                                                            ).length > 0 && (
                                                              <span
                                                                style={{
                                                                  color: "red",
                                                                  marginLeft:
                                                                    "5px",
                                                                }}
                                                              >
                                                                {`Unread (${
                                                                  item.disputeId.comments.filter(
                                                                    (comment) =>
                                                                      comment.read ===
                                                                      false
                                                                  ).length
                                                                })`}
                                                              </span>
                                                            )}
                                                        </span>
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <div className="no-dispute">
                                                      ---
                                                    </div>
                                                  )}
                                                </td>

                                                <td>
                                                  {item?.disputeId?.status ? (
                                                    disputeStatusloading &&
                                                    selectedItem == item._id ? (
                                                      <ButtonLoader
                                                        height={30}
                                                        width={30}
                                                        color={"blue"}
                                                      />
                                                    ) : (
                                                      <>
                                                        {item?.disputeId
                                                          ?.status ===
                                                        "Pending" ? (
                                                          <div className="action-download-bill">
                                                            {/* Resolved Button */}
                                                            <div>
                                                              <Tooltip
                                                                onClick={() => {
                                                                  if (
                                                                    item
                                                                      ?.disputeId
                                                                      ?.status !==
                                                                    "Resolved"
                                                                  ) {
                                                                    handleDisputeStatus(
                                                                      item,
                                                                      "Resolved"
                                                                    );
                                                                  }
                                                                }}
                                                                placement="top"
                                                                title={
                                                                  item
                                                                    ?.disputeId
                                                                    ?.status ===
                                                                  "Resolved"
                                                                    ? language ===
                                                                      "english"
                                                                      ? "सुलझा हुआ विवाद"
                                                                      : "Dispute Resolved"
                                                                    : language ===
                                                                      "english"
                                                                    ? "सुलझा"
                                                                    : "Resolve"
                                                                }
                                                                arrow
                                                              >
                                                                <span
                                                                  className={`${
                                                                    item
                                                                      ?.disputeId
                                                                      ?.status ===
                                                                    "Resolved"
                                                                      ? "disabledPaymentButton"
                                                                      : "action-to-updated-payment-status"
                                                                  }`}
                                                                  style={{
                                                                    cursor:
                                                                      item
                                                                        ?.disputeId
                                                                        ?.status ===
                                                                      "Resolved"
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                  }}
                                                                >
                                                                  <FaCheckCircle />
                                                                  {/* {language === "english" ? "सुलझा" : "Resolved"} */}
                                                                </span>
                                                              </Tooltip>
                                                            </div>

                                                            {/* Rejected Button */}
                                                            <div>
                                                              <Tooltip
                                                                onClick={() => {
                                                                  if (
                                                                    item
                                                                      ?.disputeId
                                                                      ?.status !==
                                                                    "Rejected"
                                                                  ) {
                                                                    handleDisputeStatus(
                                                                      item,
                                                                      "Rejected"
                                                                    );
                                                                  }
                                                                }}
                                                                placement="top"
                                                                title={
                                                                  item
                                                                    ?.disputeId
                                                                    ?.status ===
                                                                  "Rejected"
                                                                    ? language ===
                                                                      "english"
                                                                      ? "अस्वीकृत विवाद"
                                                                      : "Dispute Rejected"
                                                                    : language ===
                                                                      "english"
                                                                    ? "अस्वीकृत"
                                                                    : "Reject"
                                                                }
                                                                arrow
                                                              >
                                                                <span
                                                                  className={`${
                                                                    item
                                                                      ?.disputeId
                                                                      ?.status ===
                                                                    "Rejected"
                                                                      ? "disabledPaymentButton"
                                                                      : "action-to-updated-payment-status"
                                                                  }`}
                                                                  style={{
                                                                    cursor:
                                                                      item
                                                                        ?.disputeId
                                                                        ?.status ===
                                                                      "Rejected"
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                  }}
                                                                >
                                                                  <FaTimesCircle />
                                                                  {/* {language === "english" ? "अस्वीकृत" : "Rejected"} */}
                                                                </span>
                                                              </Tooltip>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="no-dispute">
                                                            ---
                                                          </div>
                                                        )}
                                                      </>
                                                    )
                                                  ) : (
                                                    <div className="no-dispute">
                                                      ---
                                                    </div>
                                                  )}
                                                  {/* </div> */}
                                                </td>
                                              </tr>
                                            </tbody>{" "}
                                          </>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="15"
                                          className="no-data-row"
                                          style={{
                                            textAlign: "center",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          No data
                                        </td>
                                      </tr>
                                    )}
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* CLOSED */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewDisputeModal
          show={show} // Pass modal visibility as prop
          handleClose={handleClose} // Pass the function to close the modal
          disputeId={disputeId} // Pass the function to handle saving the dispute
          language={language == "hindi" ? "english" : "hindi"} // Pass the language for translation
        />
      </Layout>
    </div>
  );
};

export default ViewAffiliateSocietyTransactions;
