import React, { useContext, useEffect, useState } from "react";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import "../css2/all.css";
import axios from "axios";
import "./style.css";
import { FaLocationArrow } from "react-icons/fa";
import { ThreeCircles } from "react-loader-spinner";
import { PORT } from "../Api/api";
import { GrDocumentVerified } from "react-icons/gr";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { FaHouseUser } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Layout from "../lib/Layout";
import { LanguageContext } from "../lib/LanguageContext";
import { MdPermIdentity } from "react-icons/md";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  scales,
} from "chart.js";
import SuperAdminUserCountPie from "./SuperAdminUserCountPie";
import SuperAdminAfiliateDashboard from "./SuperAdminAfiliateDashboard";
import SocietyAdminDashboard from "./SocietyAdminDashboard";
import SuperAdminAffiliateCountPie from "./SuperAdminAffiliateCountPie";
import SuperAdminTotalRevenueChart from "./SuperAdminTotalRevenueChart";
import SuperAdminTopSociety from "./SuperAdminTopSociety";
import SuperAdminTopAgency from "./SuperAdminTopAgency";
// import MonthlyJoiningsChart from "./MonthlyJoiningsChart ";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  //all months
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const { language } = useContext(LanguageContext);
  const [chartData, setChartData] = useState({
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        data: [],
        backgroundColor: ["purple", "yellow", "teal", "pink"],
        borderRadius: 5,
      },
    ],
  });
  const [chartDataBar, setChartDataBar] = useState({
    labels: ["1st Week", "2nd Week", "3rd Week", "4th Week"],
    datasets: [
      {
        data: [],
        backgroundColor: ["purple", "yellow", "teal", "pink"],
        borderRadius: 5,
      },
    ],
  });
  const [regularEntriesAttendence, setRegularEntriesAttendence] = useState({
    labels: allMonths.map((month) => month.slice(0, 3)),
    datasets: [
      {
        label: "Dataset 1",

        data: [],

        borderColor: "rgb(255,99,132)",
      },
    ],
  });
  console.log(regularEntriesAttendence)
  const getRoleId = JSON.parse(localStorage.getItem("roleId"));
  const society_id = JSON.parse(localStorage.getItem("society_id")) || null;
  const roleType = JSON.parse(localStorage.getItem("role"));
  const [valueData, setValueData] = useState();
  const [monthlyDataGuest, setMonthlyDataGuest] = useState();
  const [monthDataRegular, setmonthDataRegular] = useState();
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [regularEntries, setRegularEntries] = useState([]);
  const [typeOfEntries, setTypeOfEntries] = useState([]);
  const [houseDetails, setHouseDetails] = useState([]);
  const [purposeData, setPurposeData] = useState([]);
  const [entriesLengthBySuperAdmin, setEntriesLengthBySuperAdmin] = useState(0);
  const [purposeLengthBySuperAdmin, setPurposeLengthBySuperAdmin] = useState(0);
  const [userLength, setUserLength] = useState([]);
  const [regularChartLoading, setRegularChartLoading] = useState(true);
  const [regularEntriesData, setRegularEntriesData] = useState();
  const [regularEntriesDataList, setRegularEntriesDataList] = useState();
  const [regularEntriesName, setRegularEntriesName] = useState();
  const [verifyHouseMaidLength, setVerifyHouseMaidLength] = useState([]);
  const navigate = useNavigate();
  const thisYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(thisYear);
  const [societyYearlyData, setSocietyYearlyData] = useState({});
  const [loadingRegularEntries, setLoadingRegularEntries] = useState(true);
  const id = JSON.parse(localStorage.getItem("roleId"));
  const [societyDataLength, setSocietyDataLength] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [regularLoading, setRegularLoading] = useState(true)
  //formatLength
  function formatLength(length) {
    if (length >= 1000000) {
      return (length / 1000000).toFixed(1) + "M";
    } else if (length >= 1000) {
      return (length / 1000).toFixed(1) + "k";
    }
    return length.toString();
  }
  //fetchData Guest Entries
  const fetchData = async () => {
    try {
      const response = await axios.get(`${PORT}/getData`);
      const filterData = response.data.data
        .filter((item) => item.society_id === society_id)
        .filter((item) => item.status === "approved");
      const dataLength = filterData.length;
      setValueData(dataLength);

      const dataSubmittedDate = filterData.map((item) => item.submitedDate);
      const dataSubmittedDateJs = dataSubmittedDate.map((days) => {
        const newDateFormat = days.replace(/-/g, "/");
        const [day, months, years] = newDateFormat.split("/");
        let date = new Date(`${years}-${months}-${day}`);
        let month = date.toLocaleString("default", { month: "long" });
        let year = date.getFullYear();
        setMonthlyDataGuest(year);
        return `${month} ${year}`;
      });
      const allMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const currentYear = new Date().getFullYear();
      const monthYearCounts = {};
      allMonths.forEach((month) => {
        monthYearCounts[`${month} ${currentYear}`] = 0;
      });

      dataSubmittedDateJs.forEach((date) => {
        const monthYear = new Date(date).toLocaleDateString("en-US", {
          month: "long",
          year: "numeric",
        });
        monthYearCounts[monthYear] += 1;
      });

      const result = {};
      Object.keys(monthYearCounts).forEach((monthYear) => {
        const [month, year] = monthYear.split(" ");
        if (!result[month]) {
          result[month] = {};
        }
        result[month] = monthYearCounts[monthYear];
      });
      setChartDataLoading(false);
      setChartData({
        labels: allMonths.map((month) => month.substring(0, 3)),
        datasets: [
          {
            data: allMonths.map((month) => result[month] || 0),

            backgroundColor: [
              "purple",
              "yellow",
              "teal",
              "pink",
              "orange",
              "green",
              "blue",
              "red",
              "brown",
              "cyan",
              "magenta",
              "gray",
            ],
            borderRadius: 5,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //fetchDataForRequest
  const fetchDataForRequest = async () => {
    try {
      const response = await axios.get(`${PORT}/getData`);
      const filterData = response.data.data
        .filter((item) => item.society_id === society_id)
        .filter((item) => item.status === "approved");
      // Create an object to store data for each week
      const dataSubmittedDate = filterData.map((item) => {
        const newDateFormat = item.submitedDate?.replace(/-/g, "/");
        const [day, months, years] = newDateFormat.split("/");
        setmonthDataRegular(years);
        let date = new Date(`${years}-${months}-${day}`);
        const parsedDate = new Date(date);
        return parsedDate instanceof Date && !isNaN(parsedDate)
          ? parsedDate
          : null;
      });

      let latestMonth = null;
      const allWeeks = ["1st Week", "2nd Week", "3rd Week", "4th Week"];

      // Find the latest month
      dataSubmittedDate.forEach((date) => {
        if (date) {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const monthKey = `${year}-${month}`;

          if (!latestMonth || latestMonth.key < monthKey) {
            latestMonth = { key: monthKey, year, month };
          }
        }
      });

      const latestMonthData = dataSubmittedDate.filter((date) => {
        if (date) {
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const monthKey = `${year}-${month}`;
          return monthKey === latestMonth.key;
        }
        return false;
      });

      const weekData = allWeeks.map((week, index) => {
        const weekNumber = index + 1;
        const entriesForWeek = latestMonthData.filter((date) => {
          const dateWeekNumber = Math.ceil(date.getDate() / 7);
          return dateWeekNumber === weekNumber;
        });
        return entriesForWeek.length;
      });

      const chartData = {
        labels: allWeeks,
        datasets: [
          {
            data: weekData,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderWidth: 2,
            borderColor: "black",
            tension: 0.4,
          },
        ],
      };

      setChartDataBar(chartData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // handle entries
  const getEntries = async () => {
    try {
      let response = await axios.get(`${PORT}/getEntries`);
      const responseData = response.data.data;
      const filterData = responseData.filter(
        (item) => item.society_id === society_id
      );
      setData(filterData);
      setTypeOfEntries(filterData);
      const entriesLengthForSuperAdmin = responseData.filter(
        (item) => item.defaultPermissionLevel == "1"
      );
      setEntriesLengthBySuperAdmin(
        formatLength(entriesLengthForSuperAdmin.length)
      );
    } catch (error) {
      console.log(error);
    }
  };
  //get house Details
  const handleData = async () => {
    try {
      const response = await axios.get(`${PORT}/getHouseDetails`);
      const responseData = response.data.data;
      const filterData = responseData.filter(
        (item) => item.society_id === society_id
      );

      setHouseDetails(filterData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //get purpose list
  const handleSubmit = async () => {
    try {
      const result = await axios.get(`${PORT}/getUserNonVerfiedPrupose`);
      const responseData = result.data.data;
      const filterData = responseData.filter(
        (item) => item.society_id === society_id
      );

      setPurposeData(filterData);
      const purposeLengthForSuperAdmin = responseData.filter(
        (item) => item.defaultPermissionLevel == "1"
      );
      setPurposeLengthBySuperAdmin(
        formatLength(purposeLengthForSuperAdmin.length)
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //Regular Entries Graph
  //getEntriesForAttendence
  const getEntriesForAttendence = async () => {
    try {
      // let response = await axios.get(`${PORT}/getEntries`);
      let response = await axios.get(
        `${PORT}/getRegularEntriesOfSociety/${society_id}`
      );
      const responseData = response.data.data;
      setRegularEntriesData(responseData);
      // console.log('regularEntry Data',responseData)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getEntriesForAttendence();
  }, [data]);
  // Usage example:
  const regularEntriesLength = regularEntries?.length || 0;
  const regularFormattedLength = formatLength(regularEntriesLength);
  const purposeDataLength = purposeData?.length || 0;
  const purposeFormatLength = formatLength(purposeDataLength);
  const houseDetailsLength = houseDetails?.length || 0;
  const houseDetailsFormatLength = formatLength(houseDetailsLength);
  const typeOfEntriesLength = typeOfEntries?.length || 0;
  const typeOfEntriesFormatLength = formatLength(typeOfEntriesLength);
  //fetchSocityYearlyData
  const fetchSocityYearlyData = async () => {
    try {
      const response = await axios.get(`${PORT}/getSocietyYearlyData`);
      const data = response.data.societyDataByYear || {};
      // console.log(data);
      setSocietyYearlyData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching society data:", error);
      setIsLoading(false);
      setIsLoading(false);
    }
  };
  const selectedYearData = societyYearlyData[selectedYear] || [];
  const sponseredList = [];
  const nonSponseredList = [];
  if (selectedYearData.monthlyData) {
    // console.log('selectedYearData', selectedYearData.monthlyData["January"]);
    const monthly = {};
    const sortedMonthlyData = allMonths.map((month) => {
      const monthData = societyYearlyData[selectedYear]?.monthlyData[month] || {
        sponsored: 0,
        nonSponsored: 0,
      };
      sponseredList.push(monthData.monthlySponsoredSocieties || 0);
      nonSponseredList.push(monthData.monthlyNonSponsoredSocieties || 0);
      return {
        month,
        sponsored: monthData.monthlySponsoredSocieties || 0,
        nonSponsored: monthData.monthlyNonSponsoredSocieties || 0,
      };
    });

    // console.log("monthly data:", sortedMonthlyData,sponseredList,nonSponseredList);
  }
  //getSignUpUser
  const getSignUpUser = async () => {
    try {
      await axios.get(`${PORT}/getSignUpUser`).then(async (res) => {
        const response = await res.data.userData;
        const filterData = await response.filter(
          (item) => item.createdBy === getRoleId
        );
        setUserLength(filterData);
      });
    } catch (error) { }
  };
  const barData = {
    labels: allMonths, // X-axis labels
    datasets: [
      {
        label: "Sponsored Societies",
        data: sponseredList, // Data for the first dataset
        backgroundColor: "rgba(255, 99, 132, 0.2)", // Bar color for Dataset 1
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        barBorderRadius: 5,
      },
      {
        label: "Non-Sponsored Societies",
        data: nonSponseredList, // Data for the second dataset
        backgroundColor: "rgba(54, 162, 235, 0.2)", // Bar color for Dataset 2
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        barBorderRadius: 4,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      // title: {
      //   display: true,
      //   text: "Sponsored or Non-Sponsored Societies Overview",
      // },
      tooltip: {
        callbacks: {
          label: (context) => {
            const monthName = context.label; // Name of the hovered month
            const societies =
              societyYearlyData[selectedYear]?.monthlyData[monthName]
                ?.societies; // Get societies for the month

            if (!societies || societies.length === 0) {
              return [`No societies in ${monthName}`]; // Handle case where there are no societies
            }

            // Filter societies based on datasetIndex (Sponsored or Non-Sponsored)
            let filteredSocieties = [];
            if (context.datasetIndex === 0) {
              // Sponsored Societies
              filteredSocieties = societies.filter(
                (society) => society?.affiliateId
              );
            } else if (context.datasetIndex === 1) {
              // Non-Sponsored Societies
              filteredSocieties = societies.filter(
                (society) => !society?.affiliateId
              );
            }

            // Calculate the total count of societies for the dataset
            const totalCount = filteredSocieties.length;

            // List societies for the dataset
            const societyList = filteredSocieties.map(
              (society) =>
                `• ${society.name} ${context.datasetIndex === 0
                  ? `(${society.affiliateId.agencyName || "N/A"})`
                  : ""
                }`
            );

            // Return the total count and the list of societies
            return [
              `${context.datasetIndex === 0 ? "Sponsored" : "Non-Sponsored"
              } Societies in ${monthName} (Total: ${totalCount}):`,
              ...societyList,
            ];
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          autoSkip: false,
        },
      },
    },
  };

  const currentYear = new Date().getFullYear(); // Get the current year
  const previousYear = currentYear - 1; // Previous year
  const previousToPreviousYear = currentYear - 2;

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };
  // function lock
  // Fetch Regular Data
  useEffect(() => {
    const filteredEntries = data.filter((item) => item.entryType === "Regular");
    setRegularEntries(filteredEntries);
  }, [data]);
  //Fetch Society Data
  useEffect(() => {
    fetchSocityYearlyData();
    const getSocietyData = async () => {
      try {
        const response = await axios.get(`${PORT}/getSocietyData`);
        const data = response.data.societyData;
        setSocietyDataLength(data);
      } catch { }
    };
    getSocietyData();
  }, []);
  //Loader timer
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingRegularEntries(false);
    }, 4000); // 4 seconds

    return () => clearTimeout(timer);
  }, []);
  // Fetch Regular Data
  useEffect(() => {
    // Avoid unnecessary processing if no data is available

    if (!regularEntriesData || regularEntriesData.length === 0) {
      setRegularLoading(false); // If no data, stop the loader and show no data
      return;
    }

    const filterData = regularEntriesData;

    // Create the formatted date and entry names
    const dataName = filterData.map((item) => {
      const newDateFormat = item?.joiningDate?.replace(/-/g, "/");
      const [day, months, years] = newDateFormat.split("/");
      const formattedDate = new Date(`${years}-${months}-${day}`);
      const month = formattedDate.toLocaleString("default", { month: "long" });
      const year = formattedDate.getFullYear();
      return [item.titleEnglish, month];
    });

    setRegularEntriesName(dataName);

    const dataId = filterData.map((item) => item._id);

    const fetchData = async () => {
      setRegularLoading(true)
      try {
        // Optimized: Fetch data in parallel for all ids
        if (dataId && Array.isArray(dataId)) {
          const responses = await axios.all(
            dataId.map((id) => axios.get(`${PORT}/getVerifieUser/${id}`))
          );

          const lengths = responses.map((res) => res.data.verifyHouseMaid.length);
          const getSubmitedDate = responses.map((res) => res.data.verifyHouseMaid);

          setVerifyHouseMaidLength(lengths);
          setRegularLoading(false)
        }
      } catch (error) {
        setRegularLoading(false)
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Process datasets after data fetching
    const datasets = dataName.map((user, index) => {
      return {
        label: user[0],
        data: allMonths.map((month) => {
          // Only match the relevant months
          const dataIndex = dataName.filter(([_, m]) => m === month);
          return dataIndex.length > 0 ? verifyHouseMaidLength[index] : 0;
        }),
        borderColor: [
          "purple", "yellow", "teal", "pink", "orange", "green", "blue",
          "red", "brown", "cyan", "magenta", "gray"
        ],
        tension: 0.4,
      };
    });

    setRegularEntriesAttendence({
      ...regularEntriesAttendence,
      datasets: datasets,
    });
    setRegularLoading(false)
  }, [data]); // Added data as dependency to trigger the effect when it changes

  useEffect(() => {
    getEntries();
    fetchDataForRequest();
    fetchData();
    handleData();
    handleSubmit();
    getSignUpUser();
  }, []);
  return (
    <Layout>
      <div>
        {roleType === 1 || roleType === 2 ? (
          <>
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div className="card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                              { }
                              {language === "hindi" ? "Society" : " सोसाइटी"}
                            </p>
                            <h5 className="font-weight-bolder">
                              {societyDataLength?.length}
                            </h5>
                            <p className="mb-0">
                              <span className="text-success text-sm font-weight-bolder">
                                {language === "hindi" ? "Society" : " सोसाइटी"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div
                            className="icon icon-shape 
                        bg-gradient-primary shadow-primary text-center rounded-circle"
                          >
                            <FaHouseUser className="dashboard_icons"></FaHouseUser>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div className="card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0 text-uppercase font-weight-bold">
                              {language === "hindi" ? " User" : "  उपयोगकर्ता"}
                            </p>
                            <h5 className="font-weight-bolder">
                              {userLength?.length}
                            </h5>
                            <p className="mb-0">
                              <span className="text-success text-sm font-weight-bolder">
                                {language === "hindi"
                                  ? " User"
                                  : "  उपयोगकर्ता"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                            <MdPermIdentity className="dashboard_icons" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div className="card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                              {language === "hindi"
                                ? "Type of Entries"
                                : "  प्रविष्टियों का प्रकार"}
                            </p>
                            <h5 className="font-weight-bolder">
                              {entriesLengthBySuperAdmin}
                            </h5>
                            <p className="mb-0">
                              <span className="text-success text-sm font-weight-bolder">
                                {language === "hindi"
                                  ? "Type of Entries"
                                  : "  प्रविष्टियों का प्रकार"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                            <FaLocationArrow className="dashboard_icons" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                  <div className="card">
                    <div className="card-body p-3">
                      <div className="row">
                        <div className="col-8">
                          <div className="numbers">
                            <p className="text-sm mb-0 text-uppercase font-weight-bold">
                              {language === "hindi" ? " Purpose" : "  उद्देश्य"}
                            </p>
                            <h5 className="font-weight-bolder">
                              {purposeLengthBySuperAdmin}
                            </h5>
                            <p className="mb-0">
                              <span className="text-success text-sm font-weight-bolder">
                                {language === "hindi"
                                  ? " Purpose"
                                  : "  उद्देश्य"}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col-4 text-end">
                          <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                            <AiOutlineQuestionCircle className="dashboard_icons" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* // ChartJS */}
              <div className="row mt-4 ">
                <br />
                <br />
                <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
                  <div className="card z-index-2 h-100">
                    <div className="mx-3 mt-3">
                      <label className="me-2">
                        {language === "hindi"
                          ? ` Select Year : `
                          : ` वर्ष चुनें : `}
                      </label>
                      <select value={selectedYear} onChange={handleYearChange}>
                        <option value={previousToPreviousYear}>
                          {previousToPreviousYear}
                        </option>
                        <option value={previousYear}>{previousYear}</option>
                        <option value={currentYear}>{currentYear}</option>
                      </select>
                    </div>
                    <div className="card-header pb-0 pt-3 bg-transparent">
                      <h6 className="text-capitalize">
                        {/* {language === "hindi" ? "Society" : " सोसाइटी"} */}
                      </h6>
                      <p className="text-sm mb-0">
                        <i className="fa fa-arrow-up text-success"></i>
                        {/* <span className="font-weight-bold"></span> in{" "}
                        {societyYear} */}
                      </p>
                      {isLoading ? (
                        <div className="chartdata_loader">
                          <ThreeCircles
                            visible={true}
                            height={50}
                            width={50}
                            color="#5e72e4"
                            ariaLabel="three-circles-loading"
                          />
                        </div>
                      ) : societyYearlyData[selectedYear] ? (
                        <div>
                          <h6 style={{ textAlign: "center" }}>
                            {language === "english"
                              ? "प्रायोजित या गैर-प्रायोजित समाजों का अवलोकन"
                              : "Sponsored or Non-Sponsored Societies Overview"}
                          </h6>
                          <Bar data={barData} options={barOptions} />
                        </div>
                      ) : (
                        <div className="no-data-chart">
                          <p>No Data Found</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* affiliateData show all user count show */}
              <div className="row  h-50 ">
                <br />
                <br />
                <div className="col-lg-6 mb-lg-0 mb-4 mt-4">
                  <SuperAdminUserCountPie />
                </div>
                <div className="col-lg-6 mb-lg-0 mb-4 mt-4">
                  <SuperAdminTotalRevenueChart />
                </div>
              </div>
              {/* affiliate commssioni dashboard */}
              <div className="row ">
                <br />
                <br />
                <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
                  <SuperAdminAfiliateDashboard />
                </div>
              </div>
              {/* affiliate count */}
              <div className="row ">
                <br />
                <br />
                <div className="col-lg-6 mb-lg-0 mb-4 mt-4">
                  <SuperAdminAffiliateCountPie />
                </div>
                {/* top society commission */}
                <div className="col-lg-6 mb-lg-0 mb-4 mt-4">
                  <SuperAdminTopSociety />
                </div>
              </div>
              {/* top agency commission */}
              <div className="row ">
                <br />
                <br />
                <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
                  <SuperAdminTopAgency />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="container-fluid py-4">
            {/* <SuperAdminAfiliateDashboard/> */}{" "}
            <div className="row">
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                            { }
                            {language === "hindi"
                              ? "Regular Entries"
                              : " नियमित प्रविष्टियाँ "}
                          </p>
                          <h5 className="font-weight-bolder">
                            {regularFormattedLength}
                          </h5>
                          <p className="mb-0">
                            <span className="text-success text-sm font-weight-bolder">
                              {language === "hindi"
                                ? "Regular Entries"
                                : " नियमित प्रविष्टियाँ "}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div
                          className="icon icon-shape 
                        bg-gradient-primary shadow-primary text-center rounded-circle"
                        >
                          <GrDocumentVerified className="dashboard_icons"></GrDocumentVerified>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0  numbers_heading text-uppercase font-weight-bold">
                            {language === "hindi"
                              ? "Type of Entries"
                              : "  प्रविष्टियों का प्रकार"}
                          </p>
                          <h5 className="font-weight-bolder">
                            {typeOfEntriesFormatLength}
                          </h5>
                          <p className="mb-0">
                            <span className="text-success text-sm font-weight-bolder">
                              {language === "hindi"
                                ? "Type of Entries"
                                : "  प्रविष्टियों का प्रकार"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape bg-gradient-danger shadow-danger text-center rounded-circle">
                          <FaLocationArrow className="dashboard_icons" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-uppercase font-weight-bold">
                            {language === "hindi" ? " Purpose" : "  उद्देश्य"}
                          </p>
                          <h5 className="font-weight-bolder">
                            {purposeFormatLength}
                          </h5>
                          <p className="mb-0">
                            <span className="text-success text-sm font-weight-bolder">
                              {language === "hindi" ? " Purpose" : "  उद्देश्य"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape bg-gradient-success shadow-success text-center rounded-circle">
                          <AiOutlineQuestionCircle className="dashboard_icons" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6">
                <div className="card">
                  <div className="card-body p-3">
                    <div className="row">
                      <div className="col-8">
                        <div className="numbers">
                          <p className="text-sm mb-0 text-uppercase font-weight-bold">
                            {language === "hindi"
                              ? " House List"
                              : "     घर सूची"}
                          </p>
                          <h5 className="font-weight-bolder">
                            {houseDetailsFormatLength}
                          </h5>
                          <p className="mb-0">
                            <span className="text-success text-sm font-weight-bolder">
                              {language === "hindi"
                                ? " House List"
                                : "     घर सूची"}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-4 text-end">
                        <div className="icon icon-shape bg-gradient-warning shadow-warning text-center rounded-circle">
                          <FaHouseUser className="dashboard_icons" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* // ChartJS */}
            <div className="row mt-4 ">
              <div className="col-lg-6 mb-lg-0 mb-4">
                <div className="card z-index-2 h-100">
                  <div className="card-header pb-0 pt-3 bg-transparent">
                    <h6 className="text-capitalize">
                      {language === "hindi"
                        ? "Monthly Guest Entries Requests"
                        : "मासिक अतिथि प्रविष्टि अनुरोध"}
                    </h6>
                    <p className="text-sm mb-0">
                      <i className="fa fa-arrow-up text-success"></i>
                      <span className="font-weight-bold"></span> in{" "}
                      {monthlyDataGuest}
                    </p>
                    {chartDataLoading ? (
                      <div className="chartdata_loader">
                        <ThreeCircles
                          visible={true}
                          height={50}
                          width={50}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : (
                      <Bar
                        className="a"
                        data={chartData}
                        options={{
                          scales: {
                            x: {
                              ticks: {
                                color: "black",
                              },
                            },
                            y: {
                              min: 0,
                              ticks: {
                                color: "black",
                              },
                            },
                          },
                          responsive: true,
                          plugins: {
                            legend: {
                              labels: {
                                color: "black",
                              },
                              display: false,
                            },
                            title: {
                              display: true,
                              text:
                                language === "hindi"
                                  ? "Monthly Guest Entries Requests"
                                  : "मासिक अतिथि प्रविष्टि अनुरोध",
                              color: "black",
                            },
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-lg-0 mb-4">
                <div className="card z-index-2 h-100">
                  <div className="card-header pb-0 pt-3 bg-transparent">
                    <h6 className="text-capitalize">
                      {language === "hindi"
                        ? " Weekly Guest Entries Requests"
                        : "साप्ताहिक अतिथि प्रविष्टि अनुरोध"}
                    </h6>
                    <p className="text-sm mb-0">
                      <i className="fa fa-arrow-up text-success"></i>
                      <span className="font-weight-bold"></span> in{" "}
                      {monthlyDataGuest}
                    </p>
                    {chartDataLoading ? (
                      <div className="chartdata_loader">
                        <ThreeCircles
                          visible={true}
                          height={50}
                          width={50}
                          color="#5e72e4"
                          ariaLabel="three-circles-loading"
                        />
                      </div>
                    ) : (
                      <Line
                        data={chartDataBar}
                        options={{
                          scales: {
                            x: {
                              ticks: {
                                color: "black",
                                stepSize: 1, // Set the step size for Y-axis ticks (1, 2, 3, ...)
                                beginAtZero: true, // Ensure the Y-axis starts at zero
                              },
                            },
                            y: {
                              min: 0, // Ensure Y-axis starts from 0
                              ticks: {
                                color: "black",
                                stepSize: 1,
                                beginAtZero: true,
                                callback: function (value) {
                                  return Number.isInteger(value) ? value : "";
                                },
                              },

                              suggestedMin: 0,
                              suggestedMax: 10,
                            },
                          },
                          responsive: true,
                          plugins: {
                            legend: {
                              labels: {
                                color: "black",
                              },
                              display: false,
                            },
                            title: {
                              display: true,
                              text:
                                language === "hindi"
                                  ? " Weekly Guest Entries Requests"
                                  : "साप्ताहिक अतिथि प्रविष्टि अनुरोध",
                              color: "black",
                            },
                          },
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="col-lg-12 mb-lg-0 mb-4 mt-4">
                <div className="card z-index-2 h-100">
                  <div className="card-header pb-0 pt-3 bg-transparent">
                    <h6 className="text-capitalize">
                      {language === "hindi"
                        ? "Regular Entries Attendance"
                        : " नियमित प्रविष्टियाँ उपस्थिति"}
                    </h6>
                    <p className="text-sm mb-0">
                      <i className="fa fa-arrow-up text-success"></i>
                      <span className="font-weight-bold"></span> in{" "}
                      {monthDataRegular}
                    </p>
                    {regularLoading ? <div className="chartdata_loader">
                      <ThreeCircles
                        visible={true}
                        height={50}
                        width={50}
                        color="#5e72e4"
                        ariaLabel="three-circles-loading"
                      />
                    </div> : regularEntriesAttendence?.datasets?.length < 0 ? (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                        <p>No data available</p>
                      </div>) :
                      <Line
                        data={regularEntriesAttendence}
                        options={{
                          scales: {
                            x: {
                              ticks: {
                                color: "black",
                              },
                            },
                            y: {
                              min: 0, // Ensure Y-axis starts from 0
                              ticks: {
                                color: "black",
                                stepSize: 1,
                                beginAtZero: true,
                                callback: function (value) {
                                  return Number.isInteger(value) ? value : "";
                                },
                              },
                              suggestedMin: 0,
                              suggestedMax: 10,
                            },
                          },
                          responsive: true,
                          plugins: {
                            legend: {
                              labels: {
                                color: "black",
                              },
                            },
                          },
                        }}
                      />}

                  </div>
                </div>
              </div>
              <SocietyAdminDashboard society_id={society_id} />
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
