import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { PORT } from "../../../../Api/api";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../lib/Layout";
import { LanguageContext } from "../../../../lib/LanguageContext";
import AddBackbtn from "../../../../lib/AddBackbtn";
import EditUserModalView from "../editAffiliateUserModalView/EditUserModalView";
import ButtonLoader from "../../../../Loading/ButtonLoader";
const AddAffiliateUser = () => {
    //initial form data
    const initialFormData = {
        agencyName: "",
        contactPerson: "",
        address: "",
        email: "",
        phone: "",
        password: "",
        documentation: "",
    };
    const [step, setStep] = useState(1);
    const { language } = useContext(LanguageContext);
    const [affiliateUser, setAffiliateUser] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const nextStep = () => {
        if (validateStep()) {
            setStep(step + 1);
        }
    };
    const prevStep = () => setStep(step - 1);

    const handleSubmit = async (e) => {
        setSubmitting(true);
        setDisabled(true);
        e.preventDefault();
        const data = new FormData();
        data.append("agencyName", formData.agencyName);
        data.append("contactPerson", formData.contactPerson);
        data.append("email", formData.email);
        data.append("phone", formData.phone);
        data.append("password", formData.password);
        data.append("address", formData.address);
        data.append("status", "Approved")
        if (formData.documentation) {
            data.append("documentation", formData.documentation);
        }
        try {
            const response = await axios.post(`${PORT}/affiliateRegister`, data);
            toast.success(response.data.message);
            setTimeout(() => {
                navigate("/admin/viewAffiliateUser");
                resetForm();
            }, 1000);
        } catch (error) {
            toast.error(error.response.data.message);
            setSubmitting(false);
            setDisabled(false);
        } finally {
            setSubmitting(false);
        }
    };
    const resetForm = () => {
        setFormData(initialFormData);
    };
    const handleEyeClick = (e) => {
        e.stopPropagation();
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the file object
        setFormData({ ...formData, documentation: file });
    };
    const validateStep = () => {
        if (step === 1) {
            if (
                !formData.agencyName ||
                !formData.contactPerson ||
                !formData.address ||
                !formData.documentation
            ) {
                toast.error(
                    language === "english"
                        ? "कृपया सभी आवश्यक क्षेत्र भरें"
                        : "Please fill in all required fields."
                );
                return false;
            }
        } else if (step === 2) {
            if (!formData.email || !formData.phone || !formData.password) {
                toast.error(
                    language === "english"
                        ? "कृपया सभी आवश्यक संपर्क जानकारी भरें"
                        : "Please fill in all required contact information."
                );
                return false;
            }
        }
        return true;
    };
    return (
        <Layout>
            <br />
            <div className="table_heading">
                <h5 className="heading_top">
                    {language === "hindi"
                        ? "Add Affiliate Users"
                        : "एफिलिएट उपयोगकर्ताओं को जोड़ें"}
                </h5>
                <div className="hrline"></div>
            </div>
            <AddBackbtn />

            <div className="affiliate-user-container">
                {/* Progress Indicator */}
                <div className="progress-indicator-user">
                    {[1, 2].map((item) => (
                        <div
                            key={item}
                            className={`progress-step-user ${step >= item ? "activeLineUser" : ""
                                }`}
                        >
                            <div className="circle-user">{item}</div>
                            {item < 2 && <div className="line-user"></div>}
                        </div>
                    ))}
                </div>

                <form className="form-container-affiliate-user" onSubmit={handleSubmit}>
                    {step === 1 && (
                        <div className="form-step-user">
                            <label>
                                {language === "hindi" ? " Agency Name:" : "एजेंसी का नाम:"}
                                <input
                                    type="text"
                                    name="agencyName"
                                    value={formData.agencyName}
                                    onChange={handleChange}
                                    required
                                    placeholder="Enter agency name"
                                    maxLength={50}
                                />
                            </label>
                            <label>
                                {language === "hindi" ? "  Contact Person:" : "संपर्क व्यक्ति:"}
                                <input
                                    type="text"
                                    name="contactPerson"
                                    value={formData.contactPerson}
                                    onChange={handleChange}
                                    required
                                    maxLength={50}
                                    placeholder="Enter contact person's name"
                                />
                            </label>
                            <label>
                                Address:
                                <textarea
                                    name="address"
                                    rows={5}
                                    value={formData.address}
                                    onChange={handleChange}
                                    required
                                    minLength="200"
                                    placeholder="Enter Agency Address"
                                />
                            </label>
                            <label>
                                {language === "hindi" ? "   Documentation:" : " दस्तावेज़ीकरण:"}
                            </label>
                            <div className="input-wrapper-user">
                                <input
                                    type="file"
                                    name="documentation"
                                    onChange={handleFileChange}
                                    required
                                />
                                <div className="eye-button-edit-user" onClick={handleEyeClick}>
                                    <EditUserModalView data={affiliateUser} />
                                </div>
                            </div>

                            <button type="button" onClick={nextStep}>
                                {language === "hindi" ? "   Next" : "अगला"}
                            </button>
                        </div>
                    )}

                    {step === 2 && (
                        <div className="form-step">
                            {/* <h3>Contact Information</h3> */}
                            <label>
                                {language === "hindi" ? "    Email:" : "ईमेल:"}
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    placeholder="Enter your email address"
                                />
                            </label>
                            <label>
                                {language === "hindi" ? " Phone:" : "फोन:"}
                                <input
                                    type="number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                                        if (value.length <= 10) {
                                            handleChange(e);
                                        }
                                    }}
                                    maxLength={10}
                                    placeholder="Enter a 10-digit phone number"
                                />
                            </label>
                            <label>
                                {language === "hindi" ? " Password:" : " पासवर्ड:"}
                                <input
                                    type="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                    minLength="6"
                                    placeholder="Enter at least 6 characters"
                                />
                            </label>

                            <button type="button" onClick={prevStep}>
                                {language === "hindi" ? " Previous" : "  पिछला"}
                            </button>
                            <button type="submit" disabled={isDisabled}>
                                {isSubmitting ? (
                                    <ButtonLoader height={25} width={25} />
                                ) : language === "hindi" ? (
                                    " Submit"
                                ) : (
                                    "सबमिट करें"
                                )}
                            </button>
                        </div>
                    )}
                </form>
            </div>
            <ToastContainer />
        </Layout>
    );
};

export default AddAffiliateUser;
