import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MdDashboard } from "react-icons/md";
import Swal from "sweetalert2";
import { useContext } from "react";
import { LanguageContext } from "../../../../lib/LanguageContext";
import { FaUserPlus } from "react-icons/fa";
import "./style.css";
import { FaFileContract } from "react-icons/fa";
import checkTokenExpiration from "../../affiliateLib/destroyAuth/DestroyAuth";
import decodeToken from "../../affiliateLib/decodeToken/DecodeToken";
import { FaExchangeAlt } from "react-icons/fa";
import { MdReportProblem } from "react-icons/md";
import { PORT } from "../../../../Api/api";
import axios from "axios";

const AffiliateSidebar = () => {
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const decodedToken = decodeToken(token) || null;
  let isActive = false;
  if (decodedToken?.status == "Approved") {
    isActive = true;
  }
  // const CheckAccount=decodeToken(token)
  // console.log('ddsdsds',decodedToken);
  const AffiliateUserId = decodedToken?.id; // Replace with the logged-in user's ID
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .post(`${PORT}checkIsActiveUser`, { AffiliateUserId })
        .then((response) => {
          // console.log(response.data);
          let token = response.data.token;
          if (
            decodedToken?.status !== "Approved" &&
            response.data.active == "Approved"
          ) {
            localStorage.setItem("token", token);
            Swal.fire("Success", "Your account has been Approved.", "success");
            setTimeout(() => {
              window.location.href = "/affiliate/affiliateDashboard"; // Redirect to dashboard
            }, 1000);
          }
          if (response.data.active == "Rejected") {
            localStorage.removeItem("token"); // Remove the token if the account is rejected
            Swal.fire(
              "Error",
              "Your account has been rejected. Please contact our support team for more information.",
              "error"
            );
            setTimeout(() => {
              window.location.href = "/affiliateLogin"; // Redirect to login page
            }, 1500);
          }
          if (
            decodedToken?.status !== "Pending" &&
            response.data.active == "Pending"
          ) {
            localStorage.removeItem("token"); // Remove the token if the account is rejected
            Swal.fire(
              "Warning",
              "Your account is pending approval. Please check your email for approval notification.",
              "warning"
            );
            setTimeout(() => {
              window.location.href = "/affiliateLogin"; // Redirect to login page
            }, 1500);
          }

          if (response.data.active == false) {
            localStorage.removeItem("token"); // Remove the token if the account is rejected
            Swal.fire(
              "Error",
              "Your account is inactive. Please contact our support team for more information.",
              "error"
            );
            setTimeout(() => {
              window.location.href = "/affiliateLogin"; // Redirect to login page
            }, 1500);
          }
        });
    }, 15000); // Poll every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  //Check the Token is Expire Or Not
  useEffect(() => {
    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 1000);
    return () => clearInterval(interval);
  }, [navigate]);
  // correct code
  // useEffect(() => {
  //   const resetSession = () => {
  //     const now = new Date().getTime();
  //     localStorage.setItem("lastActivity", now); // Update last activity on user interaction
  //   };
  //   const checkTokenExpiration = () => {
  //     const token = localStorage.getItem("token");
  //     const lastActivity = localStorage.getItem("lastActivity");
  //     const lastLogout = localStorage.getItem("lastLogout");
  //     const now = new Date().getTime();
  //     const TIMEOUT_DURATION = 3 * 60 * 60 * 1000; // 3 hours in milliseconds for better testing
  //     // const TIMEOUT_DURATION = 25 * 1000; // 25 seconds in milliseconds

  //     if (token) {
  //       // If it's the first login (no lastLogout value), handle it differently
  //       if (!lastLogout) {
  //         // If there is no `lastLogout`, consider it as the first login, so no timeout.
  //         localStorage.setItem("lastLogout", now); // Set lastLogout on first login
  //       }

  //       // Check if last activity or last logout time exceeds the inactivity duration
  //       if (
  //         (lastActivity && now - parseInt(lastActivity) >= TIMEOUT_DURATION) ||
  //         (lastLogout && now - parseInt(lastLogout) >= TIMEOUT_DURATION)
  //       ) {
  //         expireSession(); // Call the expire session function
  //       }
  //     }
  //   };

  //   const expireSession = () => {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("lastActivity");
  //     localStorage.removeItem("lastLogout");
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Session Expired",
  //       text: "Your session has expired due to inactivity. Please log in again.",
  //       confirmButtonText: "OK",
  //     }).then(() => {
  //       localStorage.clear();
  //       navigate("/affiliateLogin"); // Redirect to login after session expiration
  //     });
  //   };

  //   // Set lastActivity on page load if not already set
  //   if (!localStorage.getItem("lastActivity")) {
  //     localStorage.setItem("lastActivity", new Date().getTime());
  //   }

  //   // Listen for user activity and reset session timer
  //   window.addEventListener("click", resetSession);
  //   window.addEventListener("keydown", resetSession);
  //   window.addEventListener("scroll", resetSession);
  //   window.addEventListener("mousemove", resetSession);

  //   // Store logout time when tab is closed
  //   const handleBeforeUnload = () => {
  //     localStorage.setItem("lastLogout", new Date().getTime());
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Check token expiration every minute (for quicker detection during development)
  //   const interval = setInterval(checkTokenExpiration, 60000);

  //   return () => {
  //     clearInterval(interval);
  //     window.removeEventListener("click", resetSession);
  //     window.removeEventListener("keydown", resetSession);
  //     window.removeEventListener("scroll", resetSession);
  //     window.removeEventListener("mousemove", resetSession);
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [navigate]);
  return (
    <>
      <aside
        className="sidenav bg-white navbar navbar-vertical  border-0 border-radius-xl my-3 fixed-start ms-4 "
        id="sidenav-main"
        style={{
          pointerEvents: isActive ? "auto" : "un",
          opacity: isActive ? 1 : 0.8,
          cursor: isActive ? "default" : "not-allowed",
        }}
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <a className="navbar-brand m-0 text-center" target="_blank">
            <div className="side-nav-top-logo ">
              <img src="/newLogoGuardx.png" alt="" className="" />
            </div>
            <span className="ms-1 font-weight-bold"></span>
          </a>
        </div>
        <hr className="horizontal dark mt-0" />
        <div className="affiliate-side-nav-main-div">
          <ul
            className="navbar-nav"
            style={{
              pointerEvents: isActive ? "auto" : "un",
              opacity: isActive ? 1 : 0.8,
              cursor: isActive ? "default" : "not-allowed",
            }}
          >
            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link "
                to="/affiliate/affiliateDashboard"
                style={{
                  pointerEvents: isActive ? "auto" : "none",
                  opacity: isActive ? 1 : 0.8,
                  cursor: isActive ? "default" : "not-allowed",
                }}
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <MdDashboard className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "hindi" ? " Dashboard" : " डैशबोर्ड"}
                  </span>
                </span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link"
                to="/affiliate/viewAffiliateClient"
                style={{
                  pointerEvents: isActive ? "auto" : "none",
                  opacity: isActive ? 1 : 0.8,
                  cursor: isActive ? "default" : "not-allowed",
                }}
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <FaUserPlus className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "hindi" ? "Client" : " क्लाइंट"}
                  </span>
                </span>
              </NavLink>
            </li>
            {/* Disputes */}
            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link"
                to="/affiliate/viewAffiliateDisputes"
                style={{
                  pointerEvents: isActive ? "auto" : "none",
                  opacity: isActive ? 1 : 0.8,
                  cursor: isActive ? "default" : "not-allowed",
                }}
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <MdReportProblem className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp;{" "}
                    {language === "hindi" ? "Raise Disputes" : " विवाद उठाएं"}
                  </span>
                </span>
              </NavLink>
            </li>
            {/* transactions */}
            <li className="nav-item">
              <NavLink
                className="affiliate-nav-link"
                to="/affiliate/viewAffiliateSocietyList"
                style={{
                  pointerEvents: isActive ? "auto" : "none",
                  opacity: isActive ? 1 : 0.8,
                  cursor: isActive ? "default" : "not-allowed",
                }}
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <FaExchangeAlt className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "hindi" ? "Transactions" : "लेनदेन"}
                  </span>
                </span>
              </NavLink>
            </li>
            {/* Contracts */}
            {/* <li className="nav-item">
              <NavLink
                className="affiliate-nav-link"
                to="/affiliate/viewAffiliateContracts"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center ms-2  ">
                  <i className="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                </div>
                <span className="affiliate-nav-link-text text-start">
                  <FaFileContract className="icon_font_size" />

                  <span className="nav-module-right">
                    &nbsp; {language === "english" ? "अनुबंध" : "Contracts"}
                  </span>
                </span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default AffiliateSidebar;
