import React, { useContext, useEffect, useState } from "react";
import { MdInput } from "react-icons/md";
import { MdAssignment } from "react-icons/md";
import { FaHome, FaImage, FaImages } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { FaUserCheck } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FaUser } from "react-icons/fa"; // Font Awesome
import { MdPhone } from "react-icons/md"; // Material Icons
import { MdGroup } from "react-icons/md"; // Material Icons

import {
  MdContactPhone,
  MdLocalPhone,
  MdOutlineAppRegistration,
  MdPlace,
} from "react-icons/md";
import { LanguageContext } from "../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { TbListDetails, TbPasswordUser } from "react-icons/tb";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FaHouseUser } from "react-icons/fa";

import Tooltip from "@mui/material/Tooltip";
import { FiMap } from "react-icons/fi";
import { formatDate } from "../lib/FormattedDate";
import { GoArrowDownLeft, GoArrowUpRight } from "react-icons/go";
import axios from "axios";
import { PORT } from "../Api/api";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const PreviewImage = ({ item }) => {
  const [value, setValue] = React.useState(0);
  const { language } = useContext(LanguageContext);
  const id = item.guardId;
  const [state, setState] = useState({
    right: false,
  });
  const [userData, setUserData] = useState([]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //Get User Data
  const getUserData = async () => {
    try {
      const res = await axios.get(`${PORT}/getEditUser/${id}`);
      setUserData(res.data.data || []);
    } catch (error) {}
  };
    //formatTimeWithoutSeconds
    function formatTimeWithoutSeconds(timeString) {
      // Split time into hours, minutes, and seconds
      const [hours, minutes] = timeString.split(':'); // Ignore seconds part
      const suffix = timeString.includes('pm') ? 'pm' : 'am';
    
      // Return formatted time as hh:mm AM/PM
      return `${hours}:${minutes} ${suffix}`;
    }
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            title={language === "hindi" ? "view" : "देखना"}
            placement="top"
            arrow
          >
            <div onClick={toggleDrawer(anchor, true)}>
              <h5 className=" mt-3 house-view-icon ">
                <IoEyeSharp
                  data-toggle="tooltip"
                  className="eyes_view"
                  data-placement="top"
                />
              </h5>
            </div>
          </Tooltip>
          <Drawer
            className="regular_drawer   show-top"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "100vw",
              maxWidth: "100vw",
            }}
          >
            <div className="close_btn" onClick={toggleDrawer(anchor, false)}>
              <IoMdClose className="close_icons_house" />
            </div>
            <hr className="hr_line" />
            <div className="society-view-details-main-div">
              <div className="society-view-details-top-heading">
                <div className="top-society-images-heading-icon-div">
                  <TbListDetails />
                  &nbsp;
                  {language === "hindi" ? " Entry Details" : " प्रवेश विवरण"}  
                </div>
              </div>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <MdInput />
                    &nbsp;{" "}
                    {language === "hindi" ? "Entry Type" : "प्रवेश प्रकार"}  :
                  </h3>
                  <h3 className="house_title_left">
                    <MdAssignment />
                    &nbsp;{" "}
                    {language === "hindi" ? "Purpose Type" : "पउद्देश्य प्रकार"}   :
                  </h3>
                  <h3 className="house_title_left">
                    <FaHome />
                    &nbsp;{" "}
                    {language === "hindi" ? " House Details" : "घर का विवरण"}   :
                  </h3>
                  <h3 className="house_title_left">
                    <MdDateRange />
                    &nbsp; {language === "hindi" ? "Date" : "तारीख"}   :
                  </h3>
                  <h3 className="house_title_left">
                    <FaRegClock />
                    &nbsp; {language === "hindi" ? "Clock-In" : "कार्य आरंभ"}   :
                  </h3>
                  <h3 className="house_title_left">
                    <FaRegClock />
                    &nbsp;{" "}
                    {language === "hindi" ? "Clock-Out" : "कार्य समाप्ति"}   :
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={item.id}>
                    <h3 className="house_title_left">{item.entryType}</h3>
                    <h3 className="house_title_left">
                      {item.purposeType === "null" ||
                      item.purposeType === "undefined" ? (
                        <b className="">Not Added</b>
                      ) : (
                        item.purposeType
                      )}
                    </h3>
                    <h3 className="house_title_left">
                      {" "}
                      <b className="guest-house-details-preview">Owner</b>
                      &nbsp;&nbsp;{item?.house_id?.ownerName}&nbsp;,
                      <b className="guest-house-details-preview"> House No.</b>
                      &nbsp;&nbsp;{item?.house_id?.houseNo}
                    </h3>
                    <h3 className="house_title_left">
                      {" "}
                      {item.previousDay ? (
                        <div style={{ color: "red" }}>
                          {formatDate(item.previousDay)}
                        </div>
                      ) : null}{" "}
                      &nbsp;
                      {formatDate(item.submitedDate)}
                    </h3>
                    <h3 className="house_title_left">
                      <GoArrowDownLeft className="down_icon" />{" "}
                      {formatTimeWithoutSeconds(item.submitedTime)}
                    </h3>
                    <h3 className="house_title_left">
                      {" "}
                      <GoArrowUpRight className="up_icon" />{" "}
                      {item.clockOut === "null" || !item.clockOut
                        ? "In Society"
                        : item.clockOut}
                    </h3>
                  </React.Fragment>
                </div>
              </div>
            </div>

            <List>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="society-view-details-tabs"
                  >
                    <Tab
                      label={
                        <div className="top-heading-label-title">
                          <FaImage /> &nbsp;{" "}
                          {language === "hindi" ? "Images" : "छवि"}
                        </div>
                      }
                      {...a11yProps(0)}
                    />

                    <Tab
                      label={
                        <div className="top-heading-label-title">
                          <FaImages /> &nbsp;{" "}
                          {language === "hindi" ? "Aadhaar Images" : "आधार छवि"}
                        </div>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={
                        <div className="top-heading-label-title">
                          <FaUserCheck /> &nbsp;{" "}
                          {language === "hindi"
                            ? "Entry Submitted By"
                            : "प्रवेश द्वारा प्रस्तुत"}
                        </div>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      label={
                        <div className="top-heading-label-title">
                          <FaImage /> &nbsp;{" "}
                          {language === "hindi"
                            ? "Vehicle Images"
                            : "वाहन की छवियां"}
                        </div>
                      }
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="regular-view-images-div">
                    {item?.image?.length > 0 ? (
                      Array.isArray(item.image) &&
                      item.image.map((path, index) => (
                        <img
                          key={index}
                          src={`/${path.replace("public/", "")}`}
                          alt=""
                        />
                      ))
                    ) : (
                      <div className="text-center w-100 p-4">
                        {language === "hindi" ? "No Images" : "कोई चित्र नहीं"}
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="regular-view-images-div">
                    {item?.adharImg?.length > 0 ? (
                      Array.isArray(item.adharImg) &&
                      item.adharImg.map((path, index) => (
                        <img
                          key={index}
                          src={`/${path.replace("public/", "")}`}
                          alt=""
                        />
                      ))
                    ) : (
                      <div className="text-center w-100 p-4">
                        {language === "hindi" ? "No Images" : "कोई चित्र नहीं"}
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <div className="society-view-details-main-div">
                    <div className="society-view-details-top-heading">
                      <div className="top-society-images-heading-icon-div">
                        <FaUserCheck /> &nbsp;{" "}
                        {language === "hindi"
                          ? "Entry Submitted By"
                          : "प्रवेश द्वारा प्रस्तुत"}
                      </div>
                    </div>
                    <div className="main_content_div">
                      <div className="content2_left_div">
                        <h3 className="house_title_left">
                          <FaUser />
                          &nbsp; {language === "hindi" ? "Name" : "नाम"}
                        </h3>
                        <h3 className="house_title_left">
                          <MdPhone />
                          &nbsp;{" "}
                          {language === "hindi" ? "PhoneNo" : "फ़ोन नंबर "}
                        </h3>
                        <h3 className="house_title_left">
                          <MdEmail />
                          &nbsp; {language === "hindi" ? "Email" : "ईमेल"}
                        </h3>
                        <h3 className="house_title_left">
                          <MdGroup />
                          &nbsp; {language === "hindi" ? "Role" : "भूमिका"}
                        </h3>
                      </div>
                      <div className="content2_right_div">
                        <React.Fragment key={userData.id}>
                          <h3 className="house_title_left">
                            {userData.name ? userData?.name : "Not Added"}
                          </h3>
                          <h3 className="house_title_left">
                            {userData.userPhoneNo}
                          </h3>
                          <h3 className="house_title_left">
                            {" "}
                            {userData.username}
                          </h3>
                          <h3 className="house_title_left"> {userData.role}</h3>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <div className="regular-view-images-div">
                    {item?.vehicleImg?.length > 0 ? (
                      Array.isArray(item.vehicleImg) &&
                      item.vehicleImg.map((path, index) => (
                        <img
                          key={index}
                          src={`/${path.replace("public/", "")}`}
                          alt=""
                        />
                      ))
                    ) : (
                      <div className="text-center w-100 p-4">
                        {language === "hindi" ? "No Images" : "कोई चित्र नहीं"}
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
              </Box>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default PreviewImage;
