import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import NotificationsIcon from '@mui/icons-material/Notifications';

function NotificationSidePane({ isOpen, onClose, notifications, onNotificationClick }) {
  const getNotificationIcon = (type) => {
    switch (type) {
      case 'job':
        return <WorkIcon />;
      case 'connection':
        return <PersonAddIcon />;
      case 'engagement':
        return <TrendingUpIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { width: '320px' },
      }}
    >
      <div style={{ padding: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Notifications</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </div>
      <List>
        {notifications?.map((notification) => (
          <ListItem
            key={notification._id}
            onClick={() => onNotificationClick(notification)}
            button
            style={{ backgroundColor: notification.isRead ? 'transparent' : 'rgba(0, 119, 181, 0.1)' }}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: notification.isRead ? '#bdbdbd' : '#0077B5' }}>
                {getNotificationIcon(notification.type)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={notification.title}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textPrimary">
                    {notification.content}
                  </Typography>
                  <br />
                  <Typography component="span" variant="caption" color="textSecondary">
                    {new Date(notification.createdAt).toLocaleString()}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default NotificationSidePane;

