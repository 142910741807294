import React, { useEffect, useState, useContext, useMemo } from "react";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { PORT } from "../../../Api/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../../../Navbar/Navbar";
import GuardLayout from "../../../lib/GuardLayout";
import { LanguageContext } from "../../../lib/LanguageContext";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import { Hourglass } from "react-loader-spinner";
import "./style.css";
import { RiUserAddLine } from "react-icons/ri";
import { getFcmToken } from "../../../firebase/getFCMToken/getToken";
import ButtonLoader from "../../../Loading/ButtonLoader";
import { decodeText, encodeText } from "../../../lib/DecoderEncoderText";
import decodeToken from "../../../affiliate/affiliatePanel/affiliateLib/decodeToken/DecodeToken";
function CorporateLogin() {
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorForLogin, setErrorForLogin] = useState();
  const { language } = useContext(LanguageContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const images = ["/societyimage.jpg", "/society2.jpg", "/society.jpg"];
  const [currentImage, setCurrentImage] = useState(images[0]); // Default image
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email:
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("email")
        : "",
    password:
      localStorage.getItem("rememberMe") === "true"
        ? decodeText(localStorage.getItem("password")) ||
          localStorage.getItem("password") //21/01/2025
        : "",
  };

  useEffect(() => {
    if (localStorage.getItem("rememberMe") === "true") {
      setRememberMe(true);
    }
  }, []);

  const handleDismissToast = () => {
    setShowToast(false);
  };

  const handleToast = () => {
    if (showToast) {
      toast.error(
        `${
          language === "hindi"
            ? "Unauthorized: Invalid Credentials"
            : "अनधिकृत: अमान्य उपयोगकर्ता नाम या पासवर्ड"
        }`,
        { onClose: handleDismissToast }
      );
    }
  };

  useEffect(() => {
    handleToast();
  }, [showToast, language]);
  const validationSchema = (language) =>
    Yup.object().shape({
      email: Yup.string()
        .required(
          language === "hindi"
            ? "Email is required"
            : "ईमेल या फोन नंबर आवश्यक है"
        )
        .test(
          "is-valid-contact",
          language === "hindi"
            ? "Please enter a valid email"
            : "कृपया एक मान्य ईमेल या मोबाइल नंबर दर्ज करें।",
          (value) => {
            const trimmedValue = value ? value.trim() : "";
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const phonePattern = /^\d{10}$/;
            if (!trimmedValue) return false;

            return (
              emailPattern.test(trimmedValue) || phonePattern.test(trimmedValue)
            );
          }
        ),
      password: Yup.string()
        .min(
          6,
          language === "hindi"
            ? "Password must be at least 6 characters"
            : "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए"
        )
        .max(
          25,
          language === "hindi"
            ? "Password must be of maximum 25 characters"
            : "पासवर्ड अधिकतम 25 अक्षरों का होना चाहिए"
        )
        .required(
          language === "hindi" ? "Password is required" : "पासवर्ड आवश्यक है"
        ),
    });
  //FETCH CORPORATE
  const fetchCorporateWithId = async (id) => {
    try {
      const response = await axios.get(`${PORT}/getCorporateById/${id}`);
      // Explicitly return the response object, including the status
      return {
        status: response.status,
        data: response.data,
      };
    } catch (error) {
      console.error("Error fetching corporate details:", error);

      // Handle errors and return a custom status
      return {
        status: error.response?.status || 500, // Use the error response status if available, or default to 500
        data: error.response?.data || "Error fetching corporate details",
      };
    }
  };
  //FETCH ROLE
  const fetchCorporateRoleById = async (id) => {
    alert("ok");
    try {
      const response = await axios.get(`${PORT}/getCorporateRoleById/${id}`);
      console.log(response, "______--RESPONSE--_____");
      return {
        status: response.status,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const response = await axios.post(`${PORT}/corporateLogin`, {
        username: values.email.trim(),
        password: values.password,
      });
      const { token } = response.data;
      const tokenData = decodeToken(token);
      if (response.status === 200) {
        //OWNER
        if (tokenData.data.level === "1") {
          if (rememberMe) {
            const pass = encodeText(values.password); //21/01/2025
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", pass || values.password);
            localStorage.setItem("rememberMe", "true");
            localStorage.setItem("corporateToken", token);
            localStorage.setItem("ADMINLevel", "2");
            localStorage.setItem("GUARDLevel", "3");
            navigate("/corporate/corporateDashboard");
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("rememberMe");
            localStorage.setItem("corporateToken", token);
            localStorage.setItem("ADMINLevel", "2");
            localStorage.setItem("GUARDLevel", "3");
            setIsAuthenticated(true);
            navigate("/corporate/corporateDashboard");
          }
        }
        //OWNERADMIN
        if (tokenData.data.level === "2") {
          const response = await fetchCorporateWithId(
            tokenData.data.corporate_id
          );
          if (tokenData.roleData !== null) {
            if (response.status === 200) {
              // if (tokenData.roleData !== null) {
              if (rememberMe) {
                const pass = encodeText(values.password); //21/01/2025
                localStorage.setItem("email", values.email);
                localStorage.setItem("password", pass || values.password);
                localStorage.setItem("rememberMe", "true");
                localStorage.setItem("corporateToken", token);
                localStorage.setItem("ADMINLevel", "2");
                localStorage.setItem("GUARDLevel", "3");
                navigate("/corporate/corporateDashboard");
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("rememberMe");
                localStorage.setItem("corporateToken", token);
                localStorage.setItem("ADMINLevel", "2");
                localStorage.setItem("GUARDLevel", "3");
                setIsAuthenticated(true);
                navigate("/corporate/corporateDashboard");
              }
            }
            {
              toast.error("Your Owner does not exist");
            }
          } else {
            toast.error("You don't have  role");
          }
        }
        //OWNERGUARD
        if (tokenData.data.level === "3") {
          const response = await fetchCorporateWithId(
            tokenData.data.corporate_id
          );
          if (tokenData.roleData !== null) {
            if (response.status === 200) {
              if (rememberMe) {
                const pass = encodeText(values.password); //21/01/2025
                localStorage.setItem("email", values.email);
                localStorage.setItem("password", pass || values.password);
                localStorage.setItem("rememberMe", "true");
                localStorage.setItem("corporateGuardToken", token);

                navigate("/corporateGuardPurpose");
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("rememberMe");
                localStorage.setItem("corporateGuardToken", token);
                setIsAuthenticated(true);
                navigate("/corporateGuardPurpose");
              }
            }
            {
              toast.error("Your Owner does not exist");
            }
          } else {
            toast.error("You don't have  role");
          }
        }

        //COMPANY
        if (tokenData.data.level === "4") {
          if (rememberMe) {
            const pass = encodeText(values.password); //21/01/2025
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", pass || values.password);
            localStorage.setItem("rememberMe", "true");
            localStorage.setItem("corporateToken", token);
            localStorage.setItem("ADMINLevel", "5");
            localStorage.setItem("GUARDLevel", "6");
            navigate("/corporate/corporateDashboard");
          } else {
            localStorage.removeItem("email");
            localStorage.removeItem("password");
            localStorage.removeItem("rememberMe");
            localStorage.setItem("ADMINLevel", "5");
            localStorage.setItem("GUARDLevel", "6");
            localStorage.setItem("corporateToken", token);
            setIsAuthenticated(true);
            navigate("/corporate/corporateDashboard");
          }
        }
        //COMPANYADMIN
        if (tokenData.data.level === "5") {
          const response = await fetchCorporateWithId(
            tokenData.data.corporate_id
          );
          if (response.status === 200) {
            if (tokenData.roleData !== null) {
              if (rememberMe) {
                const pass = encodeText(values.password); //21/01/2025
                localStorage.setItem("email", values.email);
                localStorage.setItem("password", pass || values.password);
                localStorage.setItem("rememberMe", "true");
                localStorage.setItem("corporateToken", token);
                localStorage.setItem("ADMINLevel", "5");
                localStorage.setItem("GUARDLevel", "6");
                navigate("/corporate/corporateDashboard");
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("rememberMe");
                localStorage.setItem("corporateToken", token);
                localStorage.setItem("ADMINLevel", "5");
                localStorage.setItem("GUARDLevel", "6");
                setIsAuthenticated(true);
                navigate("/corporate/corporateDashboard");
              }
            } else {
              toast.error("You don't have  role");
            }
          } else {
            toast.error("Your Company does not exist");
          }
        }
        //COMPANYGUARD
        if (tokenData.data.level === "6") {
          const response = await fetchCorporateWithId(
            tokenData.data.corporate_id
          );
          if (response.status === 200) {
            if (tokenData.roleData !== null) {
              if (rememberMe) {
                const pass = encodeText(values.password); //21/01/2025
                localStorage.setItem("email", values.email);
                localStorage.setItem("password", pass || values.password);
                localStorage.setItem("rememberMe", "true");
                localStorage.setItem("corporateGuardToken", token);

                navigate("/corporateGuardPurpose");
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("rememberMe");
                localStorage.setItem("corporateGuardToken", token);
                setIsAuthenticated(true);
                navigate("/corporateGuardPurpose");
              }
            } else {
              toast.error("You don't have  role");
            }
          } else {
            toast.error("Your Company does not exist");
          }
        }
      }
      setSubmitting(false);
    } catch (error) {
      setErrorForLogin(error.response.data.msg);
      setShowToast(true);
      setSubmitting(false);
      console.log(error);
    }
  };

  const useRevalidateOnLanguageChange = () => {
    const { validateForm } = useFormikContext();
    useEffect(() => {
      validateForm();
    }, [language, validateForm]);
  };

  const RevalidateOnLanguageChange = () => {
    useRevalidateOnLanguageChange();
    return null;
  };
  const handleRegister = () => {
    navigate("/affiliateRegister");
  };
  //Affiliate Token is already Exist
  useEffect(() => {
    localStorage.removeItem("forgotEmail");
    const isAuthenticated = localStorage.getItem("corporateToken");
    if (isAuthenticated || location.pathname === "/corporate") {
      navigate("/corporate/corporateDashboard");
    }
  }, [navigate, location.pathname]);

  const handleForgetPass = (emailValue) => {
    // Check if email is empty
    if (!emailValue) {
      toast.error(
        `${
          language === "hindi"
            ? "Please enter your email address"
            : "कृपया ईमेल पता दर्ज करें"
        }`,
        { onClose: handleDismissToast }
      );
      return;
    }

    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate the email format
    if (!emailRegex.test(emailValue)) {
      toast.error(
        `${language === "hindi" ? "Invalid email address" : "अमान्य ईमेल पता"}`,
        { onClose: handleDismissToast }
      );
      return;
    }
    localStorage.setItem("forgotEmail", emailValue);
    // Na vigate to forgot password page if validation passes
    navigate("/forgotPassword", {
      state: { affiliateUser: false, societyUser: false, corporateUser: true },
    });
  };
  //background image update
  useEffect(() => {
    if (images.length === 0) return;

    const interval = setInterval(() => {
      setCurrentImage((prevImage) => {
        const currentIndex = images.indexOf(prevImage);
        const nextIndex = (currentIndex + 1) % images.length;
        return images[nextIndex];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [images]);
  return (
    <div
      className="background_image"
      style={{
        backgroundImage: `url(${currentImage})`,
      }}
    >
      <GuardLayout>
        <Navbar />
        <div className="main-form-affiliate">
          <div
            className="form-container-affiliate-login"
            style={{ height: "auto", minHeight: "400px" }}
          >
            <h2 className="affiliate-login_title">
              {language === "english" ? "कॉर्पोरेट लॉगिन" : "CORPORATE LOGIN"}
            </h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(language)}
              onSubmit={handleSubmit}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <div className="form-field">
                    <label className="label_title" htmlFor="email">
                      {language === "english" ? "ईमेल पता" : "Email address"}
                    </label>
                    <Field
                      type="text"
                      name="email"
                      maxLength="50"
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/\s+/g, "");
                      }}
                    />
                    <div className="error_login">
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <label className="label_title" htmlFor="password">
                      {language === "english" ? "पासवर्ड" : "Password"}
                    </label>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      maxLength="25"
                    />
                    <div className="error_login">
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {/* Forget Password */}
                    <div
                      className="d-flex justify-content-end"
                      onClick={() => handleForgetPass(values.email)}
                    >
                      <span style={{ cursor: "pointer" }}>
                        {" "}
                        {language === "english"
                          ? "पासवर्ड भूल गए"
                          : " Forget Password"}
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="submit-button"
                  >
                    {isSubmitting ? (
                      <ButtonLoader color="green" />
                    ) : language === "english" ? (
                      "सबमिट"
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <RevalidateOnLanguageChange />
                </Form>
              )}
            </Formik>
            <ToastContainer />
            <div className="togglePasswordVisibilityLoginPageMainDiv">
              <div
                className="togglePasswordVisibilityLoginPage"
                onClick={togglePasswordVisibility}
                style={{ marginTop: "-25px" }}
              >
                {showPassword ? (
                  <IoIosEye className="eyebuttonLogin" />
                ) : (
                  <IoIosEyeOff className="eyebuttonLogin" />
                )}
              </div>
            </div>
            <div className="affiliate-bottom-div">
              {/* <label
                className="affiliate-register-div"
                onClick={handleRegister}
              >
                <RiUserAddLine />
                {language === "english" ? "साइन अप करें" : " Sign Up"}
              </label> */}

              <div className="affiliate-rememberMe-div">
                <label>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  {language === "english" ? "मुझे याद रखें" : " Remember Me"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </GuardLayout>
    </div>
  );
}

export default CorporateLogin;
